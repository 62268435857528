import {
  Button,
  IconButton,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core"
import {
  CheckCircle,
  Edit,
  FormatListNumbered,
  LocalParkingSharp,
  Loyalty,
  TextFieldsRounded,
} from "@material-ui/icons"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import DeleteIcon from "@material-ui/icons/Delete"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { isXiraApp } from "../../../../../../../../../../../config"
import { apxTheme } from "../../../../../../../../../../ApplicationFrame/styling/CustomCSS"
import {
  StyledMenu,
  StyledMenuButton,
} from "../../../../../../../../../../Organizations/components/OrgCard"
import HStack from "../../../../../../../../../../ReusableComponents/HStack"
import ThemedTooltip from "../../../../../../../../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../../../../../../../../ReusableComponents/VStack"
import { useTrackedState as useCampaignCreatorState } from "../../../../../../../../../common/components/campaigncreator/store"
import {
  MEDIA_TYPES_ENUM,
  MODAL_NEW_BUTTONS_ROW_NAMES,
  MODULE_TYPES,
  SET_BUTTONS,
  SET_BUTTONS_SLOTS_COUNT,
  SET_BUTTONS_SLOTS_UPDATE_MODE,
  SET_BUTTON_PLACEMENT_MODE,
  SET_DESCRIPTION_ENABLED,
  SET_SHOW_EDIT_SIMULATOR_BUTTON,
  SET_TITLE_ENABLED,
} from "../../../../../constants"
import { useTracked, useTrackedState } from "../../../../../store"
import {
  BORDER_CONFIG,
  CLOSE_CONFIG,
  DESCRIPTION,
  DIRECTION_CONFIG,
  MEDIA_ICON_CONFIG,
  MEDIA_IMAGE_CONFIG,
  MEDIA_VIDEO_CONFIG,
  PADDING,
  SCROLL_CONFIG,
  SHADOW_CONFIG,
  TITLE,
  TITLE_ICON,
} from "../../../../../templates/common"
import {
  CloseButtonHolder,
  DescriptionHolder,
  HoverDiv,
  NewButtonsHolder,
  TitleHolder,
} from "../../../utils"

function CloseButton({
  moduleType,
  onModuleSelection,
  close_btn_shape,
  close_btn_color,
  close_btn_fill_color,
  close_btn_type,
  close_btn_height,
  close_btn_width,
  close_btn_offset_top,
  close_btn_offset_right,
}) {
  return (
    <CloseButtonHolder
      parentStyles={{
        position: "absolute",
        top: `${+close_btn_offset_top}px`,
        right: `${+close_btn_offset_right}px`,
        height: close_btn_height,
        width: close_btn_width,
        padding: 0,
        zIndex: 1,
      }}
      config={{
        color: close_btn_color,
        backgroundColor: close_btn_fill_color,
        shape: close_btn_shape,
      }}
      hasBg={close_btn_type === "filled"}
      moduleType={moduleType}
      onModuleSelection={(e, name) => {
        e.stopPropagation()
        onModuleSelection(e, name)
      }}
    >
      <CloseIcon
        style={{
          color: close_btn_color,
          marginTop: "5px",
          height: close_btn_height,
          width: close_btn_width,
        }}
      />
    </CloseButtonHolder>
  )
}

function Title({
  moduleType,
  ui,
  onModuleSelection,
  enable_title,
  title,
  title_icon_path,
}) {
  const [hovered, setHovered] = useState(false)
  const [{ currentStep }, dispatch] = useTracked()

  const { margin, padding } = ui.title

  return (
    enable_title && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
          padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
          justifyContent:
            title.alignment === "left"
              ? "start"
              : title.alignment === "right"
                ? "end"
                : "center",
          textAlign: title.alignment,
          width: "100%",
        }}
      >
        {/* Title Icon */}
        {title.enable_icon && (
          <HoverDiv
            style={{
              border:
                moduleType === MODULE_TYPES.TITLE_ICON
                  ? "1px dashed #2979ff"
                  : "",
              marginBottom: "-5px",
              marginRight: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation()
              onModuleSelection(e, MODULE_TYPES.TITLE_ICON)
            }}
          >
            {title_icon_path === "" ? (
              <CheckCircle
                style={{
                  color: "#000",
                  width: title.icon.width ?? TITLE_ICON.icon.width,
                  height: title.icon.height ?? TITLE_ICON.icon.height,
                  borderStyle:
                    title.icon.border.style ?? TITLE_ICON.icon.border.style,
                  borderWidth: title.icon.enable_border
                    ? (title.icon.border.width ?? TITLE_ICON.icon.border.width)
                    : 0,
                  borderColor: title.icon.enable_border
                    ? (title.icon.border.color ?? TITLE_ICON.icon.border.color)
                    : "transparent",
                  borderRadius: title.icon.enable_border
                    ? `${
                        title.icon.border.radius ??
                        TITLE_ICON.icon.border.radius
                      }%`
                    : 0,
                }}
              />
            ) : (
              <img
                src={title_icon_path}
                alt="icon"
                style={{
                  color: "#000",
                  width: title.icon.width ?? TITLE_ICON.icon.width,
                  height: title.icon.height ?? TITLE_ICON.icon.height,
                  borderStyle:
                    title.icon.border.style ?? TITLE_ICON.icon.border.style,
                  borderWidth: title.icon.enable_border
                    ? (title.icon.border.width ?? TITLE_ICON.icon.border.width)
                    : 0,
                  borderColor: title.icon.enable_border
                    ? (title.icon.border.color ?? TITLE_ICON.icon.border.color)
                    : "transparent",
                  borderRadius: title.icon.enable_border
                    ? `${
                        title.icon.border.radius ??
                        TITLE_ICON.icon.border.radius
                      }%`
                    : 0,
                }}
              />
            )}
          </HoverDiv>
        )}

        {/* Title Text */}
        <div
          style={{
            position: "relative",
          }}
          onMouseEnter={() => {
            setHovered(true)
          }}
          onMouseLeave={() => {
            setHovered(false)
          }}
        >
          {/* Delete Icon */}
          {hovered && (
            <ThemedTooltip
              title="Delete Title"
              dark
            >
              <IconButton
                size="small"
                style={{
                  backgroundColor: apxTheme.palette.action.focus,
                  position: "absolute",
                  top: -10,
                  left: -12,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch({
                    type: SET_TITLE_ENABLED,
                    currentStep,
                    value: false,
                  })
                  onModuleSelection(null, MODULE_TYPES.NOTHING)
                }}
              >
                <DeleteIcon
                  style={{
                    color: "#F12D2D",
                    width: 15,
                    height: 15,
                  }}
                />
              </IconButton>
            </ThemedTooltip>
          )}
          <TitleHolder
            id="title"
            handleModuleSelection={(e) => {
              e.stopPropagation()
              onModuleSelection(e, MODULE_TYPES.TITLE_TEXT)
            }}
            selected={moduleType === MODULE_TYPES.TITLE_TEXT}
            bgColor={ui.bg_color}
            title={title}
          />
        </div>
      </div>
    )
  )
}

function Description({
  enable_description,
  description,
  moduleType,
  onModuleSelection,
  ui,
  enable_scroll,
  scroll_text_height,
  scroll_border_enabled,
  scroll_border_width,
  scroll_border_color,
  scroll_border_style,
}) {
  const [hovered, setHovered] = useState(false)
  const [{ currentStep }, dispatch] = useTracked()
  const { margin, padding } = ui.description

  return (
    enable_description && (
      <div
        onMouseEnter={() => {
          setHovered(true)
        }}
        onMouseLeave={() => {
          setHovered(false)
        }}
        style={{
          position: "relative",
          borderTop:
            enable_scroll && scroll_border_enabled
              ? `${scroll_border_width}px ${scroll_border_style} ${scroll_border_color}`
              : "none",
          borderBottom:
            enable_scroll && scroll_border_enabled
              ? `${scroll_border_width}px ${scroll_border_style} ${scroll_border_color}`
              : "none",
          borderRadius: 0,
        }}
      >
        {hovered && (
          <ThemedTooltip
            title="Delete Description"
            dark
          >
            <IconButton
              size="small"
              style={{
                backgroundColor: apxTheme.palette.action.focus,
                position: "absolute",
                top: -10,
                left: -12,
                zIndex: 10,
              }}
              onClick={(e) => {
                e.stopPropagation()
                dispatch({
                  type: SET_DESCRIPTION_ENABLED,
                  currentStep,
                  value: false,
                })
                onModuleSelection(null, MODULE_TYPES.NOTHING)
              }}
            >
              <DeleteIcon
                style={{
                  color: "#F12D2D",
                  width: 15,
                  height: 15,
                }}
              />
            </IconButton>
          </ThemedTooltip>
        )}

        <div
          style={{
            overflowY: enable_scroll ? "overlay" : "hidden",
            position: "relative",
            height: "auto",
            padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
            margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
            ...(enable_scroll ? { maxHeight: `${+scroll_text_height}px` } : {}),
          }}
        >
          <DescriptionHolder
            id="description"
            handleModuleSelection={(e) => {
              e.stopPropagation()
              onModuleSelection(e, MODULE_TYPES.DESCRIPTION_TEXT)
            }}
            selected={moduleType === MODULE_TYPES.DESCRIPTION_TEXT}
            bgColor={ui.bg_color}
            description={description}
            style={{
              marginBottom: "0px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          />
        </div>
      </div>
    )
  )
}

export const convertToPaddingOrMargin = (val, isMargin = false) => {
  if (!val) {
    return "6px"
  }

  val = Object.values({
    top: val.top,
    right: val.right,
    bottom: val.bottom,
    left: val.left,
  })

  let finalVal = ""
  val.forEach((dir, idx) => {
    finalVal += `${+dir}px${idx === val.length - 1 ? "" : " "}`
  })

  return finalVal
}

function BottomButtons({
  buttons,
  moduleType,
  onModuleSelection,
  ui,
  enable_button_placement_mode,
  enable_buttons_slots_update_mode,
  buttons_count,
  dispatch,
  currentStep,
  width,
  isExperiment = false,
}) {
  return (
    <div>
      {enable_buttons_slots_update_mode ? (
        <RadioGroup
          id="slots_arena"
          name="slots"
          value={buttons_count}
          defaultValue={buttons_count}
          onChange={(e) => {
            e.stopPropagation()
            dispatch({
              type: SET_BUTTONS_SLOTS_COUNT,
              isExperiment,
              currentStep,
              value: +e.target.value,
            })
          }}
          style={{
            gap: apxTheme.spacing(1),
          }}
        >
          {[5, 6].map((num) => (
            <HStack justify="start">
              <Radio
                size="small"
                color="primary"
                value={num}
                checked={buttons_count === num}
                onChange={(e) => {
                  e.stopPropagation()
                  dispatch({
                    type: SET_BUTTONS_SLOTS_COUNT,
                    isExperiment,
                    currentStep,
                    value: +e.target.value,
                  })
                }}
                style={{
                  padding: "2px",
                }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
              <NewButtonsHolder
                id="buttons_holder"
                style={{
                  textAlign: "center",
                  padding: "6px",
                }}
                onModuleSelection={(e, name) => {
                  e.stopPropagation()
                  onModuleSelection(
                    e,
                    name,
                    "",
                    MODAL_NEW_BUTTONS_ROW_NAMES.bottom,
                  )
                }}
                moduleType={moduleType}
                bgColor={ui.bg_color}
                config={buttons[MODAL_NEW_BUTTONS_ROW_NAMES.bottom]}
                row_name={MODAL_NEW_BUTTONS_ROW_NAMES.bottom}
                rowEmpty={
                  buttons[MODAL_NEW_BUTTONS_ROW_NAMES.bottom].length === 0
                }
                totalButtons={num}
                buttonsCount={buttons_count}
                enable_button_placement_mode={enable_button_placement_mode}
                enable_buttons_slots_update_mode={
                  enable_buttons_slots_update_mode
                }
                size={width}
                isBottomButtons={true}
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch({
                    type: SET_BUTTONS_SLOTS_COUNT,
                    isExperiment,
                    currentStep,
                    value: num,
                  })
                }}
              />
            </HStack>
          ))}
        </RadioGroup>
      ) : (
        <NewButtonsHolder
          id="buttons_holder"
          style={{
            textAlign: "center",
          }}
          onModuleSelection={(e, name) => {
            e.stopPropagation()
            onModuleSelection(e, name, "", MODAL_NEW_BUTTONS_ROW_NAMES.bottom)
          }}
          moduleType={moduleType}
          bgColor={ui.bg_color}
          config={buttons[MODAL_NEW_BUTTONS_ROW_NAMES.bottom]}
          row_name={MODAL_NEW_BUTTONS_ROW_NAMES.bottom}
          rowEmpty={buttons[MODAL_NEW_BUTTONS_ROW_NAMES.bottom].length === 0}
          totalButtons={buttons_count}
          enable_button_placement_mode={enable_button_placement_mode}
          isBottomButtons={true}
          size={width}
        />
      )}
    </div>
  )
}

function NonMediaContent({
  inapp_direction,
  inapp_nonmedia_direction,
  moduleType,
  onModuleSelection,
  enable_title = TITLE.enable_title,
  title = TITLE.title,
  enable_description = DESCRIPTION.enable_description,
  description = DESCRIPTION.description,
  buttons,
  ui,
  currentStep,
  dispatch,
  enable_button_placement_mode,
  enable_buttons_slots_update_mode,
  buttons_count,
  width,
  title_icon_path,
  enable_scroll,
  scroll_text_height,
  scroll_border_enabled,
  scroll_border_width,
  scroll_border_color,
  scroll_border_style,
  isExperiment = false,
}) {
  return (
    <VStack
      justify={inapp_direction === "horizontal" ? "center" : "start"}
      style={{
        margin: 8,
      }}
    >
      {/* Top Buttons (Horizontal Only) */}
      {inapp_direction === "horizontal" && (
        <>
          {(buttons.top.length !== 0 || enable_button_placement_mode) && (
            <NewButtonsHolder
              id="buttons_holder"
              style={{
                textAlign: "center",
                padding: "6px",
              }}
              onModuleSelection={(e, name) => {
                e.stopPropagation()
                onModuleSelection(e, name, "", MODAL_NEW_BUTTONS_ROW_NAMES.top)
              }}
              moduleType={moduleType}
              bgColor={ui.bg_color}
              config={buttons.top}
              buttonHolderStyle={{
                display: "flex",
                gap: "0px",
                justifyContent: "space-between",
              }}
              totalButtons={3}
              emptySlots={[1, 2, 3]}
              row_name={MODAL_NEW_BUTTONS_ROW_NAMES.top}
              rowEmpty={buttons.top.length === 0}
              enable_button_placement_mode={enable_button_placement_mode}
              size={width}
            />
          )}
        </>
      )}

      <div
        style={{
          display: "flex",
          flexDirection:
            inapp_nonmedia_direction === "horizontal" ? "row" : "column",
          gap: "16px",
          alignItems: inapp_nonmedia_direction === "horizontal" ? "center" : "",
          width: "100%",
        }}
      >
        {/* Title and Description */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            flex: 1,
          }}
        >
          {/* Title */}
          <Title
            moduleType={moduleType}
            onModuleSelection={onModuleSelection}
            enable_title={enable_title}
            title_icon_path={title_icon_path}
            title={title}
            ui={ui}
          />

          {/* Description */}
          <Description
            moduleType={moduleType}
            onModuleSelection={onModuleSelection}
            enable_description={enable_description}
            description={description}
            ui={ui}
            inapp_nonmedia_direction={inapp_nonmedia_direction}
            enable_scroll={enable_scroll}
            scroll_text_height={scroll_text_height}
            scroll_border_enabled={scroll_border_enabled}
            scroll_border_width={scroll_border_width}
            scroll_border_color={scroll_border_color}
            scroll_border_style={scroll_border_style}
          />
        </div>

        {/* Bottom Buttons */}
        <BottomButtons
          moduleType={moduleType}
          onModuleSelection={onModuleSelection}
          buttons={buttons}
          inapp_nonmedia_direction={inapp_nonmedia_direction}
          ui={ui}
          enable_button_placement_mode={enable_button_placement_mode}
          enable_buttons_slots_update_mode={enable_buttons_slots_update_mode}
          buttons_count={buttons_count}
          dispatch={dispatch}
          currentStep={currentStep}
          width={width}
          isExperiment={isExperiment}
        />
      </div>
    </VStack>
  )
}

export const AnimatedButton = withStyles({
  "@keyframes pulse": {
    "50%": {
      opacity: "0.5",
    },
  },
  root: {
    animation: "$pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    borderWidth: 1.5,
    borderColor: apxTheme.palette.primary.main,
    borderStyle: "dashed",
    "&:hover": {
      borderStyle: "double",
    },
  },
})(Button)

export function PlaceHolderButton({
  num,
  buttonsCount,
  row_name,
  isExperiment = false,
}) {
  const [
    {
      currentStep,
      enable_button_placement_mode,
      enable_buttons_slots_update_mode,
    },
    dispatch,
  ] = useTracked()

  return (
    <ThemedTooltip title="Click here to place the new button">
      <AnimatedButton
        size={
          enable_button_placement_mode | enable_buttons_slots_update_mode
            ? "small"
            : "medium"
        }
        id="placeholder_button"
        variant="outlined"
        color="primary"
        onClick={(e) => {
          dispatch({
            type: SET_BUTTONS,
            currentStep,
            count: buttonsCount,
            key: "buttons",
            isActionButtons: false,
            row_name,
            slot: num,
            operation: "add",
            isExperiment,
          })
          dispatch({
            type: SET_BUTTON_PLACEMENT_MODE,
            currentStep,
            value: false,
          })
          e.stopPropagation()
        }}
      >
        Slot {num}
      </AnimatedButton>
    </ThemedTooltip>
  )
}

export default function ModalSimulator({ moduleType, ui, onModuleSelection }) {
  const { showEditSimulatorButton } = useTrackedState()
  const {
    config: {
      meta: { isExperiment = false },
    },
  } = useCampaignCreatorState()

  const {
    // Padding Config
    padding: {
      top: padding_top,
      bottom: padding_bottom,
      left: padding_left,
      right: padding_right,
    } = PADDING,

    // Border Config
    enable_border = BORDER_CONFIG.enable_border,
    border: {
      radius: border_radius = BORDER_CONFIG.border.radius,
      color: border_color = BORDER_CONFIG.border.color,
      width: border_width = BORDER_CONFIG.border.width,
      style: border_style = BORDER_CONFIG.border.style,
    } = BORDER_CONFIG.border,

    // Shadow Config
    enable_shadow = SHADOW_CONFIG.enable_shadow,
    shadow: {
      color: shadow_color = SHADOW_CONFIG.shadow.color,
      offset_x: shadow_offset_x = SHADOW_CONFIG.shadow.offset_x,
      offset_y: shadow_offset_y = SHADOW_CONFIG.shadow.offset_y,
      blur_radius: shadow_blur = SHADOW_CONFIG.shadow.blur_radius,
      spread_radius: shadow_spread = SHADOW_CONFIG.shadow.spread_radius,
    },

    direction: {
      nudge: inapp_direction = DIRECTION_CONFIG.direction.nudge,
      non_media: inapp_nonmedia_direction = DIRECTION_CONFIG.direction
        .non_media,
    } = DIRECTION_CONFIG.direction,

    // Media Icon
    enable_icon = MEDIA_ICON_CONFIG.enable_icon,
    icon: {
      alignment: icon_alignment = MEDIA_ICON_CONFIG.icon.alignment,
      width: icon_width = MEDIA_ICON_CONFIG.icon.width,
      height: icon_height = MEDIA_ICON_CONFIG.icon.height,
      enable_border: icon_border_enabled = MEDIA_ICON_CONFIG.icon.enable_border,
      border: {
        radius: icon_border_radius = MEDIA_ICON_CONFIG.icon.border.radius,
        width: icon_border_width = MEDIA_ICON_CONFIG.icon.border.width,
        color: icon_border_color = MEDIA_ICON_CONFIG.icon.border.color,
        style: icon_border_style = MEDIA_ICON_CONFIG.icon.border.style,
      } = MEDIA_ICON_CONFIG.icon.border,
      path: icon_path,
    } = MEDIA_ICON_CONFIG.icon,

    // Media Image
    enable_image = MEDIA_IMAGE_CONFIG.enable_image,
    image: {
      path: image_path = MEDIA_IMAGE_CONFIG.image.path,
      width: image_width = MEDIA_IMAGE_CONFIG.image.width,
      height: image_height = MEDIA_IMAGE_CONFIG.image.height,
      enable_border: image_border_enabled = MEDIA_IMAGE_CONFIG.image
        .enable_border,
      border: {
        radius: image_border_radius = MEDIA_IMAGE_CONFIG.image.border.radius,
        width: image_border_width = MEDIA_IMAGE_CONFIG.image.border.width,
        color: image_border_color = MEDIA_IMAGE_CONFIG.image.border.color,
        style: image_border_style = MEDIA_IMAGE_CONFIG.image.border.style,
      } = MEDIA_IMAGE_CONFIG.image.border,
    } = MEDIA_IMAGE_CONFIG.image,

    // Media Video
    enable_video = MEDIA_VIDEO_CONFIG.enable_video,
    video: {
      enable_audio = MEDIA_VIDEO_CONFIG.video.enable_audio,
      path: video_path = MEDIA_VIDEO_CONFIG.video.path,
      width: video_width = MEDIA_VIDEO_CONFIG.video.width,
      height: video_height = MEDIA_VIDEO_CONFIG.video.height,
      enable_border: video_border_enabled = MEDIA_VIDEO_CONFIG.video
        .enable_border,
      border: {
        radius: video_border_radius = MEDIA_VIDEO_CONFIG.video.border.radius,
        width: video_border_width = MEDIA_VIDEO_CONFIG.video.border.width,
        color: video_border_color = MEDIA_VIDEO_CONFIG.video.border.color,
        style: video_border_style = MEDIA_VIDEO_CONFIG.video.border.style,
      } = MEDIA_VIDEO_CONFIG.video.border,

      enable_embed = MEDIA_VIDEO_CONFIG.video.enable_embed,
      embed = MEDIA_VIDEO_CONFIG.video.embed,
    } = MEDIA_VIDEO_CONFIG.video,

    // Close Icon
    enable_close_button = CLOSE_CONFIG.enable_close_button,
    close_button: {
      color: close_btn_color = CLOSE_CONFIG.close_button.color,
      fill_color: close_btn_fill_color = CLOSE_CONFIG.close_button.fill_color,
      type: close_btn_type = CLOSE_CONFIG.close_button.type,
      shape: close_btn_shape = CLOSE_CONFIG.close_button.shape,
      width: close_btn_width = CLOSE_CONFIG.close_button.width,
      height: close_btn_height = CLOSE_CONFIG.close_button.height,
      offset_right: close_btn_offset_right = CLOSE_CONFIG.close_button
        .offset_right,
      offset_top: close_btn_offset_top = CLOSE_CONFIG.close_button.offset_top,
    } = CLOSE_CONFIG.close_button,

    // Scroll Config
    enable_scroll = SCROLL_CONFIG.enable_scroll,
    scroll: {
      height: scroll_text_height = SCROLL_CONFIG.scroll.height,
      enable_border: scroll_border_enabled = SCROLL_CONFIG.scroll.enable_border,
      border: {
        width: scroll_border_width = SCROLL_CONFIG.scroll.border.width,
        color: scroll_border_color = SCROLL_CONFIG.scroll.border.color,
        style: scroll_border_style = SCROLL_CONFIG.scroll.border.style,
      } = SCROLL_CONFIG.scroll.border,
    } = SCROLL_CONFIG.scroll,

    // Title Config
    enable_title = TITLE.enable_title,
    title = TITLE.title,
    title_icon_path = title.icon.path,

    // Description Config
    enable_description = DESCRIPTION.enable_description,
    description = DESCRIPTION.enable_description,

    width,
    bg_color,
    buttons,
    buttons_count,
  } = ui

  const { enable_button_placement_mode, enable_buttons_slots_update_mode } =
    useTrackedState()

  const media_type = useMemo(() => {
    if (enable_icon && !enable_image && !enable_video) {
      return MEDIA_TYPES_ENUM.ICON
    } else if (enable_image && !enable_icon && !enable_video) {
      return MEDIA_TYPES_ENUM.IMAGE
    } else if (enable_video && !enable_icon && !enable_image) {
      return MEDIA_TYPES_ENUM.VIDEO
    } else {
      return MEDIA_TYPES_ENUM.NONE
    }
  }, [enable_icon, enable_image, enable_video])

  const [mousePosn, setMousePosn] = useState({
    mouseX: null,
    mouseY: null,
  })
  const [{ currentStep }, dispatch] = useTracked()

  useEffect(() => {
    /**
     * Set the `enable_button_placement_mode` to `false` if the user clicks outside the placeholder buttons.
     */
    const handleClickOutside = (e) => {
      if (
        (!e.target.closest("#placeholder_button") &&
          enable_button_placement_mode) ||
        (!e.target.closest("#slots_arena") && enable_buttons_slots_update_mode)
      ) {
        dispatch({
          type: SET_BUTTON_PLACEMENT_MODE,
          currentStep,
          value: false,
        })
        dispatch({
          type: SET_BUTTONS_SLOTS_UPDATE_MODE,
          currentStep,
          value: false,
        })
      }
    }
    document.addEventListener("mousedown", handleClickOutside)

    /**
     * Set the `enable_button_placement_mode` to `false` if the user presses the `Escape` key.
     */
    const handleEscape = (e) => {
      if (e.key === "Escape" && enable_button_placement_mode) {
        dispatch({
          type: SET_BUTTON_PLACEMENT_MODE,
          currentStep,
          value: false,
        })
      }
      if (e.key === "Escape" && enable_buttons_slots_update_mode) {
        dispatch({
          type: SET_BUTTONS_SLOTS_UPDATE_MODE,
          currentStep,
          value: false,
        })
      }
    }
    document.addEventListener("keydown", handleEscape)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleEscape)
    }
  }, [
    currentStep,
    dispatch,
    enable_button_placement_mode,
    enable_buttons_slots_update_mode,
  ])

  return (
    <>
      <VStack
        align="center"
        justify="center"
        style={{
          height: "auto",
          backgroundColor: bg_color ?? "#fff",
          position: "relative",
          borderWidth: enable_border ? border_width : 0,
          borderStyle: enable_border ? border_style : "none",
          borderColor: enable_border ? border_color : "none",
          borderRadius: enable_border ? border_radius : "0px",
          padding: `${padding_top}px ${padding_right}px ${padding_bottom}px ${padding_left}px`,
          cursor: !enable_button_placement_mode ? "context-menu" : "default",
          width: `${width}%`,
          boxShadow: enable_shadow
            ? `${shadow_offset_x}px ${shadow_offset_y}px ${shadow_blur}px ${shadow_spread}px ${shadow_color}`
            : "",
        }}
        onMouseEnter={() => {
          dispatch({
            type: SET_SHOW_EDIT_SIMULATOR_BUTTON,
            currentStep,
            value: true,
          })
        }}
        onMouseLeave={() => {
          dispatch({
            type: SET_SHOW_EDIT_SIMULATOR_BUTTON,
            currentStep,
            value: false,
          })
        }}
        onContextMenu={(e) => {
          if (!enable_button_placement_mode) {
            e.preventDefault()
            setMousePosn({
              mouseX: e.clientX - 2,
              mouseY: e.clientY - 4,
            })
          }
        }}
        onClick={(e) => {
          e.stopPropagation()
          onModuleSelection(e, MODULE_TYPES.BACKGROUND)
        }}
      >
        {/* Edit Right Click Button */}
        <IconButton
          style={{
            position: "absolute",
            top: showEditSimulatorButton ? -25 : 0,
            left: showEditSimulatorButton ? -25 : 0,
            opacity: showEditSimulatorButton ? 1 : 0,
            transition: "all 0.1s ease-in-out",
            backgroundColor: "#fff",
            border: `1px solid ${apxTheme.palette.action.focus}`,
            padding: 10,
          }}
          onClick={(e) => {
            e.stopPropagation()
            onModuleSelection(e, MODULE_TYPES.NOTHING)
            setMousePosn({
              mouseX: e.clientX + 10,
              mouseY: e.clientY + 10,
            })
          }}
        >
          <Edit
            style={{
              color: apxTheme.palette.primary.main,
              fontSize: 20,
            }}
          />
        </IconButton>

        {/* Close Icon */}
        {enable_close_button && (
          <CloseButton
            close_btn_fill_color={close_btn_fill_color}
            close_btn_color={close_btn_color}
            close_btn_type={close_btn_type}
            close_btn_shape={close_btn_shape}
            close_btn_height={close_btn_height}
            close_btn_width={close_btn_width}
            close_btn_offset_right={close_btn_offset_right}
            close_btn_offset_top={close_btn_offset_top}
            moduleType={moduleType}
            onModuleSelection={onModuleSelection}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
            width: "100%",
            height: "100%",
            borderRadius: border_radius,
          }}
        >
          {/* Top Buttons (Vertical Only) */}
          {inapp_direction === "vertical" && (
            <>
              {(buttons.top.length !== 0 || enable_button_placement_mode) && (
                <NewButtonsHolder
                  id="buttons_holder"
                  style={{
                    textAlign: "center",
                    padding: "0px",
                  }}
                  onModuleSelection={(e, name) => {
                    e.stopPropagation()
                    onModuleSelection(
                      e,
                      name,
                      "",
                      MODAL_NEW_BUTTONS_ROW_NAMES.top,
                    )
                  }}
                  moduleType={moduleType}
                  bgColor={ui.bg_color}
                  config={buttons.top}
                  buttonHolderStyle={{
                    display: "flex",
                    gap: "0px",
                    justifyContent: "space-between",
                  }}
                  totalButtons={3}
                  emptySlots={[1, 2, 3]}
                  row_name={MODAL_NEW_BUTTONS_ROW_NAMES.top}
                  rowEmpty={buttons.top.length === 0}
                  enable_button_placement_mode={enable_button_placement_mode}
                  size={width}
                />
              )}
            </>
          )}

          {/* Media, Center Buttons, Title and Description */}
          <div
            style={{
              display: "flex",
              flexDirection: inapp_direction === "vertical" ? "column" : "row",
              gap: "0px",
              overflowY: "auto",
              width: "100%",
            }}
          >
            {/* Media and Center Buttons */}
            {media_type !== "none" && (
              <>
                {/* Media Icon */}
                {media_type === "icon" && (
                  <div
                    style={{
                      alignSelf: icon_alignment,
                    }}
                  >
                    <HoverDiv
                      style={{
                        border:
                          moduleType === MODULE_TYPES.MEDIA_ICON
                            ? icon_border_enabled
                              ? `${icon_border_width}px dashed #2979ff`
                              : "1px dashed #2979ff"
                            : "",
                        borderRadius: icon_border_enabled
                          ? `${icon_border_radius}%`
                          : 0,
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        onModuleSelection(e, MODULE_TYPES.MEDIA_ICON)
                      }}
                    >
                      {icon_path ? (
                        <img
                          src={icon_path}
                          alt="icon"
                          style={{
                            color: "#00ff00",
                            width: icon_width,
                            height: icon_height,
                            borderWidth: icon_border_enabled
                              ? icon_border_width
                              : 0,
                            borderColor: icon_border_color,
                            borderRadius: icon_border_enabled
                              ? `${icon_border_radius}%`
                              : 0,
                            borderStyle: icon_border_style,
                          }}
                        />
                      ) : (
                        <Loyalty
                          style={{
                            padding: 4,
                            color: "#00ff00",
                            width: icon_width * 1.5,
                            height: icon_height * 1.5,
                            borderWidth: icon_border_enabled
                              ? icon_border_width
                              : 0,
                            borderColor: icon_border_color,
                            borderRadius: icon_border_enabled
                              ? `${icon_border_radius}%`
                              : 0,
                            borderStyle: icon_border_style,
                          }}
                        />
                      )}
                    </HoverDiv>
                  </div>
                )}

                {/* Media Image */}
                {media_type === "image" && (
                  <HoverDiv
                    style={{
                      border:
                        moduleType === MODULE_TYPES.MEDIA_IMAGE
                          ? image_border_enabled
                            ? `${image_border_width}px dashed #2979ff`
                            : "1px dashed #2979ff"
                          : "",
                      borderRadius: image_border_enabled
                        ? `${image_border_radius}%`
                        : 0,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      onModuleSelection(e, MODULE_TYPES.MEDIA_IMAGE)
                    }}
                  >
                    <img
                      src={
                        image_path !== ""
                          ? image_path
                          : inapp_direction === "vertical"
                            ? "https://images.unsplash.com/photo-1674754666581-4e6657392655?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                            : "https://images.unsplash.com/photo-1503249023995-51b0f3778ccf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3360&q=80"
                      }
                      width="100%"
                      height="100%"
                      alt=""
                      style={{
                        borderRadius: image_border_enabled
                          ? `${image_border_radius}%`
                          : 0,
                        borderWidth: image_border_enabled
                          ? image_border_width
                          : 0,
                        borderColor: image_border_color,
                        borderStyle: image_border_style,
                        aspectRatio: `${image_width}/${image_height}`,
                      }}
                    />
                  </HoverDiv>
                )}

                {/* Media Video */}
                {media_type === "video" && (
                  <HoverDiv
                    style={{
                      border:
                        moduleType === MODULE_TYPES.MEDIA_VIDEO
                          ? video_border_enabled
                            ? `${video_border_width}px dashed #2979ff`
                            : "1px dashed #2979ff"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      onModuleSelection(e, MODULE_TYPES.MEDIA_VIDEO)
                    }}
                  >
                    {enable_embed ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          onModuleSelection(e, MODULE_TYPES.MEDIA_VIDEO)
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: embed.html }}
                          style={{
                            width: "98%",
                            height: "98%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <video
                        src={
                          video_path !== ""
                            ? video_path
                            : "https://www.w3schools.com/tags/movie.mp4"
                        }
                        controls
                        disablePictureInPicture
                        controlsList="noplaybackrate nodownload"
                        muted={!enable_audio}
                        width="100%"
                        height="100%"
                        alt=""
                        style={{
                          borderRadius: video_border_enabled
                            ? `${video_border_radius}%`
                            : 0,
                          borderWidth: video_border_enabled
                            ? video_border_width
                            : 0,
                          borderColor: video_border_color,
                          borderStyle: video_border_style,
                          aspectRatio: `${video_width}/${video_height}`,
                        }}
                      />
                    )}
                  </HoverDiv>
                )}

                {/* Center Buttons */}
                {inapp_direction === "vertical" && (
                  <NewButtonsHolder
                    id="buttons_holder"
                    style={{
                      textAlign: "center",
                      padding: "6px",
                    }}
                    onModuleSelection={(e, name) => {
                      e.stopPropagation()
                      onModuleSelection(
                        e,
                        name,
                        "",
                        MODAL_NEW_BUTTONS_ROW_NAMES.center,
                      )
                    }}
                    moduleType={moduleType}
                    bgColor={ui.bg_color}
                    config={buttons.center}
                    buttonHolderStyle={{
                      width: "100%",
                      justifyContent: "center",
                    }}
                    totalButtons={1}
                    emptySlots={[1]}
                    row_name={MODAL_NEW_BUTTONS_ROW_NAMES.center}
                    rowEmpty={buttons.center.length === 0}
                    enable_button_placement_mode={enable_button_placement_mode}
                    size={width}
                  />
                )}
              </>
            )}

            <NonMediaContent
              inapp_direction={inapp_direction}
              inapp_nonmedia_direction={inapp_nonmedia_direction}
              buttons={buttons}
              moduleType={moduleType}
              onModuleSelection={onModuleSelection}
              ui={ui}
              dispatch={dispatch}
              currentStep={currentStep}
              enable_button_placement_mode={enable_button_placement_mode}
              enable_buttons_slots_update_mode={
                enable_buttons_slots_update_mode
              }
              buttons_count={buttons_count}
              width={width}
              enable_scroll={enable_scroll}
              scroll_text_height={scroll_text_height}
              scroll_border_enabled={scroll_border_enabled}
              scroll_border_width={scroll_border_width}
              scroll_border_color={scroll_border_color}
              scroll_border_style={scroll_border_style}
              enable_title={enable_title}
              title={title}
              title_icon_path={title_icon_path}
              enable_description={enable_description}
              description={description}
              isExperiment={isExperiment}
            />
          </div>
        </div>
      </VStack>

      {/* Right Click Context Menu */}
      <ModalInAppEditMenu
        mousePosn={mousePosn}
        setMousePosn={setMousePosn}
        enable_title={enable_title}
        enable_description={enable_description}
        currentStep={currentStep}
      />
    </>
  )
}

export function ModalInAppEditMenu({
  mousePosn,
  setMousePosn,
  enable_title = TITLE.enable_title,
  enable_description = DESCRIPTION.enable_description,
  currentStep,
}) {
  const [, dispatch] = useTracked()
  const { appId } = useParams()
  const isAppXira = useMemo(() => isXiraApp(appId), [appId])

  return (
    <StyledMenu
      keepMounted
      open={mousePosn.mouseY !== null}
      onClose={() => {
        setMousePosn({ mouseX: null, mouseY: null })
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        mousePosn.mouseY !== null && mousePosn.mouseX !== null
          ? { top: mousePosn.mouseY, left: mousePosn.mouseX }
          : undefined
      }
    >
      {!enable_title && (
        <StyledMenuButton
          startIcon={<TextFieldsRounded />}
          onClick={() => {
            dispatch({
              type: SET_TITLE_ENABLED,
              currentStep,
              value: true,
            })
            setMousePosn({ mouseX: null, mouseY: null })
          }}
        >
          Add Title
        </StyledMenuButton>
      )}
      {!enable_description && (
        <StyledMenuButton
          startIcon={<LocalParkingSharp />}
          onClick={() => {
            dispatch({
              type: SET_DESCRIPTION_ENABLED,
              currentStep,
              value: true,
            })
            setMousePosn({ mouseX: null, mouseY: null })
          }}
        >
          Add Description
        </StyledMenuButton>
      )}
      <ThemedTooltip title={isAppXira ? "Disabled for demo apps" : ""}>
        <div>
          <StyledMenuButton
            disabled={isAppXira}
            startIcon={<AddIcon />}
            onClick={() => {
              dispatch({
                type: SET_BUTTON_PLACEMENT_MODE,
                currentStep,
                value: true,
              })
              setMousePosn({ mouseX: null, mouseY: null })
            }}
          >
            Add New Button
          </StyledMenuButton>
        </div>
      </ThemedTooltip>
      <ThemedTooltip title={isAppXira ? "Disabled for demo apps" : ""}>
        <div>
          <StyledMenuButton
            disabled={isAppXira}
            startIcon={<FormatListNumbered />}
            onClick={() => {
              dispatch({
                type: SET_BUTTONS_SLOTS_UPDATE_MODE,
                currentStep,
                value: true,
              })
              setMousePosn({ mouseX: null, mouseY: null })
            }}
          >
            Update Slots
          </StyledMenuButton>
        </div>
      </ThemedTooltip>
    </StyledMenu>
  )
}
