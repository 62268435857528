import { Typography, withStyles } from "@material-ui/core"
import classnames from "classnames"
import React, { useState } from "react"
import { Treebeard, decorators } from "react-treebeard"
import Loading from "../../../../../../../ReusableComponents/Loading"
import { SET_SELECTED_HTML_VIEW } from "../../constants"
import { useTracked } from "../../store"
import { resizeBounds } from "./SSEDevicePreview"

const TreeView_Styles = () => ({
  header: {
    padding: "3px 6px",
    color: "#66696b",
    display: "inline-block",
    borderRadius: "4px",
    margin: "2px 0px",
    transition: "all 0.1s ease-in-out",
    "&:hover": {
      backgroundColor: "#3f50b5",
      color: "white",
    },
  },
  header_active: {
    background: "rgba(17, 171, 58, 0.42)",
    "&:hover": {
      background: "rgba(17, 171, 58, 0.42)",
      color: "#66696b",
    },
  },
  faIcon: {
    marginRight: 6,
    fontSize: 16,
  },
})

const Header_TreeView = ({ onSelect, node, classes }) => {
  return (
    <div
      onClick={onSelect}
      className={classnames([
        classes.header,
        node.active && classes.header_active,
      ])}
    >
      {!node.children && (
        <i className={classnames([classes.faIcon, "fa fa-dot-circle-o"])}></i>
      )}

      {node.children && (
        <i
          className={classnames([
            classes.faIcon,
            `fa fa-caret-${node.toggled ? "down" : "right"}`,
          ])}
        ></i>
      )}
      {node.name}
    </div>
  )
}

const WaitingForDevice = (props) => {
  const { withLoading, message } = props
  return (
    <div style={{ textAlign: "center" }}>
      {withLoading && <Loading size={24} />}
      <Typography
        variant={"subtitle2"}
        color={"primary"}
        style={{
          marginTop: 16,
          fontWeight: 600,
          backgroundColor: "#3f50b5cc",
          padding: "4px 12px",
          borderRadius: 4,
          color: "#ffffff",
        }}
      >
        {message}
      </Typography>
    </div>
  )
}

const Styled_Header_TreeView = withStyles(TreeView_Styles)(Header_TreeView)

const TreeView = ({ handleViewSelect, ratio }) => {
  const [state, dispatch] = useTracked()
  const { currentImage, treeView, selectedDevice } = state
  const [cursor, setCursor] = useState()
  //FIXME: NOt sure where data is being used
  const [data, setData] = useState()
  const waitingState = selectedDevice && selectedDevice !== ""

  //FIXME: Revert cursor operations to mutable ops if needed
  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false
    }
    node.active = true
    if (node.children) {
      node.toggled = toggled
    }
    node.selected = toggled

    const { sdk_id, sdk_path_id, view_bounds } = node

    dispatch({
      type: SET_SELECTED_HTML_VIEW,
      value: node.html_id,
    })
    handleViewSelect({
      id: sdk_id,
      path: sdk_path_id,
      tag: node?.sdk_tag !== "" ? node?.sdk_tag : node.name.props.tag,
      is_in_wv: node?.is_in_wv,
      wv_tag: node?.wv_tag,
      view_additional_info: node?.view_additional_info,
      view_bounds: resizeBounds(view_bounds, ratio)?.bounds,
      bounds: resizeBounds(view_bounds, ratio)?.bounds,
      views: node?.views ?? [],
    })
    setCursor(node)
    setData({ ...data } || {})
  }

  return (
    <>
      {currentImage && (
        <div
          style={{
            maxHeight: 516,
            overflow: "auto",
          }}
        >
          <Treebeard
            data={treeView}
            onToggle={onToggle}
            decorators={{
              ...decorators,
              Header: Styled_Header_TreeView,
            }}
            style={{
              tree: {
                base: {
                  backgroundColor: "transparent",
                },
                node: {
                  activeLink: {
                    backgroundColor: "transparent",
                    background: "transparent",
                  },
                },
              },
            }}
            customStyles={{
              container: {
                display: "flex",
                flexDirection: "row",
                background: "transparent",
              },
              header: {
                title: {
                  color: "red",
                },
              },
            }}
          />
        </div>
      )}
      {!currentImage && (
        <div style={{ maxWidth: "50%", margin: "auto" }}>
          <WaitingForDevice
            withLoading={waitingState}
            message={
              !waitingState
                ? "Please select a Test Device"
                : "Waiting for Device"
            }
          />
        </div>
      )}
    </>
  )
}

export default TreeView
