import { IconButton, Typography, makeStyles } from "@material-ui/core"
import { IconCheck } from "@tabler/icons-react"
import { useRef, useState } from "react"
import { apxTheme } from "../../../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../../../ReusableComponents/HStack"
import Loading from "../../../../../ReusableComponents/Loading"
import ThemedTooltip from "../../../../../ReusableComponents/ThemedTooltip"

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    fontSize: props.size === "sm" ? 20 : 26,
    fontWeight: 700,
    color: theme.palette.text.primary,
    textTransform: "capitalize",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      textDecoration: "underline",
      textDecorationStyle: "dashed",
      textUnderlineOffset: "8px",
    },
    "&:focus": {
      // minWidth: 300,
      paddingInline: 4,
      paddingBlock: 2.5,
      outline: "none",
      border: `1px dashed ${theme.palette.text.hint}`,
      borderRadius: 4,

      "&:hover": {
        cursor: "text",
        opacity: 1,
        textDecoration: "none",
      },
    },
  }),
}))

export default function EditableTitle({
  onTitleChange,
  isLoading = false,
  size = "lg",
  ...props
}) {
  const styles = useStyles({ size })
  const [text, setText] = useState(props?.children || "Untitled")
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null)

  return (
    <HStack
      spacing={4}
      align="center"
      style={{
        width: "auto",
      }}
    >
      <ThemedTooltip
        title={isFocused ? "" : "Click to Rename"}
        placement="right"
        dark
        enterDelay={700}
        enterNextDelay={700}
      >
        <Typography
          ref={inputRef}
          contentEditable
          spellCheck={false}
          suppressContentEditableWarning
          onInput={(e) => {
            setText(e.target.textContent)
          }}
          onBlur={async () => {
            setIsFocused(false)
            await onTitleChange(text)
          }}
          onFocus={() => {
            setIsFocused(true)
          }}
          className={styles.root}
          {...props}
        />
      </ThemedTooltip>

      {/* Loading Indicator */}
      {isLoading && <Loading size={18} />}

      {/* Save Button */}
      {isFocused && !isLoading && (
        <IconButton
          onClick={() => {
            inputRef.current.blur()
          }}
          aria-label="Save"
          color="default"
        >
          <IconCheck
            style={{
              color: apxTheme.palette.success.main,
            }}
          />
        </IconButton>
      )}
    </HStack>
  )
}
