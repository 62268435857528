export const SET_BADGE_POSITION = "SET_BADGE_POSITION"
export const ADD_BADGE_VARIANT = "ADD_BADGE_VARIANT"
export const CLONE_BADGE_VARIANT = "CLONE_BADGE_VARIANT"
export const DELETE_BADGE_VARIANT = "DELETE_BADGE_VARIANT"
export const SET_BADGE_OFFSET = "SET_BADGE_OFFSET"
export const SET_BADGE_ENABLE_BACKGROUND = "SET_BADGE_ENABLE_BACKGROUND"
export const SET_BADGE_BACKGROUND_WIDTH = "SET_BADGE_BACKGROUND_WIDTH"
export const SET_BADGE_BACKGROUND_HEIGHT = "SET_BADGE_BACKGROUND_HEIGHT"
export const SET_BADGE_BACKGROUND_ENABLE_IMAGE =
  "SET_BADGE_BACKGROUND_ENABLE_IMAGE"
export const SET_BADGE_BACKGROUND_IMAGE_TYPE = "SET_BADGE_BACKGROUND_IMAGE_TYPE"
export const SET_BADGE_BACKGROUND_IMAGE_PATH = "SET_BADGE_BACKGROUND_IMAGE_PATH"
export const SET_BADGE_BACKGROUND_SHAPE_COLOR =
  "SET_BADGE_BACKGROUND_SHAPE_COLOR"
export const SET_BADGE_BACKGROUND_SHAPE_TYPE = "SET_BADGE_BACKGROUND_SHAPE_TYPE"
export const SET_BADGE_BACKGROUND_SHAPE_ENABLE_BORDER =
  "SET_BADGE_BACKGROUND_SHAPE_ENABLE_BORDER"
export const SET_BADGE_BACKGROUND_SHAPE_BORDER_COLOR =
  "SET_BADGE_BACKGROUND_SHAPE_BORDER_COLOR"
export const SET_BADGE_BACKGROUND_SHAPE_BORDER_CORNERS =
  "SET_BADGE_BACKGROUND_SHAPE_BORDER_CORNERS"
export const SET_BADGE_BACKGROUND_SHAPE_BORDER_WIDTH =
  "SET_BADGE_BACKGROUND_SHAPE_BORDER_WIDTH"
export const SET_BADGE_CONTENT_ENABLE_LABEL = "SET_BADGE_CONTENT_ENABLE_LABEL"
export const SET_BADGE_CONTENT_LABEL_ENABLE_MARGIN =
  "SET_BADGE_CONTENT_LABEL_ENABLE_MARGIN"
export const SET_BADGE_CONTENT_LABEL_MARGIN = "SET_BADGE_CONTENT_LABEL_MARGIN"
export const SET_BADGE_CONTENT_LABEL_COLOR = "SET_BADGE_CONTENT_LABEL_COLOR"
export const SET_BADGE_CONTENT_LABEL_TEXT = "SET_BADGE_CONTENT_LABEL_TEXT"
export const SET_BADGE_CONTENT_LABEL_FONT = "SET_BADGE_CONTENT_LABEL_FONT"
export const SET_BADGE_CONTENT_LABEL_ALIGNMENT =
  "SET_BADGE_CONTENT_LABEL_ALIGNMENT"
export const SET_BADGE_CONTENT_LABEL_SCRIPT = "SET_BADGE_CONTENT_LABEL_SCRIPT"
export const SET_BADGE_CONTENT_ENABLE_ICON = "SET_BADGE_CONTENT_ENABLE_ICON"
export const SET_BADGE_CONTENT_ICON_TYPE = "SET_BADGE_CONTENT_ICON_TYPE"
export const SET_BADGE_CONTENT_ICON_PATH = "SET_BADGE_CONTENT_ICON_PATH"
export const SET_BADGE_CONTENT_ICON_POSITION = "SET_BADGE_CONTENT_ICON_POSITION"
export const SET_BADGE_CONTENT_ICON_WIDTH = "SET_BADGE_CONTENT_ICON_WIDTH"
export const SET_BADGE_CONTENT_ICON_HEIGHT = "SET_BADGE_CONTENT_ICON_HEIGHT"
export const SET_BADGE_CONTENT_ICON_ENABLE_MARGIN =
  "SET_BADGE_CONTENT_ICON_ENABLE_MARGIN"
export const SET_BADGE_CONTENT_ICON_MARGIN = "SET_BADGE_CONTENT_ICON_MARGIN"
export const SET_BADGE_ENABLE_SHADOW = "SET_BADGE_ENABLE_SHADOW"
export const SET_BADGE_SHADOW = "SET_BADGE_SHADOW"
export const SET_BADGE_ENABLE_ANIMATION = "SET_BADGE_ENABLE_ANIMATION"
export const SET_BADGE_ANIMATION_TYPE = "SET_BADGE_ANIMATION_TYPE"
export const SET_BADGE_ANIMATION_DURATION_MILLIS =
  "SET_BADGE_ANIMATION_DURATION_MILLIS"
export const SET_BADGE_ANIMATION_DELAY_MILLIS =
  "SET_BADGE_ANIMATION_DELAY_MILLIS"
export const SET_BADGE_ANIMATION_INTRA_COUNT = "SET_BADGE_ANIMATION_INTRA_COUNT"
export const SET_BADGE_ANIMATION_INTER_COUNT = "SET_BADGE_ANIMATION_INTER_COUNT"
export const SET_BADGE_ANIMATION_RIPPLE_AREA = "SET_BADGE_ANIMATION_RIPPLE_AREA"
export const SET_BADGE_ANIMATION_RIPPLE_CORNER_RADIUS =
  "SET_BADGE_ANIMATION_RIPPLE_CORNER_RADIUS"
export const SET_BADGE_ANIMATION_RIPPLE_COLOR =
  "SET_BADGE_ANIMATION_RIPPLE_COLOR"
export const SET_BADGE_ANIMATION_RIPPLE_SHAPE =
  "SET_BADGE_ANIMATION_RIPPLE_SHAPE"
export const SET_BADGE_ANIMATION_SHAKE_DIRECTION =
  "SET_BADGE_ANIMATION_SHAKE_DIRECTION"
export const SET_BADGE_ANIMATION_FADE_OPACITY =
  "SET_BADGE_ANIMATION_FADE_OPACITY"
export const SET_BADGE_ANIMATION_SHINE_DIRECTION =
  "SET_BADGE_ANIMATION_SHINE_DIRECTION"
export const SET_BADGE_ANIMATION_SHINE_COLOR = "SET_BADGE_ANIMATION_SHINE_COLOR"
export const SET_BADGE_ANIMATION_SHINE_LINES = "SET_BADGE_ANIMATION_SHINE_LINES"
export const SET_BADGE_ANIMATION_SHINE_LINES_GAP =
  "SET_BADGE_ANIMATION_SHINE_LINES_GAP"
export const ADD_SHINE_LINE_VARIANT = "ADD_SHINE_LINE_VARIANT"
export const DELETE_SHINE_LINE_VARIANT = "DELETE_SHINE_LINE_VARIANT"
export const SET_BADGE_ENABLE_AUTO_SCROLL = "SET_BADGE_ENABLE_AUTO_SCROLL"
export const SET_BADGE_AUTO_SCROLL_SPEED = "SET_BADGE_AUTO_SCROLL_SPEED"
export const SET_BADGE_AUTO_SCROLL_DIRECTION = "SET_BADGE_AUTO_SCROLL_DIRECTION"
export const SET_BADGE_AUTO_SCROLL_HORIZONTAL_GAP =
  "SET_BADGE_AUTO_SCROLL_HORIZONTAL_GAP"
export const SET_BADGE_DYNAMIC_TEXT_CONFIG = "SET_BADGE_DYNAMIC_TEXT_CONFIG"
