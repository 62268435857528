import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Input,
  InputLabel,
  MuiThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditOutlined"
import { Suspense, lazy, useCallback, useState } from "react"
import { updateAuthCookie, useAuth } from "../../../contexts/AuthContext"
import logger from "../../../utils/Logger"
import Cookie from "../../../utils/Storage/Cookie"
import { useDeepCompareMemo } from "../../../utils/use-deep-compare"
import useFocus from "../../../utils/use-focus"
import {
  apxTheme,
  globalStyles,
} from "../../ApplicationFrame/styling/CustomCSS"
import { useTrackedState } from "../../Auth/store"
import OrgsSideNav from "../../Organizations/components/OrgsSideNav"
import HStack from "../../ReusableComponents/HStack"
import Loading from "../../ReusableComponents/Loading"
import Snackbar from "../../ReusableComponents/Snackbar"
import ThemedTooltip from "../../ReusableComponents/ThemedTooltip"
import VStack from "../../ReusableComponents/VStack"
import WhitePaper from "../../ReusableComponents/WhitePaper"
import DialogLoader from "../../ReusableComponents/skeletons/DialogLoader"
import useUser from "../hooks/use-user"

const EditImageDialog = lazy(() => import("../components/EditImageDialog"))
const EditPasswordDialog = lazy(
  () => import("../components/EditPasswordDialog"),
)

export const StyledInputLabel = withStyles({
  root: {
    color: "#667A8A",
    fontSize: "14px",
    fontWeight: "550",
  },
})(InputLabel)

export const StyledInput = withStyles((theme) => ({
  root: {
    minWidth: 360,
    padding: 6,
    fontWeight: 600,
    fontSize: 15,
    color: "rgba(0, 40, 69)",
    border: `1px solid ${theme.palette.action.focus}`,
    borderRadius: 12,
  },
  disabled: {
    backgroundColor: "#F2F3F3",
    color: "rgba(0, 40, 69, 0.6)",
  },
  focused: {
    boxShadow: `0 0 0 1.5px ${apxTheme.palette.primary.main}`,
    borderColor: "transparent",
  },
}))(Input)

function ProfilePage({ classes }) {
  const { user } = useAuth()
  const { auth_loading } = useTrackedState()
  const { updateUserDetails } = useUser()

  const [editingEnabled, setEditingEnabled] = useState(false)
  const [name, setName] = useState(user?.name ?? "")
  const email = useDeepCompareMemo(() => user?.email, [user])

  const userName = user?.name

  const [openEditPasswordDialog, setOpenEditPasswordDialog] = useState(false)
  const [openEditImageDialog, setOpenEditImageDialog] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const [nameRef, setFocusToNameRef] = useFocus(editingEnabled)

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      const { ok, name: newName } = await updateUserDetails({
        name,
        userId: user.email,
      })
      if (ok) {
        setName(newName)
        const cookie = new Cookie(window, logger)
        const sessionFromCookie = JSON.parse(
          atob(cookie.get("_apx_n2_") || "") || "{}",
        )
        updateAuthCookie("_apx_n2_", {
          ...sessionFromCookie,
          user: { ...sessionFromCookie.user, name: newName },
        })

        setSnackBarMessage("Details Updated Successfully!")
      }
      setEditingEnabled(false)
      setOpenEditPasswordDialog(false)
      setOpenEditImageDialog(false)
    },
    [name, updateUserDetails, user.email],
  )

  return (
    <>
      <MuiThemeProvider theme={apxTheme}>
        {!auth_loading && snackBarMessage !== "" && (
          <Snackbar
            onClose={() => {
              setSnackBarMessage("")
            }}
          >
            <strong>{snackBarMessage}</strong>
          </Snackbar>
        )}
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <OrgsSideNav
              user={user}
              open={drawerOpen}
              handleOpenDrawer={handleOpenDrawer}
              handleCloseDrawer={handleCloseDrawer}
            />
            <main className={classes.content}>
              <VStack
                spacing="30px"
                style={{
                  backgroundColor: "#F9F9F9",
                  paddingBlock: "40px",
                  paddingLeft: "40px",
                  paddingRight: "50px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 30,
                  }}
                >
                  My Profile
                </Typography>
                <WhitePaper
                  style={{
                    minHeight: "70vh",
                    padding: "30px",
                  }}
                >
                  <form>
                    <VStack
                      spacing="32px"
                      align="center"
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <ThemedTooltip
                            title="Update Avatar URL"
                            placement="right"
                          >
                            <IconButton
                              size="small"
                              style={{
                                backgroundColor: apxTheme.palette.primary.main,
                                padding: 5,
                                minWidth: 24,
                                minHeight: 24,
                              }}
                              onClick={() => {
                                setOpenEditImageDialog(true)
                              }}
                            >
                              <EditIcon
                                style={{
                                  width: 16,
                                  height: 16,
                                  color: "#fff",
                                }}
                              />
                            </IconButton>
                          </ThemedTooltip>
                        }
                      >
                        <Avatar
                          style={{
                            width: 74,
                            height: 74,
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            border: `1.5px solid ${apxTheme.palette.primary.main}`,
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            style={{
                              fontSize: 40,
                            }}
                          >
                            {user?.email?.charAt(0)?.toUpperCase()}
                          </Typography>
                        </Avatar>
                      </Badge>
                      <VStack
                        spacing="16px"
                        align="center"
                        justify="center"
                      >
                        <VStack
                          spacing="6px"
                          align="start"
                        >
                          <StyledInputLabel
                            component="label"
                            htmlFor="name"
                          >
                            Name
                          </StyledInputLabel>
                          <StyledInput
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="off"
                            inputProps={{
                              autoComplete: "off",
                            }}
                            autoFocus
                            inputRef={nameRef}
                            disabled={!editingEnabled}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </VStack>
                        <VStack
                          spacing="6px"
                          align="start"
                        >
                          <StyledInputLabel>Email</StyledInputLabel>
                          <StyledInput
                            autoComplete="off"
                            disabled
                            value={email}
                            onChange={() => {}}
                          />
                        </VStack>
                        <VStack
                          spacing="6px"
                          align="start"
                        >
                          <StyledInputLabel>Password</StyledInputLabel>
                          <StyledInput
                            disabled={!editingEnabled}
                            inputProps={{
                              disabled: true,
                            }}
                            value={"************"}
                            onChange={() => {}}
                            endAdornment={
                              editingEnabled && (
                                <Button
                                  size="small"
                                  variant="text"
                                  color="primary"
                                  onClick={() => {
                                    setOpenEditPasswordDialog(true)
                                  }}
                                >
                                  Change
                                </Button>
                              )
                            }
                          />
                        </VStack>
                      </VStack>
                      <HStack
                        spacing="8px"
                        justify="center"
                        align="center"
                      >
                        {!editingEnabled ? (
                          <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            startIcon={
                              <EditIcon
                                style={{
                                  fontSize: 16,
                                }}
                              />
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              minWidth: 150,
                              minHeight: 44,
                            }}
                            onClick={() => {
                              setEditingEnabled(!editingEnabled)
                              setFocusToNameRef()
                            }}
                          >
                            Edit Details
                          </Button>
                        ) : (
                          <>
                            <Button
                              size="large"
                              color="default"
                              variant="outlined"
                              style={{
                                borderWidth: 1.3,
                                minWidth: 150,
                                minHeight: 44,
                              }}
                              onClick={() => {
                                setEditingEnabled(!editingEnabled)
                                setOpenEditPasswordDialog(false)
                                setOpenEditImageDialog(false)
                                setName(userName)
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="large"
                              color="primary"
                              variant="contained"
                              disabled={
                                auth_loading || name === userName || name === ""
                              }
                              style={{
                                minWidth: 150,
                                minHeight: 44,
                              }}
                              onClick={(e) => {
                                setEditingEnabled(!editingEnabled)
                                handleSubmit(e)
                              }}
                            >
                              {auth_loading && (
                                <Loading
                                  size={18}
                                  style={{
                                    color: "#fff",
                                  }}
                                />
                              )}
                              Save Changes
                            </Button>
                          </>
                        )}
                      </HStack>
                    </VStack>
                  </form>
                </WhitePaper>
              </VStack>
            </main>
          </div>
        </div>
        {openEditImageDialog && (
          <Suspense fallback={<DialogLoader />}>
            <EditImageDialog
              open={openEditImageDialog}
              onClose={() => {
                setOpenEditImageDialog(false)
              }}
              setSnackBarMessage={setSnackBarMessage}
            />
          </Suspense>
        )}
        {openEditPasswordDialog && (
          <Suspense fallback={<DialogLoader />}>
            <EditPasswordDialog
              open={openEditPasswordDialog}
              onClose={() => {
                setOpenEditPasswordDialog(false)
              }}
              setSnackBarMessage={setSnackBarMessage}
            />
          </Suspense>
        )}
      </MuiThemeProvider>
    </>
  )
}

export default withStyles(globalStyles, { withTheme: true })(ProfilePage)
