import {
  Avatar,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiThemeProvider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core"
import {
  QuestionAnswer as ApprovalsIcon,
  QuestionAnswerOutlined as ApprovalsOutlinedIcon,
  ExpandMore as ChevronDownIcon,
  ExpandLess as ChevronUpIcon,
  ExitToApp as LogoutIcon,
  Dashboard as OrgsIcon,
  DashboardOutlined as OrgsOutlinedIcon,
} from "@material-ui/icons"
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react"
import classNames from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { isSuper } from "../../../config"
import { darkTheme } from "../../../styling"
import { capitalizeEachWord } from "../../../utils"
import { useDeepCompareMemo } from "../../../utils/use-deep-compare"
import { apxTheme as theme } from "../../ApplicationFrame/styling/CustomCSS"
import { ROLES_ENUM } from "../../Dashboard/components/settings/constants"
import ColumnDiv from "../../ReusableComponents/ColumnDiv"
import Snackbar from "../../ReusableComponents/Snackbar"
import { useTrackedState } from "../store"

const drawerWidth = 242

const styles = () => ({
  appDropdown: { height: 92 },
  profileDropdown: { height: 100 },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 16px",
    ...theme.mixins.toolbar,
  },
  appIcon: {
    width: 40,
  },
  profileIcon: {
    width: 32,
    borderRadius: 32,
  },
  sideLogo: {
    justifyContent: "flex-start",
    width: "65%",
  },
  sideLogoDrawerClosed: {
    height: 40,
  },
  drawerPaper: {
    position: "fixed",
    zIndex: 10000,
    height: "100vh",
    width: drawerWidth,
    transition: "all 0.1s ease-in-out",
    overflowY: "auto",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaperOpen: {
    minWidth: 242,
    transition: "all 0.1s ease-in-out",
  },
  drawerPaperClosed: {
    maxWidth: "90px !important",
    overflowX: "hidden",
  },
  nested: {
    paddingLeft: "52px !important",
  },
  activeNode: {
    borderLeft: `6px solid ${theme.palette.primary.main}`,
    padding: "8px 12px",
    height: 48,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  node: {
    borderLeft: "6px solid transparent",
    padding: "8px 12px",
    height: 48,
    transition: "all 0.1s ease-in-out",
  },
  drawerClosedNode: {
    paddingLeft: "27px !important",
  },
  nodeLeft: {
    alignItems: "center",
  },
  listItemIcon: {
    minWidth: "auto",
    paddingRight: 12,
  },
  listItemIconActive: {
    minWidth: "auto",
    paddingRight: 12,
    color: theme.palette.primary.main,
  },
  listItemText: {
    fontSize: 14,
    fontWeight: "600",
    color: theme.palette.secondary.main,
  },
  listItemTextActive: {
    fontSize: 14,
    fontWeight: "600",
    color: theme.palette.primary.main,
  },

  activeDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.success.main,
  },
  inactiveDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.error.main,
  },
  sidebarVerticalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
})

const ProfileLabel = ({ children }) => (
  <Typography
    style={{
      fontWeight: 700,
      padding: "3px 8px",
      background: "#176FAC",
      borderRadius: 12,
      color: "white",
      fontSize: 11,
      width: "fit-content",
    }}
  >
    {children}
  </Typography>
)

const ParentNode = ({
  classes,
  name,
  icon: activeIcon,
  inactiveIcon,
  path,
  drawerOpen,
}) => {
  const { pathname } = useLocation()
  const pathItem = pathname.split("/").at(-1)
  const isCurrentActive = pathItem === path

  return (
    <NavLink to={path}>
      <ListItem
        disableGutters
        button
        className={classNames({
          [classes.activeNode]: isCurrentActive,
          [classes.node]: !isCurrentActive,
          [classes.drawerClosedNode]: !drawerOpen,
        })}
      >
        <ListItemIcon
          className={classNames({
            [classes.listItemIcon]: true,
            [classes.listItemIconActive]: isCurrentActive,
          })}
        >
          {isCurrentActive ? activeIcon : inactiveIcon}
        </ListItemIcon>
        {drawerOpen && (
          <ListItemText
            className={classes.listItemText}
            primary={name}
            primaryTypographyProps={{
              className: classes.listItemText,
            }}
          />
        )}
      </ListItem>
    </NavLink>
  )
}

const navigationItems = [
  {
    name: "Orgs",
    id: "orgs",
    path: "orgs",
    icon: <OrgsIcon />,
    inactiveIcon: <OrgsOutlinedIcon />,
  },
  {
    name: "Approvals",
    id: "approvals",
    path: "approvals",
    icon: <ApprovalsIcon />,
    inactiveIcon: <ApprovalsOutlinedIcon />,
  },
]

const profileItems = [
  // {
  //   name: "Requests",
  //   id: "requests",
  //   path: "requests",
  //   icon: null,
  //   children: null,
  // },
  {
    name: "My Profile",
    id: "profile",
    path: "profile",
    icon: null,
    children: null,
  },
  {
    name: "Logout",
    id: "logout",
    path: "logout",
    icon: <LogoutIcon />,
    children: null,
  },
]

const OrgsSideNav = ({
  open: drawerOpen,
  user,
  handleOpenDrawer,
  handleCloseDrawer,
  classes,
}) => {
  const { org_data, org_loading } = useTrackedState()
  const { pathname } = useLocation()

  const [isOpenProfileDropdown, setOpenProfileDropdown] = useState(false)

  const handleOpenProfileDropdown = () =>
    setOpenProfileDropdown((prev) => !prev)

  useEffect(() => {
    if (!drawerOpen) {
      setOpenProfileDropdown(false)
    }
  }, [drawerOpen])

  const currentUserFromOrgState = useDeepCompareMemo(() => {
    return {
      customer_id: user?.email,
      ...((isSuper(user?.email) || user?.email === org_data?.owner) && {
        role: ROLES_ENUM.ORG_OWNER.value,
      }),
    }
  }, [org_data, user])

  const filteredNavItems = useMemo(
    () =>
      navigationItems.filter((item) => {
        if (item.id === "approvals") {
          return isSuper(user?.email)
        }
        return true
      }),
    [user.email],
  )

  const [snackBarMessage, setSnackBarMessage] = useState("")

  return (
    <>
      {!org_loading && snackBarMessage !== "" && (
        <Snackbar
          onClose={() => {
            setSnackBarMessage("")
          }}
        >
          <strong>{snackBarMessage}</strong>
        </Snackbar>
      )}
      <MuiThemeProvider theme={darkTheme}>
        <Drawer
          variant="permanent"
          open={drawerOpen}
          className={classNames(classes.drawerPaper, {
            [classes.drawerPaperOpen]: drawerOpen,
            [classes.drawerPaperClosed]: !drawerOpen,
          })}
          classes={{
            paper: classNames({
              [classes.drawerPaperOpen]: drawerOpen,
              [classes.drawerPaperClosed]: !drawerOpen,
            }),
          }}
        >
          <div className={classes.sidebarVerticalContainer}>
            <div>
              <div
                className={classNames(classes.drawerHeader, {
                  [classes.drawerClosedNode]: !drawerOpen,
                })}
              >
                <NavLink to={`/orgs`}>
                  <img
                    alt={window.apxorAppInfo.whiteLogo}
                    src={
                      drawerOpen
                        ? "/assets/img/logo-white.png"
                        : "/assets/img/sidebar_logo.png"
                    }
                    className={classNames({
                      [classes.sideLogo]: drawerOpen,
                      [classes.sideLogoDrawerClosed]: !drawerOpen,
                    })}
                  />
                </NavLink>
                {drawerOpen && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleCloseDrawer()
                    }}
                  >
                    <Tooltip
                      title="Collapse"
                      placement="right"
                      arrow
                    >
                      <IconChevronsLeft />
                    </Tooltip>
                  </div>
                )}
              </div>
              {!drawerOpen && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleOpenDrawer()
                  }}
                >
                  <Tooltip
                    title="Expand"
                    placement="right"
                    arrow
                  >
                    <IconChevronsRight />
                  </Tooltip>
                </div>
              )}
              <List
                component="nav"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {filteredNavItems?.map((parentNode, idx) => (
                  <ParentNode
                    key={`${parentNode.id}-${idx}`}
                    classes={classes}
                    drawerOpen={drawerOpen}
                    {...parentNode}
                  />
                ))}
              </List>
            </div>
            <ColumnDiv
              styles={{
                marginBottom: "20px",
                gap: "5px",
              }}
            >
              <ListItem
                button
                onClick={() => {
                  handleOpenProfileDropdown()
                  handleOpenDrawer()
                }}
                className={classNames(classes.profileDropdown, {
                  [classes.drawerClosedNode]: !drawerOpen,
                })}
              >
                <ListItemIcon>
                  <Avatar
                    style={{
                      color: theme.palette.secondary.main,
                      border: "2px solid",
                      borderColor: theme.palette.primary.main,
                      backgroundColor: "transparent",
                      fontWeight: 600,
                    }}
                  >
                    {capitalizeEachWord(user?.[user?.name ? "name" : "email"])
                      ?.split(" ")
                      .map((name) => name[0])
                      .slice(0, 2)
                      .join("")}
                  </Avatar>
                </ListItemIcon>
                {drawerOpen && (
                  <>
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            maxWidth: 120,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontWeight: 600,
                          }}
                        >
                          {user?.name ?? user?.email}
                        </Typography>
                      }
                      secondary={
                        <ColumnDiv styles={{ gap: 4, maxWidth: 120 }}>
                          <Tooltip
                            title={user?.email}
                            placement="top"
                            arrow
                          >
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              style={{
                                maxWidth: 120,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontWeight: 600,
                              }}
                            >
                              {user?.email}
                            </Typography>
                          </Tooltip>
                          {currentUserFromOrgState?.role && (
                            <ProfileLabel>
                              {
                                Object.values(ROLES_ENUM).find(
                                  ({ value }) =>
                                    value === currentUserFromOrgState?.role,
                                )?.name
                              }
                            </ProfileLabel>
                          )}
                        </ColumnDiv>
                      }
                    />
                    {isOpenProfileDropdown ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </>
                )}
              </ListItem>

              <Collapse
                in={isOpenProfileDropdown}
                timeout="auto"
                unmountOnExit
              >
                {profileItems.map(({ name, id, icon }, idx) => {
                  const isCurrentActive = id === pathname.split("/").at(-1)

                  return (
                    <List
                      component="div"
                      disablePadding
                      key={`${id}-${idx}`}
                    >
                      <ListItem
                        disableGutters
                        button
                        className={classes.nested}
                        component={NavLink}
                        to={`/${id}`}
                      >
                        <ListItemText
                          className={classNames({
                            [classes.listItemTextActive]: isCurrentActive,
                            [classes.listItemText]: !isCurrentActive,
                          })}
                          primary={name}
                          primaryTypographyProps={{
                            className:
                              classes[
                                isCurrentActive
                                  ? "listItemTextActive"
                                  : "listItemText"
                              ],
                          }}
                        />
                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                      </ListItem>
                    </List>
                  )
                })}
              </Collapse>
            </ColumnDiv>
          </div>
        </Drawer>
      </MuiThemeProvider>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(OrgsSideNav)
