import { Box, Button, Tab, Tabs, Typography } from "@material-ui/core"
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined"
import { Suspense, lazy, useState } from "react"
import { useParams } from "react-router-dom"
import androidLogo from "../../../../Application/Dashboard/common/components/assets/android.png"
import iosLogo from "../../../../Application/Dashboard/common/components/assets/ios.png"
import webLogo from "../../../../Application/Dashboard/common/components/assets/web.png"
import { useAuth } from "../../../../contexts/AuthContext"
import HStack from "../../../ReusableComponents/HStack"
import Loading from "../../../ReusableComponents/Loading"
import IntegrationLayout from "../../IntegrationLayout"
import { SET_EVENT_LOGGING_TYPE, SET_PROGRESS } from "../../constants"
import { useDispatch, useTrackedState } from "../../store"
import CopyContent from "../components/CopyContent"
import { EVENT_LOGGING_OPTIONS_ENUM } from "../utils"

const IntegrateByMySelfSection = lazy(
  () => import("../by-myself/IntegrateByMySelfSection"),
)
const InviteDevsSection = lazy(() => import("../invite-devs/InviteDevsSection"))

const TAB_HEADERS = ["Integrate by MySelf", "Invite Developers"]

export default function IntegratePage() {
  const auth = useAuth()
  const { app_data } = useTrackedState()
  const dispatch = useDispatch()
  const { auth_loading } = auth
  const { appId } = useParams()

  const [selectedTab, setSelectedTab] = useState(0)
  const [openInviteDevelopersDialog, setOpenInviteDevelopersDialog] =
    useState(false)

  return (
    <IntegrationLayout>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: "600px",
          }}
        >
          <HStack
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "25px",
              }}
            >
              Integrate
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={
                  app_data?.basic_info?.platform === "android"
                    ? androidLogo
                    : app_data?.basic_info?.platform === "ios"
                      ? iosLogo
                      : webLogo
                }
                height="18px"
                style={{
                  marginRight: "6px",
                }}
                alt={`${app_data?.basic_info?.platform} icon`}
              />
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  marginRight: "7px",
                }}
              >
                {app_data?.basic_info?.app_name}
              </Typography>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  opacity: "0.5",
                  marginRight: "4px",
                }}
              >
                App ID
              </Typography>
              <CopyContent
                copyText={appId}
                styles={{
                  position: "unset",
                  fontSize: "20px",
                }}
              />
            </Box>
          </HStack>
          <HStack
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              marginBottom: "10px",
            }}
          >
            <Tabs
              value={selectedTab}
              textColor="primary"
              indicatorColor="primary"
              onChange={(_, value) => {
                setSelectedTab(value)
                dispatch({
                  type: SET_PROGRESS,
                  payload: 0,
                })
                dispatch({
                  type: SET_EVENT_LOGGING_TYPE,
                  payload: EVENT_LOGGING_OPTIONS_ENUM.NONE,
                })
              }}
            >
              {TAB_HEADERS.map((label, index) => (
                <Tab
                  disableRipple
                  key={`${label}-${index}`}
                  label={label}
                  style={{
                    textTransform: "none",
                    paddingBottom: "10px",
                    paddingLeft: "0px",
                    marginLeft: "-5px",
                    marginRight: "15px",
                  }}
                />
              ))}
            </Tabs>
            {selectedTab === 1 &&
              app_data?.basic_info?.customers?.length > 0 && (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  startIcon={<PersonAddOutlinedIcon />}
                  onClick={() => setOpenInviteDevelopersDialog(true)}
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                  disabled={auth_loading}
                >
                  Invite
                </Button>
              )}
          </HStack>
          <HStack>
            <Suspense
              fallback={
                <Box
                  style={{
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loading size={25} />
                </Box>
              }
            >
              {selectedTab === 0 && <IntegrateByMySelfSection />}
              {selectedTab === 1 && (
                <InviteDevsSection
                  openInviteDevelopersDialog={openInviteDevelopersDialog}
                  setOpenInviteDevelopersDialog={setOpenInviteDevelopersDialog}
                />
              )}
            </Suspense>
          </HStack>
        </div>
      </Box>
    </IntegrationLayout>
  )
}
