import * as Amplitude from "@amplitude/analytics-browser"
// import { logEvent as logApxEvent } from "apxor"
import { useParams } from "react-router-dom"
import { useTrackedState as useAppState } from "../Application/IntegrationExperience/store"
import { useTrackedState as useOrgState } from "../Application/Organizations/store"
import useUser from "../Application/Profile/hooks/use-user"
import { wrapperNudges } from "../config"
import { useDeepCompareCallbackNoCheck } from "./use-deep-compare"

export default function useApxorClient() {
  const { currentRefinedUser } = useUser()
  const { appId, orgId, campaignId } = useParams()

  // Org State
  const {
    org_data: { name: orgName },
  } = useOrgState()

  // App State
  const {
    app_data: { basic_info: { app_name = "" } = {} },
  } = useAppState()

  const logEvent = useDeepCompareCallbackNoCheck(
    (name, properties = {}) => {
      // logApxEvent(name, {
      //   Email: currentRefinedUser?.customer_id || "NA",
      //   Role: currentRefinedUser?.role?.value || "NA",
      //   Organization_Id: orgId || "NA",
      //   app_id: appId || "NA",
      //   Timestamp: Date.now(),
      //   campaign_id: campaignId || "NA",
      //   app_name: app_name || "NA",
      //   Organization_Name: orgName || "NA",
      //   ...properties,
      // })
      wrapperNudges(() => {
        Amplitude.track(name, {
          Email: currentRefinedUser?.customer_id || "NA",
          Role: currentRefinedUser?.role?.value || "NA",
          Organization_Id: orgId || "NA",
          app_id: appId || "NA",
          Timestamp: Date.now(),
          campaign_id: campaignId || "NA",
          app_name: app_name || "NA",
          Organization_Name: orgName || "NA",
          ...properties,
        })
      })
    },
    [
      appId,
      app_name,
      campaignId,
      currentRefinedUser?.customer_id,
      currentRefinedUser?.role?.value,
      orgId,
      orgName,
    ],
  )

  return {
    logEvent,
  }
}
