export const SET_BUNDLE_ID = "SET_BUNDLE_ID"
export const SET_FCM_KEY = "SET_FCM_KEY"
export const SET_IS_ANDROID_X = "SET_IS_ANDROID_X"
export const SET_PROGRESS = "SET_PROGRESS"
export const SET_EVENT_LOGGING_TYPE = "SET_EVENT_LOGGING_TYPE"
export const SET_APP_DATA = "SET_APP_DATA"
export const SET_SUB_PLATFORM = "SET_SUB_PLATFORM"
export const SET_IS_INTEGRATE = "SET_IS_INTEGRATE"

export const SET_APP_LOADING = "SET_APP_LOADING"
export const SET_APP_ERROR = "SET_APP_ERROR"
