import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Typography } from "@material-ui/core"
import IconButton from "@material-ui/core/ButtonBase"
import Tooltip from "@material-ui/core/Tooltip"
import RemoveIcon from "@material-ui/icons/Clear"
import { IconChevronRight, IconGripVertical } from "@tabler/icons-react"
import React, { Suspense, useState } from "react"
import HStack from "../../../../ReusableComponents/HStack"
import DialogLoader from "../../../../ReusableComponents/skeletons/DialogLoader"
import { useActions } from "../store"
import DimensionAddition from "./DimensionAddition"
export default function Dimension({
  id,
  index,
  dimension,
  handleRemove,
  instantDimensions,
  setInstantDimensions,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const { setDimension } = useActions()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDimensionAddition, setOpenDimensionAddition] = useState(false)

  return (
    <>
      <div
        ref={setNodeRef}
        {...attributes}
        style={{
          ...style,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 12px 12px 0px",
          background: "#F8FAFC",
          cursor: "pointer",
          borderRadius: "4px",
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setOpenDimensionAddition(true)
          setDimension(dimension)
        }}
      >
        <HStack
          spacing={6}
          align={"center"}
        >
          <div
            {...listeners}
            ref={setActivatorNodeRef}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "grab",
            }}
            onMouseDown={(e) => {
              e.target.style.cursor = "grabbing"
            }}
            onMouseUp={(e) => {
              e.target.style.cursor = "grab"
            }}
          >
            <IconGripVertical />
          </div>
          <HStack
            justify={"center"}
            align={"center"}
            style={{
              height: 20,
              width: 20,
              borderRadius: "50%",
              backgroundColor: "#020617",
              fontSize: 12,
              fontWeight: 600,
              color: "#ffffff",
            }}
          >
            {index + 1}
          </HStack>
          <Tooltip
            id={"dimension-remove-tooltip-" + id}
            title="Remove"
            placement="top"
          >
            <IconButton
              aria-label="Remove"
              onClick={handleRemove}
              style={{ color: "#020617" }}
            >
              <RemoveIcon style={{ height: "16px", width: "16px" }} />
            </IconButton>
          </Tooltip>
          <Typography
            color="textPrimary"
            style={{
              color: "#020617",
              textWrap: "nowrap",
              fontWeight: 400,
              fontSize: 10,
            }}
          >
            {dimension.alias !== ""
              ? dimension.alias
              : dimension.prop_type === "normal"
                ? dimension.id
                : "Enter Alias"}
          </Typography>
        </HStack>

        <Tooltip
          title="Edit"
          placement="top"
        >
          <IconButton style={{ color: "#64748B" }}>
            <IconChevronRight style={{ height: "16px", width: "16px" }} />
          </IconButton>
        </Tooltip>
      </div>
      {openDimensionAddition && (
        <Suspense fallback={<DialogLoader />}>
          <DimensionAddition
            idx={index}
            open={openDimensionAddition}
            onClose={() => {
              setOpenDimensionAddition(false)
            }}
            anchorEl={anchorEl}
            dimension={dimension}
            instantDimensions={instantDimensions}
            setInstantDimensions={setInstantDimensions}
            isEdit={true}
          />
        </Suspense>
      )}
    </>
  )
}
