import { Typography } from "@material-ui/core"
import { Suspense, lazy, useState, useTransition } from "react"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../ReusableComponents/HStack"
import Loading from "../../../ReusableComponents/Loading"
import SearchBar from "../../../ReusableComponents/SearchBar"
import VStack from "../../../ReusableComponents/VStack"
import AppsLayout from "../../layouts/AppsLayout"

const AppsWithUsersList = lazy(
  () => import("../../components/AppsWithUsersList"),
)

export default function UsersListingPage() {
  const [searchText, setSearchText] = useState("")
  const [isPending, startTransition] = useTransition()

  return (
    <AppsLayout>
      <VStack
        spacing="20px"
        style={{
          padding: "40px",
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
        }}
      >
        <HStack justify="space-between">
          <Typography
            style={{
              color: "#002845",
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Manage Users
          </Typography>
          <HStack spacing="8px">
            {isPending && (
              <Loading
                color={apxTheme.palette.primary.main}
                size={20}
              />
            )}
            <SearchBar
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              onClear={() => {
                setSearchText("")
              }}
              placeholder="Search by user's name/email"
              style={{
                minWidth: "350px",
              }}
            />
          </HStack>
        </HStack>

        <Suspense
          fallback={
            <VStack
              style={{
                minHeight: "90vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading color="primary" />
            </VStack>
          }
        >
          <AppsWithUsersList
            searchText={searchText}
            startTransition={startTransition}
          />
        </Suspense>
      </VStack>
    </AppsLayout>
  )
}
