import { ClickAwayListener, Popper } from "@material-ui/core"
import { useMemo } from "react"

export default function MaterialPopper(props) {
  const { placement = "bottom" } = props

  const position = useMemo(() => {
    if (placement === "top") {
      return { marginBottom: 10 }
    }
    return { marginTop: 5 }
  }, [placement])

  return (
    <ClickAwayListener onClickAway={props.onClickAway}>
      <Popper
        open={props.target !== null}
        anchorEl={props.target}
        style={{
          zIndex: 10,
          ...(props.style || {}),
          ...position,
        }}
        onClick={(e) => e.stopPropagation()}
        placement={placement}
      >
        {props.children}
      </Popper>
    </ClickAwayListener>
  )
}
