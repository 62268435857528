import { withStyles } from "@material-ui/core"
import { Editor, useMonaco } from "@monaco-editor/react"
import React, { useEffect } from "react"
import { APP_PLATFORMS } from "../../../../../../../../../../../constants"

const styles = () => ({
  dialogPaper: {
    minHeight: "calc(100% - 96px)",
    maxHeight: "calc(100% - 96px)",
  },
  search: {
    marginTop: 10,
    width: "100%",
  },
  results: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
})

function SnippetDialogContent({
  isReadOnly = false,
  value,
  onChange,
  onKeyDown,
  platform,
}) {
  const monaco = useMonaco()

  useEffect(() => {
    if (platform !== APP_PLATFORMS.web) {
      monaco?.languages?.register({
        id: "apxor",
      })
      monaco?.languages?.setMonarchTokensProvider("apxor", {
        tokenizer: {
          root: [
            {
              token: "string",
              regex: '"',
              next: "string2",
            },
            {
              token: "constant.numeric",
              regex: "(?:0|[1-9][0-9]*)\\b",
            },
            {
              token: "constant.language.boolean",
              regex: "(?:true|false)\\b",
            },
            {
              token: "keyword",
              regex:
                "(?:format|toInt|if|else|contains|httpGet|replace|onSuccess|onError|true|false)\\b",
            },
            {
              token: "variable",
              regex: "[a-zA-Z\\$_\u00a1-\uffff][a-zA-Z\\d\\$_\u00a1-\uffff]*",
            },
            {
              token: "keyword.operator",
              regex: "\\|\\||&&|!=|==|<=|<|>=|>|\\+|-|\\*|\\/|\\%|\\!",
            },
            {
              token: "paren.rparen",
              regex: /[\])}]/,
            },
            {
              token: "text",
              regex: "\\s+",
            },
          ],
        },
      })

      monaco?.languages.setLanguageConfiguration("apxor", {
        brackets: [
          ["{", "}"],
          ["[", "]"],
          ["(", ")"],
        ],
        comments: {
          lineComment: "--",
        },
      })

      monaco?.languages.registerCompletionItemProvider("apxor", {
        provideCompletionItems: function (model, position, context, token) {
          const completions = []
          const variables = window.apx_variables || []

          ;[
            "format",
            "toInt",
            "if",
            "else",
            "contains",
            "httpGet",
            "onSuccess",
            "onError",
            "true",
            "false",
            "replace",
          ].forEach(function (w) {
            completions.push({
              label: w,
              kind: monaco?.languages.CompletionItemKind.Keyword,
              insertText: w,
              detail: "Apxor DSL Keyword",
            })
          })

          variables.forEach(function (w) {
            completions.push({
              label: w,
              kind: monaco?.languages.CompletionItemKind.Variable,
              insertText: w,
              detail: "Variable",
            })
          })

          return {
            suggestions: completions,
          }
        },
      })
    } else {
      monaco?.languages.registerCompletionItemProvider("javascript", {
        provideCompletionItems: function (model, position, context, token) {
          const completions = []
          const variables = window.apx_variables || []

          variables.forEach(function (w) {
            completions.push({
              label: w,
              kind: monaco?.languages.CompletionItemKind.Variable,
              insertText: w,
              detail: "Variable",
            })
          })

          return {
            suggestions: completions,
          }
        },
      })
    }
  }, [monaco, platform])

  return (
    <div
      style={{
        border: "1px solid rgba(0,40,69,0.25)",
        borderRadius: 4,
        padding: 5,
        marginBottom: 20,
      }}
      onKeyDown={onKeyDown}
    >
      {
        <Editor
          theme="apxor-theme"
          width="100%"
          defaultLanguage={
            platform === APP_PLATFORMS.web ? "javascript" : "apxor"
          }
          defaultValue={value}
          value={value}
          onChange={onChange}
          options={{
            cursorStyle: "line",
            highlightActiveIndentGuide: true,
            formatOnPaste: true,
            formatOnType: true,
            selectOnLineNumbers: true,
            showPrintMargin: true,
            wordWrap: "on",
            fontSize: 16,
            automaticLayout: true,
            rulers: [0],
            smoothScrolling: true,
            lineHeight: 20,
            insertSpaces: true,
            tabSize: 2,
            tabCompletion: true,
            renderLineHighlight: "line",
            renderWhitespace: "boundary",
            showFoldingControls: "always",
            scrollBeyondLastLine: false,
            contextmenu: false,
          }}
          editorProps={{ $blockScrolling: true }}
          height={"220px"}
        />
      }
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {platform !== APP_PLATFORMS.web && <code>Ctrl + C to compile</code>}
      </div>
    </div>
  )
}

export default withStyles(styles)(SnippetDialogContent)
