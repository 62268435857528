import { Button, IconButton, Typography } from "@material-ui/core"
import { IconEdit, IconPlus } from "@tabler/icons-react"
import { Suspense, lazy, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { isSuper } from "../../../config"
import { useAuth } from "../../../contexts/AuthContext"
import { capitalizeEachWord } from "../../../utils"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import { useTrackedState } from "../../Organizations/store"
import HStack from "../../ReusableComponents/HStack"
import SearchBar from "../../ReusableComponents/SearchBar"
import ThemedTooltip from "../../ReusableComponents/ThemedTooltip"
import DialogLoader from "../../ReusableComponents/skeletons/DialogLoader"

const EditOrgDetailsDialog = lazy(
  () => import("../../Organizations/components/EditOrgDetailsDialog"),
)

export default function AppsListingHeader({ searchText, setSearchText }) {
  const {
    user: { email },
  } = useAuth()
  const { orgId } = useParams()
  const {
    org_data: { owner, name, icon_url },
  } = useTrackedState()

  const [openEditOrgDetailsDialog, setOpenEditOrgDetailsDialog] =
    useState(false)

  const isCurrentUserAdminOrOwner = useMemo(
    () => email === owner || isSuper(email),
    [email, owner],
  )

  return (
    <>
      <HStack justify="space-between">
        <HStack spacing="4px">
          {name ? (
            <>
              <Typography
                style={{
                  color: "#002845",
                  fontSize: "24px",
                  fontWeight: "700",
                }}
              >
                {capitalizeEachWord(name)}&apos;s Apps
              </Typography>
              {isCurrentUserAdminOrOwner && (
                <ThemedTooltip
                  title="Edit Org Details"
                  placement="right"
                >
                  <IconButton onClick={() => setOpenEditOrgDetailsDialog(true)}>
                    <IconEdit
                      size={18}
                      color={apxTheme.palette.text.primary}
                    />
                  </IconButton>
                </ThemedTooltip>
              )}
            </>
          ) : (
            <HStack
              style={{
                width: "80px",
                height: "30px",
              }}
            ></HStack>
          )}
        </HStack>
        <HStack spacing="8px">
          <SearchBar
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onClear={() => {
              setSearchText("")
            }}
            placeholder="Search by App Name"
            style={{
              minWidth: "360px",
            }}
          />
          {(owner === email || isSuper(email)) && (
            <Link
              to={`/orgs/${orgId}/apps/create`}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={
                  <IconPlus
                    size={16}
                    color="white"
                  />
                }
                style={{
                  minHeight: "44px",
                }}
              >
                Add Application
              </Button>
            </Link>
          )}
        </HStack>
      </HStack>

      {/* Edit Org Details Dialog */}
      {openEditOrgDetailsDialog && (
        <Suspense fallback={<DialogLoader />}>
          <EditOrgDetailsDialog
            open={openEditOrgDetailsDialog}
            onClose={() => setOpenEditOrgDetailsDialog(false)}
            org={{ name, icon_url }}
          />
        </Suspense>
      )}
    </>
  )
}
