import {
  makeDeleteRequest,
  makeGetRequest,
  makePatchRequest,
  makePostRequest,
  makePutRequest,
} from "../../../../common/actions"

const ART_CONFIGS = "art-configs"
const GET_GLOBAL_THEME_API = "theme"
const UPDATE = "update"
const DEFAULT = "default"
const CUSTOM_FONTS = "fonts"

export function getGlobalThemeAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    `${ART_CONFIGS}/${GET_GLOBAL_THEME_API}`,
    auth,
    appId,
    { configType: queryParams },
    onSuccessCallback,
    onFailureCallback,
  )
}

export function updateGlobalThemeAPI(auth, appId, queryParams, postBody = {}) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response || {}
  }
  return makePutRequest(
    `${ART_CONFIGS}/${GET_GLOBAL_THEME_API}/${UPDATE}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function updateGlobalThemeNameAPI(
  auth,
  appId,
  queryParams,
  postBody = {},
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response || {}
  }
  return makePatchRequest(
    `${ART_CONFIGS}/${GET_GLOBAL_THEME_API}/${UPDATE}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function postThemeAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    `${ART_CONFIGS}/${GET_GLOBAL_THEME_API}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function postDefaultThemeAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    `${ART_CONFIGS}/${GET_GLOBAL_THEME_API}/${DEFAULT}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function deleteThemeAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makeDeleteRequest(
    `${ART_CONFIGS}/${GET_GLOBAL_THEME_API}/delete`,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function addCustomFontAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makePostRequest(
    CUSTOM_FONTS,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getCustomFontsAPI(auth, appId) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    CUSTOM_FONTS,
    auth,
    appId,
    {},
    onSuccessCallback,
    onFailureCallback,
  )
}

export function editCustomFontAPI(auth, appId, queryParams, postBody) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response || {}
  }
  return makePatchRequest(
    CUSTOM_FONTS,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function deleteCustomFontAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makeDeleteRequest(
    CUSTOM_FONTS,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
  )
}
