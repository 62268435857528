import { Button, Typography } from "@material-ui/core"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import { QRCodeSVG } from "qrcode.react"
import { useState } from "react"
import { copyToClipboard } from "../../../../../../../../utils"
import { StyledInput } from "../../../../../../../Profile/pages/ProfilePage"
import HStack from "../../../../../../../ReusableComponents/HStack"
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection"
import VStack from "../../../../../../../ReusableComponents/VStack"
import { useModuleConfigStyles } from "./utils"

export default function MobileWebLaunchURL({
  defaultUrl = "",
  url,
  setURL,
  generateQRCodeCallback,
  title = "",
}) {
  const moduleConfigClasses = useModuleConfigStyles()

  const [qrCodeURL, setQrCodeURL] = useState("")
  const [isQRGenerated, setIsQRGenerated] = useState(false)

  const generateQR = () => {
    const qrCodeURL = generateQRCodeCallback()
    setQrCodeURL(qrCodeURL)
  }

  return (
    <>
      <HStack
        spacing={8}
        align={"end"}
      >
        <ModuleConfigSection
          title={title}
          style={{
            marginTop: 0,
            alignItems: "center",
            gap: 2,
            minWidth: "270px",
          }}
          typographyClassName={moduleConfigClasses.moduleConfigRoot}
          typographyStyleProps={{ fontSize: 12 }}
        >
          <HStack
            justify="space-between"
            spacing="8px"
          >
            <StyledInput
              style={{
                fontSize: "15px",
                fontWeight: "600",
                padding: "2px 4px",
              }}
              autoFocus
              placeholder="https://your-url.com"
              defaultValue={defaultUrl}
              value={url}
              onChange={(e) => {
                setURL(e.target.value)
              }}
            />
            {isQRGenerated && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={!url || url === "" || (url.trim && url.trim === "")}
                onClick={() => {
                  generateQR()
                }}
                style={{
                  padding: "10.5px 20px",
                }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "700",
                  }}
                >
                  Generate QR
                </Typography>
              </Button>
            )}
            {qrCodeURL === "" && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={!url || url === "" || (url.trim && url.trim === "")}
                onClick={() => {
                  generateQR()
                  setIsQRGenerated(true)
                }}
                style={{
                  padding: "10.5px 20px",
                }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "700",
                  }}
                >
                  Generate QR
                </Typography>
              </Button>
            )}
          </HStack>
        </ModuleConfigSection>
      </HStack>
      {qrCodeURL !== "" && (
        <VStack
          spacing={9}
          style={{ maxWidth: "150px" }}
        >
          <QRCodeSVG
            value={qrCodeURL}
            height="150px"
            width="150px"
          />
          <Button
            variant="text"
            startIcon={<FileCopyOutlinedIcon />}
            color="default"
            onClick={() => {
              copyToClipboard(qrCodeURL)
            }}
          >
            Copy Link
          </Button>
        </VStack>
      )}
    </>
  )
}
