import { withStyles } from "@material-ui/core"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

const ExpansionPanel = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.border.primary}`,
    boxShadow: "none",
    marginBottom: 4,
    "&:before": {
      display: "none",
    },
  },
  expanded: {
    margin: "auto",
    marginBottom: 4,
  },
}))(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.primary.paper}`,
    minHeight: 36,
    padding: "4px 12px",
    "&$expanded": {
      minHeight: 36,
      borderRadius: 0,
    },
    borderBottom: `2px solid ${theme.palette.text.light}`,
    borderRadius: 0,
  },
  content: {
    margin: "4px 0",
    "&$expanded": {
      margin: "4px 0",
    },
  },
  expanded: {
    borderRadius: 0,
  },
}))((props) => <MuiExpansionPanelSummary {...props} />)

ExpansionPanelSummary.muiName = "ExpansionPanelSummary"

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: "12px",
    display: "block",
  },
}))(MuiExpansionPanelDetails)

class ExpansionModuleConfig extends React.Component {
  render() {
    const {
      children,
      titleTypoStyles = {
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: 16,
      },
      title,
      detailsStyles = {},
      description,
      descriptionTypoStyles,
      defaultExpanded,
      iconStyles,
      rootStyles = {
        border: "none",
        boxShadow: "none",
      },
      summaryStyles = {},
      summaryContentClass,
      ...props
    } = this.props
    return (
      <ExpansionPanel
        defaultExpanded={defaultExpanded || false}
        square={false}
        style={{ borderRadius: 5, ...rootStyles }}
        {...props}
      >
        <ExpansionPanelSummary
          style={{
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            ...summaryStyles,
          }}
          expandIcon={
            <ExpandMoreIcon
              color="primary"
              style={{ ...iconStyles }}
            />
          }
          classes={{
            content: summaryContentClass,
          }}
        >
          <div style={{ display: "inline-block" }}>
            {title && (
              <Typography
                variant="h6"
                style={{ ...titleTypoStyles }}
              >
                {title || ""}
              </Typography>
            )}
            {description && (
              <Typography style={{ ...descriptionTypoStyles }}>
                {description || ""}
              </Typography>
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ ...detailsStyles }}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default ExpansionModuleConfig
