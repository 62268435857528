import { makeStyles } from "@material-ui/core"

export const TYPOGRAPHY_HEADING = {
  fontWeight: 800,
  fontSize: 14,
  letterSpacing: "0.11em",
  lineHeight: "19px",
}
export const TYPOGRAPHY_CAPTION = {
  fontWeight: 600,
  fontSize: 13,
  lineHeight: "17px",
  letterSpacing: "0.11em",
  color: "#002845",
}

export const useCommonDashboardStyles = makeStyles((theme) => ({
  gridItem: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 4,
    marginBottom: 4,
  }),
  gridContainer: (props) => ({
    overflowY: "scroll",
    maxHeight: 595,
  }),
  gridItemVertical: (props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  typographySubtitle2: (props) => ({
    fontWeight: 800,
    fontSize: 12,
  }),
  typographyCaption: (props) => ({
    ...TYPOGRAPHY_CAPTION,
  }),
  typographyHeading: (props) => ({
    ...TYPOGRAPHY_HEADING,
  }),
  typographyCaption500: (props) => ({
    ...TYPOGRAPHY_CAPTION,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "0.01em",
    color: "#667A8A",
  }),
  formControlLabel: (props) => ({
    marginRight: 0,
  }),
}))
