import { Box } from "@material-ui/core"
import PropTypes from "prop-types"
import { forwardRef } from "react"

const VStack = forwardRef(
  (
    {
      component = "div",
      justify = "start",
      align = "stretch",
      spacing = "1rem",
      children,
      style = {},
      ...props
    },
    ref,
  ) => {
    return (
      <Box
        itemRef={ref}
        component={component}
        display="flex"
        flexDirection="column"
        justifyContent={justify}
        alignItems={align}
        style={{
          flexDirection: "column",
          justifyContent: justify,
          alignItems: align,
          gap: spacing,
          ...style,
        }}
        {...props}
      >
        {children}
      </Box>
    )
  },
)

export default VStack

export const justifyOptions = [
  "flex-start",
  "start",
  "flex-end",
  "end",
  "center",
  "space-between",
  "space-around",
  "space-evenly",
]

export const alignOptions = [
  "flex-start",
  "start",
  "flex-end",
  "end",
  "center",
  "stretch",
]

VStack.propTypes = {
  component: PropTypes.elementType,
  justify: PropTypes.oneOf(justifyOptions),
  align: PropTypes.oneOf(alignOptions),
  spacing: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}
