import { IconButton } from "@material-ui/core"
import { IconTrash } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { useAuth } from "../../../../../../contexts/AuthContext"
import useDebounced from "../../../../../../utils/use-debounced"
import { apxTheme } from "../../../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../../../ReusableComponents/HStack"
import ThemedTooltip from "../../../../../ReusableComponents/ThemedTooltip"
import { getLabelForOperator } from "../../../../common/components/campaigncreator/components/context/components/NewContextBuilder/components/NewPropertyRow"
import {
  IndicatorSeparator,
  Option,
} from "../../../../common/components/campaigncreator/components/context/components/targetaudience/components/selects/DidNotDoEventsComboBox"
import { useSelector } from "../../../bi-reports/store"
import { getBiDimensionsValuesAPI } from "../../action"
import { customStyles, getOptionsForTypeForBiDashboard } from "../../utils"

export default function EventBuilderForVisualization({
  index,
  property = { name: "", operator: "EQ", data_type: "", value: [] },
  handleOnChange = () => {},
  handleOnDelete = () => {},
  isBIReports = false,
  id = "",
}) {
  const { name = "", operator = "EQ", data_type = "", value = [] } = property
  const { appId } = useParams()
  const { attributes } = useSelector()
  const auth = useAuth()
  const queryClient = useQueryClient()
  const [query, setQuery] = useState("")
  const dimensions = isBIReports
    ? attributes
    : (queryClient.getQueryData(["bi-dimensions", auth, appId]) ?? [])
  const [eventAttributeValues, setEventAttributeValues] = useState([])

  const getDimensionsValues = (attrName, q = "") => {
    getBiDimensionsValuesAPI(auth, appId, attrName, {
      since: moment().subtract(3, "months").toISOString(),
      till: moment().toISOString(),
      q: q,
    }).then((response) => {
      setEventAttributeValues(response)
    })
  }

  const handleSearch = useDebounced((value) => {
    setQuery(value)
  }, 1000)

  useEffect(() => {
    if (name !== "") {
      getDimensionsValues(name, query)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, query])

  return (
    <HStack
      align={"center"}
      spacing={12}
      id={`filters-${index}${id ? `-${id}` : ""}`}
    >
      <HStack
        align={"center"}
        spacing={12}
      >
        {!isBIReports && (
          <div style={{ width: 30, borderBottom: "1px solid #C5CDD2" }} />
        )}
        <Select
          inputId="property"
          placeholder="Select Property"
          isSearchable={true}
          closeMenuOnSelect={true}
          isClearable={true}
          options={
            isBIReports
              ? attributes
              : dimensions.map((p) => ({
                  label: p.attribute,
                  value: p.attribute,
                }))
          }
          menuPosition="fixed"
          isMulti={false}
          onChange={(pair) => {
            handleOnChange({
              type: "property",
              value: pair ? pair.value : "",
            })
          }}
          value={name === "" ? "" : { label: name, value: name }}
          styles={{
            ...customStyles,
            control: (base, state) => ({
              ...base,
              primary: "orange",
              "&:hover": { borderColor: "none" },
              width: 200,
              paddingInline: 6,
              paddingBlock: 0,
              fontWeight: 500,
              fontSize: 13,
              border: "none",
              boxShadow: state.isFocused
                ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
                : `0 0 0 ${state.hasValue ? "1px #CCD4DA" : "1.5px #D64A40"}`,
              borderRadius: 12,
              backgroundColor: apxTheme.palette.common.white,
              color: state.isFocused
                ? apxTheme.palette.primary.main
                : apxTheme.palette.text.primary,
            }),
          }}
          components={{
            Option,
            IndicatorSeparator,
          }}
        />

        {name !== "" && (
          <>
            <Select
              isDisabled={false}
              inputId="operator"
              placeholder="operator"
              menuPosition="fixed"
              isSearchable={true}
              closeMenuOnSelect={true}
              isClearable={true}
              options={getOptionsForTypeForBiDashboard(data_type)}
              isMulti={false}
              onChange={(pair) => {
                handleOnChange({
                  type: "operator",
                  value: pair?.value || "",
                })
              }}
              value={
                operator === ""
                  ? ""
                  : {
                      label: getLabelForOperator(operator),
                      value: operator,
                    }
              }
              styles={{
                ...customStyles,
                control: (base, state) => ({
                  ...base,
                  primary: "orange",
                  "&:hover": { borderColor: "none" },
                  width: 100,
                  paddingInline: 6,
                  paddingBlock: 0,
                  fontWeight: 500,
                  fontSize: 13,
                  border: "none",
                  boxShadow: state.isFocused
                    ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
                    : `0 0 0 ${state.hasValue ? "1px #CCD4DA" : "1.5px #D64A40"}`,
                  borderRadius: 12,
                  backgroundColor: apxTheme.palette.common.white,
                  color: state.isFocused
                    ? apxTheme.palette.primary.main
                    : apxTheme.palette.text.primary,
                }),
              }}
              components={{
                Option,
                IndicatorSeparator,
              }}
            />
            <Select
              isDisabled={false}
              inputId="value"
              menuPosition="fixed"
              placeholder="Property value"
              isSearchable={true}
              closeMenuOnSelect={true}
              isClearable={true}
              options={eventAttributeValues.map((p) => ({
                label: p,
                value: p,
              }))}
              isMulti={data_type !== "b"}
              onChange={(pair) => {
                handleOnChange({
                  type: "property-value",
                  value: pair !== null ? pair.map((p) => p.value) : [],
                })
              }}
              value={value.map((p) => ({
                label: p,
                value: p,
              }))}
              styles={{
                ...customStyles,
                control: (base, state) => ({
                  ...base,
                  primary: "orange",
                  "&:hover": { borderColor: "none" },
                  minWidth: 200,
                  paddingInline: 6,
                  paddingBlock: 0,
                  fontWeight: 500,
                  fontSize: 13,
                  border: "none",
                  boxShadow: state.isFocused
                    ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
                    : `0 0 0 ${state.hasValue ? "1px #CCD4DA" : "1.5px #D64A40"}`,
                  borderRadius: 12,
                  backgroundColor: apxTheme.palette.common.white,
                  color: state.isFocused
                    ? apxTheme.palette.primary.main
                    : apxTheme.palette.text.primary,
                }),
              }}
              onInputChange={(q) => {
                handleSearch(q)
              }}
              components={{
                Option,
                IndicatorSeparator,
              }}
            />
          </>
        )}
      </HStack>

      <ThemedTooltip
        id={index}
        title="Remove"
        dark
      >
        <IconButton
          size="small"
          aria-label="Delete"
          style={{
            marginBottom: "6px",
          }}
          onClick={() => {
            handleOnDelete(index)
          }}
        >
          <IconTrash size={"20px"} />
        </IconButton>
      </ThemedTooltip>
    </HStack>
  )
}
