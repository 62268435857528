import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@material-ui/core"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Editor, useMonaco } from "@monaco-editor/react"
import React, { useEffect, useState } from "react"
import HStack from "../../../../ReusableComponents/HStack"
import VStack from "../../../../ReusableComponents/VStack"
import WhitePaper from "../../../../ReusableComponents/WhitePaper"
export default function AceCustomFormulaQueryBuilder({
  value,
  events = [],
  onChange,
  error = "",
  isReports = false,
  dimensionDerived = [],
}) {
  const [open, setOpen] = useState(false)
  const monaco = useMonaco()

  useEffect(() => {
    monaco?.languages?.register({
      id: "apxor",
    })
    monaco?.languages?.setMonarchTokensProvider("apxor", {
      tokenizer: {
        root: [
          {
            token: "string",
            regex: '"',
            next: "string2",
          },
          {
            token: "constant.numeric",
            regex: "(?:0|[1-9][0-9]*)\\b",
          },
          {
            token: "constant.language.boolean",
            regex: "(?:true|false)\\b",
          },
          {
            token: "keyword",
            regex:
              "(?:format|toInt|if|else|contains|httpGet|replace|onSuccess|onError|true|false)\\b",
          },
          {
            token: "variable",
            regex: "[a-zA-Z\\$_\u00a1-\uffff][a-zA-Z\\d\\$_\u00a1-\uffff]*",
          },
          {
            token: "keyword.operator",
            regex: "\\|\\||&&|!=|==|<=|<|>=|>|\\+|-|\\*|\\/|\\%|\\!",
          },
          {
            token: "paren.rparen",
            regex: /[\])}]/,
          },
          {
            token: "text",
            regex: "\\s+",
          },
        ],
      },
    })

    monaco?.languages.setLanguageConfiguration("apxor", {
      brackets: [
        ["{", "}"],
        ["[", "]"],
        ["(", ")"],
      ],
      comments: {
        lineComment: "--",
      },
    })

    monaco?.languages.registerCompletionItemProvider("apxor", {
      provideCompletionItems: function (model, position, context, token) {
        const completions = []
        const variables = []

        const defaultSuggestions = [
          { key: "sum", value: "sum(C1)" },
          {
            key: "sumIf",
            value: "sumIf(C1, conditions)",
          },
          { key: "avg", value: "avg(C1)" },
          {
            key: "avgIf",
            value: "avgIf(C1, conditions)",
          },
          {
            key: "count",
            value: "count(C1)",
          },
          {
            key: "countIf",
            value: "countIf(C1, conditions)",
          },
          {
            key: "uniq",
            value: "uniq(C1)",
          },
          {
            key: "uniqIf",
            value: "uniqIf(C1, conditions)",
          },
          { key: "min", value: "min(C1)" },
          { key: "max", value: "max(C1)" },
          {
            key: "least",
            value: "least(C1, C2)",
          },
          {
            key: "greatest",
            value: "greatest(C1, C2)",
          },
          {
            key: "date_diff",
            value: "date_diff('second',C1, C2)",
          },
          {
            key: "abs",
            value: "abs(C1)",
          },
          {
            key: "toInt",
            value: "toInt(C1)",
          },
          {
            key: "secondsToTimestamp",
            value: "secondsToTimestamp(C1)",
          },
          {
            key: "millisToTimestamp",
            value: "millisToTimestamp(C1)",
          },

          {
            key: "toTimeStamp",
            value: "toTimeStamp(C1)",
          },
          {
            key: "toWeekDay",
            value: "toWeekDay(C1)",
          },
          {
            key: "toUTCHour",
            value: "toUTCHour(C1)",
          },
          {
            key: "toMonth",
            value: "toMonth(C1)",
          },
          {
            key: "toDate",
            value: "toDate(C1)",
          },
        ]

        defaultSuggestions.forEach(function (w) {
          completions.push({
            label: w.key,
            kind: monaco?.languages.CompletionItemKind.Keyword,
            insertText: `${w.key}()`,
            detail: w.value,
          })
        })

        events.forEach(function (w) {
          completions.push({
            label: w.event_name,
            kind: monaco?.languages.CompletionItemKind.Event,

            insertText: `{${String.fromCharCode(
              ...(w?.index + "").split("").map((digit) => parseInt(digit) + 64),
            )}}`,
            detail: `{${String.fromCharCode(
              ...(w?.index + "").split("").map((digit) => parseInt(digit) + 64),
            )}}`,
          })
        })

        dimensionDerived.length > 0 &&
          dimensionDerived.forEach(function (e) {
            completions.push({
              label: e.name,
              kind: monaco?.languages.CompletionItemKind.Event,
              insertText: `\`alias-${e.name}\``,
              detail: "Derived Dimensions",
            })
          })
        // const operators = ["+", "-", "*", "/"]

        // operators.forEach(function (op) {
        //   completions.push({
        //     label: op,
        //     kind: monaco?.languages.CompletionItemKind.Operator,
        //     insertText: op,
        //     detail: "Operator",
        //   })
        // })

        variables.forEach(function (w) {
          completions.push({
            label: w,
            kind: monaco?.languages.CompletionItemKind.Variable,
            insertText: w,
            detail: "Variable",
          })
        })

        return {
          suggestions: completions,
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, dimensionDerived, monaco])

  return (
    <VStack
      justify={"center"}
      align={"center"}
      component={WhitePaper}
      style={{
        width: "100%",
        padding: 5,
        paddingRight: 10,
        borderRadius: 8,
      }}
    >
      <Editor
        theme="github"
        width="100%"
        defaultLanguage={"apxor"}
        defaultValue={value}
        value={value}
        onChange={onChange}
        options={{
          cursorStyle: "line",
          highlightActiveIndentGuide: false,
          formatOnPaste: true,
          formatOnType: true,
          selectOnLineNumbers: false,
          showPrintMargin: true,
          wordWrap: "on",
          fontSize: 16,
          automaticLayout: true,
          // rulers: [0],
          smoothScrolling: true,
          lineHeight: 20,
          insertSpaces: true,
          tabSize: 2,
          tabCompletion: true,
          renderLineHighlight: "none",
          renderWhitespace: "boundary",
          showFoldingControls: "always",
          scrollBeyondLastLine: false,
          contextmenu: false,
          lineNumbers: "off",
          overviewRulerLanes: 0,
          folding: false,
          glyphMargin: false,
          minimap: { enabled: false },
        }}
        editorProps={{ $blockScrolling: false }}
        height={"100px"}
      />

      {error !== "" && (
        <Accordion
          expanded={open}
          style={{
            width: "100%",
            backgroundColor: "rgb(253, 237, 239)",
            color: "rgb(96, 32, 40)",
          }}
        >
          <AccordionSummary
            expandIcon={
              <IconButton
                size="medium"
                onClick={() => setOpen(!open)}
              >
                <ExpandMoreIcon
                  style={{
                    color: "rgb(96, 32, 40)",
                  }}
                />
              </IconButton>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HStack
              spacing={6}
              align={"center"}
            >
              <ErrorOutlineIcon />
              <Typography
                style={{
                  color: "rgb(96, 32, 40)",
                }}
              >
                {String(error)?.slice(0, 50) + "..."}
              </Typography>
            </HStack>
          </AccordionSummary>
          <AccordionDetails
            style={{
              width: "100%",
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                color: "rgb(96, 32, 40)",
                display: "inline",
                whiteSpace: "pre-wrap",
                wordBreak: "break-all",
              }}
            >
              {error}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </VStack>
  )
}
