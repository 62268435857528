import { callApi } from "../../../../api"
import { makeDefaultQueryString, withQueryStrings } from "../../../../utils"
import { makeGetRequest, makePostRequest } from "../../../common/actions"

const CUSTOM_REPORTS_API = "custom-reports"

const EXPLORER_API = "custom-reports/explorer"
const FLAT_TABLE_API = "derived/custom-reports/flat-table"
const NEW_BI_DASHBOARD = "bi-adoption/frontendapi/"

export function getFlatTableAPI(auth, appId, queryParams = {}, postBody = {}) {
  const url =
    makeDefaultQueryString(FLAT_TABLE_API, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "POST",
    auth: auth,
    body: postBody,
  }

  const onSuccessCallback = (json) => {
    const { aggregations, data = [] } = json || {}
    return {
      ...json,
      aggregations,
      data: data.map(({ dimensions, ...o }) => ({ ...dimensions, ...o })),
    }
  }
  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    false,
    false,
  )
}

export function getExplorerAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    EXPLORER_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getFlatTableForIdAPI(auth, appId, queryParams) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makeGetRequest(
    FLAT_TABLE_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function biSaveCustomReportAPI(
  auth,
  appId,
  queryParams = {},
  postBody = {},
) {
  const url =
    makeDefaultQueryString("derived/custom-reports", auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "POST",
    auth: auth,
    body: postBody,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    true,
    false,
  )
}

export function biUpdateCustomReportAPI(
  auth,
  appId,
  queryParams = {},
  postBody = {},
) {
  const url =
    makeDefaultQueryString("derived/custom-reports", auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "PUT",
    auth: auth,
    body: postBody,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    true,
    false,
  )
}

export function saveCustomReportAPI(auth, appId, queryParams, filters) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    CUSTOM_REPORTS_API,
    auth,
    appId,
    queryParams,
    filters,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getCustomReportsAPI(auth, appId, queryParams) {
  const url =
    makeDefaultQueryString("derived/custom-reports", auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "GET",
    auth: auth,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    true,
    false,
  )
}

export function getCustomReportAPI(auth, appId, reportId, queryParams) {
  const url =
    makeDefaultQueryString(`derived/custom-reports/${reportId}`, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "GET",
    auth: auth,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    true,
    false,
  )
}

export function deleteCustomReportAPI(auth, appId, reportId, queryParams) {
  const url =
    makeDefaultQueryString(`derived/custom-reports/${reportId}`, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "DELETE",
    auth: auth,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    true,
    false,
  )
}
