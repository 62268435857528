import IconButton from "@material-ui/core/ButtonBase"
import { IconCloudCheck, IconExclamationCircle } from "@tabler/icons-react"
import axios from "axios"
import { Suspense, lazy, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "sonner"
import { useAuth } from "../../contexts/AuthContext"
import { withQueryStrings } from "../../utils"
import useApxorClient from "../../utils/use-apxor-client"
import {
  useDeepCompareCallback,
  useDeepCompareMemo,
} from "../../utils/use-deep-compare"
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS"
import {
  PERMISSIONS_ENUM,
  ROLES_ENUM,
} from "../Dashboard/components/settings/constants"
import { useTrackedState } from "../IntegrationExperience/store"
import { DOWNLOAD_CONFIG_TYPES } from "./DownloadNameDialog"
import HStack from "./HStack"
import Loading from "./Loading"
import ThemedTooltip from "./ThemedTooltip"
import DialogLoader from "./skeletons/DialogLoader"

const DownloadNameDialog = lazy(() => import("./DownloadNameDialog"))

const DIALOG_TYPES = Object.freeze({
  OPEN: "OPEN",
  CLOSED: "CLOSED",
})

export default function DownloadButton({
  url,
  data,
  method,
  children,
  downloadReportTitle,
}) {
  const [state, setState] = useState({
    downloadInitiateSuccess: false,
    downloadInProgress: false,
    hasEncounteredError: false,
  })

  const auth = useAuth()
  const {
    user: { email },
  } = auth
  const { orgId, appId } = useParams()
  const { app_data } = useTrackedState()

  const currentUserFromAppState = useDeepCompareMemo(() => {
    return app_data?.basic_info?.customers?.find(
      (customer) => customer?.customer_id === email,
    )
  }, [app_data, email])

  const { logEvent } = useApxorClient()

  const [openDialog, setOpenDialog] = useState(DIALOG_TYPES.CLOSED)
  const [error, setError] = useState("")
  const onDownload = useDeepCompareCallback(
    async (name) => {
      try {
        setState({
          ...state,
          downloadInProgress: true,
        })
        setError("")

        const response = await axios.request({
          url: `${url}${withQueryStrings({
            displayName: name,
          })}`,
          method,
          data,
          withCredentials: true,
        })

        if (response.data.hasOwnProperty("id")) {
          setState({
            ...state,
            downloadInitiateSuccess: true,
          })

          toast("Download Completed!", {
            icon: <IconCloudCheck size={20} />,
            action: {
              label: "Visit",
              onClick: () => {
                window.open(
                  `/orgs/${orgId}/apps/${appId}/settings/downloads`,
                  "_blank",
                )
              },
            },
          })

          logEvent("CR_Downloaded", {
            metrics: data.metrics,
            dimensions: data.dimensions,
            filters: data.filters,
          })
          setOpenDialog(DIALOG_TYPES.CLOSED)
        }
      } catch (error) {
        if (
          (!error?.createDownloadModelStatus &&
            !error.downloadServiceStatus &&
            error?.id === "") ||
          (!error?.response?.data?.createDownloadModelStatus &&
            !error?.response?.data?.downloadServiceStatus &&
            error?.response?.data?.id === "")
        ) {
          setError(
            "The specified file name is already in use. Please select a unique file name for your custom report.",
          )
        } else {
          toast.error(error.message, {
            icon: (
              <IconExclamationCircle
                size={20}
                color="red"
              />
            ),
          })
          setOpenDialog(DIALOG_TYPES.CLOSED)
        }
      } finally {
        setState({
          ...state,
          downloadInProgress: false,
        })
      }
    },
    [appId, auth, orgId],
  )

  const showButton =
    currentUserFromAppState?.role !== ROLES_ENUM.SUPPORT.value ||
    (currentUserFromAppState?.role === ROLES_ENUM.SUPPORT.value &&
      currentUserFromAppState?.permissions?.includes(
        PERMISSIONS_ENUM.REPORT_DOWNLOAD.value,
      ))

  return (
    <HStack
      spacing={0}
      align="center"
      style={{
        marginLeft: 8,
      }}
    >
      {/* Button */}
      {showButton && (
        <ThemedTooltip
          dark
          title="Download as CSV"
        >
          <IconButton
            size="small"
            style={{
              backgroundColor: "#fff",
              border: `1px solid ${apxTheme.palette.action.selected}`,
              padding: 8,
              borderRadius: 12,
            }}
            onClick={() => {
              setOpenDialog(DIALOG_TYPES.OPEN)
            }}
            disabled={state.downloadInProgress}
          >
            {state.downloadInProgress ? (
              <Loading
                color="primary"
                size={20}
              />
            ) : (
              <>{children}</>
            )}
          </IconButton>
        </ThemedTooltip>
      )}

      {/* Download Dialog */}
      {openDialog === DIALOG_TYPES.OPEN && (
        <Suspense fallback={<DialogLoader />}>
          <DownloadNameDialog
            name={downloadReportTitle}
            open={openDialog === DIALOG_TYPES.OPEN}
            onClose={() => {
              setOpenDialog(DIALOG_TYPES.CLOSED)
              setState({
                ...state,
                downloadInProgress: false,
              })
            }}
            onDownload={onDownload}
            configType={DOWNLOAD_CONFIG_TYPES.CUSTOM_REPORT}
            error={error}
          />
        </Suspense>
      )}
    </HStack>
  )
}
