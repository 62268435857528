import { callApi } from "../../../../api"
import { makeDefaultQueryString } from "../../../../utils"
import { makeGetRequest, makePostRequest } from "../../../common/actions"

const COHORT_UPLOAD = "upload/async/cohort"

const GET_COHORTS_API = "cohorts"
const UPDATE_COHORT_API = "cohorts/update"

export function uploadCohortAPI(auth, appId, cohortDetails) {
  const { cohortName, cohortDescription = "", cohortFile } = cohortDetails
  let url = makeDefaultQueryString(COHORT_UPLOAD, auth, appId)

  const qp = new URLSearchParams({
    cohortName,
    description: cohortDescription,
  })

  url += `&${qp.toString()}`
  let formData = new FormData()
  formData.append("cohort", cohortFile, cohortFile?.name ?? "")
  const config = {
    method: "POST",
    auth: auth,
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  }
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function updateCohort(auth, appId, cohortId, since, till) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makePostRequest(
    UPDATE_COHORT_API,
    auth,
    appId,
    { cohortId, since, till },
    {},
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getCohorts(auth, appId) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }
  return makeGetRequest(
    GET_COHORTS_API,
    auth,
    appId,
    null,
    onSuccessCallback,
    onFailureCallback,
  )
}
