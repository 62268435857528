import { Button, IconButton, Popover, Typography } from "@material-ui/core"
import { IconPlus } from "@tabler/icons-react"
import { useCallback, useState } from "react"
import {
  useDeepCompareCallback,
  useDeepCompareMemo,
} from "../../../../../utils/use-deep-compare"
import { StyledInputLabel } from "../../../../Profile/pages/ProfilePage"
import ActionsTextField from "../../../../ReusableComponents/ActionsTextField"
import HStack from "../../../../ReusableComponents/HStack"
import SegmentedControl from "../../../../ReusableComponents/SegmentedControl"
import VStack from "../../../../ReusableComponents/VStack"
import {
  IndicatorSeparator,
  Option,
} from "../../../common/components/campaigncreator/components/context/components/targetaudience/components/selects/DidNotDoEventsComboBox"

import Select from "react-select"
import { apxTheme } from "../../../../ApplicationFrame/styling/CustomCSS"
import ThemedTooltip from "../../../../ReusableComponents/ThemedTooltip"
import AceCustomFormulaQueryBuilder from "../../bi-dashboards/components/AceCustomFormulaQueryBuilder"
import EventAccordion from "../../bi-dashboards/components/EventAccordion"
import { TOTAL_TRANSFORMATION } from "../../bi-dashboards/utils"
import { REPORTS_DIMENSION } from "../constants"
import { useActions, useSelector } from "../store"

const dimensionType = ["normal", "derived"]
const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 54,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 200,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    ...(!state.hasValue && {
      boxShadow: `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`,
    }),
    borderRadius: 12,
    backgroundColor: "#F8FAFC",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#94A3B8",
    fontWeight: 500,
    fontSize: 15,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 12,
    padding: 12,
    zIndex: 50,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
    zIndex: 99999,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

export default function DimensionAddition({
  open,
  onClose,
  anchorEl,
  instantDimensions,
  setInstantDimensions,
  isEdit = false,
  idx = -1,
}) {
  const { dimension, attributes, dimensionWithType } = useSelector()
  const { setDimension } = useActions()
  const [attribute_type, setAttribute_type] = useState(dimension.data_type)

  const [customFormulaData, setCustomFormulaData] = useState({
    events:
      Object.keys(dimension.vmap).length > 0
        ? Object.entries(dimension?.vmap).map(([key, value], index) => ({
            event_name: value.id,
            index: index + 1,
            data_type: value.data_type,
          }))
        : [],
    formulas: [],
    queries: dimension.formula ?? "",
  })

  const handleChange = useCallback(
    (instantDimensions) => {
      setInstantDimensions(instantDimensions)
    },
    [setInstantDimensions],
  )

  const handleAddDimension = useDeepCompareCallback(
    (dimension) => {
      if (dimension) {
        const updated = [...instantDimensions, dimension]
        setInstantDimensions(updated)
        handleChange(updated)
      }
    },
    [instantDimensions, handleChange, setInstantDimensions],
  )

  const handleUpdateDimension = useDeepCompareCallback(
    (index, newValues) => {
      const updatedDimensions = instantDimensions.map((dimension, i) =>
        i === index ? { ...dimension, ...newValues } : { ...dimension },
      )
      setInstantDimensions(updatedDimensions)
      handleChange(updatedDimensions)
    },
    [instantDimensions, handleChange, setInstantDimensions],
  )

  const remainingAttributes = useDeepCompareMemo(() => {
    const filterDimensionByType =
      attribute_type !== ""
        ? dimensionWithType
            .filter((p) => p.typeOf === attribute_type)
            .map((p) => p.attribute)
        : []
    const removeUnFilterDataFromAttributes =
      filterDimensionByType.length !== 0
        ? attributes.filter((attribute) =>
            filterDimensionByType.includes(attribute.value),
          )
        : attributes
    return removeUnFilterDataFromAttributes
  }, [attributes, dimension.vmap, attribute_type])

  const handlingError = () => {
    if (dimension.prop_type === "normal") {
      if (dimension.id === "") {
        return true
      }
      return false
    } else {
      if (dimension.alias === "") {
        return true
      }
      if (
        customFormulaData.queries === "" ||
        customFormulaData.events.length === 0
      ) {
        return true
      }
      if (customFormulaData.events.some((item) => item.event_name === "")) {
        return true
      }
      return false
    }
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <VStack
          justify={"space-between"}
          style={{
            minWidth: "500px",
            padding: "32px",
          }}
        >
          <VStack>
            <HStack justify={"space-between"}>
              <Typography variant="subtitle2">Dimension Type</Typography>
              <SegmentedControl
                value={dimension?.prop_type ?? ""}
                onChange={(value) => {
                  if (value === "derived") {
                    setCustomFormulaData({
                      events: Object.keys(dimension.vmap).length
                        ? Object.entries(dimension?.vmap).map(
                            ([key, value], index) => ({
                              event_name: value.id,
                              index: index + 1,
                              data_type: value.data_type,
                            }),
                          )
                        : [
                            {
                              event_name: "",
                              index: 1,
                              data_type: "",
                            },
                          ],
                      formulas: [],
                      queries: dimension.formula ?? "",
                    })
                  }
                  if (value !== dimension.prop_type) {
                    const dim = {
                      ...REPORTS_DIMENSION.dimension,
                      prop_type: value,
                      vmap: {
                        A: {
                          id: "",
                          data_type: "",
                        },
                      },
                    }
                    setAttribute_type("")
                    setDimension(dim)
                  } else {
                    const dim = {
                      ...dimension,
                      prop_type: value,
                    }
                    setDimension(dim)
                  }
                }}
                items={dimensionType.map((x) => ({
                  name: x.charAt(0).toUpperCase() + x.slice(1),
                  value: x,
                }))}
                size="sm"
              />
            </HStack>
            <VStack>
              <StyledInputLabel htmlFor="name">
                Alias
                {dimension?.prop_type !== "normal" && (
                  <sup
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </sup>
                )}
              </StyledInputLabel>

              <ActionsTextField
                id="name"
                autoFocus
                name="name"
                placeholder={
                  dimension?.prop_type !== "normal" ? "" : "optional"
                }
                value={dimension.alias ?? ""}
                onChange={(e) => {
                  const dim = {
                    ...dimension,
                    alias: e.target.value,
                  }
                  setDimension(dim)
                }}
                style={{ marginBottom: 0 }}
                InputProps={{
                  style: {
                    backgroundColor: "#F8FAFC",
                    padding: 6,
                    fontWeight: 600,
                    fontSize: 15,
                    color: "rgba(0, 40, 69)",
                    borderWidth: "1px",
                    borderRadius: 12,
                    minHeight: 36,
                    width: "100%",
                  },
                }}
              />
            </VStack>
            {dimension.prop_type === "normal" && (
              <VStack style={{ minHeight: "180px" }}>
                <StyledInputLabel htmlFor="dimension-select">
                  Dimension
                </StyledInputLabel>
                <Select
                  inputId="dimension-select"
                  placeholder="Select dimension"
                  noOptionsMessage={() => (
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: 13,
                      }}
                    >
                      No Properties Found
                    </Typography>
                  )}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  options={remainingAttributes}
                  onChange={(pair) => {
                    const value = pair !== null ? pair.value : ""
                    const dataType =
                      value !== "" && value !== undefined && value !== null
                        ? dimensionWithType.find((p) => p.attribute === value)
                            .typeOf
                        : ""
                    const dim = {
                      ...dimension,
                      id: value,
                      data_type: dataType,
                    }
                    setAttribute_type(dataType)
                    setDimension(dim)
                  }}
                  value={
                    dimension.id !== ""
                      ? {
                          label: dimension.id,
                          value: dimension.id,
                        }
                      : null
                  }
                  maxMenuHeight={150}
                  styles={{
                    ...customStyles,
                    control: (base, state) => ({
                      ...base,
                      minHeight: 43,
                      primary: "orange",
                      "&:hover": { borderColor: "none" },
                      minWidth: 200,
                      width: "100%",
                      paddingInline: 6,
                      paddingBlock: 0,
                      fontWeight: 500,
                      fontSize: 13,
                      border: "none",
                      boxShadow: state.isFocused
                        ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
                        : `0 0 0 ${!state.hasValue && "1.5px #D64A40"}`,
                      borderRadius: 6,
                      backgroundColor: "#E2E8F0",
                      color: state.isFocused
                        ? apxTheme.palette.primary.main
                        : apxTheme.palette.text.primary,
                    }),
                  }}
                  components={{
                    Option,
                    IndicatorSeparator,
                  }}
                />
              </VStack>
            )}
            {dimension.prop_type === "derived" && (
              <>
                <VStack
                  spacing={8}
                  style={{
                    borderRadius: 12,
                  }}
                >
                  <HStack
                    align={"center"}
                    justify={"space-between"}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#020617",
                      }}
                    >
                      Derived formula
                    </Typography>

                    <ThemedTooltip
                      dark
                      title="Add Properties"
                    >
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          if (customFormulaData.events.length > 0) {
                            setCustomFormulaData({
                              ...customFormulaData,
                              events: [
                                ...customFormulaData.events,
                                {
                                  event_name: "",
                                  index: customFormulaData.events.length + 1,
                                  data_type: "",
                                },
                              ],
                            })
                          } else {
                            setCustomFormulaData({
                              events: [
                                {
                                  event_name: "",
                                  index: 1,
                                  data_type: "",
                                },
                              ],
                              formulas: [],
                              queries: "",
                            })
                          }
                        }}
                      >
                        <IconPlus size={20} />
                      </IconButton>
                    </ThemedTooltip>
                  </HStack>

                  {customFormulaData?.events?.length > 0 && (
                    <VStack spacing={10}>
                      <EventAccordion
                        customFormulaData={customFormulaData}
                        setCustomFormulaData={setCustomFormulaData}
                        isReports={true}
                        attributes={remainingAttributes}
                        attributesWithType={dimensionWithType}
                        setAttribute_type={setAttribute_type}
                      />
                    </VStack>
                  )}
                  <VStack spacing={10}>
                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      Expression Builder
                    </Typography>
                    <AceCustomFormulaQueryBuilder
                      events={customFormulaData?.events}
                      value={customFormulaData?.queries}
                      onChange={(q) => {
                        setCustomFormulaData({
                          ...customFormulaData,
                          queries: q,
                        })
                      }}
                      isReports={true}
                      dimensionDerived={instantDimensions.map((d) => ({
                        name: d?.alias ? d?.alias : d?.id,
                      }))}
                    />
                  </VStack>
                  <Select
                    placeholder="Final transformation"
                    isClearable={true}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    options={TOTAL_TRANSFORMATION}
                    onChange={(pair) => {
                      const dim = {
                        ...dimension,
                        final_transformation: pair ? pair.value : "",
                      }
                      setDimension(dim)
                    }}
                    maxMenuHeight={200}
                    value={
                      dimension.final_transformation === ""
                        ? ""
                        : {
                            label: TOTAL_TRANSFORMATION.find(
                              (transform) =>
                                transform.value ===
                                dimension.final_transformation,
                            ).label,
                            value: dimension.final_transformation,
                          }
                    }
                    styles={{
                      ...customStyles,
                      control: (base, state) => ({
                        ...base,

                        primary: "orange",
                        cursor: "pointer",
                        "&:hover": { borderColor: "none" },
                        width: 200,
                        paddingInline: 6,
                        paddingBlock: 0,
                        fontWeight: 500,
                        fontSize: 13,
                        border: "none",
                        boxShadow: state.isFocused
                          ? `0 0 0 1.5px #1387E8}`
                          : `0 0 0 1px #CCD4DA}`,
                        borderRadius: 12,
                        backgroundColor: apxTheme.palette.common.white,
                        color: state.isFocused
                          ? apxTheme.palette.primary.main
                          : apxTheme.palette.text.primary,
                      }),
                      menu: (base) => ({
                        ...base,
                        borderRadius: 12,
                        marginTop: 6,
                        boxShadow:
                          "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
                        paddingInline: 4,
                        width: 200,
                        bottom: "100%",
                        top: "auto",
                      }),
                    }}
                    components={{
                      Option,
                      IndicatorSeparator,
                    }}
                  />
                  <Typography variant="subtitle2">
                    {`Final Query :
                      ${dimension.final_transformation !== "" ? dimension.final_transformation + "(" : ""}
                      ${customFormulaData.queries}
                      ${dimension.final_transformation !== "" ? ")" : ""}
                      `}
                  </Typography>
                </VStack>
              </>
            )}
          </VStack>
          <HStack justify={"flex-end"}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={handlingError()}
              onClick={() => {
                if (dimension.prop_type === "derived") {
                  const transformedObject = customFormulaData.events.reduce(
                    (acc, obj) => {
                      const index = String.fromCharCode(
                        ...(obj?.index + "")
                          .split("")
                          .map((digit) => parseInt(digit) + 64),
                      )
                      acc[index] = {
                        id: obj.event_name,
                        data_type: obj.data_type,
                      }
                      return acc
                    },
                    {},
                  )
                  const finalConfig = {
                    ...dimension,
                    vmap: transformedObject,
                    formula: customFormulaData.queries ?? "",
                    data_type: attribute_type,
                  }

                  if (isEdit) {
                    handleUpdateDimension(idx, finalConfig)
                  } else {
                    handleAddDimension(finalConfig)
                  }
                } else {
                  if (isEdit) {
                    handleUpdateDimension(idx, dimension)
                  } else {
                    handleAddDimension(dimension)
                  }
                }
                setDimension(REPORTS_DIMENSION.dimension)
                onClose()
              }}
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </Popover>
    </>
  )
}
