import { MuiThemeProvider, withStyles } from "@material-ui/core"
import classNames from "classnames"
import React, { useCallback, useState } from "react"
import { useAuth } from "../../../contexts/AuthContext"
import {
  apxTheme,
  globalStyles,
} from "../../ApplicationFrame/styling/CustomCSS"
import OrgsSideNav from "../components/OrgsSideNav"

function OrgLayout({ children, classes }) {
  const { user } = useAuth()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  return (
    <MuiThemeProvider theme={apxTheme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <OrgsSideNav
            user={user}
            open={drawerOpen}
            handleOpenDrawer={handleOpenDrawer}
            handleCloseDrawer={handleCloseDrawer}
          />
          <main
            className={classNames(classes.content)}
            style={{
              ...(drawerOpen ? { marginLeft: "242px" } : {}),
            }}
          >
            {children}
          </main>
        </div>
      </div>
    </MuiThemeProvider>
  )
}

export default withStyles(globalStyles, { withTheme: true })(OrgLayout)
