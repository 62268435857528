import { Button, Drawer, Grid, IconButton, Typography } from "@material-ui/core"
import { IconChevronLeft } from "@tabler/icons-react"
import React from "react"
import { flushSync } from "react-dom"
import HStack from "../../../../ReusableComponents/HStack"
import VStack from "../../../../ReusableComponents/VStack"
import { useActions, useSelector } from "../store"
import DimensionSelector from "./DimensionSelector"
import Filters from "./Filters"

export default function DimensionsDrawer({ open, onClose }) {
  const { dimensions, filters, instantDimensions, instantFilters } =
    useSelector()
  const {
    setDimensions,
    setFilters,
    setRefreshData,
    setSelectedDimensions,
    setInstantDimensions,
    setInstantFilters,
  } = useActions()

  return (
    <Drawer
      PaperProps={{ style: { width: "90vw", padding: 30 } }}
      anchor={"right"}
      open={open}
      onClose={onClose}
      transitionDuration={500}
    >
      <VStack
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <HStack align="start">
          <IconButton
            color="primary"
            onClick={onClose}
            size="16"
            style={{
              color: "#000000",
            }}
          >
            <IconChevronLeft />
          </IconButton>
          <VStack>
            <Typography
              color="textPrimary"
              style={{
                color: "#020617",
                textWrap: "nowrap",
                fontWeight: 500,
                fontSize: 32,
              }}
            >
              Dimensions
            </Typography>
            <Typography
              color="textPrimary"
              style={{
                color: "#64748B",
                textWrap: "nowrap",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Analyzes the frequency of events and highlights anomalies.
            </Typography>
          </VStack>
        </HStack>
        <Grid
          container
          style={{
            minHeight: "80%",
            maxHeight: "80%",
          }}
        >
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              width: "100%",
              padding: "3.5rem",
              borderRight: "1px solid #E2E8F0",
            }}
          >
            <DimensionSelector
              instantDimensions={instantDimensions}
              setInstantDimensions={setInstantDimensions}
            />
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "3.5rem",
            }}
          >
            <Filters
              instantFilters={instantFilters}
              setInstantFilters={setInstantFilters}
            />
          </Grid>
        </Grid>
        <HStack
          align={"center"}
          justify={"flex-end"}
          styles={{ position: "fixed" }}
        >
          <HStack spacing={12}>
            {" "}
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                borderRadius: "50px",
                background: "#334155",
              }}
              onClick={() => {
                setDimensions(dimensions)
                setFilters(filters)
                setRefreshData(true)
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={
                instantDimensions.length === 0 ||
                (instantFilters.length > 0 &&
                  instantFilters.some(
                    (item) =>
                      item.name === "" ||
                      item.operator === "" ||
                      item.value?.length < 1,
                  ))
              }
              style={{ borderRadius: "50px" }}
              onClick={() => {
                flushSync(() => {
                  setFilters(instantFilters)
                  setDimensions(instantDimensions)
                  setSelectedDimensions(instantDimensions.slice(0, 1))
                })

                setRefreshData(true)
                onClose()
              }}
            >
              Apply Dimensions
            </Button>
          </HStack>
        </HStack>
      </VStack>
    </Drawer>
  )
}
