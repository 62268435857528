import { makeGetRequest, makePatchRequest } from "../../../common/actions"

const COHORT_DOWNLOAD = "dynamic-cohorts/download"
const GET_COHORTS_API = "dynamic-cohorts"
const UPDATE_COHORT_DESC_API = "dynamic-cohorts/desc"

export function get3rdDynamicCohorts(auth, appId, queryParams = {}) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makeGetRequest(
    GET_COHORTS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
  )
}

export function updateDynamicCohortsDescription(auth, appId, cohortId, desc) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makePatchRequest(
    UPDATE_COHORT_DESC_API,
    auth,
    appId,
    { cohortId },
    { description: desc },
    onSuccessCallback,
    onFailureCallback,
  )
}

export function downloadDynamicCohort(auth, appId, cohortId, displayName) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makeGetRequest(
    COHORT_DOWNLOAD,
    auth,
    appId,
    { cohortId, displayName },
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getDynamicCohortByID(auth, appId, cohortId) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makeGetRequest(
    `${GET_COHORTS_API}/${cohortId}`,
    auth,
    appId,
    {},
    onSuccessCallback,
    onFailureCallback,
  )
}

export function getLatestNudgeFromID(auth, appId, cohortId) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }
  return makeGetRequest(
    `${GET_COHORTS_API}/latest-nudge`,
    auth,
    appId,
    {
      cohortId,
    },
    onSuccessCallback,
    onFailureCallback,
  )
}
