import {
  Box,
  Button,
  ButtonBase,
  Input,
  Radio,
  Typography,
  withStyles,
} from "@material-ui/core"
import { useCallback, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  PLATFORM_IMAGES,
  getPlatformLogoColor,
} from "../../../App/styling/CustomCSS"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import useIntegration from "../../../Auth/integration-experience/hooks/use-integration"
import { APP_PLATFORM_NAMES } from "../../../Dashboard/common/components/campaigncreator/components/CreateEditCampaignDialog"
import ClassicCard from "../../../ReusableComponents/ClassicCard"
import HStack from "../../../ReusableComponents/HStack"
import Loading from "../../../ReusableComponents/Loading"
import IntegrationLayout from "../../IntegrationLayout"
import { useTrackedState } from "../../store"

const APP_PLATFORMS = {
  omni: "Omni",
  android: "Android",
  ios: "iOS",
  web: "Web",
}

const ORadio = withStyles({
  root: {
    "&$checked": {
      color: "#FF7F33",
    },
  },
  checked: {},
})((props) => (
  <Radio
    color="default"
    {...props}
  />
))

export default function AddNewAppPage() {
  const { orgId } = useParams()
  const { addNewApplication } = useIntegration()
  const { app_loading } = useTrackedState()
  const { push } = useHistory()

  const [app_name, set_app_name] = useState("")
  const [app_img_url, set_app_img_url] = useState("")
  const [app_platform, set_app_platform] = useState(
    Object.keys(APP_PLATFORMS)[0],
  )

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      const appId = await addNewApplication(app_name, "", app_platform, orgId)
      push(`/orgs/${orgId}/apps/${appId}/integrate`)
    },
    [addNewApplication, app_name, app_platform, orgId, push],
  )

  return (
    <IntegrationLayout>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "600px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            Add Application
          </Typography>
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginBottom: "16px",
              }}
            >
              <Typography
                component={"label"}
                htmlFor="app_name"
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "#667A8A",
                  marginBottom: "4px",
                }}
              >
                Application Name*
              </Typography>
              <Input
                id="app_name"
                name="app_name"
                type="text"
                value={app_name}
                onChange={(e) => set_app_name(e.target.value)}
                required
                autoFocus
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  width: "100%",
                  padding: "5px",
                  border: `1px solid ${apxTheme.palette.action.selected}`,
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginBottom: "16px",
              }}
            >
              <Typography
                component={"label"}
                htmlFor="app_img_url"
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "#667A8A",
                  marginBottom: "4px",
                }}
              >
                App Icon URL
              </Typography>
              <Input
                id="app_img_url"
                name="app_img_url"
                type="text"
                value={app_img_url}
                onChange={(e) => set_app_img_url(e.target.value)}
                placeholder="https://cloudinary.com/v1/logo.png"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  width: "100%",
                  padding: "5px",
                  border: `1px solid ${apxTheme.palette.action.selected}`,
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginBottom: "45px",
              }}
            >
              <Typography
                component={"label"}
                htmlFor="platform"
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "#667A8A",
                  marginBottom: "4px",
                }}
              >
                Platform*
              </Typography>
              <HStack spacing="15px">
                {Object.keys(APP_PLATFORMS).map((platformKey, idx) => (
                  <ButtonBase
                    key={idx}
                    style={{
                      minWidth: "170px",
                    }}
                    disableRipple
                    disableTouchRipple
                    onClick={() => set_app_platform(platformKey)}
                  >
                    <ClassicCard
                      style={{
                        boxShadow: "none",
                        borderWidth: 1.5,
                        borderRadius: 12,
                      }}
                      selected={app_platform === platformKey}
                    >
                      <HStack
                        spacing="2px"
                        justify="space-evenly"
                      >
                        <ORadio checked={app_platform === platformKey} />
                        {PLATFORM_IMAGES(
                          platformKey,
                          getPlatformLogoColor(platformKey),
                          24,
                        )}
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          {APP_PLATFORM_NAMES[platformKey]}
                        </Typography>
                      </HStack>
                    </ClassicCard>
                  </ButtonBase>
                ))}
              </HStack>
            </Box>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={app_name === ""}
              style={{
                height: 48,
              }}
            >
              {app_loading ? (
                <Loading
                  size={28}
                  color="#FFFFFF"
                />
              ) : (
                "Next"
              )}
            </Button>
          </form>
        </div>
      </Box>
    </IntegrationLayout>
  )
}
