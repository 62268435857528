import { Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"
import HStack from "./HStack"
import ThemedTooltip from "./ThemedTooltip"

export default function ModuleConfigSection({
  children,
  title,
  style = {},
  typographyStyleProps,
  typographyClassName,
  rootClassName,
  tooltipTextBesideTitle = "",
  tooltipIcon = null,
}) {
  const Title = () => (
    <Typography
      gutterBottom
      style={{ fontWeight: 400, ...typographyStyleProps }}
      variant={"subtitle1"}
      className={typographyClassName}
    >
      {title}
    </Typography>
  )

  return (
    <div
      id={title}
      style={{ ...style }}
      className={rootClassName}
    >
      {tooltipTextBesideTitle !== "" && tooltipIcon ? (
        <HStack spacing="4px">
          <Title />
          <ThemedTooltip
            title={tooltipTextBesideTitle}
            dark
          >
            {tooltipIcon}
          </ThemedTooltip>
        </HStack>
      ) : (
        <Title />
      )}

      {children}
    </div>
  )
}

ModuleConfigSection.propTypes = {
  title: PropTypes.string.isRequired,
  typographyProps: PropTypes.object,
}
