import { Button, Typography } from "@material-ui/core"
import { StyledInput } from "../../../../../../../Profile/pages/ProfilePage"
import HStack from "../../../../../../../ReusableComponents/HStack"
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection"
import { useModuleConfigStyles } from "./utils"

export default function DesktopWebLaunchURL({
  defaultUrl = "",
  url,
  setURL,
  launchCallback: launchURLCallback,
  title = "",
}) {
  const moduleConfigClasses = useModuleConfigStyles()

  return (
    <HStack
      spacing={8}
      align={"end"}
    >
      <ModuleConfigSection
        title={title}
        style={{
          marginTop: 0,
          alignItems: "center",
          gap: 2,
          minWidth: "270px",
        }}
        typographyClassName={moduleConfigClasses.moduleConfigRoot}
        typographyStyleProps={{ fontSize: 12 }}
      >
        <HStack
          justify="space-between"
          spacing="8px"
          id="desktop-web-launch-url-input-section"
        >
          <StyledInput
            style={{
              fontSize: "15px",
              fontWeight: "600",
              padding: "2px 4px",
            }}
            autoFocus
            placeholder="https://your-url.com"
            defaultValue={defaultUrl}
            value={url}
            onChange={(e) => {
              setURL(e.target.value)
            }}
          />

          <Button
            id="desktop-web-launch-url-button"
            size="large"
            variant="contained"
            color="primary"
            disabled={!url || url === "" || (url.trim && url.trim === "")}
            onClick={() => {
              launchURLCallback()
            }}
            style={{
              padding: "10.5px 20px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "15px",
                fontWeight: "700",
              }}
            >
              Launch URL
            </Typography>
          </Button>
        </HStack>
      </ModuleConfigSection>
    </HStack>
  )
}
