import { Typography } from "@material-ui/core"
import { useEffect, useMemo } from "react"
import HStack from "../../../ReusableComponents/HStack"
import WhitePaper from "../../../ReusableComponents/WhitePaper"
import Filters from "../../common/components/Filters"
import { useDateFilters } from "../../common/utils"
import { CustomReportHolder } from "./components/CustomReportHolder"
import { useActions } from "./store"

export default function BiCustomReports({ app }) {
  const { $reset } = useActions()
  const [dateFilters, setDateFilters] = useDateFilters()

  const queryParams = useMemo(
    () => ({ ...dateFilters, versions: null, cohort: null }),
    [dateFilters],
  )

  useEffect(() => {
    return () => {
      $reset()
    }
  }, [$reset])

  return (
    <>
      <HStack
        justify={"space-between"}
        style={{ padding: "12px 36px 0px 36px" }}
      >
        <Typography
          color="textPrimary"
          style={{
            fontWeight: 700,
            fontSize: 24,
          }}
        >
          BI Reports
        </Typography>
        <Filters
          app={app}
          dateFilters={dateFilters}
          showCohort={false}
          showVersion={false}
          onDateFiltersChange={(start, end) => {
            setDateFilters({
              since: start,
              till: end,
            })
          }}
          style={{
            border: "2px solid red",
          }}
        />
      </HStack>
      <HStack
        style={{
          minHeight: "80vh",
          margin: "44px 36px",
        }}
        align={"start"}
        component={WhitePaper}
      >
        <CustomReportHolder queryParams={queryParams} />
      </HStack>
    </>
  )
}
