import React, { useState } from "react"
import MultiSelect from "../../../../../ReusableComponents/MultiSelect"
import { DEFAULT_DATATYPE_OPTIONS } from "./constants"

export default function DatatypeSelector({
  type,
  handleOnChange,
  options = DEFAULT_DATATYPE_OPTIONS,
}) {
  const [selectedOption, setSelectedOption] = useState(
    options.findIndex((item) => item.value === type) === -1 ? "" : type,
  )

  return (
    <MultiSelect
      single
      clearable={false}
      isSearchable={false}
      menuIsOpen={true}
      style={{ maxWidth: 125, minWidth: 125 }}
      value={selectedOption}
      handleChange={(value, label) => {
        setSelectedOption(value)
        handleOnChange(value, label)
      }}
      options={options}
    />
  )
}
