import { Button, Typography } from "@material-ui/core"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import { QRCodeSVG } from "qrcode.react"
import { useState } from "react"
import { copyToClipboard } from "../../../../../../../../utils"
import { StyledInput } from "../../../../../../../Profile/pages/ProfilePage"
import ColumnDiv from "../../../../../../../ReusableComponents/ColumnDiv"
import HStack from "../../../../../../../ReusableComponents/HStack"
import ModuleConfigSection from "../../../../../../../ReusableComponents/ModuleConfigSection"
import SegmentedControl from "../../../../../../../ReusableComponents/SegmentedControl"
import VStack from "../../../../../../../ReusableComponents/VStack"
import { useModuleConfigStyles } from "./utils"

const WEB_DEVICE_TYPES = Object.freeze({
  WEB: "web",
  MOBILE: "mobile",
})

export default function WebLaunchURL({
  defaultUrl = "",
  url,
  setURL,
  launchCallback: launchURLCallback,
  generateQRCodeCallback,
  title = "",
}) {
  const moduleConfigClasses = useModuleConfigStyles()

  const [deviceType, setDeviceType] = useState(WEB_DEVICE_TYPES.WEB)
  const [qrCodeURL, setQrCodeURL] = useState("")
  const [isQRGenerated, setIsQRGenerated] = useState(false)

  const generateQR = () => {
    const qrCodeURL = generateQRCodeCallback()
    setQrCodeURL(qrCodeURL)
  }

  return (
    <VStack style={{ gap: "10px", minWidth: "110%" }}>
      <HStack>
        <SegmentedControl
          value={deviceType}
          onChange={(value) => {
            setDeviceType(value)
          }}
          items={Object.values(WEB_DEVICE_TYPES).map((deviceType) => ({
            value: deviceType,
            name:
              deviceType === WEB_DEVICE_TYPES.WEB
                ? "Desktop Web"
                : "Mobile Web",
          }))}
          size="sm"
        />
      </HStack>

      <HStack
        spacing={8}
        align="end"
      >
        <ModuleConfigSection
          title={
            <Typography
              color="textSecondary"
              style={{
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "5px",
              }}
            >
              {title}
            </Typography>
          }
          style={{
            marginTop: 0,
            alignItems: "center",
            gap: 2,
            minWidth: "270px",
          }}
          typographyClassName={moduleConfigClasses.moduleConfigRoot}
          typographyStyleProps={{ fontSize: 12 }}
        >
          <HStack
            justify="space-between"
            spacing="8px"
          >
            <StyledInput
              style={{
                fontSize: "15px",
                fontWeight: "600",
                padding: "2px 4px",
              }}
              autoFocus
              placeholder="https://your-url.com"
              defaultValue={defaultUrl}
              value={url}
              onChange={(e) => {
                setURL(e.target.value)
              }}
            />
            {deviceType === WEB_DEVICE_TYPES.MOBILE && isQRGenerated && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={!url || url === "" || (url.trim && url.trim === "")}
                onClick={() => {
                  if (deviceType === WEB_DEVICE_TYPES.MOBILE) {
                    generateQR()
                  } else {
                    launchURLCallback()
                  }
                }}
                style={{
                  padding: "10.5px 20px",
                }}
                id="web-launch-url-input-btn"
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "700",
                  }}
                >
                  Generate QR
                </Typography>
              </Button>
            )}
            {((qrCodeURL === "" && deviceType === WEB_DEVICE_TYPES.MOBILE) ||
              deviceType === WEB_DEVICE_TYPES.WEB) && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={!url || url === "" || (url.trim && url.trim === "")}
                onClick={() => {
                  if (deviceType === WEB_DEVICE_TYPES.MOBILE) {
                    generateQR()
                    setIsQRGenerated(true)
                  } else {
                    launchURLCallback()
                  }
                }}
                style={{
                  padding: "10.5px 20px",
                }}
                id="web-launch-url-input-btn"
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "700",
                  }}
                >
                  {deviceType === WEB_DEVICE_TYPES.WEB
                    ? "Launch URL"
                    : "Generate QR"}
                </Typography>
              </Button>
            )}
          </HStack>
        </ModuleConfigSection>
      </HStack>

      {qrCodeURL !== "" && deviceType === WEB_DEVICE_TYPES.MOBILE && (
        <ColumnDiv styles={{ gap: "9px", maxWidth: "150px" }}>
          <QRCodeSVG
            value={qrCodeURL}
            height="150px"
            width="150px"
          />
          <Button
            variant="text"
            startIcon={<FileCopyOutlinedIcon />}
            color="default"
            onClick={() => {
              copyToClipboard(qrCodeURL)
            }}
          >
            Copy Link
          </Button>
        </ColumnDiv>
      )}
    </VStack>
  )
}
