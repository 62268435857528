import { Button, Typography } from "@material-ui/core"
import { useQueryClient } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { useAuth } from "../../../../../../../../contexts/AuthContext"
import generateShortID from "../../../../../../../../utils"
import useApxorClient from "../../../../../../../../utils/use-apxor-client"
import { apxTheme } from "../../../../../../../ApplicationFrame/styling/CustomCSS"
import VStack from "../../../../../../../ReusableComponents/VStack"
import { useDateFilters } from "../../../../../utils"
import {
  SET_TERMINATE_INFO_GOAL_EVENT,
  SET_TERMINATE_INFO_GOAL_EVENT_ADD_ATTRIBUTE,
  SET_TERMINATE_INFO_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR,
  SET_TERMINATE_INFO_GOAL_EVENT_ADD_ATTRIBUTE_VALUES,
  SET_TERMINATE_INFO_GOAL_EVENT_REMOVE_ATTRIBUTE,
} from "../../../constants"
import { useTracked } from "../../../store"
import NewPropertyRow from "./NewContextBuilder/components/NewPropertyRow"
import {
  IndicatorSeparator,
  Option,
} from "./targetaudience/components/selects/DidNotDoEventsComboBox"

const customStyles = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: 500,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    borderRadius: 8,
    border: "none",
    boxShadow: state.isFocused
      ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)"
      : "0 0 0 1px #CCD4DA",
    backgroundColor: apxTheme.palette.common.white,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#AEBCC7",
    fontSize: 13,
    fontWeight: 600,
  }),
  menu: (base) => ({
    ...base,
    width: 500,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

const SURVEY_EXCLUSION_EVENTS = [
  "apx_survey_question_asked",
  "apx_survey_question_answered",
  "apx_back_button_clicked",
]

const SURVEY_INCLUSION_EVENTS = [
  "apx_survey_close_button_clicked",
  "apx_survey_response",
]

export default function SurveyStopConditionsEventBuilder() {
  const { logEvent } = useApxorClient()

  const [state, dispatch] = useTracked()
  const {
    config: {
      terminate_info: {
        goal_events: { events },
      },
      meta: { config_type } = { config_type: "walkthrough" },
      surveyType = "latest",
      ui = {},
    },
  } = state

  const auth = useAuth()
  const { appId } = useParams()
  const [dateFilters] = useDateFilters(true)

  const queryClient = useQueryClient()

  const appEvents =
    queryClient.getQueryData(["app-events", auth, appId, dateFilters]) ?? []

  const isLatestSurvey = useMemo(
    () => config_type === "survey" && surveyType === "latest",
    [config_type, surveyType],
  )

  const { survey: { q_and_a = [] } = {} } = ui || { survey: {} }

  const handleChange = useCallback(
    (value) => {
      if (!value) {
        dispatch({
          type: SET_TERMINATE_INFO_GOAL_EVENT,
          payload: [],
        })
      } else {
        const data = {
          _id: generateShortID(),
          event_type: "app_event",
          context_rule_type: "APP",
          type: "did",
          sequence: -1,
          time_bounds: {
            lower: 0,
            upper: 6000000,
          },
          count_config: {
            count: 1,
            operator: "GTE",
          },
          combine_operator: "OR",
          activity: "",
          details: {
            additional_info: {},
            name: value,
          },
          trigger: {
            event_type: "app_start",
            activity: "",
            details: {
              name: "",
              additional_info: {},
            },
            time_bounds: {
              lower: 0,
              upper: 60000000,
            },
          },
        }
        dispatch({
          type: SET_TERMINATE_INFO_GOAL_EVENT,
          payload: [data],
        })
      }
      logEvent("GoalEventSelected")
    },
    [dispatch, logEvent],
  )

  // Filtering some events for surveys
  // Filtering inclusion events because, we add anyways, to avoid duplication we are filtering before.
  const options = appEvents
    .filter(
      (event) =>
        !SURVEY_EXCLUSION_EVENTS.includes(event) &&
        !SURVEY_INCLUSION_EVENTS.includes(event) &&
        !event.includes("apx_survey"),
    )
    .concat(SURVEY_INCLUSION_EVENTS)
    .map((event) => ({ label: event, value: event }))

  const goal_event_attributes =
    events && Array.isArray(events) && events.length > 0
      ? Object.keys(events[0].details.additional_info).map((key) => ({
          ...events[0].details.additional_info[key],
          key,
        }))
      : []

  const getAddFilterButtonState = () => {
    if (
      !(events && Array.isArray(events) && events.length > 0) ||
      events[0].details.name === ""
    ) {
      return true
    } else if (Object.keys(events[0].details.additional_info).length === 0) {
      return false
    } else {
      const finalGoalAttributes =
        goal_event_attributes[goal_event_attributes.length - 1]
      if (finalGoalAttributes.key === "") {
        return true
      } else if (
        finalGoalAttributes.key !== "" &&
        finalGoalAttributes.op === ""
      ) {
        return true
      } else if (
        finalGoalAttributes.key !== "" &&
        finalGoalAttributes.op !== "" &&
        Array.isArray(finalGoalAttributes.val) &&
        finalGoalAttributes.val.length === 0
      ) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <VStack spacing="12px">
      <Select
        inputId="goal-events"
        placeholder="Select goal event"
        noOptionsMessage={() => (
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 13,
            }}
          >
            No Events Found
          </Typography>
        )}
        isSearchable={true}
        isClearable={true}
        isMulti={false}
        closeMenuOnSelect={true}
        options={options}
        onChange={(pair) => {
          handleChange(pair?.value ?? "")
        }}
        value={
          events.length > 0
            ? options.find((o) => o.value === events[0].details.name)
            : ""
        }
        styles={customStyles}
        maxMenuHeight={150}
        components={{
          Option,
          IndicatorSeparator,
        }}
      />

      {goal_event_attributes.length > 0 && (
        <VStack spacing={0}>
          {goal_event_attributes?.map((item, index) => (
            <NewPropertyRow
              menuPlacement="top"
              index={index}
              appId={appId}
              eventType={"event"}
              eventName={
                events.length > 0
                  ? options.find((o) => o.value === events[0].details.name)
                      .value
                  : ""
              }
              property={{ name: item.key, operator: item.op, value: item.val }}
              handleOnChange={(data) => {
                dispatch({
                  type: data.type,
                  key: item.key,
                  ...(data.type === SET_TERMINATE_INFO_GOAL_EVENT_ADD_ATTRIBUTE
                    ? { newKey: data.value }
                    : data.type ===
                        SET_TERMINATE_INFO_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR
                      ? { value: data.value }
                      : data.type ===
                          SET_TERMINATE_INFO_GOAL_EVENT_ADD_ATTRIBUTE_VALUES
                        ? { value: data.value }
                        : {}),
                })
              }}
              handleOnDelete={() => {
                dispatch({
                  type: SET_TERMINATE_INFO_GOAL_EVENT_REMOVE_ATTRIBUTE,
                  key: item.key,
                })
              }}
              isLatestSurvey={isLatestSurvey}
              surveyQandA={isLatestSurvey ? q_and_a : []}
            />
          ))}
        </VStack>
      )}
      {events && Array.isArray(events) && events.length > 0 && (
        <Button
          disabled={getAddFilterButtonState()}
          variant="contained"
          style={{ color: "primary", width: 150 }}
          color="primary"
          onClick={() => {
            dispatch({
              type: SET_TERMINATE_INFO_GOAL_EVENT_ADD_ATTRIBUTE,
              key: "",
              newKey: "",
            })
          }}
        >
          + Add Filter
        </Button>
      )}
    </VStack>
  )
}
