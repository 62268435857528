import {
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import { ChevronLeft } from "@material-ui/icons"
import BulbIcon from "@material-ui/icons/EmojiObjectsOutlined"
import SendIcon from "@material-ui/icons/SendRounded"
import { IconMail, IconScan } from "@tabler/icons-react"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { APP_PLATFORMS } from "../../../../constants"
import { useAuth } from "../../../../contexts/AuthContext"
import { isValidEmail } from "../../../../utils"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import useUser from "../../../Profile/hooks/use-user"
import ColumnDiv from "../../../ReusableComponents/ColumnDiv"
import HStack from "../../../ReusableComponents/HStack"
import Loading from "../../../ReusableComponents/Loading"
import RowDiv from "../../../ReusableComponents/RowDiv"
import { FEATURES_LIST_ENUM } from "../../components/settings/general/constants"
import DesktopWebLaunchURL from "../../components/walkthroughs/components/design/components/common/DesktopWebLaunchURL"
import MobileWebLaunchURL from "../../components/walkthroughs/components/design/components/common/MobileWebLaunchURL"
import TestDeviceAdditionWithQR from "../../components/walkthroughs/components/design/components/common/TestDeviceAdditionWithQR"
import WebLaunchURL from "../../components/walkthroughs/components/design/components/common/WebLaunchURL"
import { WEB_LAUNCH_MODE } from "../../components/walkthroughs/components/design/constants"
import {
  generateQRCodeURL,
  openURLInNewTab,
} from "../../components/walkthroughs/components/design/utils"
import { triggerTestEmails } from "../actions"
import apxor_app from "./assets/apxor_app.png"
import { useCommonConfigStyles } from "./campaigncreator/utils"
import { useTrackedState } from "../../../IntegrationExperience/store"

export default function DownloadApxorAppContent({
  showQRForTestDeviceAddition = false,
  setShowQRForTestDeviceAddition = () => {},
  showBackButton,
  handleOnBackButtonClick,
  isTestingFlow = false,
  platform = "",
  is_mweb = "",
}) {
  const auth = useAuth()
  const typographyClasses = useCommonConfigStyles()
  const { currentRefinedUser, appFeaturesArr } = useUser()
  const { app_data } = useTrackedState()

  const enableAVF =
    appFeaturesArr?.includes(FEATURES_LIST_ENUM.NUDGES.children.AVT.value) &&
    currentRefinedUser?.flags?.includes(
      FEATURES_LIST_ENUM.NUDGES.children.AVT.value,
    )

  const ref = useRef()
  const [url, setURL] = useState(app_data?.basic_info?.bundle_id ?? "")
  const [emailList, setEmailList] = useState([])
  const [pending, setPending] = useState(false)
  const [successText, setSuccessText] = useState("")
  const [errorText, setErrorText] = useState("")
  const [inputValue, setInputValue] = useState("")

  const handleChange = useCallback((value) => {
    setEmailList((prevList) => [...prevList, value.replace(/\n/g, "")])
  }, [])

  const handleDelete = useCallback(
    (item) => () => {
      setEmailList((prevList) =>
        prevList.filter((eachMail) => eachMail !== item),
      )
    },
    [],
  )

  const newVolatileID = useMemo(
    () => Math.random().toString(36).substring(2, 15),
    [],
  )

  const triggerEmails = () => {
    setPending(true)
    triggerTestEmails(
      auth,
      { platform },
      {
        email_ids: (emailList.indexOf(inputValue) === -1
          ? [...emailList, inputValue.replace(/\n/g, "")]
          : emailList
        ).filter((eachItem) => eachItem !== ""),
      },
    )
      .then((response) => {
        setPending(false)
        setSuccessText(
          "Open the email link in your mobile to register your test device",
        )
        setInputValue("")
      })
      .catch((err) => {
        setPending(false)
        if (err?.message?.startsWith("BundleIdentifiers not found for app")) {
          setErrorText("Please add Bundle Identifiers to your app")
        } else {
          setErrorText("Please check your email ID")
        }
      })
  }

  const isSubmitEnabled = useMemo(
    () => emailList.length > 0 && emailList.map((email) => isValidEmail(email)),
    [emailList],
  )

  const { appId } = useParams()

  return (
    <>
      <ColumnDiv
        styles={{ gap: 16 }}
        customRef={ref}
      >
        {platform !== APP_PLATFORMS.web && (
          <Grid
            container
            spacing={0}
          >
            <Grid
              item
              xs={isTestingFlow ? 6 : 12}
            >
              <RowDiv>
                {showBackButton && (
                  <IconButton
                    style={{ padding: 0, minHeight: 8 }}
                    onClick={() => {
                      setSuccessText("")
                      if (handleOnBackButtonClick) {
                        handleOnBackButtonClick()
                      }
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
                <HStack
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      fontSize: 24,
                      fontWeight: 700,
                      lineHeight: "34px",
                      color: "#002845",
                    }}
                  >
                    Register New Device
                  </Typography>
                  {showQRForTestDeviceAddition ? (
                    <Button
                      onClick={() => {
                        setShowQRForTestDeviceAddition(
                          !showQRForTestDeviceAddition,
                        )
                      }}
                      style={{
                        backgroundColor: "#f4f5f5",
                        marginLeft: 16,
                        paddingBlock: 18,
                        paddingInline: 22,
                        borderRadius: 30,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#313957",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconMail />
                        &nbsp; Add device via email address
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setShowQRForTestDeviceAddition(
                          !showQRForTestDeviceAddition,
                        )
                      }}
                      style={{
                        backgroundColor: "#f4f5f5",
                        marginLeft: 16,
                        paddingBlock: 18,
                        paddingInline: 22,
                        borderRadius: 30,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#313957",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconScan />
                        &nbsp; Try smart way to select device
                      </Typography>
                    </Button>
                  )}
                </HStack>
              </RowDiv>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: "19px",
                  opacity: 0.7,
                  color: "#002845",
                  marginTop: 8,
                  marginBottom: 30,
                }}
              >
                You will be able to preview and test your campaigns on your
                device.
              </Typography>
              {!showQRForTestDeviceAddition && (
                <ColumnDiv styles={{ justifyContent: "center", gap: "10px" }}>
                  <ColumnDiv
                    styles={{
                      gap: "2px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#002845",
                      }}
                    >
                      Enter Emails
                    </Typography>
                    <RowDiv
                      center
                      styles={{ gap: "1px" }}
                    >
                      <BulbIcon
                        style={{
                          height: 12,
                          width: 12,
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: "#002845",
                          opacity: 0.7,
                        }}
                      >
                        <b>TIP</b> : Press <b>Enter</b> to enable the{" "}
                        <b>Send</b> button.
                      </Typography>
                    </RowDiv>
                  </ColumnDiv>
                  <TextField
                    fullWidth
                    autoFocus
                    placeholder={"Enter Multiple Email IDs"}
                    multiline
                    minRows={2}
                    InputProps={{
                      startAdornment: emailList.map((item) => (
                        <Chip
                          key={item}
                          tabIndex={-1}
                          label={item}
                          onDelete={handleDelete(item)}
                          style={{ marginInline: "4px", borderRadius: "4px" }}
                        />
                      )),
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          if (
                            isValidEmail(event.target.value) &&
                            emailList.indexOf(event.target.value) === -1
                          ) {
                            handleChange(event.target.value)
                            setInputValue("")
                            event.target.value = ""
                          } else {
                            if (
                              emailList.length > 0 &&
                              event.target.value.trim() === ""
                            ) {
                              triggerEmails()
                            } else {
                              setErrorText("Not a valid email!")
                              event.preventDefault()
                            }
                          }
                        }
                        if (
                          emailList.length &&
                          !inputValue.length &&
                          event.key === "Backspace"
                        ) {
                          setEmailList(emailList.slice(0, emailList.length - 1))
                        }
                      },
                      onChange: (event) => {
                        if (errorText) {
                          setErrorText("")
                        }
                        setInputValue(event.target.value)
                      },
                    }}
                  />
                  <RowDiv
                    center
                    styles={{ gap: "8px" }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={!isSubmitEnabled}
                      onClick={triggerEmails}
                      endIcon={
                        !pending && (
                          <SendIcon
                            style={{
                              marginLeft: "5px",
                              height: 14,
                              width: 14,
                            }}
                          />
                        )
                      }
                    >
                      {pending ? (
                        <Loading
                          color="#fff"
                          size={22}
                        />
                      ) : (
                        `Send Email${emailList.length > 1 ? "s" : ""}`
                      )}
                    </Button>
                    {errorText !== "" && (
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 550,
                          color: apxTheme.palette.error.main,
                        }}
                      >
                        {errorText}
                      </Typography>
                    )}
                    {successText !== "" && (
                      <Typography
                        style={{
                          fontSize: 12,
                          fontWeight: 550,
                          color: apxTheme.palette.primary.main,
                        }}
                      >
                        {successText}
                      </Typography>
                    )}
                  </RowDiv>
                </ColumnDiv>
              )}
              {showQRForTestDeviceAddition && (
                <TestDeviceAdditionWithQR selectedPlatform={platform} />
              )}
            </Grid>
            {isTestingFlow && (
              <Grid
                item
                xs={6}
                style={{
                  padding: 20,
                }}
              >
                <div
                  style={{
                    background: `url(${apxor_app})`,
                    width: 214,
                    height: 437,
                    margin: "0 auto",
                  }}
                ></div>
              </Grid>
            )}
          </Grid>
        )}
      </ColumnDiv>
      {platform === APP_PLATFORMS.web && (
        <ColumnDiv styles={{ gap: 16, margin: "10px 20px" }}>
          <Typography className={typographyClasses.typographyHeading}>
            Register New Device
          </Typography>

          {is_mweb === "" ? (
            <WebLaunchURL
              url={url}
              setURL={setURL}
              launchCallback={() => {
                openURLInNewTab(
                  url,
                  appId,
                  newVolatileID,
                  WEB_LAUNCH_MODE.VIEW_SELECTION,
                  !enableAVF ? "v1" : "v2",
                )
              }}
              generateQRCodeCallback={() => {
                const urlWithParams = generateQRCodeURL(
                  url,
                  appId,
                  newVolatileID,
                  WEB_LAUNCH_MODE.VIEW_SELECTION,
                  !enableAVF ? "v1" : "v2",
                )
                return urlWithParams
              }}
              title="Launch URL to register a new test device"
            />
          ) : (
            <>
              {is_mweb ? (
                <MobileWebLaunchURL
                  url={url}
                  setURL={setURL}
                  generateQRCodeCallback={() => {
                    const urlWithParams = generateQRCodeURL(
                      url,
                      auth.appId,
                      newVolatileID,
                      WEB_LAUNCH_MODE.VIEW_SELECTION,
                      !enableAVF ? "v1" : "v2",
                    )
                    return urlWithParams
                  }}
                  title="Launch URL to register a new test device"
                />
              ) : (
                <DesktopWebLaunchURL
                  url={url}
                  defaultUrl={url}
                  setURL={setURL}
                  launchCallback={() => {
                    openURLInNewTab(
                      url,
                      appId,
                      newVolatileID,
                      WEB_LAUNCH_MODE.VIEW_SELECTION,
                      !enableAVF ? "v1" : "v2",
                    )
                  }}
                  title="Launch URL to register a new test device"
                />
              )}
            </>
          )}
        </ColumnDiv>
      )}
    </>
  )
}
