import React from "react"
import PropTypes from "prop-types"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { isNumber } from "../../utils"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    maxWidth: 250,
    tableRightBorder: {
      borderWidth: 1,
      borderColor: "red",
      borderStyle: "solid",
    },
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    border: "1px solid #000",
  },
}))(TableRow)

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function SortableTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{
              fontSize: 16,
              fontWeight: 800,
              borderWidth: 1,
              borderColor: "black",
              borderStyle: "solid",
            }}
            align={"left"}
            padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

SortableTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: 500,
  },
}))

const getRows = (rows) => {
  return rows.map((o, index) => ({ tableIndex: index, ...o }))
}

export default function FixedHeaderSortableTable({
  rows,
  keys,
  orderByKey,
  headerLabels,
  TextCell,
}) {
  const classes = useStyles()
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState(orderByKey)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Paper className={classes.paper}>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
        >
          <SortableTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            headCells={[
              {
                id: "tableIndex",
                label: "S.No",
                numeric: true,
              },
              ...headerLabels.map((cell, index) => ({
                id: keys[index],
                label: cell,
                numeric: isNumber(cell),
              })),
            ]}
            onSelectAllClick={() => null}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(getRows(rows), getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `sortable-table-${index}`
                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={labelId}
                  >
                    <StyledTableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="default"
                      style={{
                        borderWidth: 1,
                        borderColor: "black",
                        borderStyle: "solid",
                      }}
                    >
                      {row.tableIndex + 1}
                    </StyledTableCell>
                    {keys.map((key) => (
                      <StyledTableCell
                        align="left"
                        style={{
                          borderWidth: 1,
                          borderColor: "black",
                          borderStyle: "solid",
                        }}
                      >
                        <TextCell
                          data={row[key]}
                          property={key}
                        />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                )
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
