import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
  Select as MuiSelect,
  MenuItem,
} from "@material-ui/core"
import { useQueryClient } from "@tanstack/react-query"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { useAuth } from "../../../../../../../../contexts/AuthContext"
import { useDeepCompareEffect } from "../../../../../../../../utils/use-deep-compare"
import { apxTheme } from "../../../../../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../../../../../ReusableComponents/HStack"
import ThemedTooltip from "../../../../../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../../../../../ReusableComponents/VStack"
import { getAttributeValuesAPI } from "../../../../../../../common/actions"
import { StyledNumberInput } from "../../../../../../components/surveys/components/design/components/simulator/components/toolbars/TextToolbar"
import {
  SET_ENABLE_SURVEY_COMPLETED,
  SET_ENABLE_SURVEY_CYCLE,
  SET_ENABLE_SURVEY_LIMITER,
  SET_ENABLE_TIME_BASED_TYPE,
  SET_ENABLE_TOUCH,
  SET_TERMINATE_INFO_ATTRIBUTES_USER,
  SET_TERMINATE_INFO_ENABLE_ATTRIBUTES,
  SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS,
  SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS,
  SET_TERMINATE_INFO_SURVEY_CYCLE_DURATION,
  SET_TERMINATE_INFO_SURVEY_CYCLE_SURVEY_RESPONSE_LIMIT,
  SET_TERMINATE_INFO_SURVEY_LIMITER_SUBMISSION_EVENT,
  SET_TERMINATE_INFO_SURVEY_LIMITER_SURVEY_RESPONSE_LIMIT,
  SET_TIME_BASED_DAYS,
} from "../../../../../../components/walkthroughs/components/design/constants"
import { useDateFilters } from "../../../../../utils"
import { DEFAULT_DATA_TYPES } from "../../../constants"
import { useTracked } from "../../../store"
import {
  IndicatorSeparator,
  Option,
} from "./targetaudience/components/selects/DidNotDoEventsComboBox"
import SurveyStopConditionsEventBuilder from "./SurveyStopConditionsEventBuilder"
import { HelpOutline } from "@material-ui/icons"

export const CustomCheckbox = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "#0573EC",
    },
  },
  checked: {},
})(Checkbox)

export const NewCustomStylesForSelectFeild = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: "100%",
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    borderRadius: 8,
    border: "none",
    boxShadow: state.isFocused
      ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
      : `0 0 0 ${state.hasValue ? "1px #CCD4DA" : "1.5px #D64A40"}`,
    backgroundColor: apxTheme.palette.common.white,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    transform: "scale(1.1)",
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    fontSize: 16,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "#D64A40",
    },
  }),
}

const SUBMISSION_EVENTS = [
  "apx_survey_question_answered",
  "apx_survey_launched",
  "apx_survey_completed",
]

export default function StopConditions() {
  const [state, dispatch] = useTracked()

  const auth = useAuth()
  const [dateFilters] = useDateFilters(true)

  const { appId } = useParams()

  const [attrValues, setAttrValues] = useState([])
  const [query, setQuery] = useState("")

  const queryClient = useQueryClient()

  const user_attributes = queryClient
    .getQueryData(["attributes", auth, appId])
    .user.filter((name) => name.indexOf("apx_") === -1)

  const {
    config: {
      meta: { config_type = "walkthrough", surveyType = "latest" },
      goal_event = "",
      terminate_info: {
        enable_goal_events = false,
        goal_events: { events = [] },
        enable_time_based = false,
        time_based: { days = 0 } = {
          days: 0,
        },
        enable_touch = false,
        enable_attributes = false,
        attributes: { user = [] } = {
          user: [],
        },
        enable_survey_completed = false,
        enable_survey_limiter = false,
        enable_survey_cycle = false,
        survey_limiter = {
          survey_response_limit: 1,
        },
        submission_event = "apx_survey_question_answered",
        survey_cycle = {
          survey_cycle_duration: 24,
          survey_response_limit: 1,
        },
      },
    },
  } = state

  const [advancedSurveyStopConditions, setAdvancedSurveyStopConditions] =
    useState(enable_survey_limiter || enable_survey_cycle)

  const isLatestSurvey = useMemo(
    () => config_type === "survey" && surveyType === "latest",
    [config_type, surveyType],
  )

  useDeepCompareEffect(() => {
    if (user.length > 0 && user[0].name !== "") {
      getAttributeValuesAPI(auth, appId, {
        ...dateFilters,
        of: "users",
        attribute: user[0]?.name,
        q: query,
      }).then((response) => {
        const filteredValues = response.filter(
          (name) => !["apx_none"].includes(name),
        )
        setAttrValues(filteredValues)
      })
    }
  }, [appId, query, user[0]?.name, auth, dateFilters])

  const user_attribute_options = user_attributes.map((p) => ({
    label: p,
    value: p,
  }))
  const user_attribute_options_datatypes = DEFAULT_DATA_TYPES.map((p) => ({
    label: p.label,
    value: p.value,
  }))
  const user_attribute_values_options = attrValues.map((p) => ({
    label: p,
    value: p,
  }))

  return (
    <VStack style={{ width: "100%" }}>
      <Typography
        color="textPrimary"
        style={{
          fontSize: 14,
          fontWeight: 800,
        }}
      >
        {isLatestSurvey ? "Surveys" : "Badges"} Stop Conditions
      </Typography>

      <VStack
        spacing="24px"
        style={{ width: "100%" }}
      >
        {/* Properties */}
        <HStack
          style={{ width: "100%" }}
          align="center"
          spacing="11px"
        >
          <CustomCheckbox
            id="properties"
            checked={enable_attributes}
            size="small"
            color="primary"
            onChange={(_e, checked) => {
              dispatch({
                type: SET_TERMINATE_INFO_ENABLE_ATTRIBUTES,
                enabled: checked,
              })
            }}
          />

          <Typography
            component={"label"}
            htmlFor="property-name"
            style={{
              color: "#000",
              fontSize: 13,
              fontWeight: 600,
            }}
          >
            User Property
          </Typography>

          {enable_attributes && (
            <VStack
              spacing="8px"
              style={{
                width: "30%",
                marginBottom: -25,
              }}
            >
              <Select
                isDisabled={!enable_attributes}
                inputId="property-name"
                placeholder="Select Property"
                isSearchable={true}
                closeMenuOnSelect={true}
                isClearable={true}
                options={user_attribute_options}
                isMulti={false}
                onChange={(pair) => {
                  dispatch({
                    type: SET_TERMINATE_INFO_ATTRIBUTES_USER,
                    value: pair?.value
                      ? {
                          name: pair.value,
                          value: [],
                          type: "",
                          operator: "EQ",
                        }
                      : null,
                  })
                }}
                value={user_attribute_options.find(
                  (o) => o.value === user?.[0]?.name,
                )}
                styles={{
                  pointerEvents: enable_attributes ? "auto" : "none",
                  ...NewCustomStylesForSelectFeild,
                }}
                components={{
                  Option,
                  IndicatorSeparator,
                }}
              />

              <Typography
                style={{
                  color: "#D64A40",
                  fontSize: 12,
                  fontWeight: 500,
                  opacity: enable_attributes && !user?.[0]?.name ? 1 : 0,
                }}
              >
                Select User Property
              </Typography>
            </VStack>
          )}
          {enable_attributes && user.length > 0 && user[0].name !== "" && (
            <>
              <Typography
                component={"label"}
                htmlFor="property-value"
                style={{
                  color: "#000",
                  fontSize: 13,
                  fontWeight: 600,
                  opacity: enable_attributes ? "1" : "0.5",
                }}
              >
                Data Type
              </Typography>

              <VStack
                spacing="8px"
                style={{
                  width: "20%",
                  marginBottom: -25,
                }}
              >
                <Select
                  isDisabled={!enable_attributes}
                  inputId="property-value"
                  placeholder="select data type"
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  isClearable={true}
                  options={user_attribute_options_datatypes}
                  isMulti={false}
                  onChange={(pair) => {
                    dispatch({
                      type: SET_TERMINATE_INFO_ATTRIBUTES_USER,
                      value: {
                        name: user?.[0]?.name,
                        value: [],
                        type: pair?.value || "",
                        operator: "EQ",
                      },
                    })
                  }}
                  onInputChange={(q) => {
                    setQuery(q)
                  }}
                  value={user_attribute_options_datatypes.find(
                    (o) => o.value === user?.[0]?.type,
                  )}
                  styles={{
                    ...NewCustomStylesForSelectFeild,
                    opacity: enable_attributes ? "1" : "0.5",
                    pointerEvents: enable_attributes ? "auto" : "none",
                  }}
                  components={{
                    Option,
                    IndicatorSeparator,
                  }}
                />

                <Typography
                  style={{
                    color: "#D64A40",
                    fontSize: 12,
                    fontWeight: 500,
                    opacity:
                      enable_attributes &&
                      user?.[0]?.name &&
                      !user?.[0]?.type &&
                      user?.[0]?.type === ""
                        ? 1
                        : 0,
                  }}
                >
                  Select a Value
                </Typography>
              </VStack>
            </>
          )}

          {/* Property Values */}
          {enable_attributes &&
            user.length > 0 &&
            user[0].name !== "" &&
            user[0].type !== "" && (
              <>
                <Typography
                  component={"label"}
                  htmlFor="property-value"
                  style={{
                    color: "#000",
                    fontSize: 13,
                    fontWeight: 600,
                    opacity: enable_attributes ? "1" : "0.5",
                  }}
                >
                  updates to
                </Typography>

                <VStack
                  spacing="8px"
                  style={{
                    width: "30%",
                    marginBottom: -25,
                  }}
                >
                  <Select
                    isDisabled={!enable_attributes}
                    inputId="property-value"
                    placeholder="Select Value"
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    isClearable={true}
                    options={user_attribute_values_options}
                    isMulti={false}
                    onChange={(pair) => {
                      dispatch({
                        type: SET_TERMINATE_INFO_ATTRIBUTES_USER,
                        value: {
                          name: user?.[0]?.name,
                          value: pair?.value ? [pair?.value] : [],
                          type: user?.[0].type ? user?.[0].type : "",
                          operator: "EQ",
                        },
                      })
                    }}
                    onInputChange={(q) => {
                      setQuery(q)
                    }}
                    value={user_attribute_values_options.find(
                      (o) => o.value === user?.[0]?.value?.[0],
                    )}
                    styles={{
                      ...NewCustomStylesForSelectFeild,
                      opacity: enable_attributes ? "1" : "0.5",
                      pointerEvents: enable_attributes ? "auto" : "none",
                    }}
                    components={{
                      Option,
                      IndicatorSeparator,
                    }}
                  />

                  <Typography
                    style={{
                      color: "#D64A40",
                      fontSize: 12,
                      fontWeight: 500,
                      opacity:
                        enable_attributes &&
                        user?.[0]?.name &&
                        !user?.[0]?.value?.[0]
                          ? 1
                          : 0,
                    }}
                  >
                    Select a Value
                  </Typography>
                </VStack>
              </>
            )}
        </HStack>

        {/* Goal Events */}
        <HStack
          style={{ alignItems: "start" }}
          spacing={"22px"}
        >
          <HStack spacing="11px">
            <CustomCheckbox
              disabled={isLatestSurvey ? false : !goal_event}
              id="goal-event-of-this-campaign"
              checked={
                isLatestSurvey
                  ? enable_goal_events
                  : enable_goal_events && goal_event
              }
              size="small"
              color="primary"
              onChange={(_e, checked) => {
                dispatch({
                  type: SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS,
                  enabled: checked,
                })

                const data = {
                  event_type: "app_event",
                  time_bounds: {
                    lower: 0,
                    upper: 6000000,
                  },
                  count_config: {
                    count: 1,
                    operator: "GTE",
                  },
                  combine_operator: "OR",
                  details: {
                    additional_info: {},
                    name: goal_event,
                  },
                }

                if (isLatestSurvey && events.length === 0 && checked) {
                  dispatch({
                    type: SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS,
                    value: [data],
                  })
                }

                // If not latest survey (till now it is badge only) we are managing terminate_info.goal_events.events
                // as [] if unchecked otherwise filled with data
                if (!isLatestSurvey) {
                  if (checked) {
                    dispatch({
                      type: SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS,
                      value: [data],
                    })
                  } else {
                    dispatch({
                      type: SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS,
                      value: [],
                    })
                  }
                }
              }}
            />

            {isLatestSurvey ? (
              <Typography
                component={"label"}
                htmlFor="goal-event-of-this-campaign"
                style={{
                  color: "#000",
                  fontSize: 13,
                  fontWeight: 600,
                }}
              >
                Goal event of this campaign
              </Typography>
            ) : (
              <ThemedTooltip
                dark
                title={
                  goal_event
                    ? ""
                    : "Enable Goal Event to use this Stop Condition"
                }
              >
                <Typography
                  component={"label"}
                  htmlFor="goal-event-of-this-campaign"
                  style={{
                    color: "#000",
                    fontSize: 13,
                    fontWeight: 600,
                    opacity: goal_event ? "1" : "0.5",
                  }}
                >
                  Goal event of this campaign
                </Typography>
              </ThemedTooltip>
            )}
          </HStack>
          {isLatestSurvey && enable_goal_events && (
            <SurveyStopConditionsEventBuilder />
          )}
        </HStack>

        {/* Time based */}
        {!isLatestSurvey && (
          <HStack
            style={{ width: "100%" }}
            align={"center"}
            spacing="11px"
          >
            <CustomCheckbox
              id="enable-time-based"
              checked={enable_time_based}
              size="small"
              color="primary"
              onChange={(_e, checked) => {
                dispatch({ type: SET_ENABLE_TIME_BASED_TYPE, enabled: checked })
              }}
            />

            <HStack
              style={{ width: "100%" }}
              align={"center"}
              spacing="11px"
            >
              <Typography
                component={"label"}
                htmlFor="enable-time-based"
                style={{
                  color: "#000",
                  fontSize: 13,
                  fontWeight: 600,
                }}
              >
                After
              </Typography>
              <StyledNumberInput
                min={1}
                disabled={!enable_time_based}
                id="days"
                value={days}
                handleChange={(value) => {
                  dispatch({
                    type: SET_TIME_BASED_DAYS,
                    value,
                  })
                }}
                onChange={(e) => {
                  dispatch({
                    type: SET_TIME_BASED_DAYS,
                    value: +e.target.value,
                  })
                }}
                style={{
                  borderRadius: 8,
                  border: "1px solid #CCD4DA",
                  width: 94,
                  height: 38,
                  backgroundColor: "#fff",
                  "&:disabled": {
                    backgroundColor: "#fff !important",
                  },
                  marginInline: 8,
                  pointerEvents: enable_time_based ? "auto" : "none",
                  cursor: enable_time_based ? "auto" : "not-allowed",
                }}
              />
              <Typography
                component={"label"}
                htmlFor="enable-time-based"
                style={{
                  color: "#000",
                  fontSize: 13,
                  fontWeight: 600,
                }}
              >
                days from the {isLatestSurvey ? "survey" : "badge"} is shown
              </Typography>
            </HStack>
          </HStack>
        )}

        {/* Touch */}
        {!isLatestSurvey && (
          <HStack spacing="11px">
            <CustomCheckbox
              id="target-element"
              checked={enable_touch}
              size="small"
              color="primary"
              onChange={(_e, checked) => {
                dispatch({ type: SET_ENABLE_TOUCH, enabled: checked })
              }}
            />
            <Typography
              component={"label"}
              htmlFor="target-element"
              style={{
                color: "#000",
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              Click on the target element
            </Typography>
          </HStack>
        )}

        {/* Survey Completed */}
        {isLatestSurvey && (
          <HStack spacing="11px">
            <CustomCheckbox
              id="survey-completed"
              checked={enable_survey_completed}
              size="small"
              color="primary"
              onChange={(_e, checked) => {
                dispatch({
                  type: SET_ENABLE_SURVEY_COMPLETED,
                  enabled: checked,
                })
              }}
            />
            <Typography
              component={"label"}
              htmlFor="survey-completed"
              style={{
                color: "#000",
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              Survey completed
            </Typography>
          </HStack>
        )}

        {/* Advanced Survey Stop Conditions */}
        {isLatestSurvey && (
          <VStack>
            <HStack spacing="11px">
              <CustomCheckbox
                id="advanced-survey-stop-conditions"
                checked={advancedSurveyStopConditions}
                size="small"
                color="primary"
                onChange={(_e, checked) => {
                  dispatch({
                    type: SET_ENABLE_SURVEY_LIMITER,
                    enabled: checked,
                  })
                  if (!checked) {
                    dispatch({
                      type: SET_ENABLE_SURVEY_CYCLE,
                      enabled: false,
                    })
                  }
                  setAdvancedSurveyStopConditions(checked)
                }}
              />
              <Typography
                component={"label"}
                htmlFor="advanced-survey-stop-conditions"
                style={{
                  color: "#000",
                  fontSize: 13,
                  fontWeight: 600,
                }}
              >
                Advanced Survey Stop Conditions
              </Typography>
            </HStack>

            {advancedSurveyStopConditions && (
              <FormControl component="fieldset">
                <RadioGroup
                  value={
                    enable_survey_limiter
                      ? "survey_limiter"
                      : enable_survey_cycle
                        ? "survey_cycle"
                        : null
                  }
                  onChange={(e) => {
                    if (e.target.value === "survey_limiter") {
                      dispatch({
                        type: SET_ENABLE_SURVEY_LIMITER,
                        enabled: true,
                      })
                      dispatch({
                        type: SET_ENABLE_SURVEY_CYCLE,
                        enabled: false,
                      })
                    } else if (e.target.value === "survey_cycle") {
                      dispatch({
                        type: SET_ENABLE_SURVEY_CYCLE,
                        enabled: true,
                      })
                      dispatch({
                        type: SET_ENABLE_SURVEY_LIMITER,
                        enabled: false,
                      })
                    }
                  }}
                  style={{ marginLeft: 50, display: "flex", gap: "12px" }}
                >
                  <FormControlLabel
                    value="survey_limiter"
                    control={<Radio color="primary" />}
                    label={
                      <HStack
                        spacing="10px"
                        align="center"
                      >
                        <Typography
                          color="textPrimary"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          Number of
                        </Typography>

                        <HStack spacing={3}>
                          <MuiSelect
                            disabled={!enable_survey_limiter}
                            id="survey-limiter"
                            value={submission_event}
                            onChange={(e) => {
                              dispatch({
                                type: SET_TERMINATE_INFO_SURVEY_LIMITER_SUBMISSION_EVENT,
                                event: e.target.value,
                              })
                            }}
                          >
                            {SUBMISSION_EVENTS.map((e) => (
                              <MenuItem value={e}>{e}</MenuItem>
                            ))}
                          </MuiSelect>
                          <ThemedTooltip
                            title={
                              <>
                                apx_survey_launched: This means the survey is
                                displayed to the user.
                                apx_survey_question_answered: This means the
                                user answers at least one question and then
                                closes the survey. apx_survey_completed: This is
                                triggered when the user answers all the
                                questions and clicks the submit button.
                              </>
                            }
                            dark
                          >
                            <HelpOutline
                              style={{
                                fontSize: 14.5,
                                color: apxTheme.palette.text.primary,
                              }}
                            />
                          </ThemedTooltip>
                        </HStack>
                        <Typography
                          color="textPrimary"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          is
                        </Typography>
                        <TextField
                          value={survey_limiter.survey_response_limit}
                          style={{ maxWidth: 72 }}
                          inputProps={{
                            min: 1,
                            pattern: "[0-9]*",
                            inputMode: "numeric",
                          }}
                          type={"number"}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value >= 1) {
                              dispatch({
                                type: SET_TERMINATE_INFO_SURVEY_LIMITER_SURVEY_RESPONSE_LIMIT,
                                value: value,
                              })
                            }
                          }}
                        />
                      </HStack>
                    }
                  />
                  <FormControlLabel
                    value="survey_cycle"
                    control={<Radio color="primary" />}
                    label={
                      <HStack
                        spacing="10px"
                        align="center"
                      >
                        <Typography
                          color="textPrimary"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          Number of
                        </Typography>
                        <HStack spacing={3}>
                          <MuiSelect
                            disabled={!enable_survey_cycle}
                            id="survey-cycle"
                            value={submission_event}
                            onChange={(e) => {
                              dispatch({
                                type: SET_TERMINATE_INFO_SURVEY_LIMITER_SUBMISSION_EVENT,
                                event: e.target.value,
                              })
                            }}
                          >
                            {SUBMISSION_EVENTS.map((e) => (
                              <MenuItem value={e}>{e}</MenuItem>
                            ))}
                          </MuiSelect>
                          <ThemedTooltip
                            title={
                              <>
                                apx_survey_launched: This means the survey is
                                displayed to the user.
                                apx_survey_question_answered: This means the
                                user answers at least one question and then
                                closes the survey. apx_survey_completed: This is
                                triggered when the user answers all the
                                questions and clicks the submit button.
                              </>
                            }
                            dark
                          >
                            <HelpOutline
                              style={{
                                fontSize: 14.5,
                                color: apxTheme.palette.text.primary,
                              }}
                            />
                          </ThemedTooltip>
                        </HStack>
                        <Typography
                          color="textPrimary"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          is
                        </Typography>
                        <TextField
                          value={survey_cycle.survey_response_limit}
                          style={{ maxWidth: 72 }}
                          inputProps={{ min: 1 }}
                          type={"number"}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value >= 1) {
                              dispatch({
                                type: SET_TERMINATE_INFO_SURVEY_CYCLE_SURVEY_RESPONSE_LIMIT,
                                value: value,
                              })
                            }
                          }}
                        />
                        <Typography
                          color="textPrimary"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          in
                        </Typography>
                        <TextField
                          value={Math.trunc(
                            survey_cycle.survey_cycle_duration / 24,
                          )}
                          style={{ maxWidth: 72 }}
                          inputProps={{
                            min: 1,
                          }}
                          type={"number"}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value >= 1) {
                              dispatch({
                                type: SET_TERMINATE_INFO_SURVEY_CYCLE_DURATION,
                                value: Math.trunc(value * 24),
                              })
                            }
                          }}
                        />
                        <Typography
                          color="textPrimary"
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          days
                        </Typography>
                      </HStack>
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}
          </VStack>
        )}
      </VStack>
    </VStack>
  )
}
