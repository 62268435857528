import { makeStyles } from "@material-ui/core"
import { motion } from "framer-motion"
import { useId } from "react"

const useStyles = makeStyles((theme) => ({
  controlContainer: (props) => ({
    width: props.size === "lg" ? "100%" : "",
    backgroundColor: theme.palette.action.hover,
    display: "inline-flex",
    padding: 4,
    columnGap: 4,
    justifyContent: "space-between",
    borderRadius: 8,
    margin: 0,
  }),
  controlItem: (props) => ({
    minWidth: 44,
    width: props.size === "lg" ? "30%" : "",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    padding: props.size === "sm" ? "3px 8px" : "5px 10px",
    position: "relative",
  }),
  controlItemBg: (props) => ({
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    borderRadius: 6,
    boxShadow:
      "0 0px 8px 0 rgba(0, 0, 0, 0.12), 0 0px 1px 0 rgba(0, 0, 0, 0.04)",
  }),
  controlItemText: (props) => ({
    position: "relative",
    textTransform: "capitalize",
    fontWeight: 550,
    fontSize: props.size === "sm" ? 13 : 15,
    color: theme.palette.text.primary,
  }),
}))

/**
 * A segmented radio component
 */
export default function SegmentedControl({
  size = "md",
  items = [],
  value,
  onChange,
  style = {},
}) {
  const id = useId()
  const styles = useStyles({ size })

  return (
    <>
      <div className={styles.controlContainer}>
        {items.map((item) => {
          return (
            <div
              key={item.value}
              role="radio"
              aria-checked={value === item.value}
              className={styles.controlItem}
              style={{
                ...style,
              }}
              onClick={() => {
                onChange(item.value)
              }}
            >
              {value === item.value && (
                <motion.div
                  layoutId={id}
                  transition={{ duration: 0.2, type: "tween" }}
                  className={styles.controlItemBg}
                ></motion.div>
              )}
              <div className={styles.controlItemText}>{item.name}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}
