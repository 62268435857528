import Logger from "../Logger"
import Scanner, {
  TOKEN_ARROW,
  TOKEN_BANG,
  TOKEN_BANG_EQUAL,
  TOKEN_COMMA,
  TOKEN_CONTAINS,
  TOKEN_DOT,
  TOKEN_ELSE,
  TOKEN_EOF,
  TOKEN_EQUAL_EQUAL,
  TOKEN_ERROR,
  TOKEN_FALSE,
  TOKEN_FORMAT,
  TOKEN_GREATER,
  TOKEN_GREATER_EQUAL,
  TOKEN_HTTP_GET,
  TOKEN_IDENTIFIER,
  TOKEN_IF,
  TOKEN_LEFT_BRACE,
  TOKEN_LEFT_PAREN,
  TOKEN_LESS,
  TOKEN_LESS_EQUAL,
  TOKEN_LOGICAL_AND,
  TOKEN_LOGICAL_OR,
  TOKEN_MINUS,
  TOKEN_MODULO,
  TOKEN_NUMBER,
  TOKEN_ON_ERROR,
  TOKEN_ON_SUCCESS,
  TOKEN_PLUS,
  TOKEN_REPLACE,
  TOKEN_RIGHT_BRACE,
  TOKEN_RIGHT_PAREN,
  TOKEN_SEMICOLON,
  TOKEN_SLASH,
  TOKEN_STAR,
  TOKEN_STRING,
  TOKEN_TO_INT,
  TOKEN_TRUE,
} from "./scanner"

let ifCounter = 0
let maxIfCounter = 0
let catchCounter = 0
let maxCatchCounter = 0
let scope = {}

class BytecodeGenerator {
  generateBytecode() {}
}

class Literal extends BytecodeGenerator {
  constructor(literal) {
    super()
    this.literal = literal
  }

  generateBytecode() {
    return (
      "p " +
      (this.literal.replace
        ? this.literal.replace(/[;]+/g, "apxsmc")
        : this.literal)
    )
  }
}

class IdentifierLiteral extends BytecodeGenerator {
  constructor(identifier) {
    super()
    this.identifier = identifier
  }

  generateBytecode() {
    return "e " + this.identifier
  }
}

class BlockStatement extends BytecodeGenerator {
  constructor(statements) {
    super()
    this.statements = statements
  }

  generateBytecode() {
    return this.statements
      .map((statement) => statement.generateBytecode())
      .join(";")
  }
}

class OrExpression extends BytecodeGenerator {
  constructor(left, right) {
    super()
    this.left = left
    this.right = right
  }

  generateBytecode() {
    return (
      this.left.generateBytecode() + ";" + this.right.generateBytecode() + ";or"
    )
  }
}

class AndExpression extends BytecodeGenerator {
  constructor(left, right) {
    super()
    this.left = left
    this.right = right
  }

  generateBytecode() {
    return (
      this.left.generateBytecode() +
      ";" +
      this.right.generateBytecode() +
      ";and"
    )
  }
}

class UnaryExpression extends BytecodeGenerator {
  constructor(operator, right) {
    super()
    this.operator = operator
    this.right = right
  }

  generateBytecode() {
    let right = this.right.generateBytecode()
    switch (this.operator.type) {
      case TOKEN_BANG:
        return right + ";neg"
      case TOKEN_TO_INT:
        return right + ";toi"
      default:
        break
    }
  }
}

class BinaryExpression extends BytecodeGenerator {
  constructor(left, operator, right) {
    super()
    this.left = left
    this.operator = operator
    this.right = right
  }

  generateBytecode() {
    let left = this.left.generateBytecode()
    let right = this.right.generateBytecode()
    switch (this.operator.type) {
      case TOKEN_PLUS:
        return right + ";" + left + ";add"
      case TOKEN_MINUS:
        return right + ";" + left + ";sub"
      case TOKEN_STAR:
        return right + ";" + left + ";mul"
      case TOKEN_SLASH:
        return right + ";" + left + ";div"
      case TOKEN_MODULO:
        return right + ";" + left + ";mod"
      case TOKEN_LESS:
        return right + ";" + left + ";lt"
      case TOKEN_LESS_EQUAL:
        return right + ";" + left + ";lte"
      case TOKEN_GREATER:
        return right + ";" + left + ";gt"
      case TOKEN_GREATER_EQUAL:
        return right + ";" + left + ";gte"
      case TOKEN_CONTAINS:
        return right + ";" + left + ";ext"
      case TOKEN_EQUAL_EQUAL:
        return right + ";" + left + ";eq"
      case TOKEN_BANG_EQUAL:
        return right + ";" + left + ";neq"
      default:
        break
    }
  }
}

class GroupingExpression extends BytecodeGenerator {
  constructor(expr) {
    super()
    this.expr = expr
  }

  generateBytecode() {
    return this.expr.generateBytecode()
  }
}

class IfStatement extends BytecodeGenerator {
  constructor(condition, thenBranch, elseBranch) {
    super()
    this.condition = condition
    this.thenBranch = thenBranch
    this.elseBranch = elseBranch
  }

  generateBytecode() {
    ifCounter++

    const returnString =
      this.condition.generateBytecode() +
      (this.elseBranch ? `;jz ${ifCounter};` : `;jz L${ifCounter};`) +
      this.thenBranch.generateBytecode() +
      `;jmp L${ifCounter};` +
      (this.elseBranch
        ? `${ifCounter}:` +
          this.elseBranch.generateBytecode() +
          `;jmp L${ifCounter};`
        : "") +
      `L${ifCounter}:`

    maxIfCounter = maxIfCounter < ifCounter ? ifCounter : maxIfCounter
    ifCounter--

    return returnString
  }
}

class FormatStatement extends BytecodeGenerator {
  constructor(string, parameters) {
    super()
    this.string = string
    this.parameters = parameters || []
  }

  generateBytecode() {
    let params = []
    this.parameters.forEach((param) => params.push(param.generateBytecode()))
    let p = params.length > 0 ? params.reverse().join(";") + ";" : ""
    return (
      p +
      'r "' +
      this.string.replace(/[;]+/g, "apxsmc") +
      '",' +
      this.parameters.length
    )
  }
}

class ExpressionStatement extends BytecodeGenerator {
  constructor(expr) {
    super()
    this.expr = expr
  }

  generateBytecode() {
    return this.expr.generateBytecode()
  }
}

class HttpGetStatement extends BytecodeGenerator {
  constructor(
    urlStatement,
    { responseVariables, onSuccessBlock },
    onErrorBlock,
  ) {
    super()
    this.urlStatement = urlStatement
    this.responseVariables = responseVariables.reverse()
    this.onSuccessBlock = onSuccessBlock
    this.catchBlock = onErrorBlock
  }

  generateBytecode() {
    catchCounter++
    let returnString = `${this.urlStatement.generateBytecode()};ea [${this.responseVariables.map(
      (param) => `"${param}"`,
    )}],C${catchCounter};${this.onSuccessBlock.generateBytecode()};jmp H${catchCounter};C${catchCounter}:`
    if (this.catchBlock != null) {
      returnString +=
        this.catchBlock.generateBytecode() + `;jmp H${catchCounter}`
    }
    returnString += `;H${catchCounter}:`

    maxCatchCounter =
      maxCatchCounter < catchCounter ? catchCounter : maxCatchCounter
    catchCounter--

    return returnString
  }
}

class ReplaceStatement extends BytecodeGenerator {
  constructor(originalString, targetString, replacement) {
    super()
    this.originalString = originalString
    this.targetString = targetString
    this.replacement = replacement
  }

  generateBytecode() {
    const byteCode1 = this.replacement.generateBytecode()
    const byteCode2 = this.targetString.generateBytecode()
    const byteCode3 = this.originalString.generateBytecode()

    return `${byteCode1};${byteCode2};${byteCode3};rep`
  }
}

export default class Parser {
  constructor() {
    this.hadError = false
    this.tokens = []
    this.variables = []
    this.current = 0
  }

  beginScope() {
    scope = {
      enclosing: {
        ...scope,
      },
    }
  }

  endScope() {
    scope = scope.enclosing || {}
  }

  parse(source, variables = [], allowAPICalls = true) {
    let scanner = new Scanner(source)
    this.tokens = []
    this.variables = variables
    this.current = 0
    this.hadError = false

    ifCounter = 0
    maxIfCounter = 0
    catchCounter = 0
    maxCatchCounter = 0

    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        let token = scanner.scanToken()
        if (token?.type === TOKEN_ERROR) {
          this.hadError = true
          this.error(token, token?.literal)
          break
        }
        if (token?.type === TOKEN_EOF) {
          break
        }
        if (token?.type === TOKEN_HTTP_GET && !allowAPICalls) {
          this.hadError = true
          this.error(token, "API calls are not allowed")
          break
        }
        this.tokens.push(token)
      } catch (e) {
        this.hadError = true
        throw e
      }
    }

    if (this.hadError) {
      return
    }

    return this.interpret()
  }

  interpret() {
    let statements = []

    while (!this.isAtEnd()) {
      statements.push(this.statement())
    }

    // Parsed successfully, now generate instructions using the statements
    Logger.log(statements)
    const bytecode = statements
      .map((stmt) => {
        const result = stmt.generateBytecode()
        ifCounter = maxIfCounter
        catchCounter = maxCatchCounter
        return result
      })
      .join(";")
      .replace(";;", ";")

    Logger.log(bytecode)

    return bytecode
  }

  statement() {
    // Follow the given DSL to implement functions
    if (this.match(TOKEN_IF)) {
      return this.ifStatement()
    }
    if (this.match(TOKEN_LEFT_BRACE)) {
      return this.block()
    }
    if (this.match(TOKEN_FORMAT)) {
      return this.formatStatement()
    }
    if (this.match(TOKEN_HTTP_GET)) {
      return this.httpGetStatement()
    }
    if (this.match(TOKEN_REPLACE)) {
      return this.replaceStatement()
    }
    return this.expressionStatement()
  }

  ifStatement() {
    this.consume(TOKEN_LEFT_PAREN, "Expected '(' in an if statement.")
    let expression = this.expression()
    let elseBranch = null
    this.consume(TOKEN_RIGHT_PAREN, "Expected ')' in an if statement.")
    let thenBranch = this.statement()
    if (this.match(TOKEN_ELSE)) {
      elseBranch = this.statement()
    }

    return new IfStatement(expression, thenBranch, elseBranch)
  }

  block() {
    let statements = []
    while (!this.isAtEnd() && this.peek().type !== TOKEN_RIGHT_BRACE) {
      statements.push(this.statement())
    }
    this.consume(TOKEN_RIGHT_BRACE, "Expected '}' in a block statement.")
    return new BlockStatement(statements)
  }

  formatStatement() {
    this.consume(TOKEN_LEFT_PAREN, "Expected '(' in an format statement.")
    this.consume(
      TOKEN_STRING,
      "Expected string as a first parameter in an format statement.",
    )
    let string = this.previous().literal
    let params = []
    while (this.match(TOKEN_COMMA)) {
      params.push(this.expression())
    }
    this.consume(
      TOKEN_RIGHT_PAREN,
      "Expected ')' after params in format statement.",
    )
    // this.consume(
    //   TOKEN_SEMICOLON,
    //   "Expected ';' after an format statement."
    // );
    return new FormatStatement(string, params)
  }

  httpGetStatement() {
    this.consume(TOKEN_LEFT_PAREN, "Expected '(' after httpGet.", true)

    let urlStatement
    if (this.match(TOKEN_STRING)) {
      urlStatement = new Literal(this.previous().literal)
    } else if (this.match(TOKEN_FORMAT)) {
      urlStatement = this.formatStatement()
    } else {
      this.error(
        this.previous(),
        "Either String or format statment allowed as a parameter to httpGet function. Error at ",
        true,
      )
    }

    this.consume(
      TOKEN_RIGHT_PAREN,
      "Expected ')' after httpGet expression.",
      true,
    )
    this.consume(TOKEN_DOT, "Expected '.' after httpGet function.", true)
    this.consume(
      TOKEN_ON_SUCCESS,
      "Expected 'onSuccess' after httpGet function.",
      true,
    )
    this.consume(TOKEN_LEFT_PAREN, "Expected '(' after onSuccess.", true)
    this.consume(
      TOKEN_LEFT_PAREN,
      "Expected an anonymous function in 'onSuccess' block.",
      true,
    )
    this.consume(
      TOKEN_RIGHT_PAREN,
      "Expected ')' after function parameters.",
      true,
    )
    this.consume(TOKEN_ARROW, "Expected '=>' after function declaration.", true)
    this.beginScope()
    const onSuccessBlock = this.statement()
    const responseVariables = Object.keys(scope).filter(
      (key) => key !== "enclosing",
    )
    this.endScope()
    this.consume(TOKEN_RIGHT_PAREN, "Expected ')' after then statement.", true)

    let onErrorBlock = null
    if (this.match(TOKEN_DOT)) {
      this.consume(TOKEN_ON_ERROR, "Expected 'onError' after dot.", true)
      this.consume(TOKEN_LEFT_PAREN, "Expected '(' after 'onError'.", true)
      this.consume(
        TOKEN_LEFT_PAREN,
        "Expected an anonymous function in 'onError' block.",
        true,
      )
      this.consume(TOKEN_RIGHT_PAREN, "Expected ')' in 'onError' block.", true)
      this.consume(
        TOKEN_ARROW,
        "Expected '=>' after function declaration.",
        true,
      )
      onErrorBlock = this.statement()
      this.consume(
        TOKEN_RIGHT_PAREN,
        "Expected ')' after statement in onError block.",
        true,
      )
    }

    this.consume(
      TOKEN_SEMICOLON,
      "Expected ';' at the end of httpGet statement.",
      true,
    )

    return new HttpGetStatement(
      urlStatement,
      { responseVariables, onSuccessBlock },
      onErrorBlock,
    )
  }

  replaceStatement() {
    this.consume(TOKEN_LEFT_PAREN, "Expected '(' after replace")

    let stringTobeReplaced
    if (this.match(TOKEN_STRING)) {
      stringTobeReplaced = new Literal(this.previous().literal)
    } else if (this.match(TOKEN_FORMAT)) {
      stringTobeReplaced = this.formatStatement()
    } else if (this.match(TOKEN_IDENTIFIER)) {
      stringTobeReplaced = this.identifier()
    } else if (this.match(TOKEN_REPLACE)) {
      stringTobeReplaced = this.replaceStatement()
    } else {
      this.error(
        this.peek(),
        "Expected first parameter as replace or string or format",
        false,
      )
    }

    this.consume(
      TOKEN_COMMA,
      "Expected ',' after the first parameter in replace",
    )

    this.consume(TOKEN_STRING, "Expected second parameter as String in replace")
    const targetString = new Literal(this.previous().literal)

    this.consume(
      TOKEN_COMMA,
      "Expected ',' after the second parameter in replace",
    )

    this.consume(TOKEN_STRING, "Expected third parameter as String in replace")
    const replacement = new Literal(this.previous().literal)

    this.consume(TOKEN_RIGHT_PAREN, "Expected ')' for replace")

    return new ReplaceStatement(stringTobeReplaced, targetString, replacement)
  }

  expressionStatement() {
    let expr = this.expression()
    this.consume(TOKEN_SEMICOLON, "Expected ';' after an expression.")
    return new ExpressionStatement(expr)
  }

  expression() {
    return this.or()
  }

  toInt() {
    const operator = this.previous()
    this.consume(TOKEN_LEFT_PAREN, "Expected '(' for 'toInt'.")
    let expr
    if (this.match(TOKEN_REPLACE)) {
      expr = this.replaceStatement()
    } else {
      expr = this.expression()
    }
    this.consume(TOKEN_RIGHT_PAREN, "Expected ')' after expression.")

    return new UnaryExpression(operator, expr)
  }

  or() {
    let left = this.and()

    while (this.match(TOKEN_LOGICAL_OR)) {
      left = new OrExpression(left, this.and())
    }

    return left
  }

  and() {
    let left = this.equality()

    while (this.match(TOKEN_LOGICAL_AND)) {
      left = new AndExpression(left, this.equality())
    }

    return left
  }

  equality() {
    let left = this.comparison()

    while (this.match([TOKEN_EQUAL_EQUAL, TOKEN_BANG_EQUAL])) {
      let operator = this.previous()
      left = new BinaryExpression(left, operator, this.comparison())
    }

    return left
  }

  comparison() {
    let left = this.addition()

    while (
      this.match([
        TOKEN_LESS,
        TOKEN_LESS_EQUAL,
        TOKEN_GREATER,
        TOKEN_GREATER_EQUAL,
        TOKEN_CONTAINS,
      ])
    ) {
      let operator = this.previous()
      left = new BinaryExpression(left, operator, this.addition())
    }

    return left
  }

  addition() {
    let left = this.subtraction()

    while (this.match(TOKEN_PLUS)) {
      let operator = this.previous()
      left = new BinaryExpression(left, operator, this.subtraction())
    }

    return left
  }

  subtraction() {
    let left = this.multiplication()

    while (this.match(TOKEN_MINUS)) {
      let operator = this.previous()
      left = new BinaryExpression(left, operator, this.multiplication())
    }

    return left
  }

  multiplication() {
    let left = this.division()

    while (this.match(TOKEN_STAR)) {
      let operator = this.previous()
      left = new BinaryExpression(left, operator, this.division())
    }

    return left
  }

  division() {
    let left = this.modulo()

    while (this.match(TOKEN_SLASH)) {
      let operator = this.previous()
      left = new BinaryExpression(left, operator, this.modulo())
    }

    return left
  }

  modulo() {
    let left = this.negate()

    while (this.match(TOKEN_MODULO)) {
      let operator = this.previous()
      left = new BinaryExpression(left, operator, this.negate())
    }

    return left
  }

  negate() {
    if (this.match(TOKEN_BANG)) {
      let operator = this.previous()
      let right = this.negate()
      return new UnaryExpression(operator, right)
    }
    return this.primary()
  }

  primary() {
    if (this.match(TOKEN_IDENTIFIER)) {
      return this.identifier()
    }
    if (this.match([TOKEN_NUMBER, TOKEN_STRING])) {
      return new Literal(this.previous().literal)
    }
    if (this.match(TOKEN_FALSE) || this.match(TOKEN_TRUE)) {
      return new Literal(this.previous().literal === "true")
    }
    if (this.match(TOKEN_TO_INT)) {
      return this.toInt()
    }
    if (this.match(TOKEN_LEFT_PAREN)) {
      let expr = this.expression()
      this.consume(
        TOKEN_RIGHT_PAREN,
        "Expected ')' after a grouping expression.",
      )
      return new GroupingExpression(expr)
    }
    this.error(this.peek(), "Unexpected symbol ", true)
  }

  identifier() {
    let variable = this.previous().literal
    if (!this.variables.includes(variable)) {
      this.error(this.previous(), "Undefined variable ", true)
    }
    scope[variable] = true
    return new IdentifierLiteral(variable)
  }

  isAtEnd() {
    return this.current >= this.tokens.length
  }

  previous() {
    return this.tokens[this.current - 1]
  }

  advance() {
    if (!this.isAtEnd()) {
      this.current += 1
    }
    return this.previous()
  }

  peek() {
    return this.tokens[this.current]
  }

  match(types) {
    if (this.isAtEnd()) return false

    let tokenTypes = types
    if (!Array.isArray(types)) {
      tokenTypes = [types]
    }
    for (let i = 0; i < tokenTypes.length; i++) {
      if (this.peek().type === tokenTypes[i]) {
        this.advance()
        return true
      }
    }
    return false
  }

  consume(type, message, showPosition = false) {
    if (this.isAtEnd()) {
      this.error(this.previous(), message, showPosition)
      return
    }
    if (this.peek().type === type) {
      this.advance()
    } else {
      this.hadError = true
      this.error(this.peek(), message, showPosition)
    }
  }

  error(token, message, showPosition = false) {
    throw new Error(
      message +
        (showPosition && token.originalSource ? token.originalSource : " ") +
        " at line " +
        token.line +
        " column " +
        token.position,
    )
  }
}
