import { callApi } from "../../../../api"
import { makeDefaultQueryString, withQueryStrings } from "../../../../utils"
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
} from "../../../common/actions"

const CUSTOM_DASHBOARDS_API = "dashboards"
const NEW_BI_DASHBOARD = "bi-adoption/frontendapi/"

export function getBiDimensionsAPI(auth, appId, queryParams) {
  const url =
    makeDefaultQueryString("dimensions", auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "GET",
    auth: auth,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    true,
    false,
  )
}

export function getBiDimensionsValuesAPI(auth, appId, property, queryParams) {
  const url =
    makeDefaultQueryString(`dimensions/${property}`, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "GET",
    auth: auth,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return {}
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    true,
    false,
  )
}

export function getBiPiachartDataAPI(
  auth,
  appId,
  queryParams = {},
  postBody = {},
) {
  const url =
    makeDefaultQueryString(`custom-widget/pi-chart`, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "POST",
    auth: auth,
    body: postBody,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    false,
    false,
  )
}

export function getBiFrequencyDataAPI(
  auth,
  appId,
  queryParams = {},
  postBody = {},
) {
  const url =
    makeDefaultQueryString(`custom-widget/frequency-chart`, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "POST",
    auth: auth,
    body: postBody,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    false,
    false,
  )
}

export function getBiTrendsDataAPI(
  auth,
  appId,
  queryParams = {},
  postBody = {},
) {
  const url =
    makeDefaultQueryString(`custom-widget/trend`, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "POST",
    auth: auth,
    body: postBody,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    false,
    false,
  )
}

export function getBiAggregateDataAPI(
  auth,
  appId,
  queryParams = {},
  postBody = {},
) {
  const url =
    makeDefaultQueryString(`custom-widget/aggregates`, auth, appId) +
    withQueryStrings(queryParams)

  const requestConfig = {
    method: "POST",
    auth: auth,
    body: postBody,
  }

  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return response
  }

  return callApi(
    url,
    requestConfig,
    onSuccessCallback,
    onFailureCallback,
    NEW_BI_DASHBOARD,
    false,
    false,
  )
}

export function getCustomDashboardsAPI(auth, appId, queryParams = {}) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    CUSTOM_DASHBOARDS_API,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    false,
    true,
  )
}

export function getDashboardByIdAPI(
  auth,
  appId,
  queryParams = {},
  dashboardId,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    `${CUSTOM_DASHBOARDS_API}/${dashboardId}`,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    false,
    true,
  )
}

export function getStickiessMetricAPI(auth, appId, queryParams = {}) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeGetRequest(
    `metrics/stickiness`,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    false,
    true,
  )
}

export function createNewCustomDashboardsAPI(
  auth,
  appId,
  queryParams = {},
  postBody,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makePostRequest(
    CUSTOM_DASHBOARDS_API,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    null,
    false,
    true,
  )
}

export function getCustomFormulasWidgetDataAPI(
  auth,
  appId,
  queryParams = {},
  postBody,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makePostRequest(
    "custom-formula",
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    null,
    false,
  )
}

export function getHourlyDistributionAPI(
  auth,
  appId,
  queryParams = {},
  postBody,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makePostRequest(
    "events/distribution/hour",
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    null,
    false,
  )
}

export function saveWidgetInCustomDashboardsAPI(
  auth,
  appId,
  queryParams = {},
  postBody,
  dashboardId,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makePutRequest(
    `${CUSTOM_DASHBOARDS_API}/${dashboardId}`,
    auth,
    appId,
    queryParams,
    postBody,
    onSuccessCallback,
    onFailureCallback,
    null,
    true,
  )
}

export function deleteDashboardByIdAPI(
  auth,
  appId,
  queryParams = {},
  dashboardId,
) {
  const onSuccessCallback = (json) => {
    return json
  }
  const onFailureCallback = (response) => {
    return []
  }
  return makeDeleteRequest(
    `${CUSTOM_DASHBOARDS_API}/${dashboardId}`,
    auth,
    appId,
    queryParams,
    onSuccessCallback,
    onFailureCallback,
    true,
  )
}
