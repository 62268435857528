import {
  DATA_TYPES,
  DEFAULT_OPERATOR_OPTIONS,
  EQUALITY_OPERATOR_OPTIONS,
  EVENT_TYPES,
  NUMBER_OPERATOR_OPTIONS,
  STRING_OPERATOR_OPTIONS,
} from "./constants"

export const getEventType = (type, isConfig = false) => {
  switch (type) {
    case EVENT_TYPES.ACTIVITY.label:
      return isConfig
        ? EVENT_TYPES.ACTIVITY.configName
        : EVENT_TYPES.ACTIVITY.displayName
    case EVENT_TYPES.CLIENT.label:
      return isConfig
        ? EVENT_TYPES.CLIENT.configName
        : EVENT_TYPES.CLIENT.displayName
    case EVENT_TYPES.CUSTOM_SCREEN.label:
      return isConfig
        ? EVENT_TYPES.CUSTOM_SCREEN.configName
        : EVENT_TYPES.CUSTOM_SCREEN.displayName
    default:
      return isConfig ? EVENT_TYPES.APP.configName : EVENT_TYPES.APP.displayName
  }
}

export const removeLastChar = (str) => {
  if (!str) {
    return ""
  }
  return str.substring(0, str.length - 1)
}

export const getDatatype = (type) => {
  switch (type) {
    case "l":
      return DATA_TYPES.INTEGER
    case "f":
      return DATA_TYPES.DECIMAL
    default:
      return DATA_TYPES.STRING
  }
}

export const getOperatorString = (operator) => {
  switch (operator) {
    case "GT":
      return ">"
    case "GTE":
      return ">="
    case "LT":
      return "<"
    case "LTE":
      return "<="
    case "EQ":
      return "=="
    case "NEQ":
      return "!="
    case "R":
      return "REGEX"
    default:
      return ""
  }
}

export const getOptionsForType = (type) => {
  switch (type) {
    case DATA_TYPES.STRING:
      return STRING_OPERATOR_OPTIONS
    case DATA_TYPES.BOOLEAN:
      return EQUALITY_OPERATOR_OPTIONS
    case DATA_TYPES.DECIMAL:
    case DATA_TYPES.INTEGER:
      return NUMBER_OPERATOR_OPTIONS
    default:
      return DEFAULT_OPERATOR_OPTIONS
  }
}
