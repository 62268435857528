import * as Amplitude from "@amplitude/analytics-browser"
// import { init, setSessionProperties } from "apxor"
import { useMemo } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { Toaster } from "sonner"
// import Package from "../../package.json"
import { Provider as IntegrationProvider } from "../Application/IntegrationExperience/store"
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
  wrapperNudges,
} from "../config"
import ForgotPasswordPage from "./Auth/integration-experience/pages/ForgotPasswordPage"
import LogoutPage from "./Auth/integration-experience/pages/LogoutPage"
import ResetPasswordPage from "./Auth/integration-experience/pages/ResetPasswordPage"
import SignInPage from "./Auth/integration-experience/pages/SignInPage"
import SignUpPage from "./Auth/integration-experience/pages/SignUpPage"
import { Provider as AuthProvider } from "./Auth/store"
import AuthorizedRoute from "./AuthorizedRoute"
import { Provider as SettingsProvider } from "./Dashboard/components/settings/store"
import Error401 from "./Error/401"
import Error404 from "./Error/404"
import DesktopOnlyPage from "./Error/DesktopOnlyPage"
import MaintenanceModePage from "./Error/MaintenanceModePage"
import InvitationRevokedPage from "./IntegrationExperience/pages/InvitationRevokedPage"
import { Provider as OrganizationsProvider } from "./Organizations/store"

// function success(data) {
//   console.log("SDK Initialized: " + data.client_id)
// }
// function error() {
//   console.log("SDK is not initialized..........")
// }

// init(
//   getSiteId(),
//   {
//     debug: true,
//     plugins: ["ApxorRTM"],
//     deps: [ApxorRTM, CE],
//     version: Package.version,
//   },
//   success,
//   error,
// )

wrapperNudges(() => {
  Amplitude.init("a0e64bf464ddfa93337ddadb422c886f")
})

// setSessionProperties({ dashboard_version: Package.version })

export default function Root() {
  const isMobileDevice = useMemo(() => /Mobi/i.test(navigator.userAgent), [])
  const isUnderMaintenance = false

  if (isUnderMaintenance) {
    return <MaintenanceModePage />
  }

  return isMobileDevice ? (
    <DesktopOnlyPage />
  ) : (
    <>
      <AuthProvider>
        <OrganizationsProvider>
          <IntegrationProvider>
            <SettingsProvider>
              <BrowserRouter>
                <Switch>
                  <Route
                    exact
                    path="/invitation-revoked"
                  >
                    <InvitationRevokedPage />
                  </Route>
                  <Route
                    exact
                    path="/"
                  >
                    {isExperienceDashboard() ? (
                      <Redirect
                        to={`/orgs/:orgId/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                      />
                    ) : (
                      <Redirect to="/orgs" />
                    )}
                  </Route>
                  <AuthorizedRoute path="/orgs/:orgId/apps" />
                  <AuthorizedRoute path="/orgs/:orgId/users" />
                  <AuthorizedRoute path="/approvals" />
                  <AuthorizedRoute path="/orgs" />
                  <Route
                    exact
                    path="/login"
                  >
                    <SignInPage />
                  </Route>
                  <Route
                    exact
                    path="/sign_up"
                  >
                    <SignUpPage />
                  </Route>
                  <Route
                    exact
                    path="/forgot-password"
                  >
                    <ForgotPasswordPage />
                  </Route>
                  <Route
                    exact
                    path="/reset-password"
                  >
                    <ResetPasswordPage />
                  </Route>
                  <AuthorizedRoute path="/profile" />

                  <Route
                    exact
                    path="/logout"
                  >
                    <LogoutPage />
                  </Route>
                  <Route path="/unauthorised">
                    <Error401 />
                  </Route>
                  <Route path="*">
                    <Error404 />
                  </Route>
                </Switch>
              </BrowserRouter>
            </SettingsProvider>
          </IntegrationProvider>
        </OrganizationsProvider>
      </AuthProvider>

      <Toaster
        position="bottom-right"
        closeButton={true}
        duration={3000}
        visibleToasts={4}
        richColors
        toastOptions={{
          className: "sonner-toast",
        }}
      />
    </>
  )
}
