import { Chip, Typography } from "@material-ui/core"
import { IconCheck } from "@tabler/icons-react"
import { useRef } from "react"
import Select from "react-select/async"
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS"
import HStack from "./HStack"
import VStack from "./VStack"

const customStyles = {
  control: (base, state) => ({
    ...base,
    // maxHeight: 34,
    minHeight: "3em",
    height: 45,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 300,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
}

const formatGroupLabel = (data) => (
  <HStack
    justify="space-between"
    style={{
      marginBlock: 6,
    }}
  >
    {/* Label */}
    <span
      style={{
        fontSize: 10,
        fontWeight: 800,
        color: apxTheme.palette.text.primary,
      }}
    >
      {data.label}
    </span>

    {/* Length */}
    <span
      style={{
        backgroundColor: apxTheme.palette.action.selected,
        borderRadius: "50%",
        color: apxTheme.palette.text.primary,
        fontSize: 10,
        fontWeight: 600,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center",
      }}
    >
      {data.options.length}
    </span>
  </HStack>
)

function Option({ innerProps, defaultTheme = "", isDisabled, ...props }) {
  const ref = useRef(null)

  return (
    <div
      {...innerProps}
      {...props}
      ref={ref}
      style={{
        ...innerProps.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: isDisabled ? 0.5 : 1,
        backgroundColor: props.isFocused
          ? "#F8F8F8"
          : props.isSelected
            ? apxTheme.palette.grey[100]
            : "transparent",
        color: props.isFocused
          ? apxTheme.palette.primary.main
          : apxTheme.palette.text.primary,
        cursor: isDisabled ? "not-allowed" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 12,
        padding: 12,
        zIndex: 50,
      }}
    >
      <VStack
        spacing={1}
        style={{ flex: 1 }}
      >
        <HStack
          justify={"space-between"}
          style={{
            paddingRight: 15,
          }}
        >
          <span>{props.data?.label}</span>
          {defaultTheme !== "" && defaultTheme === props?.data?.ui?._id && (
            <Chip
              label="Default"
              size="small"
              style={{
                backgroundColor: " #002845",
                color: "#FFFFFF",
                borderRadius: 8,
                fontWeight: 700,
              }}
            />
          )}
        </HStack>
      </VStack>

      {props.isSelected ? (
        <IconCheck
          color={apxTheme.palette.success.main}
          size={19}
        />
      ) : (
        <IconCheck
          color={"transparent"}
          size={19}
        />
      )}
    </div>
  )
}

export default function ThemeSelector({
  theme,
  allThemes = [],
  onChange,
  defaultTheme = "",
  configType,
}) {
  const custom_themes = allThemes?.filter((theme) => !theme.ui.state)
  const default_themes = allThemes?.filter((theme) => theme.ui.state)
  const defaultThemeFromLc =
    JSON.parse(localStorage.getItem("apx_default_theme")) || {}

  return (
    <Select
      cacheOptions
      defaultOptions
      loadOptions={(inputValue) => {
        return new Promise((resolve) => {
          const options = [
            {
              label: "Default Themes",
              options: default_themes?.map((theme) => ({
                label: theme.name,
                value: theme.name,
                ui: theme,
              })),
            },
            {
              label: "Custom Themes",
              options: custom_themes.map((theme) => ({
                label: theme.name,
                value: theme.name,
                ui: theme,
              })),
            },
          ]

          const filteredOptions = options.map((group) => ({
            ...group,
            options: group.options.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase()),
            ),
          }))

          resolve(filteredOptions)
        })
      }}
      placeholder="Select Theme"
      noOptionsMessage={() => (
        <Typography
          style={{
            fontWeight: 700,
            fontSize: 13,
          }}
        >
          No Themes found
        </Typography>
      )}
      closeMenuOnSelect={true}
      isClearable={false}
      isSearchable={true}
      isMulti={false}
      onChange={(pair) => {
        onChange(pair)
      }}
      value={{
        label: theme?.name,
        value: theme?.name,
      }}
      formatGroupLabel={formatGroupLabel}
      components={{
        Option: (props) => (
          <Option
            {...props}
            defaultTheme={
              defaultTheme !== ""
                ? defaultTheme
                : defaultThemeFromLc[configType]
            }
          />
        ),
      }}
      styles={customStyles}
    />
  )
}
