import { useState } from "react"
import ModalPopup from "../../../../../../../../ReusableComponents/ModalPopup"
import DynamicTextDialogContent from "../components/dynamicTextv2"

export default function DynamicText2Dialog({
  openFlag,
  onSuccess,
  onFail,
  dynamicType,
  tab,
  originalScript: propScript,
  variableMap: propVariableMap,
  hideRemove = false,
}) {
  const [type, setType] = useState(dynamicType ?? "")
  const [selectedTab, setSelectedTab] = useState(tab)
  const [originalScript, setOriginalScript] = useState(propScript ?? "")
  const [commands, setCommands] = useState("")
  const [variableMap, setVariableMap] = useState(propVariableMap ?? null)

  const handleDone = (
    tab = selectedTab,
    dType = type,
    cmds = commands,
    script = originalScript,
    vmap = variableMap,
  ) => {
    if (onSuccess) {
      onSuccess(tab, dType, cmds, script, vmap)
    }
  }

  return (
    <ModalPopup
      maxWidth="1400px"
      open={openFlag}
      onClose={onFail}
      title="Dynamic Text"
    >
      <DynamicTextDialogContent
        hideRemove={hideRemove}
        originalScript={originalScript}
        variableMap={variableMap}
        selectedTab={selectedTab}
        onSuccessfulParse={(
          selectedTab,
          type,
          commands,
          originalScript,
          variableMap,
        ) => {
          setType(type)
          setSelectedTab(selectedTab)
          setCommands(commands)
          setOriginalScript(originalScript)
          setVariableMap(variableMap)
        }}
        onDone={handleDone}
        onCancel={() => onFail && onFail()}
      />
    </ModalPopup>
  )
}
