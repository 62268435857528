import { COUNT_TYPE_ENUM } from "../../../../constants"

export const ItemTypes = {
  DIMENSION: "dimension",
}

export const DIMENSION_TYPE = {
  attribute: "attribute",
  event: "event",
}

export const REPORTS_DIMENSION = {
  dimension: {
    prop_type: "normal",
    alias: "",
    formula: "",
    vmap: {},
    final_transformation: "",
    id: "",
    data_type: "",
  },
}

export const DEFAULT_METRICS_BOX_TITLE = "New Report"

export const REPORT_ACTIONS_DIALOG_STATES = Object.freeze({
  CLOSED: "closed",
  DELETE: "delete",
  SAVE: "save",
})

export const ACTIVE_TAB_ENUM = Object.freeze({
  EXPLORER: "explorer",
  FLAT_TABLE: "flat-table",
})

export const CUSTOM_REPORTS_METRICS = [
  {
    label: "Total Events",
    value: COUNT_TYPE_ENUM.impressions,
  },
  {
    label: "Users",
    value: COUNT_TYPE_ENUM.users,
  },
  {
    label: "Sessions",
    value: COUNT_TYPE_ENUM.sessions,
  },
]
