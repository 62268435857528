import { IconButton } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { IconTrash } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { useAuth } from "../../../../../contexts/AuthContext"
import { apxTheme } from "../../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../../ReusableComponents/HStack"
import ThemedTooltip from "../../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../../ReusableComponents/VStack"
import {
  IndicatorSeparator,
  Option,
} from "../../../common/components/campaigncreator/components/context/components/targetaudience/components/selects/DidNotDoEventsComboBox"
import { customStyles } from "../utils"

export default function EventAccordion({
  customFormulaData,
  setCustomFormulaData,
  setShowGraph = () => {},
  isReports = false,
  attributes = [],
  attributesWithType = [],
  setAttribute_type = () => {},
}) {
  const auth = useAuth()
  const { appId } = useParams()

  const queryClient = useQueryClient()

  const dimensions = isReports
    ? attributes
    : (queryClient.getQueryData(["bi-dimensions", auth, appId]) ?? [])

  attributesWithType = isReports ? attributesWithType : dimensions

  const eventOptions = isReports
    ? attributes
    : dimensions.map((event) => ({
        label: event.attribute,
        value: event.attribute,
      }))

  return (
    <VStack
      style={{
        width: "100%",
      }}
    >
      {customFormulaData?.events?.length > 0 &&
        customFormulaData?.events?.map((event, index) => (
          <HStack
            key={index}
            spacing={12}
            style={{
              width: "100%",
              padding: 10,
              backgroundColor: "#f1f5f9",
              borderRadius: 12,
            }}
          >
            <HStack
              justify={"center"}
              align={"center"}
              style={{
                height: 40,
                width: 40,
                borderRadius: 8,
                backgroundColor: "#020617",
                fontSize: 14,
                fontWeight: 600,
                color: "#ffffff",
              }}
            >
              {String.fromCharCode(
                ...(event?.index + "")
                  .split("")
                  .map((digit) => parseInt(digit) + 64),
              )}
            </HStack>
            <HStack
              justify={"space-between"}
              style={{
                width: "100%",
              }}
            >
              <Select
                inputId="event"
                placeholder="Select Property"
                noOptionsMessage={() => (
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 13,
                    }}
                  >
                    No Properties Found
                  </Typography>
                )}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                closeMenuOnSelect={true}
                options={eventOptions}
                onChange={(pair) => {
                  setShowGraph(false)

                  if (pair === null) {
                    setCustomFormulaData({
                      ...customFormulaData,
                      events: customFormulaData.events?.map((e, i) =>
                        i === index
                          ? {
                              ...e,
                              event_name: "",
                              data_type: "",
                            }
                          : e,
                      ),
                    })
                    if (isReports) {
                      setAttribute_type("")
                    }
                    return
                  }

                  const dataType =
                    pair.value !== null
                      ? attributesWithType.find(
                          (p) => p.attribute === pair.value,
                        ).typeOf
                      : ""

                  if (isReports) {
                    setAttribute_type(dataType)
                  }

                  setCustomFormulaData({
                    ...customFormulaData,
                    events: customFormulaData.events?.map((e, i) =>
                      i === index
                        ? {
                            ...e,
                            event_name: pair?.value ?? "",
                            data_type: isReports
                              ? dataType
                              : dimensions.find(
                                  (p) => p.attribute === pair.value,
                                ).typeOf,
                          }
                        : e,
                    ),
                  })
                }}
                value={
                  eventOptions.find((o) => o.value === event.event_name) ?? ""
                }
                styles={{
                  ...customStyles,
                  control: (base, state) => ({
                    ...base,
                    minHeight: 43,
                    primary: "orange",
                    "&:hover": { borderColor: "none" },
                    minWidth: 200,
                    width: 300,
                    paddingInline: 6,
                    paddingBlock: 0,
                    fontWeight: 500,
                    fontSize: 13,
                    border: "none",
                    boxShadow: state.isFocused
                      ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
                      : `0 0 0 ${!state.hasValue && "1.5px #D64A40"}`,
                    borderRadius: 20,
                    backgroundColor: "#E2E8F0",
                    color: state.isFocused
                      ? apxTheme.palette.primary.main
                      : apxTheme.palette.text.primary,
                  }),
                }}
                components={{
                  Option,
                  IndicatorSeparator,
                }}
              />
              <ThemedTooltip
                id={index}
                title="Remove"
                dark
              >
                <IconButton
                  size="small"
                  aria-label="Delete"
                  onClick={() => {
                    setShowGraph(false)
                    setCustomFormulaData({
                      ...customFormulaData,
                      events: customFormulaData.events.filter(
                        (_, i) => i !== index,
                      ),
                      formulas: customFormulaData.formulas.filter((f, i) =>
                        customFormulaData.events.length === 0
                          ? {
                              ...f,
                              operators: "",
                              second_formula: "",
                              second_event: "",
                            }
                          : i !== index,
                      ),
                    })
                  }}
                >
                  <IconTrash size={"20px"} />
                </IconButton>
              </ThemedTooltip>
            </HStack>
          </HStack>
        ))}
    </VStack>
  )
}
