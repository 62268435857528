export const TOKEN_SEMICOLON = "TOKEN_SEMICOLON"
export const TOKEN_PLUS = "TOKEN_PLUS"
export const TOKEN_MINUS = "TOKEN_MINUS"
export const TOKEN_STAR = "TOKEN_STAR"
export const TOKEN_SLASH = "TOKEN_SLASH"
export const TOKEN_MODULO = "TOKEN_MODULO"
export const TOKEN_LEFT_PAREN = "TOKEN_LEFT_PAREN"
export const TOKEN_RIGHT_PAREN = "TOKEN_RIGHT_PAREN"
export const TOKEN_LEFT_BRACE = "TOKEN_LEFT_BRACE"
export const TOKEN_RIGHT_BRACE = "TOKEN_RIGHT_BRACE"
export const TOKEN_BANG = "TOKEN_BANG"
export const TOKEN_BANG_EQUAL = "TOKEN_BANG_EQUAL"
export const TOKEN_EQUAL = "TOKEN_EQUAL"
export const TOKEN_EQUAL_EQUAL = "TOKEN_EQUAL_EQUAL"
export const TOKEN_GREATER = "TOKEN_GREATER"
export const TOKEN_GREATER_EQUAL = "TOKEN_GREATER_EQUAL"
export const TOKEN_LESS = "TOKEN_LESS"
export const TOKEN_LESS_EQUAL = "TOKEN_LESS_EQUAL"
export const TOKEN_ERROR = "TOKEN_ERROR"
export const TOKEN_NUMBER = "TOKEN_NUMBER"
export const TOKEN_STRING = "TOKEN_STRING"
export const TOKEN_EOF = "TOKEN_EOF"
export const TOKEN_LOGICAL_AND = "TOKEN_LOGICAL_AND"
export const TOKEN_LOGICAL_OR = "TOKEN_LOGICAL_OR"
export const TOKEN_CONTAINS = "TOKEN_CONTAINS"
export const TOKEN_ELSE = "TOKEN_ELSE"
export const TOKEN_FALSE = "TOKEN_FALSE"
export const TOKEN_IF = "TOKEN_IF"
export const TOKEN_FORMAT = "TOKEN_FORMAT"
export const TOKEN_TRUE = "TOKEN_TRUE"
export const TOKEN_IDENTIFIER = "TOKEN_IDENTIFIER"
export const TOKEN_COMMA = "TOKEN_COMMA"
export const TOKEN_TO_INT = "TOKEN_TO_INT"
export const TOKEN_HTTP_GET = "TOKEN_HTTP_GET"
export const TOKEN_ON_SUCCESS = "TOKEN_ON_SUCCESS"
export const TOKEN_ON_ERROR = "TOKEN_ON_ERROR"
export const TOKEN_DOT = "TOKEN_DOT"
export const TOKEN_ARROW = "TOKEN_ARROW"
export const TOKEN_REPLACE = "TOKEN_REPLACE"

const KEYWORDS = {
  contains: TOKEN_CONTAINS,
  else: TOKEN_ELSE,
  false: TOKEN_FALSE,
  if: TOKEN_IF,
  format: TOKEN_FORMAT,
  toInt: TOKEN_TO_INT,
  true: TOKEN_TRUE,
  httpGet: TOKEN_HTTP_GET,
  onSuccess: TOKEN_ON_SUCCESS,
  onError: TOKEN_ON_ERROR,
  replace: TOKEN_REPLACE,
}

export default class Scanner {
  constructor(source) {
    this.source = source
    this.line = 1
    this.current = 0
    this.start = 0
  }

  isAtEnd() {
    return this.current >= this.source.length
  }

  advance() {
    this.current += 1
    return this.source[this.current - 1]
  }

  peek() {
    return this.source[this.current]
  }

  peekNext() {
    return this.source[this.current + 1]
  }

  match(expected) {
    if (this.isAtEnd()) return false
    if (this.source[this.current] !== expected) return false

    this.advance()
    return true
  }

  makeToken(type, literal) {
    return {
      type,
      line: this.line,
      literal,
      originalSource: this.source.substring(this.start, this.current),
      position: this.current,
    }
  }

  errorToken(message) {
    return {
      type: TOKEN_ERROR,
      line: this.line,
      literal: message,
      position: this.current,
    }
  }

  isAlpha(c) {
    var codePoint = c.codePointAt(0)
    return (
      (codePoint >= 65 && codePoint <= 90) ||
      (codePoint >= 97 && codePoint <= 122) ||
      c === "_"
    )
  }

  isDigit(c) {
    return c >= "0" && c <= "9"
  }

  number() {
    while (this.isDigit(this.peek())) {
      this.advance()
    }

    if (this.peek() === "." && this.isDigit(this.peekNext())) {
      this.advance()
      while (this.isDigit(this.peek())) {
        this.advance()
      }
    }

    return this.makeToken(
      TOKEN_NUMBER,
      Number(this.source.substring(this.start, this.current)),
    )
  }

  string() {
    while (this.peek() !== '"' && !this.isAtEnd()) {
      this.advance()
      if (this.peek() === "\n") {
        this.line += 1
      }
    }

    if (this.isAtEnd()) {
      return this.errorToken("Unterminated string.")
    }

    this.advance()

    return this.makeToken(
      TOKEN_STRING,
      this.source.substring(this.start + 1, this.current - 1),
    )
  }

  identifier() {
    while (
      !this.isAtEnd() &&
      (this.isAlpha(this.peek()) || this.isDigit(this.peek()))
    ) {
      this.advance()
    }

    var text = this.source.substring(this.start, this.current)
    if (KEYWORDS.hasOwnProperty(text)) {
      return this.makeToken(KEYWORDS[text])
    }

    return this.makeToken(TOKEN_IDENTIFIER, text)
  }

  skipWhitespace() {
    for (;;) {
      switch (this.peek()) {
        case " ":
        case "\r":
        case "\t":
          this.advance()
          break
        case "\n":
          this.advance()
          this.line += 1
          break
        default:
          return
      }
    }
  }

  scanToken() {
    this.skipWhitespace()

    this.start = this.current

    if (this.isAtEnd()) return this.makeToken(TOKEN_EOF)

    var c = this.advance()
    if (this.isAlpha(c)) {
      return this.identifier()
    }
    if (this.isDigit(c)) {
      return this.number()
    }

    switch (c) {
      case "(":
        return this.makeToken(TOKEN_LEFT_PAREN)
      case ")":
        return this.makeToken(TOKEN_RIGHT_PAREN)
      case "{":
        return this.makeToken(TOKEN_LEFT_BRACE)
      case "}":
        return this.makeToken(TOKEN_RIGHT_BRACE)
      case "+":
        return this.makeToken(TOKEN_PLUS)
      case "-":
        return this.makeToken(TOKEN_MINUS)
      case "*":
        return this.makeToken(TOKEN_STAR)
      case "/":
        return this.makeToken(TOKEN_SLASH)
      case ";":
        return this.makeToken(TOKEN_SEMICOLON)
      case "%":
        return this.makeToken(TOKEN_MODULO)
      case ".":
        return this.makeToken(TOKEN_DOT)
      case ">":
        return this.makeToken(
          this.match("=") ? TOKEN_GREATER_EQUAL : TOKEN_GREATER,
        )
      case "<":
        return this.makeToken(this.match("=") ? TOKEN_LESS_EQUAL : TOKEN_LESS)
      case "!":
        return this.makeToken(this.match("=") ? TOKEN_BANG_EQUAL : TOKEN_BANG)
      case "=":
        return this.makeToken(
          this.match(">")
            ? TOKEN_ARROW
            : this.match("=")
              ? TOKEN_EQUAL_EQUAL
              : TOKEN_EQUAL,
        )
      case '"':
        return this.string()
      case ",":
        return this.makeToken(TOKEN_COMMA)
      case "&":
        if (this.match("&")) {
          return this.makeToken(TOKEN_LOGICAL_AND)
        }
        return this.errorToken("Unexpected character")
      case "|":
        if (this.match("|")) {
          return this.makeToken(TOKEN_LOGICAL_OR)
        }
        return this.errorToken("Unexpected character")
      default:
        return this.errorToken("Unexpected character")
    }
  }
}
