import { Typography } from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import { IconCheck } from "@tabler/icons-react"
import moment from "moment"
import { useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import Select from "react-select"
import { DASHBOARD_ENDPOINTS } from "../../../../constants/Endpoints"
import { useAuth } from "../../../../contexts/AuthContext"
import { useDeepCompareEffectNoCheck } from "../../../../utils/use-deep-compare"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import { useTrackedState } from "../../../IntegrationExperience/store"
import DateRangePickerWrapper from "../../../ReusableComponents/DateRangePicker"
import HStack from "../../../ReusableComponents/HStack"
import ThemedTooltip from "../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../ReusableComponents/VStack"
import { getAppVersions, getCohorts } from "../actions"

export function Option({ innerProps, isDisabled, ...props }) {
  return (
    <div
      {...innerProps}
      {...props}
      style={{
        ...innerProps.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: isDisabled ? 0.5 : 1,
        backgroundColor: props.isFocused
          ? "#F8F8F8"
          : props.isSelected
            ? apxTheme.palette.grey[100]
            : "transparent",
        color: props.isFocused
          ? apxTheme.palette.primary.main
          : apxTheme.palette.text.primary,
        cursor: isDisabled ? "not-allowed" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 12,
        padding: 12,
        zIndex: 50,
        ...(props.data?.isButton && {
          backgroundColor: props.isFocused
            ? apxTheme.palette.grey[800]
            : apxTheme.palette.grey[900],
          transition: "background-color 0.2s ease-in-out",
          color: apxTheme.palette.common.white,
          fontSize: 14,
          flex: 1,
          fontWeight: 900,
          position: "sticky",
          bottom: 0,
          insetInline: 0,
          padding: 14,
          borderRadius: 8,
        }),
      }}
    >
      <Typography
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {props.data?.label}
      </Typography>
      {props.data?.processingStatus === "in-progress" &&
        !props.data?.isButton && (
          <ThemedTooltip
            title="Processing..."
            css={{ fontSize: 13, fontWeight: 800 }}
            dark
          >
            <WarningIcon
              style={{
                color: apxTheme.palette.warning.main,
                pointerEvents: "auto",
                opacity: 1,
                cursor: "default",
                zIndex: 9999,
                marginLeft: 8,
                width: 20,
                height: 20,
              }}
            />
          </ThemedTooltip>
        )}
      {props.isSelected && !props.data?.isButton && (
        <IconCheck
          color={apxTheme.palette.success.main}
          size={19}
        />
      )}
    </div>
  )
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: 350,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

function CohortSelect({ refresh, onCohortChange }) {
  const auth = useAuth()
  const { appId } = useParams()
  const [cohorts, setCohorts] = useState([])
  const [selectedCohort, setSelectedCohort] = useState("")

  useDeepCompareEffectNoCheck(() => {
    getCohorts(auth, appId).then((response) => {
      setCohorts(response)
    })
  }, [appId, auth, refresh])

  const finalOptions =
    cohorts.map((o) => ({
      label: o?.name,
      value: o?.cohort_id,
      processingStatus: o?.processing_status ?? "",
    })) ?? []

  return (
    <Select
      inputId="select-cohort"
      placeholder="Select a Cohort"
      noOptionsMessage={() => (
        <Typography
          style={{
            fontWeight: 700,
            fontSize: 13,
          }}
        >
          No Cohorts Found
        </Typography>
      )}
      isOptionDisabled={(cohort) => {
        return cohort?.processingStatus === "completed"
          ? false
          : cohort?.processingStatus === "in-progress"
            ? true
            : false
      }}
      isSearchable={true}
      isClearable={true}
      isMulti={false}
      closeMenuOnSelect={true}
      options={finalOptions}
      value={finalOptions.find((o) => o.value === selectedCohort)}
      onChange={(pair) => {
        const value = pair?.value
        setSelectedCohort(value)

        if (onCohortChange) {
          onCohortChange(value)
        }
      }}
      components={{
        Option,
      }}
      styles={customStyles}
    />
  )
}

function VersionsSelect({ refresh, onVersionChange }) {
  const auth = useAuth()
  const { appId } = useParams()

  const [versions, setVersions] = useState([])
  const [selectedVersions, setSelectedVersions] = useState([])

  useDeepCompareEffectNoCheck(() => {
    getAppVersions(auth, appId).then((response) => {
      setVersions(response)
    })
  }, [appId, auth, refresh])

  return (
    <Select
      inputId="select-version"
      placeholder="Select a Version"
      noOptionsMessage={() => (
        <Typography
          style={{
            fontWeight: 700,
            fontSize: 13,
          }}
        >
          No Versions Found
        </Typography>
      )}
      isSearchable={true}
      isClearable={true}
      isMulti={true}
      closeMenuOnSelect={true}
      options={versions.map((o) => ({ label: o, value: o }))}
      value={selectedVersions}
      onChange={(values) => {
        if (onVersionChange) {
          setSelectedVersions(values)
          onVersionChange(values ? values.map((v) => v.value) : "")
        }
      }}
      components={{
        Option,
      }}
      styles={customStyles}
    />
  )
}

export default function Filters({
  dateFilters,
  onCohortChange,
  onVersionChange,
  onDateFiltersChange,
  showVersion = false,
  showCohort = false,
  refresh = 0,
  startDateOffset = null,
}) {
  const { app_data: { settings: { timezone = "UTC" } = {} } = {} } =
    useTrackedState()

  const location = useLocation()

  const overrideTZ = [
    DASHBOARD_ENDPOINTS.UNINSTALLS,
    DASHBOARD_ENDPOINTS.RETENTION,
  ].some((item) => location.pathname.match(item))

  return (
    <HStack
      justify="end"
      align="start"
      spacing={4}
      style={{
        marginBlock: 16,
        paddingInline: 16,
      }}
    >
      {/* Cohort Picker */}
      {showCohort && (
        <CohortSelect
          refresh={refresh}
          onCohortChange={onCohortChange}
        />
      )}

      {/* Versions Picker */}
      {showVersion && (
        <VersionsSelect
          refresh={refresh}
          onVersionChange={onVersionChange}
        />
      )}

      {/* Date Range Picker */}
      <VStack spacing={2}>
        <DateRangePickerWrapper
          startDate={moment(dateFilters?.since)}
          endDate={moment(dateFilters?.till).utc().startOf("day")}
          showDefaultInputIcon
          small
          handleChange={(start, end) => {
            const startDate =
              moment(start).utc().toISOString().slice(0, 23) + "Z"
            const endDate =
              moment(end).utc().endOf("day").toISOString().slice(0, 23) + "Z"
            if (onDateFiltersChange) {
              onDateFiltersChange(startDate, endDate)
            }
            localStorage.setItem(
              "filters",
              JSON.stringify({ since: startDate, till: endDate }),
            )
          }}
          disableAfter
          startDateOffset={startDateOffset}
        />

        <Typography
          variant="caption"
          style={{
            fontWeight: 500,
            fontSize: 11,
            marginLeft: 12,
          }}
        >
          {overrideTZ ? (
            <>Timezone will be overridden by UTC</>
          ) : (
            <>
              Timezone: <b>{timezone}</b>
            </>
          )}
        </Typography>
      </VStack>
    </HStack>
  )
}
