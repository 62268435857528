import { Button, Typography } from "@material-ui/core"
import { memo, useState } from "react"
import { useParams } from "react-router-dom"
import { capitalizeEachWord } from "../../utils"
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS"
import { StyledInput, StyledInputLabel } from "../Profile/pages/ProfilePage"
import HStack from "./HStack"
import ModalPopup from "./ModalPopup"
import VStack from "./VStack"

export const DOWNLOAD_CONFIG_TYPES = Object.freeze({
  CAMPAIGN: "campaign",
  SURVEY: "survey",
  CUSTOM_REPORT: "custom report",
  DYNAMIC_COHORT: "dynamic cohort",
  BI_WIDGET: "widget",
})

function DownloadNameDialog({
  open,
  onClose,
  onDownload,
  name,
  error = "",
  configType = DOWNLOAD_CONFIG_TYPES.CAMPAIGN,
}) {
  const { orgId, appId } = useParams()

  const [displayName, setDisplayName] = useState(
    configType === DOWNLOAD_CONFIG_TYPES.CUSTOM_REPORT ||
      configType === DOWNLOAD_CONFIG_TYPES.BI_WIDGET
      ? `${capitalizeEachWord(configType)} ${name} Data`
      : `${name} ${capitalizeEachWord(configType)} Results`,
  )

  return (
    <ModalPopup
      open={open}
      onClose={onClose}
      title={`Download ${capitalizeEachWord(configType)} ${
        configType === DOWNLOAD_CONFIG_TYPES.CUSTOM_REPORT ||
        configType === DOWNLOAD_CONFIG_TYPES.BI_WIDGET
          ? "Data"
          : "Results"
      }`}
      maxWidth="700px"
    >
      <VStack spacing={32}>
        <VStack spacing={6}>
          <StyledInputLabel htmlFor="name">
            Please enter a name for your download :
          </StyledInputLabel>
          <StyledInput
            id="name"
            autoFocus
            placeholder="Enter Name"
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value)
            }}
            style={{
              boxShadow: `0 0 0 1.5px ${
                error !== "" ? "red" : apxTheme.palette.primary.main
              }`,
            }}
          />

          {/* Error */}
          {error !== "" && (
            <Typography
              style={{
                fontSize: 13,
                fontWeight: 600,
                color: "red",
              }}
            >
              {error}
            </Typography>
          )}
        </VStack>

        <ul
          style={{
            listStylePosition: "inside",
          }}
        >
          <li>
            <Typography
              style={{
                display: "inline",
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              You can find this file in the{" "}
              <a
                href={`/orgs/${orgId}/apps/${appId}/settings/downloads`}
                target="_blank"
                rel="noreferrer"
              >
                Downloads Page
              </a>{" "}
              of the dashboard.
            </Typography>
          </li>
          <li>
            <Typography
              style={{
                display: "inline",
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              This file will be available for download for 1 day.
            </Typography>
          </li>
        </ul>

        <HStack
          justify="end"
          spacing={30}
        >
          <Button
            size="large"
            variant="text"
            color="default"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={async () => {
              await onDownload(displayName)
            }}
          >
            Download
          </Button>
        </HStack>
      </VStack>
    </ModalPopup>
  )
}

export default memo(DownloadNameDialog)
