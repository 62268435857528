import { makeStyles } from "@material-ui/core"

export const useModuleConfigStyles = makeStyles((theme) => ({
  moduleConfigRoot: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: "700 !important",
  },
  sectionRoot: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "700 !important",
  },
  root: {
    borderRadius: 3,
    boxShadow:
      "0 0 0 1px rgba(63,63,68,0.05), 0 3px 10px 0 rgba(63,63,68,0.15)",
    marginBottom: 4,
    marginTop: 4,
    padding: 12,
  },
}))
