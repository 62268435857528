import { Suspense, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import useApxorClient from "../../../utils/use-apxor-client"
import useMutateRouterState from "../../../utils/use-mutate-router-state"
import VStack from "../../ReusableComponents/VStack"
import AppsGrid, { AppsGridSkeleton } from "../components/AppsGrid"
import AppsListingHeader from "../components/AppsListingHeader"
import AppsLayout from "../layouts/AppsLayout"

export default function AppsListingPage() {
  const [searchText, setSearchText] = useState("")
  const [logCount, setLogCount] = useState(0)

  const { state } = useLocation()
  const { logEvent } = useApxorClient()
  const { clearStateFields } = useMutateRouterState()

  useEffect(() => {
    if (logCount === 0) {
      if (state?.from_login) {
        logEvent("Apps_PageLaunched", { source: "login-page" })
      } else {
        logEvent("Apps_PageLaunched", { source: "Orgs-page" })
      }
      setLogCount(logCount + 1)
      clearStateFields(["from_login"])
    }
  }, [clearStateFields, logEvent, state?.from_login, logCount])

  return (
    <AppsLayout>
      <VStack
        spacing="50px"
        style={{
          padding: "40px",
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
        }}
      >
        <AppsListingHeader
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <Suspense fallback={<AppsGridSkeleton />}>
          <AppsGrid searchText={searchText} />
        </Suspense>
      </VStack>
    </AppsLayout>
  )
}
