import { useParams } from "react-router-dom"
import { useAuth } from "../../../../../contexts/AuthContext"
import { toTitleCase } from "../../../../../utils"
import { useDeepCompareMemo } from "../../../../../utils/use-deep-compare"
import Loading from "../../../../ReusableComponents/Loading"
import SortableDataTable from "../../../../ReusableComponents/SortableDataTable"
import { useSelector } from "../store"

export default function FlatTable({ queryParams }) {
  const { appId } = useParams()
  const auth = useAuth()

  const {
    dimensions,
    metrics,
    flatTableData: { aggregations, data },
    filters,
    fetchingInProgress,
  } = useSelector()

  const headerLabels = useDeepCompareMemo(
    () => [
      ...dimensions.map((o) => (o.alias ? o.alias : o.id)),
      ...metrics.map((m) => {
        if (["users", "sessions"].includes(m)) return toTitleCase(m)
        return `${toTitleCase(m)} (${aggregations[m] || "NA"})`
      }),
    ],
    [aggregations, dimensions, metrics],
  )

  const keys = useDeepCompareMemo(
    () => [...dimensions.map((o) => (o.alias ? o.alias : o.id)), ...metrics],
    [dimensions, metrics],
  )

  if (fetchingInProgress) {
    return (
      <section className="content">
        <Loading />
      </section>
    )
  } else {
    return (
      <div style={{ width: "100%" }}>
        <SortableDataTable
          downloadParams={{
            appId,
            auth,
            queryParams,
            dimensions,
            filters,
            metrics,
            isBiReport: true,
          }}
          boxProps={{
            title: "Flat Table",
          }}
          data={data}
          headerLabels={headerLabels}
          keys={keys}
          tableType={"flat-table"}
          downloadReportTitle={`FlatTable-${new Date().toLocaleString(
            "en-US",
          )}`}
        />
      </div>
    )
  }
}
