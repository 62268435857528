import { Typography } from "@material-ui/core"
import { QRCodeSVG } from "qrcode.react"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useAuth } from "../../../../../../../../contexts/AuthContext"
import { useDeepCompareEffectNoCheck } from "../../../../../../../../utils/use-deep-compare"
import VStack from "../../../../../../../ReusableComponents/VStack"
import { getTestDeviceAdditionQRURL } from "../../../../../../common/actions"
import Loading from "../../../../../../../ReusableComponents/Loading"
import { APP_PLATFORMS } from "../../../../../../../../constants"
import { useTrackedState } from "../../../../../../../IntegrationExperience/store"

export default function TestDeviceAdditionWithQR({
  children = <></>,
  selectedPlatform = APP_PLATFORMS.android,
  styles = {},
}) {
  const {
    app_data: { basic_info: { platform = APP_PLATFORMS.android } = {} } = {},
  } = useTrackedState()

  const auth = useAuth()

  const { appId } = useParams()

  const [loading, setLoading] = useState(false)
  const [qrCodeURL, setQrCodeURL] = useState("")

  const newVolatileID = useMemo(
    () => Math.random().toString(36).substring(2, 15),
    [],
  )

  useDeepCompareEffectNoCheck(() => {
    ;(async () => {
      setLoading(true)
      const response = await getTestDeviceAdditionQRURL(auth, appId, {
        platform: platform === APP_PLATFORMS.omni ? selectedPlatform : platform,
      })

      setQrCodeURL(
        Array.isArray(response)
          ? `${response?.[0]}%26volatile_id%3D${newVolatileID}`
          : "",
      )
      setLoading(false)
    })()
  }, [appId, auth, platform])

  return (
    !loading && (
      <VStack
        style={styles}
        justify={"center"}
        align={"center"}
      >
        <Typography
          color="textPrimary"
          style={{ fontWeight: 500, fontSize: 22 }}
        >
          Scan to add device
        </Typography>

        {qrCodeURL === "" ? (
          <Loading />
        ) : (
          <QRCodeSVG
            value={qrCodeURL}
            height="225px"
            width="225px"
          />
        )}
        <Typography
          color="textPrimary"
          style={{
            fontWeight: 400,
            textAlign: "center",
            marginTop: 8,
            fontSize: 14,
          }}
        >
          Your device will be added as the test device when you scan the QR code
          using your mobile scanner.
        </Typography>
        {children}
      </VStack>
    )
  )
}
