import { createTrackedSelector } from "react-tracked"
import { defineModule } from "zoov"
import {
  ACTIVE_TAB_ENUM,
  DEFAULT_METRICS_BOX_TITLE,
  REPORTS_DIMENSION,
} from "./constants"

const CustomReportsModule = defineModule({
  openDrawer: false,
  metrics: [],
  dimensions: [],
  instantDimensions: [],
  dimension: REPORTS_DIMENSION.dimension,
  filters: [],
  instantFilters: [],
  reports: [],
  attributes: [],
  dimensionWithType: [],
  selectedDimensions: [],
  activeTab: ACTIVE_TAB_ENUM.FLAT_TABLE,
  selectedReport: "",
  boxTitle: DEFAULT_METRICS_BOX_TITLE,
  explorerData: {
    aggregations: {},
    data: [],
  },
  flatTableData: {
    aggregations: {},
    data: [],
  },
  fetchingInProgress: false,
  fetchReports: true,
  refreshData: false,
})
  .computed({
    isExplorer: (state) => state.activeTab === ACTIVE_TAB_ENUM.EXPLORER,
  })
  .actions({
    setOpenDrawer: (draft, openDrawer) => {
      draft.openDrawer = openDrawer
    },
    setMetrics: (draft, metrics) => {
      draft.metrics = metrics
    },
    setDimensions: (draft, dimensions) => {
      draft.dimensions = dimensions
    },
    setInstantDimensions: (draft, instantDimensions) => {
      draft.instantDimensions = instantDimensions
    },
    setDimension: (draft, dimension) => {
      draft.dimension = dimension
    },
    setFilters: (draft, filters) => {
      draft.filters = filters
    },
    setInstantFilters: (draft, instantFilters) => {
      draft.instantFilters = instantFilters
    },
    setReports: (draft, reports) => {
      draft.reports = reports
    },
    setAttributes: (draft, attributes) => {
      draft.attributes = attributes
    },
    setDimensionWithType: (draft, dimensionWithType) => {
      draft.dimensionWithType = dimensionWithType
    },
    setSelectedDimensions: (draft, selectedDimensions) => {
      draft.selectedDimensions = selectedDimensions
    },
    setActiveTab: (draft, activeTab) => {
      draft.activeTab = activeTab
    },
    setSelectedReport: (draft, selectedReport) => {
      draft.selectedReport = selectedReport
    },
    setBoxTitle: (draft, boxTitle) => {
      draft.boxTitle = boxTitle
    },
    setExplorerData: (draft, explorerData) => {
      draft.explorerData = explorerData
    },
    setFlatTableData: (draft, flatTableData) => {
      draft.flatTableData = flatTableData
    },
    setFetchingInProgress: (draft, fetchingInProgress) => {
      draft.fetchingInProgress = fetchingInProgress
    },
    setFetchReports: (draft, fetchReports) => {
      draft.fetchReports = fetchReports
    },
    setRefreshData: (draft, refreshData) => {
      draft.refreshData = refreshData
    },
  })
  .methods(({ getActions }) => ({
    resetState: () => {
      getActions().setMetrics([])
      getActions().setDimensions([])
      getActions().setSelectedDimensions([])
      getActions().setFilters([])
      getActions().setBoxTitle(DEFAULT_METRICS_BOX_TITLE)
      getActions().setExplorerData({ aggregations: {}, data: [] })
      getActions().setFlatTableData({ aggregations: {}, data: [] })
      getActions().setSelectedReport("")
      getActions().setDimension(REPORTS_DIMENSION.dimension)
    },
  }))
  .build()

export const { useActions, useComputed } = CustomReportsModule

const useStateValues = createTrackedSelector(CustomReportsModule.useState)

export const useSelector = () => {
  const state = useStateValues()
  const { isExplorer } = useComputed()
  return { ...state, isExplorer }
}
