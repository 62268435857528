import { FormControlLabel, Typography } from "@material-ui/core"
import { IconCheck } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import Select from "react-select/async"
import { isExperienceDashboard } from "../../../../../../../../../../../config"
import { LOGICAL_OPERATORS } from "../../../../../../../../../../../constants"
import { useAuth } from "../../../../../../../../../../../contexts/AuthContext"
import { useDeepCompareMemoNoCheck } from "../../../../../../../../../../../utils/use-deep-compare"
import { apxTheme } from "../../../../../../../../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../../../../../../../../ReusableComponents/HStack"
import VStack from "../../../../../../../../../../ReusableComponents/VStack"
import { useDateFilters } from "../../../../../../../../utils"
import {
  CLIENT_EVENTS_FOR_APP,
  SET_DIDNOT_DO_EVENTS,
  SET_EXCLUDE_EVENTS_TOGGLE,
} from "../../../../../../constants"
import { useDispatch } from "../../../../../../store"
import { CustomCheckbox } from "../../../StopConditions"

export function Option({ innerProps, isDisabled, ...props }) {
  return (
    <div
      {...innerProps}
      {...props}
      style={{
        ...innerProps.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: isDisabled ? 0.5 : 1,
        backgroundColor: props.isFocused
          ? "#F8F8F8"
          : props.isSelected
            ? apxTheme.palette.grey[100]
            : "transparent",
        color: props.isFocused
          ? apxTheme.palette.primary.main
          : apxTheme.palette.text.primary,
        cursor: isDisabled ? "not-allowed" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 12,
        padding: 12,
        zIndex: 50,
        ...(props.data?.isButton && {
          backgroundColor: props.isFocused
            ? apxTheme.palette.grey[800]
            : apxTheme.palette.grey[900],
          transition: "background-color 0.2s ease-in-out",
          color: apxTheme.palette.common.white,
          fontSize: 14,
          flex: 1,
          fontWeight: 900,
          position: "sticky",
          bottom: 0,
          insetInline: 0,
          padding: 14,
          borderRadius: 8,
        }),
      }}
    >
      <div style={{ flex: 1 }}>{props.data?.label}</div>

      {props.isSelected && !props.data?.isButton ? (
        <IconCheck
          color={apxTheme.palette.success.main}
          size={19}
        />
      ) : (
        <IconCheck
          color={"transparent"}
          size={19}
        />
      )}
    </div>
  )
}

export function IndicatorSeparator() {
  return (
    <div
      style={{
        width: 0,
        height: 0,
        marginInline: -6,
      }}
    />
  )
}

export const customStyles = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: "75%",
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    borderRadius: 8,
    border: "none",
    boxShadow: state.isFocused ? `0 0 0 1.5px #1387E8` : `0 0 0 1px #CCD4DA`,
    backgroundColor: apxTheme.palette.common.white,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "75%",
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

export const newCustomStyles = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: 400,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    borderRadius: 8,
    border: "none",
    boxShadow: state.isFocused ? `0 0 0 1.5px #1387E8` : `0 0 0 1px #CCD4DA`,
    backgroundColor: apxTheme.palette.common.white,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 99999,
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

export const formatGroupLabel = (data) => (
  <HStack
    justify="space-between"
    style={{
      marginBlock: 6,
    }}
  >
    {/* Label */}
    <span
      style={{
        fontSize: 10,
        fontWeight: 800,
        paddingBlock: 2,
        color: apxTheme.palette.text.primary,
      }}
    >
      {data.label}
    </span>

    {/* Length */}
    <span
      style={{
        backgroundColor: apxTheme.palette.action.selected,
        borderRadius: 12,
        color: apxTheme.palette.text.primary,
        fontSize: 10,
        fontWeight: 600,
        paddingInline: 6,
        paddingBlock: 2,
        textAlign: "center",
      }}
    >
      {data.options.length}
    </span>
  </HStack>
)

const transformToOptions = (appEvents, client_events, appId) => {
  // App Events
  let events = [
    {
      label: "App Events",
      options: appEvents.map((name) => ({
        label: name,
        value: name,
        eventType: "App Events",
      })),
    },
  ]

  // Client Events
  let clientEvents = [...client_events]
  if (Object.keys(CLIENT_EVENTS_FOR_APP).includes(appId))
    clientEvents = clientEvents.concat(
      Object.keys(CLIENT_EVENTS_FOR_APP[appId]),
    )

  events.push({
    label: "Client Events",
    options: clientEvents.map((name) => ({
      label: name,
      value: name,
      eventType: "Client Events",
    })),
  })

  return events
}

export default function DidNotDoEventsComboBox({ platform, value }) {
  const queryClient = useQueryClient()
  const { appId } = useParams()
  const auth = useAuth()
  const [dateFilters] = useDateFilters(true)
  const dispatch = useDispatch()

  const appEvents =
    queryClient.getQueryData(["app-events", auth, appId, dateFilters]) ?? []
  const clientEvents =
    queryClient.getQueryData(["client-events", auth, appId, platform]) ?? []

  const options = useDeepCompareMemoNoCheck(
    () => transformToOptions(appEvents, clientEvents, appId),
    [appEvents, appId, clientEvents],
  )

  return (
    <VStack spacing={4}>
      <Typography
        component={"label"}
        htmlFor="did-not-do-events"
        style={{
          fontSize: 13,
          fontWeight: 500,
        }}
      >
        Users who didn't perform the below events in their lifetime
      </Typography>

      <Select
        inputId="did-not-do-events"
        isDisabled={isExperienceDashboard()}
        cacheOptions
        defaultOptions
        loadOptions={(inputValue) => {
          return new Promise((resolve) => {
            const filteredOptions = options.map((group) => ({
              ...group,
              options: group.options.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase()),
              ),
            }))

            resolve(filteredOptions)
          })
        }}
        placeholder="Search or Select an Event"
        noOptionsMessage={() => (
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 13,
            }}
          >
            No Events Found
          </Typography>
        )}
        isSearchable={true}
        isClearable={true}
        isMulti={true}
        closeMenuOnSelect={true}
        options={options}
        onChange={(choices) => {
          let updatedEventsList = []

          if (Array.isArray(choices)) {
            choices.forEach((event) => {
              updatedEventsList.push({
                name: event.value,
                op: LOGICAL_OPERATORS.LT,
                value: 1,
              })
            })
          }
          dispatch({
            type: SET_DIDNOT_DO_EVENTS,
            events: updatedEventsList,
          })
        }}
        value={value.map((event) => ({
          label: event,
          value: event,
        }))}
        styles={customStyles}
        formatGroupLabel={formatGroupLabel}
        components={{
          Option,
          IndicatorSeparator,
        }}
      />
    </VStack>
  )
}

export function NewDidNotDoEventsComboBox({ platform, value, overall_cfg }) {
  const queryClient = useQueryClient()
  const { appId } = useParams()
  const auth = useAuth()
  const [dateFilters] = useDateFilters(true)
  const dispatch = useDispatch()

  const appEvents =
    queryClient.getQueryData(["app-events", auth, appId, dateFilters]) ?? []
  const clientEvents =
    queryClient.getQueryData(["client-events", auth, appId, platform]) ?? []

  const options = useDeepCompareMemoNoCheck(
    () => transformToOptions(appEvents, clientEvents, appId),
    [appEvents, appId, clientEvents],
  )

  return (
    <HStack>
      <FormControlLabel
        // disabled={isExperienceDashboard()}
        style={{ width: "auto" }}
        label={
          <Typography
            style={{
              color: "rgba(0, 0, 0, 0.68)",
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Users who did{" "}
            {!overall_cfg?.hasEvents &&
              "an event at least once in their lifetime"}{" "}
          </Typography>
        }
        control={
          <CustomCheckbox
            size="small"
            color="default"
            checked={overall_cfg?.hasEvents}
            onChange={(e) => {
              dispatch({
                type: SET_EXCLUDE_EVENTS_TOGGLE,
                enabled: e.target.checked,
              })
            }}
          />
        }
      />
      {overall_cfg?.hasEvents && (
        <>
          <Select
            inputId="did-not-do-events"
            isDisabled={isExperienceDashboard()}
            cacheOptions
            defaultOptions
            loadOptions={(inputValue) => {
              return new Promise((resolve) => {
                const filteredOptions = options.map((group) => ({
                  ...group,
                  options: group.options.filter((option) =>
                    option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase()),
                  ),
                }))

                resolve(filteredOptions)
              })
            }}
            placeholder="Search or Select an Event"
            noOptionsMessage={() => (
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 13,
                }}
              >
                No Events Found
              </Typography>
            )}
            isSearchable={true}
            isClearable={true}
            isMulti={true}
            closeMenuOnSelect={true}
            options={options}
            onChange={(choices) => {
              let updatedEventsList = []

              if (Array.isArray(choices)) {
                choices.forEach((event) => {
                  updatedEventsList.push({
                    name: event.value,
                    op: LOGICAL_OPERATORS.LT,
                    value: 1,
                  })
                })
              }
              dispatch({
                type: SET_DIDNOT_DO_EVENTS,
                events: updatedEventsList,
              })
            }}
            value={
              value.map((event) => ({
                label: event,
                value: event,
              })) || ""
            }
            styles={newCustomStyles}
            formatGroupLabel={formatGroupLabel}
            components={{
              Option,
              IndicatorSeparator,
            }}
          />
          <Typography
            style={{
              color: "rgba(0, 0, 0, 0.68)",
              fontSize: 12,
              fontWeight: 500,
              marginLeft: 10,
            }}
          >
            at least once in their lifetime
          </Typography>
        </>
      )}
    </HStack>
  )
}
