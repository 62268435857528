import { useReducer } from "react"
import { createContainer } from "react-tracked"
import { useTrackedState as integrationLevelStore } from "../../../../IntegrationExperience/store"
import { APP_PLATFORMS } from "./constants"
import { initial_state, reducer } from "./reducer"

const useValue = () => {
  const {
    app_data: {
      basic_info: { platform = APP_PLATFORMS.android },
    },
  } = integrationLevelStore()

  return useReducer(reducer, {
    ...initial_state,
    config: {
      ...initial_state.config,
      meta: {
        ...initial_state.config.meta,
        platform:
          platform !== APP_PLATFORMS.omni ? platform : APP_PLATFORMS.android,
      },
    },
  })
}

export const {
  Provider,
  useTracked,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue)
