import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { createRoot } from "react-dom/client"
import Root from "./Application"
import "./index.css"
import "/node_modules/react-grid-layout/css/styles.css"

const queryClient = new QueryClient()

const container = document.querySelector("#root")
const root = createRoot(container)

root.render(
  <QueryClientProvider client={queryClient}>
    <Root />
  </QueryClientProvider>,
)
