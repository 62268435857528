import {
  Button,
  Divider,
  IconButton,
  Input,
  withStyles,
} from "@material-ui/core"
import { ToggleButton } from "@material-ui/lab"
import {
  IconAlignCenter,
  IconAlignJustified,
  IconAlignLeft,
  IconAlignRight,
  IconArrowBarBoth,
  IconArrowBarDown,
  IconArrowBarLeft,
  IconArrowBarRight,
  IconArrowBarUp,
  IconBold,
  IconBorderCorners,
  IconChevronDown,
  IconChevronUp,
  IconItalic,
  IconPlus,
  IconX,
} from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { isExperienceDashboard } from "../../../../../../../../../../config"
import { APP_PLATFORMS } from "../../../../../../../../../../constants"
import { useAuth } from "../../../../../../../../../../contexts/AuthContext"
import { capitalizeEachWord } from "../../../../../../../../../../utils"
import { apxTheme } from "../../../../../../../../../ApplicationFrame/styling/CustomCSS"
import useUser from "../../../../../../../../../Profile/hooks/use-user"
import CustomColorPicker from "../../../../../../../../../ReusableComponents/CustomColorPicker"
import FontFamilySelector from "../../../../../../../../../ReusableComponents/FontFamilySelector"
import HStack from "../../../../../../../../../ReusableComponents/HStack"
import ThemedTooltip from "../../../../../../../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../../../../../../../ReusableComponents/VStack"
import { IndicatorSeparator } from "../../../../../../../../common/components/campaigncreator/components/context/components/targetaudience/components/selects/DidNotDoEventsComboBox"
import { useTrackedState } from "../../../../../../../../common/components/campaigncreator/store"
import { FEATURES_LIST_ENUM } from "../../../../../../../settings/general/constants"
import DynamicText2Dialog from "../../../../../../../walkthroughs/components/design/components/configuration/components/DynamicText"
import { NEW_MODULE_TYPES } from "../../../../../../constants"
import { useActions } from "../../../../state"
import MaterialPopper from "../MaterialPopper"
import "./toolbars.css"

function isTruthy(value) {
  return value !== undefined && value !== null && value !== ""
}

export const otherDropdownStyles = {
  control: (base, state) => ({
    ...base,
    maxheight: 34,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 120,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 12,
    padding: 12,
    zIndex: 50,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
}

export const actionDropdownStyles = {
  control: (base, state) => ({
    ...base,
    maxheight: 34,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 120,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 12,
    padding: 12,
    zIndex: 50,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
}

export const responseBasedDropdownStyles = {
  control: (base, state) => ({
    ...base,
    maxheight: 34,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 500,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 12,
    padding: 12,
    zIndex: 50,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
}

export const StyledMultiLineInput = withStyles((theme) => ({
  root: {
    padding: 5,
    fontWeight: 500,
    fontSize: 13,
    color: theme.palette.text.primary,
    borderRadius: 12,
    border: "none",
    backgroundColor: theme.palette.action.hover,
  },
  disabled: {
    backgroundColor: "#F2F3F3",
    color: "rgba(0, 40, 69, 0.6)",
  },
  focused: {
    boxShadow: "0 0 0 1.5px rgba(1, 125, 253, 0.88)",
    borderColor: "transparent",
  },
}))(Input)

export const StyledInput = withStyles((theme) => ({
  root: {
    padding: 5,
    fontWeight: 500,
    fontSize: 13,
    color: theme.palette.text.primary,
    borderRadius: 12,
    border: "none",
    maxHeight: 34,
    backgroundColor: theme.palette.action.hover,
  },
  disabled: {
    backgroundColor: "#F2F3F3",
    color: "rgba(0, 40, 69, 0.6)",
    cursor: "not-allowed",
    padding: 5,
  },
  focused: {
    boxShadow: "0 0 0 1.5px rgba(1, 125, 253, 0.88)",
    borderColor: "transparent",
  },
}))(Input)

/**
 * Custom number input component.
 * @param {Object} props The props to pass to the input component.
 * @param {string} [props.tooltipText=""] The text to display in the tooltip.
 * @param {function} props.handleChange The function to call when the input value changes.
 * @param {number} props.value The value of the input.
 * @param {number} [props.min] The minimum value allowed for the input.
 * @param {number} [props.max] The maximum value allowed for the input.
 * @param {Object} [props.style] The style object to apply to the input component.
 * @returns {JSX.Element} The custom number input component.
 */
export function StyledNumberInput({
  tooltipText = "",
  handleChange,
  value,
  min,
  max,
  step = 1,
  style,
  ...props
}) {
  return (
    <ThemedTooltip
      dark
      title={tooltipText}
    >
      <StyledInput
        type="number"
        style={{
          width: 66,
          ...style,
        }}
        inputMode="numeric"
        inputProps={{
          className: "numInput",
        }}
        endAdornment={
          <VStack
            onClick={(e) => {
              e?.stopPropagation()
            }}
          >
            <IconChevronUp
              color="#8497A5"
              width={18}
              height={18}
              style={{
                marginBottom: -10,
                cursor: props.disabled
                  ? "not-allowed"
                  : isTruthy(max)
                    ? +value >= +max
                      ? "not-allowed"
                      : "pointer"
                    : "pointer",
              }}
              onClick={(e) => {
                e?.stopPropagation()
                if (!props.disabled) {
                  if (isTruthy(max)) {
                    if (+value < +max) handleChange(+value + step)
                    e?.stopPropagation()
                  } else handleChange(+value + step)
                }
              }}
            />
            <IconChevronDown
              color="#8497A5"
              width={18}
              height={18}
              style={{
                marginTop: -10,
                cursor:
                  +value <= +min
                    ? "not-allowed"
                    : props.disabled
                      ? "not-allowed"
                      : "pointer",
              }}
              onClick={(e) => {
                e?.stopPropagation()
                if (!props.disabled) {
                  if (isTruthy(min)) {
                    if (+value > +min) handleChange(+value - step)
                  } else handleChange(+value - step)
                }
              }}
            />
          </VStack>
        }
        value={+value}
        onClick={(e) => {
          e?.target?.select?.()
          if (props?.onClick) {
            props.onClick(e)
          }
        }}
        {...props}
        onBlur={(e) => {
          if (e?.target?.value > max) {
            handleChange(max)
          } else if (e?.target?.value < min) {
            handleChange(min)
          }

          if (props?.onBlur) {
            props.onBlur(e)
          }
        }}
      />
    </ThemedTooltip>
  )
}

export const CustomToggleButton = withStyles((theme) => ({
  root: {
    padding: 5,
    minWidth: 34,
    minHeight: 34,
    borderRadius: 12,
    border: "none",
    backgroundColor: theme.palette.action.hover,
    color: "#8497A5",
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
}))(ToggleButton)

export const CustomIconButton = withStyles((theme) => ({
  root: {
    padding: 5,
    minWidth: 34,
    minHeight: 34,
    borderRadius: 12,
    border: "none",
    backgroundColor: theme.palette.action.hover,
    color: "#8497A5",
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
}))(IconButton)

export function getNextAlignmentOption(alignment) {
  if (alignment === "left") return "center"
  if (alignment === "center") return "right"
  if (alignment === "right") return "justify"
  if (alignment === "justify") return "left"
}

export default function TextToolbar({
  setTarget: setTotalTarget,
  enablePadding = true,
  enableMargin = true,
  enablePosition = false,
  enableDisableColor = false,
  dynamicType = "",
  script,
  showDynamicText = false,
  onScriptDialogClose,
  textConfig,
  onTextChange,
  onColorChange,
  onAlignmentChange,
  onFontFamilyChange,
  onFontStyleChange,
  onFontWeightChange,
  onFontSizeChange,
  onPaddingChange,
  onMarginChange,
  onActiveColorChange,
  onInactiveColorChange,
  onDisableColorChange,
  onPositionChange,
  moduleType,
  onClickAway = () => {},
}) {
  const {
    config: {
      meta: { platform = APP_PLATFORMS.android } = {
        meta: { platform: APP_PLATFORMS.android },
      },
    } = {
      meta: { platform: APP_PLATFORMS.android },
    },
  } = useTrackedState()

  const [showDynamicTextDialog, setShowDynamicTextDialog] = useState(false)

  const { setModuleType } = useActions()

  const [target, setTarget] = useState("")
  const paddingElRef = useRef(null)
  const marginElRef = useRef(null)

  // Fetch custom fonts from query client cache
  const queryClient = useQueryClient()
  const auth = useAuth()
  const { appId } = useParams()
  const customFonts =
    queryClient.getQueryData(["custom-fonts", auth, appId]) ?? []

  const { appFeaturesArr } = useUser()
  const showCustomFonts = appFeaturesArr.includes(
    FEATURES_LIST_ENUM.THEMES.children.CUSTOM_FONTS.value,
  )

  return (
    <VStack
      spacing={12}
      style={{
        backgroundColor: "#fff",
        borderRadius: 18,
        border: "none",
        padding: 12,
        boxShadow: "0px 4px 23px 0px rgba(0, 40, 69, 0.10)",
      }}
    >
      {![NEW_MODULE_TYPES.RATING_BUTTON_TEXT].includes(moduleType) && (
        <HStack
          spacing={8}
          align={"center"}
        >
          {/* Text */}
          {![NEW_MODULE_TYPES.RATING_BUTTON_TEXT].includes(moduleType) && (
            <StyledInput
              value={textConfig.text}
              onChange={(e) => {
                if (onTextChange) {
                  onTextChange(e.target.value)
                }
              }}
              // inputProps={{
              //   maxLength: MAX_CHARACTERS_LIMIT,
              // }}
              style={{
                width:
                  platform !== APP_PLATFORMS.web
                    ? "100%"
                    : NEW_MODULE_TYPES.OPTION_TEXT === moduleType
                      ? "70%"
                      : NEW_MODULE_TYPES.BUTTON_TEXT === moduleType
                        ? "65%"
                        : [
                              NEW_MODULE_TYPES.RATING_LABEL_START,
                              NEW_MODULE_TYPES.RATING_LABEL_END,
                            ].includes(moduleType)
                          ? "82%"
                          : NEW_MODULE_TYPES.RATING_Q_AND_S_SUGGESTION ===
                              moduleType
                            ? "100%"
                            : "85%",
              }}
              disabled={textConfig?.is_dyn ? true : false}
            />
          )}

          {/* Dynamic Text */}
          {showDynamicText && (
            <Button
              disabled={isExperienceDashboard()}
              variant="outlined"
              size="small"
              onClick={() => {
                setShowDynamicTextDialog(true)
              }}
              startIcon={
                <IconPlus
                  size={14}
                  stroke={2.5}
                />
              }
              style={{
                borderColor: "transparent",
                backgroundColor: "#f5f5f5",
                height: 34,
                color: "hsl(0,0%,20%)",
              }}
            >
              Dynamic Text
            </Button>
          )}
        </HStack>
      )}

      <>
        <HStack spacing={8}>
          {/* Font Family */}
          <ThemedTooltip
            dark
            title={
              platform === APP_PLATFORMS.android ? (
                <>
                  Custom Fonts are only available from <b>RTM-X Plugin v222</b>{" "}
                  and <b>Surveys Plugin v203</b>
                </>
              ) : (
                ""
              )
            }
          >
            <div>
              <FontFamilySelector
                customFonts={customFonts}
                fontFamily={textConfig?.font?.family}
                onChange={(pair) => {
                  if (onFontFamilyChange)
                    onFontFamilyChange({
                      family: pair.value,
                      platform,
                      ext: pair.ext,
                      source: pair.source,
                      kind: pair.type,
                      url: pair?.url,
                    })
                }}
                platform={platform}
                showCustomFonts={showCustomFonts}
              />
            </div>
          </ThemedTooltip>
          {/* Font Size */}
          <StyledNumberInput
            min={1}
            tooltipText="Font Size"
            value={textConfig?.font?.size ?? 10}
            onChange={(e) => {
              if (onFontSizeChange) onFontSizeChange(+e.target.value)
            }}
            handleChange={(value) => {
              if (onFontSizeChange) onFontSizeChange(+value)
            }}
          />
          {/* Color */}
          {![
            NEW_MODULE_TYPES.OPTION_TEXT,
            NEW_MODULE_TYPES.RATING_BUTTON_TEXT,
            NEW_MODULE_TYPES.RATING_Q_AND_S_SUGGESTION,
          ].includes(moduleType) && (
            <>
              <ThemedTooltip
                title="Color"
                dark
              >
                <div>
                  <CustomColorPicker
                    color={textConfig?.color}
                    showColorText={false}
                    handleColorChange={(selectedColor) => {
                      if (onColorChange) onColorChange(selectedColor.hex)
                    }}
                    styles={{ padding: 8 }}
                    enableGradient={false}
                  />
                </div>
              </ThemedTooltip>
            </>
          )}
          {platform !== APP_PLATFORMS.ios && (
            <>
              {/* Font Weight */}
              <CustomToggleButton
                value={textConfig?.font?.weight === "bold"}
                selected={textConfig?.font?.weight === "bold"}
                onChange={() => {
                  if (onFontWeightChange)
                    onFontWeightChange(
                      textConfig?.font?.weight === "bold" ? "normal" : "bold",
                    )
                }}
              >
                <IconBold />
              </CustomToggleButton>
              {/* Font Style */}
              <CustomToggleButton
                value={textConfig?.font.style === "italic"}
                selected={textConfig?.font.style === "italic"}
                onChange={() => {
                  if (onFontStyleChange)
                    onFontStyleChange(
                      textConfig?.font.style === "italic" ? "normal" : "italic",
                    )
                }}
              >
                <IconItalic />
              </CustomToggleButton>
            </>
          )}
          {/* Alignment */}
          {![
            NEW_MODULE_TYPES.BUTTON_TEXT,
            NEW_MODULE_TYPES.OPTION_TEXT,
            NEW_MODULE_TYPES.RATING_BUTTON_TEXT,
            NEW_MODULE_TYPES.RATING_LABEL_START,
            NEW_MODULE_TYPES.RATING_LABEL_END,
            NEW_MODULE_TYPES.RATING_Q_AND_S_SUGGESTION,
            NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON0_TEXT,
            NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON1_TEXT,
          ].includes(moduleType) && (
            <ThemedTooltip
              title={capitalizeEachWord(textConfig?.alignment)}
              dark
            >
              <CustomIconButton
                onClick={() => {
                  if (onAlignmentChange)
                    onAlignmentChange(
                      getNextAlignmentOption(textConfig?.alignment),
                    )
                }}
              >
                {textConfig?.alignment === "left" ? (
                  <IconAlignLeft />
                ) : textConfig?.alignment === "center" ? (
                  <IconAlignCenter />
                ) : textConfig?.alignment === "right" ? (
                  <IconAlignRight />
                ) : (
                  <IconAlignJustified />
                )}
              </CustomIconButton>
            </ThemedTooltip>
          )}
          {[
            NEW_MODULE_TYPES.OPTION_TEXT,
            NEW_MODULE_TYPES.RATING_BUTTON_TEXT,
            NEW_MODULE_TYPES.RATING_Q_AND_S_SUGGESTION,
          ].includes(moduleType) && (
            <>
              <Divider
                orientation="vertical"
                flexItem
              />

              {/* Active Color */}
              <ThemedTooltip
                title="Active Color"
                dark
                id="active-color"
              >
                <div>
                  <CustomColorPicker
                    color={textConfig?.color_active}
                    showColorText={false}
                    handleColorChange={(selectedColor) => {
                      if (onActiveColorChange)
                        onActiveColorChange(selectedColor.hex)
                    }}
                    styles={{ padding: 8 }}
                    enableGradient={false}
                  />
                </div>
              </ThemedTooltip>
              {/* Inactive Color */}
              <ThemedTooltip
                title="Inactive Color"
                dark
                id="inactive-color"
              >
                <div>
                  <CustomColorPicker
                    color={textConfig?.color_inactive}
                    showColorText={false}
                    handleColorChange={(selectedColor) => {
                      if (onInactiveColorChange)
                        onInactiveColorChange(selectedColor.hex)
                    }}
                    styles={{ padding: 8 }}
                    enableGradient={false}
                  />
                </div>
              </ThemedTooltip>
              {/* Disable Color */}
              {enableDisableColor && (
                <ThemedTooltip
                  title="Disable Color"
                  dark
                  id="disable-color"
                >
                  <div>
                    <CustomColorPicker
                      color={textConfig?.color_disable}
                      showColorText={false}
                      handleColorChange={(selectedColor) => {
                        if (onInactiveColorChange)
                          onDisableColorChange(selectedColor.hex)
                      }}
                      styles={{ padding: 8 }}
                      enableGradient={false}
                    />
                  </div>
                </ThemedTooltip>
              )}
            </>
          )}
          {/* Margin */}
          {enableMargin &&
            ![
              NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON0_TEXT,
              NEW_MODULE_TYPES.SURVEYREQUEST_BUTTON1_TEXT,
            ].includes(moduleType) && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                />
                <HStack spacing={8}>
                  <>
                    <div
                      ref={marginElRef}
                      style={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      {/* Margin X */}
                      <StyledNumberInput
                        min={0}
                        tooltipText={
                          target === marginElRef.current
                            ? "Margin Left"
                            : "Horizontal Margin"
                        }
                        value={textConfig?.margin?.left}
                        handleChange={(value) => {
                          if (onMarginChange) {
                            if (target === marginElRef.current)
                              onMarginChange(["left"], value)
                            else onMarginChange(["left", "right"], value)
                          }
                        }}
                        onChange={(e) => {
                          if (onMarginChange) {
                            if (target === marginElRef.current)
                              onMarginChange(["left"], +e.target.value)
                            else
                              onMarginChange(["left", "right"], +e.target.value)
                          }
                        }}
                        style={{
                          width: 90,
                        }}
                        startAdornment={
                          <VStack>
                            {target !== marginElRef.current ? (
                              <IconArrowBarBoth
                                color="#8497A5"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <IconArrowBarLeft
                                color="#8497A5"
                                width={20}
                                height={20}
                              />
                            )}
                          </VStack>
                        }
                      />

                      {/* Margin Y */}
                      <StyledNumberInput
                        min={0}
                        tooltipText={
                          target === marginElRef.current
                            ? "Margin Top"
                            : "Vertical Margin"
                        }
                        handleChange={(value) => {
                          if (onMarginChange) {
                            if (target === marginElRef.current)
                              onMarginChange(["top"], +value)
                            else onMarginChange(["top", "bottom"], +value)
                          }
                        }}
                        value={textConfig?.margin?.top}
                        onChange={(e) => {
                          if (onMarginChange) {
                            if (target === marginElRef.current)
                              onMarginChange(["top"], +e.target.value)
                            else
                              onMarginChange(["top", "bottom"], +e.target.value)
                          }
                        }}
                        style={{
                          width: 90,
                        }}
                        startAdornment={
                          <VStack>
                            {target !== marginElRef.current ? (
                              <IconArrowBarBoth
                                color="#8497A5"
                                width={20}
                                height={20}
                                style={{
                                  transform: "rotate(90deg)",
                                }}
                              />
                            ) : (
                              <IconArrowBarUp
                                color="#8497A5"
                                width={20}
                                height={20}
                              />
                            )}
                          </VStack>
                        }
                      />
                    </div>

                    <ThemedTooltip
                      title={
                        target === marginElRef.current ? "Collapse" : "Expand"
                      }
                      dark
                    >
                      <IconBorderCorners
                        color={"#8497A5"}
                        width={20}
                        height={20}
                        onClick={() => {
                          setTarget(
                            target === "" || target === paddingElRef.current
                              ? marginElRef.current
                              : "",
                          )
                        }}
                      />
                    </ThemedTooltip>

                    {target === marginElRef.current && (
                      <MaterialPopper
                        placement="bottom-start"
                        target={target}
                        style={{
                          marginLeft: -8,
                        }}
                        onClickAway={onClickAway}
                      >
                        <HStack
                          spacing={8}
                          style={{
                            padding: 8,
                            backgroundColor: "#fff",
                            borderRadius: 18,
                            border: "none",
                            boxShadow: "0px 4px 23px 0px rgba(0, 40, 69, 0.10)",
                            marginTop: 16,
                          }}
                        >
                          {/* Margin Right */}
                          <StyledNumberInput
                            min={0}
                            tooltipText="Margin Right"
                            value={textConfig?.margin?.right}
                            handleChange={(value) => {
                              if (onMarginChange)
                                onMarginChange(["right"], value)
                            }}
                            onChange={(e) => {
                              if (onMarginChange)
                                onMarginChange(["right"], +e.target.value)
                            }}
                            style={{
                              width: 90,
                            }}
                            startAdornment={
                              <VStack>
                                <IconArrowBarRight
                                  color="#8497A5"
                                  width={20}
                                  height={20}
                                />
                              </VStack>
                            }
                          />

                          {/* Margin Bottom */}
                          <StyledNumberInput
                            min={0}
                            tooltipText="Margin Bottom"
                            value={textConfig?.margin?.bottom}
                            handleChange={(value) => {
                              if (onMarginChange)
                                onMarginChange(["bottom"], value)
                            }}
                            onChange={(e) => {
                              if (onMarginChange)
                                onMarginChange(["bottom"], +e.target.value)
                            }}
                            style={{
                              width: 90,
                            }}
                            startAdornment={
                              <VStack>
                                <IconArrowBarDown
                                  color="#8497A5"
                                  width={20}
                                  height={20}
                                />
                              </VStack>
                            }
                          />

                          <ThemedTooltip
                            title="Collapse"
                            dark
                          >
                            <IconX
                              color="#8497A5"
                              width={20}
                              height={20}
                              onClick={() => {
                                setTarget("")
                              }}
                            />
                          </ThemedTooltip>
                        </HStack>
                      </MaterialPopper>
                    )}
                  </>
                </HStack>
              </>
            )}
          {/* Position */}
          {enablePosition && (
            <>
              <Divider
                orientation="vertical"
                flexItem
              />

              <Select
                id="position"
                placeholder="Select Position"
                isSearchable={false}
                closeMenuOnSelect={true}
                isClearable={false}
                options={
                  moduleType === NEW_MODULE_TYPES.RATING_REACTION_TEXT
                    ? [
                        {
                          label: "Above Image",
                          value: "above_image",
                        },
                        {
                          label: "Below Image",
                          value: "below_image",
                        },
                        {
                          label: "Below Buttons",
                          value: "below_buttons",
                        },
                      ]
                    : [
                        {
                          label: "Top",
                          value: "top",
                        },
                        {
                          label: "Bottom",
                          value: "bottom",
                        },
                      ]
                }
                isMulti={false}
                onChange={(pair) => {
                  if (onPositionChange) onPositionChange(pair.value)
                  setTotalTarget(null)
                  setModuleType(NEW_MODULE_TYPES.NOTHING)
                }}
                value={{
                  label: capitalizeEachWord(
                    textConfig?.position.replace(/_/g, " "),
                  ),
                  value: textConfig?.position,
                }}
                styles={otherDropdownStyles}
                components={{
                  IndicatorSeparator,
                }}
              />
            </>
          )}
        </HStack>
      </>

      {showDynamicTextDialog && (
        <DynamicText2Dialog
          openFlag={showDynamicTextDialog !== null}
          onSuccess={(tab, type, cmds, script, vmap) => {
            setShowDynamicTextDialog(false)
            onScriptDialogClose(tab, type, cmds, script, vmap)
          }}
          onFail={() => setShowDynamicTextDialog(false)}
          dynamicType={dynamicType ?? ""}
          tab={script?.t ?? 0}
          originalScript={script?.src ?? ""}
          variableMap={script?.vmap ?? {}}
        />
      )}
    </VStack>
  )
}
