/* eslint-disable prettier/prettier */
import moment from "moment"
import { useCommonDashboardStyles } from "../../../../common/constants"
import { ANSWER_MODES } from "../../../components/surveys/components/design/constants"
import {
  ACTION_CLASSES,
  INAPP_LAYOUT_TYPES,
} from "../../../components/walkthroughs/components/design/constants"
import {
  APP_PLATFORMS,
  DEFAULT_GOAL_EVENT_STATE,
  TARGET_AUDIENCE_ENUM,
} from "./constants"

export const SECTIONS_ENUM = {
  ALL: "All",
  ACTIVE: "Active",
  PAUSED: "Paused",
  COMPLETED: "Completed",
  DRAFTS: "Drafts",
}

export const getPlatformBasedTimelimitKey = (messages2) => {
  const {
    new_message: {
      meta: { platform },
    },
  } = messages2
  if (platform === APP_PLATFORMS.ios) {
    return "time_limits_in_day"
  } else {
    return "at_specific_time"
  }
}

export const getRouteForActionType = (actionType) => {
  switch (actionType) {
    case "walkthroughs":
    case "walkthrough":
      return "walkthroughs"
    case "surveys":
    case "survey":
      return "surveys"
    default:
      return "404"
  }
}

function checkObjectValues(obj) {
  for (const key in obj) {
    if (key === "value") {
      if (
        obj.hasOwnProperty(key) &&
        Array.isArray(obj[key]) &&
        obj[key].length === 0
      ) {
        return false
      }
    } else {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return false
      }
    }
  }
  return true
}

// Function to check every object in the array
function checkArrayValues(array) {
  for (const obj of array) {
    if (!checkObjectValues(obj)) {
      return false
    }
  }
  return true
}

export const checkIfValidToPublishOrTest = (
  stateRef,
  validationRef,
  setValidationMessage,
) => {
  const {
    audience: {
      audience_type,
      cohort_id,
      segment_id,
      attributes: { user = [], session = [], hasUser = false, hasSes = false },
      hasVer,
      ver = {},
    },
    overall_cfg: {
      events = [],
      exclude_cohort,
      hasEvents = false,
      hasExcludeCohort = false,
    },
    meta: { name, config_type, platform, isExperiment },
    goal_event = "",
    primary_goal = DEFAULT_GOAL_EVENT_STATE.primary_goal,
    goal_met_check = false,
    no_context_enabled = false,
    conditions = [],
    enable_conditions = true,
    conditions_v2 = [],
    enable_conditions_v2 = false,
    pre_cs = [],
    terminate_info: {
      conditions: delete_conditions = [],
      attributes: { user: stop_conditions_user_attributes = [] } = {},
      enable_attributes = false,
      enable_goal_events = false,
      goal_events: { events: terminateInfoGoalEvents = [] },
    } = {},
    validity: { start_date, end_date },
    ui = {},
    uis = [],
    surveyType,
  } = stateRef.current

  let currentMessage = ""

  // Date validations
  if (moment(end_date).isSameOrBefore(moment(start_date))) {
    currentMessage = "End Date cannot be less than Start Date"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // Campaign Name validation
  if (name === "") {
    currentMessage = "Campaign must have a name"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // Design Validation
  if (config_type === "survey") {
    if (surveyType === "latest") {
      if (ui?.survey?.q_and_a?.length < 1) {
        currentMessage = "At least one question must be added"

        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    } else {
      if (ui.questions?.length < 1) {
        currentMessage = "At least one question must be added"

        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }
  }

  // Request for Survey Validation
  if (
    config_type === "survey" &&
    surveyType === "legacy" &&
    !ui?.questions?.some(
      (question) =>
        question.answer_mode !== ANSWER_MODES.request_survey &&
        question.answer_mode !== ANSWER_MODES.success_message,
    )
  ) {
    currentMessage = <>Add atleast 1 survey question.</>

    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }
  if (
    config_type === "survey" &&
    surveyType === "legacy" &&
    ui?.questions?.some(
      (question) => question?.answer_mode === ANSWER_MODES.request_survey,
    )
  ) {
    if (
      ui?.dialog_config?.icon_type === "other" &&
      ui?.dialog_config?.icon_name === ""
    ) {
      currentMessage = `Icon ${
        platform === APP_PLATFORMS.web ? "URL" : "Name"
      } must be provided for custom icons`

      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
  }

  // Design Validations for NPS
  if (config_type === "survey" && ui.questions?.length > 0) {
    let [redFlag, redIndex] = [false, null]

    ui.questions.every((eachQuestion, index) => {
      if (
        eachQuestion.answer_mode &&
        eachQuestion.answer_mode === ANSWER_MODES.rating &&
        eachQuestion.advanced_options
      ) {
        ;[redFlag, redIndex] = [true, index]
        return false
      }

      return true
    })
    if (redFlag) {
      if (
        ui.is_response_based_survey &&
        ((!ui.show_success_message && redIndex !== ui.questions.length - 1) ||
          (ui.show_success_message && redIndex !== ui.questions.length - 2))
      ) {
        currentMessage =
          "NPS with Advanced options should be the last question in Response based survey"
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
    }
  }

  // Survey Stop Conditions Goal Event validations
  if (config_type === "survey" && surveyType === "latest") {
    if (
      enable_goal_events &&
      terminateInfoGoalEvents &&
      Array.isArray(terminateInfoGoalEvents) &&
      terminateInfoGoalEvents.length > 0 &&
      terminateInfoGoalEvents[0].details.name === ""
    ) {
      currentMessage =
        "In Trigger Page Stop Conditions, goal event should not be empty"
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
      return false
    }

    let terminateInfoGoalEventAdditionalInfoKeys =
      terminateInfoGoalEvents &&
      Array.isArray(terminateInfoGoalEvents) &&
      terminateInfoGoalEvents.length > 0 &&
      Object.prototype.hasOwnProperty.call(
        terminateInfoGoalEvents[0],
        "details",
      ) &&
      Object.prototype.hasOwnProperty.call(
        terminateInfoGoalEvents[0].details,
        "additional_info",
      ) &&
      Object.keys(terminateInfoGoalEvents[0].details.additional_info).length > 0
        ? Object.keys(terminateInfoGoalEvents[0].details.additional_info)
        : []

    if (
      enable_goal_events &&
      terminateInfoGoalEventAdditionalInfoKeys.length > 0
    ) {
      terminateInfoGoalEventAdditionalInfoKeys.forEach((key) => {
        let currentProperty =
          terminateInfoGoalEvents[0].details.additional_info[key]

        // Empty values validation
        if (
          key === "" ||
          currentProperty.op === "" ||
          (Array.isArray(currentProperty.val ?? []) &&
            (currentProperty.val ?? []).length === 0)
        ) {
          currentMessage =
            "In Trigger Page Stop Conditions goal event, property name, operator value, property value should not be empty"
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
          return false
        }
        // Correct values validation
        else {
          const isOnlySurveyResponseProperty = /^Q\d+$/.test(key)

          const isAdvancedChoiceProperty =
            /^Q\d+$/.test(key.split(".")[0]) &&
            key.includes("apx_nps_advanced_choice")

          const q_and_a =
            ui &&
            Object.prototype.hasOwnProperty.call(ui, "survey") &&
            Object.prototype.hasOwnProperty.call(ui.survey, "q_and_a")
              ? ui.survey.q_and_a
              : []

          if (isOnlySurveyResponseProperty) {
            if (q_and_a.length < parseInt(key.slice(1))) {
              currentMessage = `In Trigger Page Stop Conditions goal event, ${key} property name is not valid as number of questions are less than this question specific property value.`
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
              return false
            } else {
              const currentQuestion = q_and_a[parseInt(key.slice(1) - 1)]
              const hasAnswer =
                currentQuestion &&
                Object.prototype.hasOwnProperty.call(currentQuestion, "answer")
              if (
                hasAnswer &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer,
                  "answer_mode",
                ) &&
                currentQuestion.answer.answer_mode === "rating" &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer,
                  "rating",
                ) &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer.rating,
                  "buttons",
                ) &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer.rating.buttons,
                  "start",
                ) &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer.rating.buttons,
                  "end",
                )
              ) {
                let start = currentQuestion.answer.rating.buttons.start
                let end = currentQuestion.answer.rating.buttons.end
                let currentAllowedChoiceRange = Array.from(
                  { length: end - start + 1 },
                  (_, idx) => start + idx,
                )
                currentProperty.val.forEach((v) => {
                  if (!currentAllowedChoiceRange.includes(parseInt(v))) {
                    currentMessage = `In Trigger Page Stop Conditions goal event, ${key} property value should be between ${start} and ${end}.`
                    validationRef.current = currentMessage
                    setValidationMessage(currentMessage)
                    return false
                  }
                })
              } else if (
                hasAnswer &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer,
                  "answer_mode",
                ) &&
                (currentQuestion.answer.answer_mode === "single_choice" ||
                  currentQuestion.answer.answer_mode === "multi_choice") &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer,
                  "choices",
                ) &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer.choices,
                  "list",
                )
              ) {
                let currentQuestionChoices =
                  currentQuestion.answer.choices.list.map(
                    (choice) => choice.value,
                  )
                currentProperty.val.forEach((v) => {
                  if (!currentQuestionChoices.includes(v)) {
                    currentMessage = `In Trigger Page Stop Conditions goal event, ${key} property has invalid value`
                    validationRef.current = currentMessage
                    setValidationMessage(currentMessage)
                    return false
                  }
                })
              }
            }
          } else if (isAdvancedChoiceProperty) {
            const questionIndex = parseInt(key.split(".")[0].slice(1)) - 1

            if (q_and_a.length < questionIndex) {
              currentMessage = `In Trigger Page Stop Conditions goal event, ${key} property name is not valid as number of questions are less than this question specific property value.`
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
              return false
            } else {
              const currentQuestion = q_and_a[questionIndex]

              const hasAnswer =
                currentQuestion &&
                Object.prototype.hasOwnProperty.call(currentQuestion, "answer")

              if (
                hasAnswer &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer,
                  "rating",
                ) &&
                Object.prototype.hasOwnProperty.call(
                  currentQuestion.answer.rating,
                  "q_and_s",
                )
              ) {
                let currentAllowedSuggestions =
                  currentQuestion.answer.rating.q_and_s
                    .map((q) => {
                      if (
                        Object.prototype.hasOwnProperty.call(q, "suggestion") &&
                        Object.prototype.hasOwnProperty.call(
                          q.suggestion,
                          "text",
                        )
                      ) {
                        return q.suggestion.text
                      } else {
                        return []
                      }
                    })
                    .flat()

                currentProperty.val.forEach((v) => {
                  if (!currentAllowedSuggestions.includes(v)) {
                    currentMessage = `In Trigger Page Stop Conditions goal event, ${key} property has invalid value`
                    validationRef.current = currentMessage
                    setValidationMessage(currentMessage)
                    return false
                  }
                })
              }
            }
          }
        }
      })
    }
  }

  if (config_type === "survey" && ui.questions?.length > 0) {
    ui.questions.map((eachQuestion, index) => {
      if (
        eachQuestion.answer_mode &&
        eachQuestion.answer_mode === ANSWER_MODES.rating &&
        eachQuestion.advanced_options
      ) {
        if (eachQuestion.rules && eachQuestion.rules.length > 0) {
          eachQuestion.rules.map((eachRule) => {
            if (!eachRule.min || !eachRule.max) {
              currentMessage =
                "Minimum and Maximum conditions for Advanced rules should not be empty"
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
              return false
            }
            return true
          })
        } else {
          currentMessage =
            "Rules should not be empty when advanced options are enabled"
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
          return false
        }
      }
      return true
    })
  }

  if (config_type === "walkthrough" || config_type === "onboarding") {
    if (uis.length < 1) {
      currentMessage = "At least one template must be added"
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
    if (config_type === "onboarding" && uis.length < 2) {
      currentMessage = "At least two steps must be added"
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
    if (
      uis?.length > 1 &&
      uis?.some((ui) => ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS) &&
      platform === APP_PLATFORMS.android
    ) {
      if (isExperiment) {
        const variants = stateRef.current?.experiment?.variants
        Object.keys(variants)
          .filter((key) => key !== "CG")
          .map((eachVariant) => {
            if (
              variants[eachVariant].indices &&
              variants[eachVariant].indices.length > 0
            ) {
              const variantSpecificUIS = uis.filter((eachUI) =>
                variants[eachVariant].indices.includes(eachUI.index),
              )
              let count = 0
              variantSpecificUIS.map(() => {
                if (
                  ui?.type === "IN_APP" &&
                  ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS
                ) {
                  count += 1
                }
                if (count > 1) {
                  currentMessage =
                    "Walkthrough can't be created with Video In-Apps"
                  if (validationRef.current !== currentMessage) {
                    validationRef.current = currentMessage
                    setValidationMessage(currentMessage)
                  }
                  return false
                }
                return true
              })
            }
            return true
          })
      } else {
        currentMessage = "Walkthrough can't be created with Video In-Apps"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }

    if (
      uis?.some((ui) => ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS) &&
      uis?.some((ui) => ui?.ui?.display_type !== "pip")
    ) {
      uis.map((eachUI) => {
        // Video URL Validation
        if (eachUI?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS) {
          if (eachUI?.ui?.v_url === "") {
            currentMessage = "A video URL is required for Video InApp"
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          } else {
            if (
              !eachUI?.ui?.v_url?.startsWith("https://") ||
              !(
                eachUI?.ui?.v_url?.endsWith(".mp4") ||
                eachUI?.ui?.v_url?.endsWith(".MP4")
              ) ||
              !(eachUI?.ui?.valid_video_url ?? true)
            ) {
              currentMessage =
                "Only valid mp4 videos with secure https streaming are supported for Video InApp"
              if (validationRef.current !== currentMessage) {
                validationRef.current = currentMessage
                setValidationMessage(currentMessage)
              }
              return false
            }
          }
        }

        // Binding Validation
        if (eachUI?.ui?.binding && eachUI?.ui?.activity === "") {
          currentMessage =
            "When binding is enabled in Video InApp, an activity or a screen must be selected"
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage
            setValidationMessage(currentMessage)
          }
          return false
        }

        // Download-When Conditions Validation
        if (eachUI?.ui?.download && pre_cs?.length === 0) {
          currentMessage =
            "When the video loading type is DOWNLOAD, make sure to add at least one download-when condition"
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage
            setValidationMessage(currentMessage)
          }
          return false
        } else {
          let condition = pre_cs.find((condition) => {
            if (condition.type === "did") {
              return condition.details.name === ""
            }
            if (condition.type === "didn't") {
              return (
                condition.details.additional_info.nkpi.length < 1 ||
                condition.trigger.details.name === ""
              )
            }
            return null
          })
          if (condition) {
            if (condition.type === "did") {
              currentMessage = "Event name must be selected in Download Events"
            } else if (condition.type === "didn't") {
              if (condition.details.additional_info.nkpi.length < 1) {
                currentMessage =
                  "At least one event must be selected in Who didn't download event"
              } else {
                currentMessage =
                  "After Event name must be selected for didn't event condition"
              }
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }

          condition = pre_cs.find((condition) => {
            if (condition.type === "did") {
              return (
                Object.keys(condition.details.additional_info).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.details.additional_info[attributeName].val ===
                      "" ||
                    condition.details.additional_info[attributeName].op === "",
                ) !== -1
              )
            }
            if (condition.type === "didn't") {
              return (
                Object.keys(
                  condition.trigger.details.additional_info,
                ).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.trigger.details.additional_info[attributeName]
                      .val === "",
                ) !== -1
              )
            }
            return null
          })
          if (condition) {
            if (condition.type === "did") {
              currentMessage =
                "Property name, operator and value must be selected for event " +
                condition.details.name
            } else if (condition.type === "didn't") {
              currentMessage =
                "Property name and value must be selected for after event " +
                condition.trigger.details.name
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }
        }

        // Delete-When Conditions Validation
        if (eachUI?.ui?.download && delete_conditions?.length === 0) {
          currentMessage =
            "When the video loading type is DOWNLOAD, make sure to add at least one delete-when condition"
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage
            setValidationMessage(currentMessage)
          }
          return false
        } else {
          let condition = delete_conditions.find((condition) => {
            if (condition.type === "did") {
              return condition.details.name === ""
            }
            if (condition.type === "didn't") {
              return (
                condition.details.additional_info.nkpi.length < 1 ||
                condition.trigger.details.name === ""
              )
            }
            return null
          })
          if (condition) {
            if (goal_met_check && !goal_event) {
              currentMessage = (
                <>
                  Goal event must be selected if <b>Goal Met</b> is one of the{" "}
                  <b>delete conditions</b>
                </>
              )
            } else if (condition.type === "did") {
              currentMessage = "Event name must be selected in Delete Events"
            } else if (condition.type === "didn't") {
              if (condition.details.additional_info.nkpi.length < 1) {
                currentMessage =
                  "At least one event must be selected in Who didn't delete event"
              } else {
                currentMessage =
                  "After Event name must be selected for didn't event condition"
              }
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }

          condition = delete_conditions.find((condition) => {
            if (condition.type === "did") {
              return (
                Object.keys(condition.details.additional_info).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.details.additional_info[attributeName].val ===
                      "" ||
                    condition.details.additional_info[attributeName].op === "",
                ) !== -1
              )
            }
            if (condition.type === "didn't") {
              return (
                Object.keys(
                  condition.trigger.details.additional_info,
                ).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.trigger.details.additional_info[attributeName]
                      .val === "",
                ) !== -1
              )
            }
            return null
          })
          if (condition) {
            if (condition.type === "did") {
              currentMessage =
                "Property name, operator and value must be selected for event " +
                condition.details.name
            } else if (condition.type === "didn't") {
              currentMessage =
                "Property name and value must be selected for after event " +
                condition.trigger.details.name
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }
        }

        return true
      })
    }
  }

  //Badges Validation
  if (
    config_type === "walkthrough" &&
    uis?.length === 1 &&
    uis?.[0]?.ui?.display_type === "badge" &&
    uis?.[0]?.ui?.action_class === ACTION_CLASSES.NUDGE
  ) {
    // Background image URL validation for badges
    if (
      uis?.[0]?.ui?.badge?.background.enable_image &&
      uis?.[0]?.ui?.badge?.background.image.path === ""
    ) {
      currentMessage =
        "Background image URL of the badge background must not be empty"
    }

    //Badges Stop Conditions User Attributes Validation
    if (
      enable_attributes &&
      stop_conditions_user_attributes?.[0]?.name !== "" &&
      stop_conditions_user_attributes?.[0]?.value.length < 1
    ) {
      currentMessage =
        "User Property name and value in badge stop conditions must not be empty"
    }

    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
  }

  // Target Audience Validation
  if (hasEvents && !events?.length) {
    currentMessage = "Event must be selected in Target audience page"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (hasExcludeCohort && !exclude_cohort?.cohort_id) {
    currentMessage = "Cohort must be selected in Target audience page"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }
  if (audience_type === TARGET_AUDIENCE_ENUM.SEGMENT.key && segment_id === "") {
    currentMessage = "Segment must be selected"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (audience_type === TARGET_AUDIENCE_ENUM.COHORT.key && cohort_id === "") {
    currentMessage = "Cohort must be selected"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    audience_type === TARGET_AUDIENCE_ENUM.DYNAMIC_COHORT.key &&
    cohort_id === ""
  ) {
    currentMessage = "Dynamic Cohort must be selected"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // Goals Validation
  if (config_type !== "survey") {
    // if (primary_goal.type === "event" && primary_goal.name === "") {
    //   currentMessage = (
    //     <>
    //       Primary Goal event must be selected in the <b>Target Section</b>
    //     </>
    //   )
    //   if (validationRef.current !== currentMessage) {
    //     validationRef.current = currentMessage
    //     setValidationMessage(currentMessage)
    //   }
    //   return false
    // }

    if (
      primary_goal.type === "event" &&
      primary_goal.attributes.length > 0 &&
      !checkArrayValues(primary_goal.attributes)
    ) {
      currentMessage = (
        <>
          Primary goal event and properties aren't selected properly in the{" "}
          <b>Target Section</b>
        </>
      )
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
  }

  // A/B Distribution Validation
  if (config_type === "walkthrough" && isExperiment) {
    const variants = stateRef.current?.experiment?.variants
    if (variants) {
      const distributions = Object.keys(variants).map((eachVariant) => {
        return variants[eachVariant].distribution
      })
      const sum = distributions.reduce((a, b) => a + b, 0)
      if (sum !== 100) {
        currentMessage =
          "In Target Page, Variant Distributions are UnEven, make sure they amount to 100%"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }
  }

  if (config_type === "walkthrough" && isExperiment) {
    const variants = stateRef.current?.experiment?.variants
    if (variants) {
      Object.keys(variants)
        .filter((key) => key !== "CG")
        .map((eachVariant) => {
          if (variants[eachVariant].indices.length <= 0) {
            currentMessage = "One or more variants are Empty, please add a step"
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }

          return true
        })
    }
  }

  if (hasUser && (!Array.isArray(user) || user.length < 1)) {
    currentMessage = "No Properties selected in By User Properties section"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    hasUser &&
    user.findIndex((attr) => attr.name === "" || attr.value?.length < 1) !== -1
  ) {
    currentMessage =
      "By User Properties section should not contain empty property name or value"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (hasSes && (!Array.isArray(session) || session.length < 1)) {
    currentMessage = "No Properties selected in By Session Properties section"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    hasSes &&
    session.findIndex((attr) => attr.name === "" || attr.value?.length < 1) !==
      -1
  ) {
    currentMessage =
      "By Session Properties section should not contain empty property name or value"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    hasVer &&
    ((platform === APP_PLATFORMS.android && !Number(ver.build_number)) ||
      ((platform === APP_PLATFORMS.ios || platform === APP_PLATFORMS.web) &&
        ver.val.trim &&
        ver.val.trim().length < 1))
  ) {
    currentMessage = (
      <>
        Expected valid version value in{" "}
        <b>
          {platform === APP_PLATFORMS.android
            ? "By Build Number"
            : "By App Version"}{" "}
        </b>
        section
      </>
    )
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // legacy conditions validation
  if (enable_conditions) {
    if (conditions.length === 0) {
      if (!(platform === APP_PLATFORMS.web && no_context_enabled)) {
        currentMessage =
          "In Target page, Trigger must contain at least one condition"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    } else {
      let condition = conditions.find((condition) => {
        if (condition.type === "did") {
          return condition.details.name === ""
        }
        if (condition.type === "didn't") {
          return (
            condition.details.additional_info.nkpi.length < 1 ||
            condition.trigger.details.name === ""
          )
        }
        return null
      })
      if (condition) {
        if (condition.type === "did") {
          currentMessage =
            "Event name must be selected in Trigger Events section of Target Page"
        } else if (condition.type === "didn't") {
          if (condition.details.additional_info.nkpi.length < 1) {
            currentMessage =
              "At least one event must be selected in Who didn't trigger event section of Target Page"
          } else {
            currentMessage =
              "After Event name must be selected for didn't event condition in Target Page"
          }
        }
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }

      condition = conditions.find((condition) => {
        if (condition.type === "did") {
          return (
            Object.keys(condition.details.additional_info).findIndex(
              (attributeName) =>
                attributeName === "" ||
                condition.details.additional_info[attributeName].val === "" ||
                condition.details.additional_info[attributeName].op === "",
            ) !== -1
          )
        }
        if (condition.type === "didn't") {
          return (
            Object.keys(condition.trigger.details.additional_info).findIndex(
              (attributeName) =>
                attributeName === "" ||
                condition.trigger.details.additional_info[attributeName].val ===
                  "",
            ) !== -1
          )
        }
        return null
      })
      if (condition) {
        if (condition.type === "did") {
          currentMessage =
            "Property name, operator and value must be selected for event " +
            condition.details.name
        } else if (condition.type === "didn't") {
          currentMessage =
            "Property name and value must be selected for after event " +
            condition.trigger.details.name
        }
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }
  }

  // latest conditions validation
  if (enable_conditions_v2) {
    if (conditions_v2.length === 0) {
      if (!(platform === APP_PLATFORMS.web && no_context_enabled)) {
        currentMessage =
          "In Target page, Trigger must contain at least one condition in Advanced Conditons"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    } else {
      let isConditionsEligible = true
      conditions_v2.forEach((outerCondition) => {
        if (outerCondition.condition_type === "single") {
          if (outerCondition.event_type === "") {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (
            outerCondition.event_type === "screen_event" &&
            !outerCondition.screen
          ) {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (
            outerCondition.event_type === "activity_event" &&
            !outerCondition.activity
          ) {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (
            outerCondition.event_type === "app_event" &&
            !outerCondition.details.name
          ) {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (outerCondition.details.name) {
            Object.keys(outerCondition.details.additional_info).forEach((i) => {
              if (
                (!outerCondition.details.additional_info[i].val &&
                  (outerCondition.details.additional_info[i]?.vals?.length ??
                    0) === 0) ||
                !outerCondition.details.additional_info[i].t ||
                !outerCondition.details.additional_info[i].op
              ) {
                currentMessage = `In Target page, Trigger section, property ${i} have incomplete value`
                isConditionsEligible = false
              }
            })
          }
        } else {
          outerCondition.conditions_v2.forEach((innerCondition, index) => {
            if (innerCondition.event_type === "") {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (
              innerCondition.event_type === "screen_event" &&
              !innerCondition.screen
            ) {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (
              innerCondition.event_type === "activity_event" &&
              !innerCondition.activity
            ) {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (
              innerCondition.event_type === "app_event" &&
              innerCondition.details.name === ""
            ) {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (innerCondition.details.name) {
              Object.keys(innerCondition.details.additional_info).forEach(
                (i) => {
                  if (
                    (!innerCondition.details.additional_info[i].val &&
                      (innerCondition.details.additional_info[i]?.vals
                        ?.length ?? 0) === 0) ||
                    !innerCondition.details.additional_info[i].t ||
                    !innerCondition.details.additional_info[i].op
                  ) {
                    currentMessage = `In Target page, Trigger section, property ${i} have incomplete value`
                    isConditionsEligible = false
                  }
                },
              )
            }
          })
        }
      })
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      if (!isConditionsEligible) return false
    }
  }

  if (!enable_conditions && !enable_conditions_v2) {
    if (!(platform === APP_PLATFORMS.web && no_context_enabled)) {
      currentMessage =
        "In Target page, Trigger must contain at least one condition"
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
  }
  if (validationRef.current !== currentMessage) {
    setValidationMessage("")
    validationRef.current = ""
  }
  return true
}

export const checkIfValidToPublish = (
  stateRef,
  validationRef,
  setValidationMessage,
) => {
  const {
    audience: {
      audience_type,
      cohort_id,
      segment_id,
      attributes: { user = [], session = [], hasUser = false, hasSes = false },
      hasVer,
      ver = {},
    },
    overall_cfg: {
      events = [],
      exclude_cohort,
      hasEvents = false,
      hasExcludeCohort = false,
    },
    meta: { name, config_type, platform, isExperiment },
    goal_event = "",
    primary_goal = DEFAULT_GOAL_EVENT_STATE.primary_goal,
    goal_met_check = false,
    no_context_enabled = false,
    conditions = [],
    enable_conditions = true,
    conditions_v2 = [],
    enable_conditions_v2 = false,
    pre_cs = [],
    terminate_info: {
      conditions: delete_conditions = [],
      attributes: { user: stop_conditions_user_attributes = [] } = {},
      enable_attributes = false,
    } = {},
    validity: { start_date, end_date },
    ui = {},
    uis = [],
    surveyType,
  } = stateRef.current

  let currentMessage = ""

  // Date validations
  if (moment(end_date).isSameOrBefore(moment(start_date))) {
    currentMessage = "End Date cannot be less than Start Date"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    moment(end_date).isBefore(moment.now()) ||
    moment(start_date).isBefore(moment().startOf("day"))
  ) {
    currentMessage = "End Date or Start date cannot be past date time"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // Campaign Name validation
  if (name === "") {
    currentMessage = "Campaign must have a name"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // Design Validation
  if (config_type === "survey") {
    if (surveyType === "latest") {
      if (ui?.survey?.q_and_a?.length < 1) {
        currentMessage = "At least one question must be added"

        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    } else {
      if (ui.questions?.length < 1) {
        currentMessage = "At least one question must be added"

        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }
  }

  // Request for Survey Validation
  if (
    config_type === "survey" &&
    surveyType === "legacy" &&
    !ui?.questions?.some(
      (question) =>
        question.answer_mode !== ANSWER_MODES.request_survey &&
        question.answer_mode !== ANSWER_MODES.success_message,
    )
  ) {
    currentMessage = <>Add atleast 1 survey question.</>

    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }
  if (
    config_type === "survey" &&
    surveyType === "legacy" &&
    ui?.questions?.some(
      (question) => question?.answer_mode === ANSWER_MODES.request_survey,
    )
  ) {
    if (
      ui?.dialog_config?.icon_type === "other" &&
      ui?.dialog_config?.icon_name === ""
    ) {
      currentMessage = `Icon ${
        platform === APP_PLATFORMS.web ? "URL" : "Name"
      } must be provided for custom icons`

      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
  }

  // Design Validations for NPS
  if (config_type === "survey" && ui.questions?.length > 0) {
    let [redFlag, redIndex] = [false, null]

    ui.questions.every((eachQuestion, index) => {
      if (
        eachQuestion.answer_mode &&
        eachQuestion.answer_mode === ANSWER_MODES.rating &&
        eachQuestion.advanced_options
      ) {
        ;[redFlag, redIndex] = [true, index]
        return false
      }

      return true
    })
    if (redFlag) {
      if (
        ui.is_response_based_survey &&
        ((!ui.show_success_message && redIndex !== ui.questions.length - 1) ||
          (ui.show_success_message && redIndex !== ui.questions.length - 2))
      ) {
        currentMessage =
          "NPS with Advanced options should be the last question in Response based survey"
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
    }
  }

  if (config_type === "survey" && ui.questions?.length > 0) {
    ui.questions.map((eachQuestion, index) => {
      if (
        eachQuestion.answer_mode &&
        eachQuestion.answer_mode === ANSWER_MODES.rating &&
        eachQuestion.advanced_options
      ) {
        if (eachQuestion.rules && eachQuestion.rules.length > 0) {
          eachQuestion.rules.map((eachRule) => {
            if (!eachRule.min || !eachRule.max) {
              currentMessage =
                "Minimum and Maximum conditions for Advanced rules should not be empty"
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
              return false
            }
            return true
          })
        } else {
          currentMessage =
            "Rules should not be empty when advanced options are enabled"
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
          return false
        }
      }
      return true
    })
  }

  if (config_type === "walkthrough" || config_type === "onboarding") {
    if (uis.length < 1) {
      currentMessage = "At least one template must be added"
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
    if (config_type === "onboarding" && uis.length < 2) {
      currentMessage = "At least two steps must be added"
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
    if (
      uis?.length > 1 &&
      uis?.some((ui) => ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS) &&
      platform === APP_PLATFORMS.android
    ) {
      if (isExperiment) {
        const variants = stateRef.current?.experiment?.variants
        Object.keys(variants)
          .filter((key) => key !== "CG")
          .map((eachVariant) => {
            if (
              variants[eachVariant].indices &&
              variants[eachVariant].indices.length > 0
            ) {
              const variantSpecificUIS = uis.filter((eachUI) =>
                variants[eachVariant].indices.includes(eachUI.index),
              )
              let count = 0
              variantSpecificUIS.map(() => {
                if (
                  ui?.type === "IN_APP" &&
                  ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS
                ) {
                  count += 1
                }
                if (count > 1) {
                  currentMessage =
                    "Walkthrough can't be created with Video In-Apps"
                  if (validationRef.current !== currentMessage) {
                    validationRef.current = currentMessage
                    setValidationMessage(currentMessage)
                  }
                  return false
                }
                return true
              })
            }
            return true
          })
      } else {
        currentMessage = "Walkthrough can't be created with Video In-Apps"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }

    if (
      uis?.some((ui) => ui?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS) &&
      uis?.some((ui) => ui?.ui?.display_type !== "pip")
    ) {
      uis.map((eachUI) => {
        // Video URL Validation
        if (eachUI?.ui?.layout_type === INAPP_LAYOUT_TYPES.VIDEOS) {
          if (eachUI?.ui?.v_url === "") {
            currentMessage = "A video URL is required for Video InApp"
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          } else {
            if (
              !eachUI?.ui?.v_url?.startsWith("https://") ||
              !(
                eachUI?.ui?.v_url?.endsWith(".mp4") ||
                eachUI?.ui?.v_url?.endsWith(".MP4")
              ) ||
              !(eachUI?.ui?.valid_video_url ?? true)
            ) {
              currentMessage =
                "Only valid mp4 videos with secure https streaming are supported for Video InApp"
              if (validationRef.current !== currentMessage) {
                validationRef.current = currentMessage
                setValidationMessage(currentMessage)
              }
              return false
            }
          }
        }

        // Binding Validation
        if (eachUI?.ui?.binding && eachUI?.ui?.activity === "") {
          currentMessage =
            "When binding is enabled in Video InApp, an activity or a screen must be selected"
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage
            setValidationMessage(currentMessage)
          }
          return false
        }

        // Download-When Conditions Validation
        if (eachUI?.ui?.download && pre_cs?.length === 0) {
          currentMessage =
            "When the video loading type is DOWNLOAD, make sure to add at least one download-when condition"
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage
            setValidationMessage(currentMessage)
          }
          return false
        } else {
          let condition = pre_cs.find((condition) => {
            if (condition.type === "did") {
              return condition.details.name === ""
            }
            if (condition.type === "didn't") {
              return (
                condition.details.additional_info.nkpi.length < 1 ||
                condition.trigger.details.name === ""
              )
            }
            return null
          })
          if (condition) {
            if (condition.type === "did") {
              currentMessage = "Event name must be selected in Download Events"
            } else if (condition.type === "didn't") {
              if (condition.details.additional_info.nkpi.length < 1) {
                currentMessage =
                  "At least one event must be selected in Who didn't download event"
              } else {
                currentMessage =
                  "After Event name must be selected for didn't event condition"
              }
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }

          condition = pre_cs.find((condition) => {
            if (condition.type === "did") {
              return (
                Object.keys(condition.details.additional_info).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.details.additional_info[attributeName].val ===
                      "" ||
                    condition.details.additional_info[attributeName].op === "",
                ) !== -1
              )
            }
            if (condition.type === "didn't") {
              return (
                Object.keys(
                  condition.trigger.details.additional_info,
                ).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.trigger.details.additional_info[attributeName]
                      .val === "",
                ) !== -1
              )
            }
            return null
          })
          if (condition) {
            if (condition.type === "did") {
              currentMessage =
                "Property name, operator and value must be selected for event " +
                condition.details.name
            } else if (condition.type === "didn't") {
              currentMessage =
                "Property name and value must be selected for after event " +
                condition.trigger.details.name
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }
        }

        // Delete-When Conditions Validation
        if (eachUI?.ui?.download && delete_conditions?.length === 0) {
          currentMessage =
            "When the video loading type is DOWNLOAD, make sure to add at least one delete-when condition"
          if (validationRef.current !== currentMessage) {
            validationRef.current = currentMessage
            setValidationMessage(currentMessage)
          }
          return false
        } else {
          let condition = delete_conditions.find((condition) => {
            if (condition.type === "did") {
              return condition.details.name === ""
            }
            if (condition.type === "didn't") {
              return (
                condition.details.additional_info.nkpi.length < 1 ||
                condition.trigger.details.name === ""
              )
            }
            return null
          })
          if (condition) {
            if (goal_met_check && !goal_event) {
              currentMessage = (
                <>
                  Goal event must be selected if <b>Goal Met</b> is one of the{" "}
                  <b>delete conditions</b>
                </>
              )
            } else if (condition.type === "did") {
              currentMessage = "Event name must be selected in Delete Events"
            } else if (condition.type === "didn't") {
              if (condition.details.additional_info.nkpi.length < 1) {
                currentMessage =
                  "At least one event must be selected in Who didn't delete event"
              } else {
                currentMessage =
                  "After Event name must be selected for didn't event condition"
              }
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }

          condition = delete_conditions.find((condition) => {
            if (condition.type === "did") {
              return (
                Object.keys(condition.details.additional_info).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.details.additional_info[attributeName].val ===
                      "" ||
                    condition.details.additional_info[attributeName].op === "",
                ) !== -1
              )
            }
            if (condition.type === "didn't") {
              return (
                Object.keys(
                  condition.trigger.details.additional_info,
                ).findIndex(
                  (attributeName) =>
                    attributeName === "" ||
                    condition.trigger.details.additional_info[attributeName]
                      .val === "",
                ) !== -1
              )
            }
            return null
          })
          if (condition) {
            if (condition.type === "did") {
              currentMessage =
                "Property name, operator and value must be selected for event " +
                condition.details.name
            } else if (condition.type === "didn't") {
              currentMessage =
                "Property name and value must be selected for after event " +
                condition.trigger.details.name
            }
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }
        }

        return true
      })
    }
  }

  //Badges Validation
  if (
    config_type === "walkthrough" &&
    uis?.length === 1 &&
    uis?.[0]?.ui?.display_type === "badge" &&
    uis?.[0]?.ui?.action_class === ACTION_CLASSES.NUDGE
  ) {
    // Background image URL validation for badges
    if (
      uis?.[0]?.ui?.badge?.background.enable_image &&
      uis?.[0]?.ui?.badge?.background.image.path === ""
    ) {
      currentMessage =
        "Background image URL of the badge background must not be empty"
    }

    //Badges Stop Conditions User Attributes Validation
    if (
      enable_attributes &&
      stop_conditions_user_attributes?.[0]?.name !== "" &&
      stop_conditions_user_attributes?.[0]?.value.length < 1
    ) {
      currentMessage =
        "User Property name and value in badge stop conditions must not be empty"
    }

    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
  }

  // Target Audience Validation
  if (hasEvents && !events?.length) {
    currentMessage = "Event must be selected in Target audience page"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (hasExcludeCohort && !exclude_cohort?.cohort_id) {
    currentMessage = "Cohort must be selected in Target audience page"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }
  if (audience_type === TARGET_AUDIENCE_ENUM.SEGMENT.key && segment_id === "") {
    currentMessage = "Segment must be selected"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (audience_type === TARGET_AUDIENCE_ENUM.COHORT.key && cohort_id === "") {
    currentMessage = "Cohort must be selected"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    audience_type === TARGET_AUDIENCE_ENUM.DYNAMIC_COHORT.key &&
    cohort_id === ""
  ) {
    currentMessage = "Dynamic Cohort must be selected"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // Goals Validation
  if (config_type !== "survey") {
    // if (primary_goal.type === "event" && primary_goal.name === "") {
    //   currentMessage = (
    //     <>
    //       Primary Goal event must be selected in the <b>Target Section</b>
    //     </>
    //   )
    //   if (validationRef.current !== currentMessage) {
    //     validationRef.current = currentMessage
    //     setValidationMessage(currentMessage)
    //   }
    //   return false
    // }

    if (
      primary_goal.type === "event" &&
      primary_goal.attributes.length > 0 &&
      !checkArrayValues(primary_goal.attributes)
    ) {
      currentMessage = (
        <>
          Primary goal event and properties aren't selected properly in the{" "}
          <b>Target Section</b>
        </>
      )
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
  }

  // A/B Distribution Validation
  if (config_type === "walkthrough" && isExperiment) {
    const variants = stateRef.current?.experiment?.variants
    if (variants) {
      const distributions = Object.keys(variants).map((eachVariant) => {
        return variants[eachVariant].distribution
      })
      const sum = distributions.reduce((a, b) => a + b, 0)
      if (sum !== 100) {
        currentMessage =
          "In Target Page, Variant Distributions are UnEven, make sure they amount to 100%"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }
  }

  if (config_type === "walkthrough" && isExperiment) {
    const variants = stateRef.current?.experiment?.variants
    if (variants) {
      Object.keys(variants)
        .filter((key) => key !== "CG")
        .map((eachVariant) => {
          if (variants[eachVariant].indices.length <= 0) {
            currentMessage = "One or more variants are Empty, please add a step"
            if (validationRef.current !== currentMessage) {
              validationRef.current = currentMessage
              setValidationMessage(currentMessage)
            }
            return false
          }

          return true
        })
    }
  }

  if (hasUser && (!Array.isArray(user) || user.length < 1)) {
    currentMessage = "No Properties selected in By User Properties section"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    hasUser &&
    user.findIndex((attr) => attr.name === "" || attr.value?.length < 1) !== -1
  ) {
    currentMessage =
      "By User Properties section should not contain empty property name or value"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (hasSes && (!Array.isArray(session) || session.length < 1)) {
    currentMessage = "No Properties selected in By Session Properties section"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    hasSes &&
    session.findIndex((attr) => attr.name === "" || attr.value?.length < 1) !==
      -1
  ) {
    currentMessage =
      "By Session Properties section should not contain empty property name or value"
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  if (
    hasVer &&
    ((platform === APP_PLATFORMS.android && !Number(ver.build_number)) ||
      ((platform === APP_PLATFORMS.ios || platform === APP_PLATFORMS.web) &&
        ver.val.trim &&
        ver.val.trim().length < 1))
  ) {
    currentMessage = (
      <>
        Expected valid version value in{" "}
        <b>
          {platform === APP_PLATFORMS.android
            ? "By Build Number"
            : "By App Version"}{" "}
        </b>
        section
      </>
    )
    if (validationRef.current !== currentMessage) {
      validationRef.current = currentMessage
      setValidationMessage(currentMessage)
    }
    return false
  }

  // legacy conditions validation
  if (enable_conditions) {
    if (conditions.length === 0) {
      if (!(platform === APP_PLATFORMS.web && no_context_enabled)) {
        currentMessage =
          "In Target page, Trigger must contain at least one condition"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    } else {
      let condition = conditions.find((condition) => {
        if (condition.type === "did") {
          return condition.details.name === ""
        }
        if (condition.type === "didn't") {
          return (
            condition.details.additional_info.nkpi.length < 1 ||
            condition.trigger.details.name === ""
          )
        }
        return null
      })
      if (condition) {
        if (condition.type === "did") {
          currentMessage =
            "Event name must be selected in Trigger Events section of Target Page"
        } else if (condition.type === "didn't") {
          if (condition.details.additional_info.nkpi.length < 1) {
            currentMessage =
              "At least one event must be selected in Who didn't trigger event section of Target Page"
          } else {
            currentMessage =
              "After Event name must be selected for didn't event condition in Target Page"
          }
        }
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }

      condition = conditions.find((condition) => {
        if (condition.type === "did") {
          return (
            Object.keys(condition.details.additional_info).findIndex(
              (attributeName) =>
                attributeName === "" ||
                condition.details.additional_info[attributeName].val === "" ||
                condition.details.additional_info[attributeName].op === "",
            ) !== -1
          )
        }
        if (condition.type === "didn't") {
          return (
            Object.keys(condition.trigger.details.additional_info).findIndex(
              (attributeName) =>
                attributeName === "" ||
                condition.trigger.details.additional_info[attributeName].val ===
                  "",
            ) !== -1
          )
        }
        return null
      })
      if (condition) {
        if (condition.type === "did") {
          currentMessage =
            "Property name, operator and value must be selected for event " +
            condition.details.name
        } else if (condition.type === "didn't") {
          currentMessage =
            "Property name, operator and value must be selected for after event " +
            condition.trigger.details.name
        }
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    }
  }

  //latest conditions validation
  if (enable_conditions_v2) {
    if (conditions_v2.length === 0) {
      if (!(platform === APP_PLATFORMS.web && no_context_enabled)) {
        currentMessage =
          "In Target page, Trigger must contain at least one condition in Advanced Conditons"
        if (validationRef.current !== currentMessage) {
          validationRef.current = currentMessage
          setValidationMessage(currentMessage)
        }
        return false
      }
    } else {
      let isConditionsEligible = true
      conditions_v2.forEach((outerCondition) => {
        if (outerCondition.condition_type === "single") {
          if (outerCondition.event_type === "") {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (
            outerCondition.event_type === "screen_event" &&
            !outerCondition.screen
          ) {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (
            outerCondition.event_type === "activity_event" &&
            !outerCondition.activity
          ) {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (
            (outerCondition.event_type === "app_event" ||
              outerCondition.event_type === "client_event") &&
            !outerCondition.details.name
          ) {
            currentMessage =
              "In Target page, Trigger section, condition should have event selected"
            isConditionsEligible = false
          }
          if (outerCondition.details.name) {
            Object.keys(outerCondition.details.additional_info).forEach((i) => {
              if (
                (!outerCondition.details.additional_info[i].val &&
                  (outerCondition.details.additional_info[i]?.vals?.length ??
                    0) === 0) ||
                !outerCondition.details.additional_info[i].t ||
                !outerCondition.details.additional_info[i].op
              ) {
                currentMessage = `In Target page, Trigger section, property ${i} have incomplete value`
                isConditionsEligible = false
              }
            })
          }
        } else {
          outerCondition.conditions_v2.forEach((innerCondition) => {
            if (innerCondition.event_type === "") {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (
              innerCondition.event_type === "screen_event" &&
              !innerCondition.screen
            ) {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (
              innerCondition.event_type === "activity_event" &&
              !innerCondition.activity
            ) {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (
              (innerCondition.event_type === "app_event" ||
                innerCondition.event_type === "client_event") &&
              innerCondition.details.name === ""
            ) {
              currentMessage =
                "In Target page, Trigger section, condition should have event selected"
              isConditionsEligible = false
            }
            if (innerCondition.details.name) {
              Object.keys(innerCondition.details.additional_info).forEach(
                (i) => {
                  if (
                    (!innerCondition.details.additional_info[i].val &&
                      (innerCondition.details.additional_info[i].vals?.length ??
                        0) === 0) ||
                    !innerCondition.details.additional_info[i].t ||
                    !innerCondition.details.additional_info[i].val
                  ) {
                    currentMessage = `In Target page, Trigger section, property ${i} have incomplete value`
                    isConditionsEligible = false
                  }
                },
              )
            }
          })
        }
      })
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      if (!isConditionsEligible) return false
    }
  }

  if (!enable_conditions && !enable_conditions_v2) {
    if (!(platform === APP_PLATFORMS.web && no_context_enabled)) {
      currentMessage =
        "In Target page, Trigger must contain at least one condition"
      if (validationRef.current !== currentMessage) {
        validationRef.current = currentMessage
        setValidationMessage(currentMessage)
      }
      return false
    }
  }
  if (validationRef.current !== currentMessage) {
    setValidationMessage("")
    validationRef.current = ""
  }
  return true
}

export const useCommonConfigStyles = useCommonDashboardStyles
