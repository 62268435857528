import { useReducer } from "react"
import { createContainer } from "react-tracked"
import { useTrackedState as campaignLevelState } from "../../../../common/components/campaigncreator/store"
import { initial_ui_state, reducer } from "./reducer"

const useValue = () => {
  const state = campaignLevelState()
  return useReducer(reducer, {
    ...initial_ui_state,
    platform: state.config.meta.platform,
  })
}

export const {
  Provider,
  useTracked,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue)
