import { arrayMove } from "@dnd-kit/sortable"
import produce from "immer"
import { cloneDeep } from "lodash"
import { APP_PLATFORMS } from "../../../../../../constants"
import generateShortID, { isDefined } from "../../../../../../utils"
import { sendEvent } from "../../../../common/ApxEventHandler"
import {
  MESSAGE_ENUM,
  SET_FREQ_COUNT,
  SET_GOAL_EVENT,
} from "../../../../common/components/campaigncreator/constants"
import { useDispatch } from "../../../../common/components/campaigncreator/store"
import {
  ADD_BADGE_VARIANT,
  ADD_SHINE_LINE_VARIANT,
  CLONE_BADGE_VARIANT,
  DELETE_BADGE_VARIANT,
  DELETE_SHINE_LINE_VARIANT,
  SET_BADGE_ANIMATION_DELAY_MILLIS,
  SET_BADGE_ANIMATION_DURATION_MILLIS,
  SET_BADGE_ANIMATION_FADE_OPACITY,
  SET_BADGE_ANIMATION_INTER_COUNT,
  SET_BADGE_ANIMATION_INTRA_COUNT,
  SET_BADGE_ANIMATION_RIPPLE_AREA,
  SET_BADGE_ANIMATION_RIPPLE_COLOR,
  SET_BADGE_ANIMATION_RIPPLE_CORNER_RADIUS,
  SET_BADGE_ANIMATION_RIPPLE_SHAPE,
  SET_BADGE_ANIMATION_SHAKE_DIRECTION,
  SET_BADGE_ANIMATION_SHINE_COLOR,
  SET_BADGE_ANIMATION_SHINE_DIRECTION,
  SET_BADGE_ANIMATION_SHINE_LINES,
  SET_BADGE_ANIMATION_SHINE_LINES_GAP,
  SET_BADGE_ANIMATION_TYPE,
  SET_BADGE_AUTO_SCROLL_DIRECTION,
  SET_BADGE_AUTO_SCROLL_HORIZONTAL_GAP,
  SET_BADGE_AUTO_SCROLL_SPEED,
  SET_BADGE_BACKGROUND_ENABLE_IMAGE,
  SET_BADGE_BACKGROUND_HEIGHT,
  SET_BADGE_BACKGROUND_IMAGE_PATH,
  SET_BADGE_BACKGROUND_IMAGE_TYPE,
  SET_BADGE_BACKGROUND_SHAPE_BORDER_COLOR,
  SET_BADGE_BACKGROUND_SHAPE_BORDER_CORNERS,
  SET_BADGE_BACKGROUND_SHAPE_BORDER_WIDTH,
  SET_BADGE_BACKGROUND_SHAPE_COLOR,
  SET_BADGE_BACKGROUND_SHAPE_ENABLE_BORDER,
  SET_BADGE_BACKGROUND_SHAPE_TYPE,
  SET_BADGE_BACKGROUND_WIDTH,
  SET_BADGE_CONTENT_ENABLE_ICON,
  SET_BADGE_CONTENT_ENABLE_LABEL,
  SET_BADGE_CONTENT_ICON_ENABLE_MARGIN,
  SET_BADGE_CONTENT_ICON_HEIGHT,
  SET_BADGE_CONTENT_ICON_MARGIN,
  SET_BADGE_CONTENT_ICON_PATH,
  SET_BADGE_CONTENT_ICON_POSITION,
  SET_BADGE_CONTENT_ICON_TYPE,
  SET_BADGE_CONTENT_ICON_WIDTH,
  SET_BADGE_CONTENT_LABEL_ALIGNMENT,
  SET_BADGE_CONTENT_LABEL_COLOR,
  SET_BADGE_CONTENT_LABEL_ENABLE_MARGIN,
  SET_BADGE_CONTENT_LABEL_FONT,
  SET_BADGE_CONTENT_LABEL_MARGIN,
  SET_BADGE_CONTENT_LABEL_TEXT,
  SET_BADGE_DYNAMIC_TEXT_CONFIG,
  SET_BADGE_ENABLE_ANIMATION,
  SET_BADGE_ENABLE_AUTO_SCROLL,
  SET_BADGE_ENABLE_BACKGROUND,
  SET_BADGE_ENABLE_SHADOW,
  SET_BADGE_OFFSET,
  SET_BADGE_POSITION,
  SET_BADGE_SHADOW,
} from "./badge-constants"
import { constructHTML } from "./components/configuration/utils"
import {
  ACTION_CLASSES,
  ADD_NEXT_STEP_TO_CAMPAIGN,
  BTN_ACTION_ENUM,
  CHECK_AND_REMOVE_ELEMENT_HIGHLIGHTER,
  CHECK_AND_REMOVE_WIDTH,
  CHECK_AND_SET_ENABLE_PIP_MODE,
  CLEAR_VIEW_MAP,
  CONVERT_INAPP_TO_HTML,
  DEFAULT_HTML_SCRIPT_JSON,
  DEFAULT_INTENT_ACTION,
  DELETE_STEP_FROM_CAMPAIGN,
  INITIAL_ONBOARDING_STATE,
  MEDIA_TYPES_ENUM,
  MODULE_TYPES,
  MOVE_STEP,
  NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_CIRCLE,
  NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_RECT,
  NEW_CONFIG_COACHMARK_SHAPE,
  NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_CIRCLE,
  NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_RECT,
  NEW_CONFIG_SET_BACKGROUND_COLOR,
  NEW_CONFIG_SET_BACKGROUND_OFFSET,
  NEW_CONFIG_SET_BACKGROUND_OPACITY,
  NEW_CONFIG_SET_BUTTONS,
  NEW_CONFIG_SET_BUTTONS_ALIGNMENT,
  NEW_CONFIG_SET_BUTTONS_LENGTH,
  NEW_CONFIG_SET_BUTTONS_POSITION,
  NEXT_BUTTON_STATE,
  NEXT_BUTTON_STATE_FOR_CAROUSAL,
  ON_COPY_STEP,
  ON_UIS_CHANGE,
  PREV_BUTTON_STATE,
  PREV_BUTTON_STATE_FOR_CAROUSAL,
  REMOVE_SNIPPET_FROM_HTML_CONFIG,
  SET_ADAPTIVE_TEXT,
  SET_ANIMATION_DURATION,
  SET_ANIMATION_ENABLED,
  SET_ANIMATION_TYPE,
  SET_ARROW_BORDER_COLOR,
  SET_ARROW_BORDER_ENABLED,
  SET_ARROW_BORDER_RADIUS,
  SET_ARROW_BORDER_STYLE,
  SET_ARROW_BORDER_WIDTH,
  SET_ARROW_COLOR,
  SET_ARROW_ENABLED,
  SET_ARROW_TYPE,
  SET_AUTO_DISMISS_DURATION,
  SET_AUTO_DISMISS_ENABLED,
  SET_BACKGROUND_COLOR,
  SET_BACKGROUND_OFFSET_DISTANCE,
  SET_BACKGROUND_OFFSET_SKID,
  SET_BACKGROUND_POSITION_OFFSET_DISTANCE,
  SET_BACKGROUND_POSITION_OFFSET_SKID,
  SET_BADGE_CAN_HORIZONTAL_AUTO_SCROLL_OPTION_SHOW,
  SET_BADGE_VARIANT,
  SET_BG_IMAGE,
  SET_BG_IMAGE_SIZE,
  SET_BLACKLIST_SCREENS,
  SET_BORDER_BOTTOM_RADIUS,
  SET_BORDER_RADIUS,
  SET_BUTTONS,
  SET_BUTTONS_SLOTS_COUNT,
  SET_BUTTONS_SLOTS_UPDATE_MODE,
  SET_BUTTON_ACTION,
  SET_BUTTON_ACTION_TYPE,
  SET_BUTTON_ADAPTIVE_TEXT,
  SET_BUTTON_BG_COLOR,
  SET_BUTTON_BORDER_RADIUS,
  SET_BUTTON_COLOR,
  SET_BUTTON_DYNAMIC_TEXT_CONFIG,
  SET_BUTTON_FONT_FAMILY,
  SET_BUTTON_FONT_SIZE,
  SET_BUTTON_FONT_STYLE,
  SET_BUTTON_IMAGE,
  SET_BUTTON_MARGIN,
  SET_BUTTON_PADDING,
  SET_BUTTON_PLACEMENT_MODE,
  SET_BUTTON_REDIRECT_ACTIVITY,
  SET_BUTTON_REDIRECT_DEEPLINK,
  SET_BUTTON_REDIRECT_TYPE,
  SET_BUTTON_TEXT,
  SET_BUTTON_TEXT_MARGIN,
  SET_BUTTON_TEXT_PADDING,
  SET_CAROUSEL_COUNT,
  SET_CAROUSEL_IMAGES,
  SET_CLOSE_BUTTON_ACTION,
  SET_CLOSE_BUTTON_COLOR,
  SET_CLOSE_BUTTON_ENABLED,
  SET_CLOSE_BUTTON_FILL_COLOR,
  SET_CLOSE_BUTTON_HEIGHT,
  SET_CLOSE_BUTTON_OFFSET_RIGHT,
  SET_CLOSE_BUTTON_OFFSET_TOP,
  SET_CLOSE_BUTTON_PATH,
  SET_CLOSE_BUTTON_SHAPE,
  SET_CLOSE_BUTTON_TYPE,
  SET_CLOSE_BUTTON_WIDTH,
  SET_COACHMARK_HAS_ARROW,
  SET_COACHMARK_HAS_RIPPLE,
  SET_COACHMARK_TYPE,
  SET_COLOR,
  SET_CURRENT_IMAGE,
  SET_CURRENT_STEP,
  SET_DELAY,
  SET_DESCRIPTION_ENABLED,
  SET_DESCRIPTION_MARGIN,
  SET_DESCRIPTION_PADDING,
  SET_DIMMED_BACKGROUND,
  SET_DIM_BACKGROUND,
  SET_DIM_BACKGROUND_COLOR,
  SET_DIM_BACKGROUND_OPACITY,
  SET_DISMISS_OUTSIDE_TOUCH,
  SET_DISMISS_OVERLAP,
  SET_DYNAMIC_TEXT_CONFIG,
  SET_ELEMENT_HIGHLIGHTER_BORDER_COLOR,
  SET_ELEMENT_HIGHLIGHTER_BORDER_OPACITY,
  SET_ELEMENT_HIGHLIGHTER_BORDER_WIDTH,
  SET_ELEMENT_HIGHLIGHTER_CORNER_RADIUS,
  SET_ELEMENT_HIGHLIGHTER_OFFSET_BOTTOM,
  SET_ELEMENT_HIGHLIGHTER_OFFSET_LEFT,
  SET_ELEMENT_HIGHLIGHTER_OFFSET_RADIUS,
  SET_ELEMENT_HIGHLIGHTER_OFFSET_RIGHT,
  SET_ELEMENT_HIGHLIGHTER_OFFSET_TOP,
  SET_ELEMENT_HIGHLIGHTER_SHAPE,
  SET_ENABLE_PIP_MODE,
  SET_ENABLE_TIME_BASED,
  SET_ENABLE_TOUCH_VALUE,
  SET_END_OF_VIDEO,
  SET_EXPERIMENT_STATE,
  SET_FONT_FAMILY,
  SET_FONT_SIZE,
  SET_FONT_STYLE,
  SET_GRADIENT,
  SET_HEIGHT,
  SET_HTML_ACTION_CONFIG,
  SET_HTML_SCRIPT_CONFIG,
  SET_IMAGE,
  SET_IMAGE_ONLY_REDIRECTION_CONFIG,
  SET_IMAGE_SIZE,
  SET_IMG,
  SET_INAPP_DISMISS_OUTSIDE_TOUCH,
  SET_INAPP_HTML,
  SET_INAPP_HTML_URL,
  SET_INAPP_POSITION,
  SET_INLINE_ANIMATION_ENABLED,
  SET_IS_CONTENT_VIEW_SELECTION_MODE,
  SET_LAYOUT,
  SET_MAXIMUM_HEIGHT,
  SET_MEDIA_ICON_ALIGNMENT,
  SET_MEDIA_ICON_BORDER_COLOR,
  SET_MEDIA_ICON_BORDER_ENABLED,
  SET_MEDIA_ICON_BORDER_RADIUS,
  SET_MEDIA_ICON_BORDER_STYLE,
  SET_MEDIA_ICON_BORDER_WIDTH,
  SET_MEDIA_ICON_HEIGHT,
  SET_MEDIA_ICON_PATH,
  SET_MEDIA_ICON_WIDTH,
  SET_MEDIA_IMAGE_ACTION,
  SET_MEDIA_IMAGE_ACTION_ENABLED,
  SET_MEDIA_IMAGE_BORDER_COLOR,
  SET_MEDIA_IMAGE_BORDER_ENABLED,
  SET_MEDIA_IMAGE_BORDER_RADIUS,
  SET_MEDIA_IMAGE_BORDER_STYLE,
  SET_MEDIA_IMAGE_BORDER_WIDTH,
  SET_MEDIA_IMAGE_HEIGHT,
  SET_MEDIA_IMAGE_PATH,
  SET_MEDIA_IMAGE_WIDTH,
  SET_MEDIA_VIDEO_AUDIO_ENABLED,
  SET_MEDIA_VIDEO_BORDER_COLOR,
  SET_MEDIA_VIDEO_BORDER_ENABLED,
  SET_MEDIA_VIDEO_BORDER_RADIUS,
  SET_MEDIA_VIDEO_BORDER_STYLE,
  SET_MEDIA_VIDEO_BORDER_WIDTH,
  SET_MEDIA_VIDEO_EMBED_ENABLED,
  SET_MEDIA_VIDEO_EMBED_HTML,
  SET_MEDIA_VIDEO_HEIGHT,
  SET_MEDIA_VIDEO_PATH,
  SET_MEDIA_VIDEO_WIDTH,
  SET_MESSAGE_POSITION,
  SET_MODAL_DIRECTION,
  SET_MODAL_MARGIN_BOTTOM,
  SET_MODAL_MARGIN_LEFT,
  SET_MODAL_MARGIN_RIGHT,
  SET_MODAL_MARGIN_TOP,
  SET_MODAL_MEDIA_TYPE,
  SET_MODAL_NONMEDIA_DIRECTION,
  SET_MODAL_PADDING_BOTTOM,
  SET_MODAL_PADDING_LEFT,
  SET_MODAL_PADDING_RIGHT,
  SET_MODAL_PADDING_TOP,
  SET_MODAL_WIDTH,
  SET_MODULE_TYPE,
  SET_NEW_ADAPTIVE_TEXT,
  SET_NEW_BORDER_COLOR,
  SET_NEW_BORDER_ENABLED,
  SET_NEW_BORDER_RADIUS,
  SET_NEW_BORDER_STYLE,
  SET_NEW_BORDER_WIDTH,
  SET_NEW_BUTTON_ACTION_DATA,
  SET_NEW_BUTTON_ACTION_ENABLED,
  SET_NEW_BUTTON_ACTION_TYPE,
  SET_NEW_BUTTON_ADAPTIVE_TEXT,
  SET_NEW_BUTTON_BG_COLOR,
  SET_NEW_BUTTON_BORDER_COLOR,
  SET_NEW_BUTTON_BORDER_ENABLED,
  SET_NEW_BUTTON_BORDER_RADIUS,
  SET_NEW_BUTTON_BORDER_STYLE,
  SET_NEW_BUTTON_BORDER_WIDTH,
  SET_NEW_BUTTON_DYNAMIC_TEXT,
  SET_NEW_BUTTON_FONT_FAMILY,
  SET_NEW_BUTTON_FONT_SIZE,
  SET_NEW_BUTTON_FONT_STYLE,
  SET_NEW_BUTTON_FONT_WEIGHT,
  SET_NEW_BUTTON_MARGIN,
  SET_NEW_BUTTON_PADDING,
  SET_NEW_BUTTON_REDIRECT_TYPE,
  SET_NEW_BUTTON_REDIRECT_URL,
  SET_NEW_BUTTON_TEXT,
  SET_NEW_BUTTON_TEXT_COLOR,
  SET_NEW_BUTTON_TEXT_MARGIN,
  SET_NEW_BUTTON_TEXT_PADDING,
  SET_NEW_CONFIG_COACHMARK_ARROW_ANGLE,
  SET_NEW_CONFIG_COACHMARK_ARROW_ASPECT_RATIO,
  SET_NEW_CONFIG_COACHMARK_ARROW_BORDER_PROPERTY,
  SET_NEW_CONFIG_COACHMARK_ARROW_DIMENSION,
  SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE,
  SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE_BORDER,
  SET_NEW_CONFIG_COACHMARK_ARROW_OFFSET,
  SET_NEW_CONFIG_COACHMARK_ARROW_PATH,
  SET_NEW_CONFIG_COACHMARK_ARROW_POSITION,
  SET_NEW_CONFIG_COACHMARK_ARROW_TYPE,
  SET_NEW_CONFIG_COACHMARK_ENABLE_RIPPLE,
  SET_NEW_CONFIG_COACHMARK_IMAGE_ASPECT_RATIO,
  SET_NEW_CONFIG_COACHMARK_IMAGE_BORDER_PROPERTY,
  SET_NEW_CONFIG_COACHMARK_IMAGE_DIMENSION,
  SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE,
  SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE_BORDER,
  SET_NEW_CONFIG_COACHMARK_IMAGE_OFFSET,
  SET_NEW_CONFIG_COACHMARK_IMAGE_PATH,
  SET_NEW_CONFIG_COACHMARK_IMAGE_POSITION,
  SET_NEW_CONFIG_COACHMARK_IMAGE_TYPE,
  SET_NEW_CONFIG_COACHMARK_RIPPLE_COLOR,
  SET_NEW_CONFIG_COACHMARK_RIPPLE_DURATION,
  SET_NEW_CONFIG_COACHMARK_RIPPLE_OPACITY,
  SET_NEW_CONFIG_COACHMARK_RIPPLE_TYPE,
  SET_NEW_CONFIG_COACHMARK_RIPPLE_WIDTH,
  SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_ENABLED,
  SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_PROPERTY,
  SET_NEW_CONFIG_CONTENT_BUTTON_COLOR,
  SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION,
  SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION_TYPE,
  SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_TYPE,
  SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_URL,
  SET_NEW_CONFIG_CONTENT_BUTTON_MARGIN,
  SET_NEW_CONFIG_CONTENT_BUTTON_PADDING,
  SET_NEW_CONFIG_CONTENT_ENABLE_DESC,
  SET_NEW_CONFIG_CONTENT_ENABLE_TITLE,
  SET_NEW_CONFIG_CONTENT_HEIGHT,
  SET_NEW_CONFIG_CONTENT_MARGIN,
  SET_NEW_CONFIG_CONTENT_OFFSET,
  SET_NEW_CONFIG_CONTENT_POSITION,
  SET_NEW_CONFIG_CONTENT_TEXT,
  SET_NEW_CONFIG_CONTENT_TEXT_ALIGNMENT,
  SET_NEW_CONFIG_CONTENT_TEXT_COLOR,
  SET_NEW_CONFIG_CONTENT_TEXT_FONT_PROPERTY,
  SET_NEW_CONFIG_CONTENT_TEXT_MARGIN,
  SET_NEW_CONFIG_CONTENT_TEXT_PADDING,
  SET_NEW_CONFIG_CONTENT_WIDTH,
  SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_COLOR,
  SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_ENABLE,
  SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_OPACITY,
  SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_RADIUS,
  SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_STYLE,
  SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_WIDTH,
  SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_SHAPE,
  SET_NEW_DYNAMIC_TEXT_CONFIG,
  SET_NEW_FONT_FAMILY,
  SET_NEW_FONT_SIZE,
  SET_NEW_FONT_STYLE,
  SET_NEW_FONT_WEIGHT,
  SET_NEW_TEXT_ALIGNMENT,
  SET_NEXT_STEP_DATA,
  SET_ONBOARDING_ACTION_BUTTON_MARGIN,
  SET_ONBOARDING_ANIM_DURATION,
  SET_ONBOARDING_ANIM_TYPE,
  SET_ONBOARDING_BACKGROUND_COLOR,
  SET_ONBOARDING_BORDER_COLOR,
  SET_ONBOARDING_BORDER_WIDTH,
  SET_ONBOARDING_BOTTOM_PAD,
  SET_ONBOARDING_BTN_ADAPTIVE_TEXT,
  SET_ONBOARDING_BTN_BACKGROUND_COLOR,
  SET_ONBOARDING_BTN_COLOR,
  SET_ONBOARDING_BTN_FONT_FAMILY,
  SET_ONBOARDING_BTN_FONT_SIZE,
  SET_ONBOARDING_BTN_FONT_STYLE,
  SET_ONBOARDING_BTN_TEXT,
  SET_ONBOARDING_CORNER_WIDTH,
  SET_ONBOARDING_COUNTER_BOTTOM_DISTANCE,
  SET_ONBOARDING_COUNTER_COLOR,
  SET_ONBOARDING_COUNTER_FONT_FAMILY,
  SET_ONBOARDING_COUNTER_FONT_STYLE,
  SET_ONBOARDING_COUNTER_LEFT_DISTANCE,
  SET_ONBOARDING_COUNTER_TEXT,
  SET_ONBOARDING_COUNTER_TEXT_SIZE,
  SET_ONBOARDING_DISMISS_ACTION,
  SET_ONBOARDING_DISMISS_TYPE,
  SET_ONBOARDING_DIVIDER_COLOR,
  SET_ONBOARDING_DIVIDER_ENABLED,
  SET_ONBOARDING_DIVIDER_HEIGHT,
  SET_ONBOARDING_DIVIDER_MARGIN,
  SET_ONBOARDING_DOTS_ACTIVE_COLOR,
  SET_ONBOARDING_DOTS_BOTTOM_DIST,
  SET_ONBOARDING_DOTS_DISTANCE,
  SET_ONBOARDING_DOTS_INACTIVE_COLOR,
  SET_ONBOARDING_DOTS_OPACITY,
  SET_ONBOARDING_DOTS_RADIUS,
  SET_ONBOARDING_FADEOUT_OPACITY,
  SET_ONBOARDING_HAS_COUNTER,
  SET_ONBOARDING_HAS_DOTS,
  SET_ONBOARDING_HORIZONTAL_MARGIN,
  SET_ONBOARDING_LAST_STEP_TEXT,
  SET_ONBOARDING_LEFT_PAD,
  SET_ONBOARDING_MODE,
  SET_ONBOARDING_NEXT_PREV_ENABLED,
  SET_ONBOARDING_PAGINATION_COUNTER_TYPE,
  SET_ONBOARDING_PAGINATION_TYPE,
  SET_ONBOARDING_PREV_NEXT_DIST,
  SET_ONBOARDING_RIGHT_PAD,
  SET_ONBOARDING_SKIP_ENABLED,
  SET_ONBOARDING_SKIP_RIGHT,
  SET_ONBOARDING_SKIP_TOP,
  SET_ONBOARDING_STATE,
  SET_ONBOARDING_TEXT_MARGIN,
  SET_ONBOARDING_TEXT_PADDING,
  SET_ONBOARDING_TOOLTIP_MARGIN,
  SET_ONBOARDING_TOP_PAD,
  SET_ONBOARDING_VERTICAL_MARGIN,
  SET_ONLY_IMAGE,
  SET_OPACITY,
  SET_OPEN_LAUNCH_URL_DIALOG,
  SET_ORIENTATION,
  SET_PASSIVE_NUDGE_ANIMATION_TYPE,
  SET_PIP_BUTTON_COLOR,
  SET_PIP_BUTTON_ENABLED,
  SET_PIP_BUTTON_FILL_COLOR,
  SET_PIP_BUTTON_HEIGHT,
  SET_PIP_BUTTON_OFFSET_LEFT,
  SET_PIP_BUTTON_OFFSET_TOP,
  SET_PIP_BUTTON_PATH,
  SET_PIP_BUTTON_SHAPE,
  SET_PIP_BUTTON_TYPE,
  SET_PIP_BUTTON_WIDTH,
  SET_PIP_MODE,
  SET_PLATFORM,
  SET_PREVIEW_DATA,
  SET_PREVIEW_MODE,
  SET_REFRESH_STATE,
  SET_ROW_NAME,
  SET_SCREEN_RESTRICTION_TYPE,
  SET_SCROLLABLE,
  SET_SCROLLABLE_BORDER,
  SET_SCROLLABLE_BORDER_COLOR,
  SET_SCROLLABLE_BORDER_RADIUS,
  SET_SCROLLABLE_BORDER_STYLE,
  SET_SCROLLABLE_BORDER_WIDTH,
  SET_SCROLLABLE_HEIGHT,
  SET_SELECTED_HTML_VIEW,
  SET_SHADOW_BLUR_RADIUS,
  SET_SHADOW_COLOR,
  SET_SHADOW_ENABLED,
  SET_SHADOW_OFFSET_X,
  SET_SHADOW_OFFSET_Y,
  SET_SHADOW_SPREAD_RADIUS,
  SET_SHOW_CONFIGURATION_DRAWER,
  SET_SHOW_CUSTOMIZE_TEMPLATE_CONFIRMATION,
  SET_SHOW_EDIT_SIMULATOR_BUTTON,
  SET_SHOW_QR_FOR_TEST_DEVICE_ADDITION,
  SET_SHOW_QR_IN_VIEW_ID_PATH_SELECTION,
  SET_SHOW_WEB_VIEW_TEMPLATES,
  SET_SLOT_NUMBER,
  SET_TERMINATE_INFO_ENABLE_ATTRIBUTES_VALUE,
  SET_TEXT,
  SET_TEXT_ALIGNMENT,
  SET_THEME,
  SET_TITLE_ENABLED,
  SET_TITLE_ICON_BORDER_COLOR,
  SET_TITLE_ICON_BORDER_ENABLED,
  SET_TITLE_ICON_BORDER_RADIUS,
  SET_TITLE_ICON_BORDER_STYLE,
  SET_TITLE_ICON_BORDER_WIDTH,
  SET_TITLE_ICON_ENABLED,
  SET_TITLE_ICON_HEIGHT,
  SET_TITLE_ICON_PATH,
  SET_TITLE_ICON_WIDTH,
  SET_TITLE_MARGIN,
  SET_TITLE_PADDING,
  SET_TOOLTIP_IMAGE_TYPE,
  SET_TOOLTIP_IMAGE_URL,
  SET_TOOLTIP_TEXT_TYPE,
  SET_TOUCH_URL,
  SET_TREEVIEW,
  SET_UIS_CONFIG,
  SET_VALID_VIDEO_LINK,
  SET_VIDEO_ACTIVITY,
  SET_VIDEO_AUTO_REPLAY,
  SET_VIDEO_BINDING,
  SET_VIDEO_BUTTON_COLOR,
  SET_VIDEO_CAN_MINIMIZE,
  SET_VIDEO_CLOSE,
  SET_VIDEO_HAS_AUDIO,
  SET_VIDEO_ISFULLSCREEN,
  SET_VIDEO_LINK,
  SET_VIDEO_LOADING_TYPE,
  SET_VIDEO_MUTE_AUDIO,
  SET_VIDEO_PROGRESS_BAR,
  SET_VIEWID_SELECTION_DIALOG_DATA,
  SET_VIEW_MAP,
  SET_WEB_HTML_DATA,
  SET_WEB_URL,
  SET_WEB_VIEW,
  SET_WEB_VIEW_TAG,
  SET_WHITELIST_SCREENS,
  SET_WIDTH,
  SET_WIDTH_TYPE,
  SKIP_BUTTON_STATE,
  TEMPLATE_BEEN_SET,
  TITLE_OR_DESC_PADDING_MARGIN,
  UNSET_CAROUSAL_IMAGE,
  UPDATE_IN_NEW_STEP,
  UPDATE_ONBOARDING_STATE_IN_UIS,
  WIDTH_TYPES,
} from "./constants"
import {
  ACTION_BUTTON_CONFIG,
  ADD_BADGE_VARIANT_CONFIG,
  BACKGROUND_OFFSET_CONFIG,
  BADGE_ANIMATION_CONFIG,
  BADGE_SHINE_ONE_LINE_CONFIG,
  BUTTON_CONFIG,
  CLOSE_CONFIG,
  DISMISS_ACTIONS_CONFIG,
  FONT_CONFIG,
  NEW_BUTTON_CONFIG,
  NEW_COACHMARKS_ARROW,
  NEW_COACHMARKS_HIGHLIGHTER,
  NEW_COACHMARKS_IMAGE,
  NEW_COACHMARKS_LIST_BUTTONS,
  PIP_BUTTON_CONFIG,
  TEXT_CONFIG,
} from "./templates/common"
import { DEFAULT_ELEMENT_HIGHLIGHTER_CONFIG } from "./templates/tooltip"
import { getUI as getUIFromIndex } from "./utils"

export const initial_ui_state = {
  preview_mode: false,
  can_horizontal_auto_scroll_option_show: false,
  selectedBadgeVariant: 0,
  showQRinViewIdPathSelection: false,
  showQRForTestDeviceAddition: false,
  template_been_set: false,
  uis: [],
  app_id: "",
  nextStep: {},
  currentStep: -1,
  uiIndex: -1,
  moduleType: "",
  isOnboardingMode: false,
  onboardingState: INITIAL_ONBOARDING_STATE,
  platform: APP_PLATFORMS.android,
  sseData: {
    screen: {
      orientation: "portrait",
    },
  },
  img: [],
  treeView: [],
  eventSource: {},
  currentImage: null,
  selectedHTMLView: null,
  layout: null,
  orientation: "portrait",
  isContentViewSelectionMode: false,
  viewMap: {},
  enable_button_placement_mode: false,
  enable_buttons_slots_update_mode: false,
  enable_end_of_video: false,
  enable_pip_mode: false,
  row_name: "",
  slot: 0,
  showConfigurationDrawer: false,
  showCustomizeTemplateConfirmation: false,
  showEditSimulatorButton: false,
  showWebViewTemplates: false,
  isRefresh: true,
  openLaunchUrlDialog: false,
}

export const reducer = produce((draft, action) => {
  let newDraft = cloneDeep(draft)
  const dispatch = useDispatch()

  if (action.hasOwnProperty("currentStep") && !isDefined(action.currentStep)) {
    return newDraft
  }

  let currentStep =
    action.currentStep !== undefined && action.currentStep !== null
      ? action.currentStep
      : newDraft.currentStep
  let isExperiment = action.isExperiment ?? false
  let row_name = newDraft.row_name ?? action.row_name
  let keyName = action.keyName
  let index
  let config
  let html_config

  const getUI = (step, uis, isExperiment = null) => {
    return getUIFromIndex(
      step,
      uis,
      isExperiment !== null ? isExperiment : newDraft.isExperiment,
    )
  }

  const getButtonConfig = (newDraft, currentStep, keyName, row_name = "") => {
    if (newDraft.moduleType === "") {
      return [null, -1]
    }

    if (
      [MODULE_TYPES.NEW_BUTTON, MODULE_TYPES.NEW_BUTTON_TEXT].includes(
        newDraft.moduleType,
      )
    ) {
      return [
        getUI(currentStep, newDraft.uis).ui.buttons[row_name],
        getUI(currentStep, newDraft.uis).ui.buttons[row_name].findIndex(
          (x) => x.slot === action.slot,
        ),
      ]
    }

    const config =
      newDraft.moduleType === MODULE_TYPES.BUTTON1 ||
      newDraft.moduleType === MODULE_TYPES.BUTTON2 ||
      newDraft.moduleType === MODULE_TYPES.BUTTON_TEXT1 ||
      newDraft.moduleType === MODULE_TYPES.BUTTON_TEXT2
        ? getUI(currentStep, newDraft.uis).ui.buttons_config
        : getUI(currentStep, newDraft.uis).ui.action_buttons_config
    const index = config.findIndex((x) => x.__id === keyName)
    return [config, index]
  }

  const getButtonConfigByModKey = (newDraft, currentStep, keyName, modKey) => {
    const config = getUI(currentStep, newDraft.uis).ui[modKey]
    const index = config.findIndex((x) => x.__id === keyName)
    return [config, index]
  }

  function getElementHighlighterConfig(step, uis) {
    if (getUI(step, uis).ui.highlighter) {
      return getUI(step, uis).ui.highlighter
    } else {
      return null
    }
  }

  function update_onboarding_state(isOnboardingMode = false) {
    if (newDraft.isOnboardingMode || isOnboardingMode) {
      newDraft.uis.forEach((eachUI, index) => {
        eachUI.ui.skipEnabled = newDraft.onboardingState.skipEnabled
        eachUI.ui.nextPrevEnabled = newDraft.onboardingState.nextPrevEnabled

        // first step wont have prev
        if (index === 0) {
          if (eachUI.ui.prev) delete eachUI.ui.prev
          newDraft.onboardingState.has_counter = eachUI.ui.has_counter
          if (
            !eachUI.ui.skip &&
            newDraft.uis.length > 1 &&
            newDraft.onboardingState.skipEnabled
          ) {
            eachUI.ui.skip = { ...SKIP_BUTTON_STATE.skip }
          }
        } else {
          // add prev to the last step
          if (!eachUI.ui.prev && newDraft.onboardingState.nextPrevEnabled) {
            if (eachUI.ui.layout_type === "default") {
              eachUI.ui.prev = { ...PREV_BUTTON_STATE.prev }
            } else {
              eachUI.ui.prev = { ...PREV_BUTTON_STATE_FOR_CAROUSAL.prev }
            }
          }
        }

        // last step wont have skip
        if (index !== 0 && index + 1 === newDraft.uis.length) {
          if (eachUI.ui.skip) delete eachUI.ui.skip
        }

        // if new step is added we need to manually add skip to the second last step
        if (
          !eachUI.ui.skip &&
          index !== 0 &&
          index + 1 === newDraft.uis.length - 1 &&
          newDraft.onboardingState.skipEnabled
        ) {
          eachUI.ui.skip = { ...SKIP_BUTTON_STATE.skip }
        }

        // Append `next` button config
        if (!eachUI.ui.next && eachUI.ui.nextPrevEnabled) {
          if (eachUI.ui.layout_type === "default") {
            eachUI.ui.next = { ...NEXT_BUTTON_STATE.next }
          } else {
            eachUI.ui.next = { ...NEXT_BUTTON_STATE_FOR_CAROUSAL.next }
          }
        }

        //in event of new step or copy step the next text should be updated
        if (
          eachUI.ui.next &&
          [
            newDraft.onboardingState.lastStepText,
            NEXT_BUTTON_STATE.next.text,
            NEXT_BUTTON_STATE_FOR_CAROUSAL.next,
          ].includes(eachUI?.ui?.next?.text) &&
          index !== newDraft.uis.length - 1
        ) {
          eachUI.ui.next.text = NEXT_BUTTON_STATE_FOR_CAROUSAL.next.text
        }

        //in event of new step or copy step the prev text should be updated
        if (
          eachUI.ui.prev &&
          index !== 0 &&
          [
            PREV_BUTTON_STATE.prev.text,
            PREV_BUTTON_STATE_FOR_CAROUSAL.prev.text,
          ].includes(eachUI.ui.prev.text)
        ) {
          eachUI.ui.prev.text = PREV_BUTTON_STATE_FOR_CAROUSAL.prev.text
        }

        eachUI.ui.counter.text = `${index + 1}/${newDraft.uis.length}`
        eachUI.ui.has_counter = newDraft.onboardingState.has_counter
        newDraft.onboardingState.carousal.dots = eachUI.ui.carousal.dots

        // Update the last step text
        if (eachUI.ui.nextPrevEnabled && index === newDraft.uis.length - 1) {
          eachUI.ui.next.text = newDraft.onboardingState.lastStepText
        }

        if (
          eachUI.ui.action_class &&
          eachUI.ui.action_class === ACTION_CLASSES.TOOLTIP
        ) {
          // Set defaults based on the template type
          eachUI.ui.buttons_config = []
          eachUI.ui.dim_background = true
        } else {
          eachUI.ui.carousal.d_type = newDraft.onboardingState.carousal.d_type
        }
      })
    }
  }

  switch (action.type) {
    case SET_IS_CONTENT_VIEW_SELECTION_MODE:
      newDraft.isContentViewSelectionMode = action.value
      break
    case SET_VIEW_MAP:
      newDraft.viewMap[action.key] = action.value
      break
    case CLEAR_VIEW_MAP:
      newDraft.viewMap = {}
      break
    case SET_AUTO_DISMISS_DURATION: {
      let temp_new = cloneDeep(getUI(currentStep, newDraft.uis).ui)
      if (temp_new?.root_level_keys?.terminate_info?.auto_dismiss) {
        temp_new = {
          ...temp_new,
          root_level_keys: {
            ...temp_new?.root_level_keys,
            terminate_info: {
              ...temp_new?.root_level_keys?.terminate_info,
              duration: action.value,
            },
          },
        }
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui = temp_new
      break
    }
    case SET_OPEN_LAUNCH_URL_DIALOG:
      newDraft.openLaunchUrlDialog = action.value
      break
    case SET_AUTO_DISMISS_ENABLED: {
      let temp_new = cloneDeep(getUI(currentStep, newDraft.uis).ui)
      temp_new = {
        ...temp_new,
        root_level_keys: {
          ...temp_new?.root_level_keys,
          terminate_info: {
            ...temp_new?.root_level_keys?.terminate_info,
            auto_dismiss: action.enabled,
            ...(!temp_new?.root_level_keys?.terminate_info?.hasOwnProperty(
              "duration",
            ) && {
              duration: 5000,
            }),
          },
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui = temp_new
      break
    }
    case TEMPLATE_BEEN_SET:
      newDraft.template_been_set = action.value
      break
    case CONVERT_INAPP_TO_HTML:
      getUI(currentStep, newDraft.uis).ui.html_config.data = constructHTML(
        getUI(currentStep, newDraft.uis).ui,
        newDraft.platform,
        true,
      )
      getUI(currentStep, newDraft.uis).ui.layout_type = "custom"
      break
    case UPDATE_IN_NEW_STEP:
      getUI(currentStep, newDraft.uis).ui.ui = action.value
      break

    /**
     * Do not update this reducer until you're clear how it works first
     */
    case DELETE_STEP_FROM_CAMPAIGN:
      if (action.isExperiment) {
        newDraft.uis = newDraft.uis.filter(
          (eachUI) => eachUI.index !== action.index,
        )
        /**
         * `newDraft.uis`: Is already updated with the new UIs
         * `action.finalUIs`: Is the stale variant-specific UIs. We can only use this to determine the current step
         * `action.index`: Is the `index` key of the UI to be deleted
         * `action.finalUIs?.[0]`: Hence, even if the first UI is deleted, the `action.finalUIs?.[0]?.index` will not be `undefined` (since stale)
         */
        const deletingZeroth = action.finalUIs?.[0]?.index === action.index

        // If the first and only UI is deleted, then the current step should be -1
        if (action.finalUIs?.length === 1) {
          newDraft.currentStep = -1
        } else {
          // If the first UI is deleted, then the current step should be the second UI, else the first UI
          newDraft.currentStep = deletingZeroth
            ? (action.finalUIs?.[1]?.index ?? -1)
            : (action.finalUIs?.[0]?.index ?? -1)
        }
      } else {
        if (
          newDraft.uis.length === 1 &&
          newDraft.uis?.[0]?.ui?.action_class === ACTION_CLASSES.NUDGE &&
          newDraft.uis?.[0]?.ui?.display_type === "badge"
        ) {
          dispatch({ type: SET_FREQ_COUNT, count: 1 })
          dispatch({ type: SET_ENABLE_TIME_BASED })
          dispatch({ type: SET_GOAL_EVENT })
          dispatch({ type: SET_ENABLE_TOUCH_VALUE })
          dispatch({ type: SET_TERMINATE_INFO_ENABLE_ATTRIBUTES_VALUE })
        }
        newDraft.uis = newDraft.uis.filter((_, index) => index !== action.index)
        newDraft.currentStep = newDraft.uis.length < 1 ? -1 : 0
      }

      // Update the onboarding state in all UIs
      update_onboarding_state()
      break
    case SET_NEXT_STEP_DATA:
      newDraft.nextStep = action.value
      newDraft.nextStep.index = newDraft.uiIndex
      if (action.blobName && action.provider) {
        newDraft.nextStep.blobName = action.blobName
        newDraft.nextStep.provider = action.provider
      }
      break
    case ADD_NEXT_STEP_TO_CAMPAIGN:
      if (action.isInApp) {
        newDraft.uis.push(
          action?.isThemeEnabled
            ? {
                ...newDraft.nextStep,
                ui: { ...newDraft?.nextStep?.ui, theme: action?.theme },
              }
            : newDraft.nextStep,
        )
      } else {
        newDraft.uis.push({
          ...newDraft.nextStep,
          provider: action.provider,
          blobName: action.blobName,
          index: newDraft.nextStep.index,
          ui: {
            ...newDraft.nextStep.ui,
            ...action.value,
            ...(action?.isThemeEnabled && { theme: action?.theme }),
          },
        })
      }
      newDraft.currentStep = action.isExperiment
        ? newDraft.uiIndex
        : newDraft.uis.length - 1
      newDraft.uiIndex += 1
      newDraft.nextStep = {}

      // Update the onboarding state in all UIs
      update_onboarding_state()
      break
    case MOVE_STEP: {
      if (action.to === action.from) return
      let fromIndex = action.from
      let toIndex = action.to
      if (action.isExperiment) {
        fromIndex = newDraft.uis.findIndex((ui) => ui.index === action.from)
        toIndex = newDraft.uis.findIndex((ui) => ui.index === action.to)
      }
      newDraft.uis = arrayMove(newDraft.uis, fromIndex, toIndex)
      update_onboarding_state()
      break
    }
    case ON_COPY_STEP: {
      let temp_new = cloneDeep(
        getUI(currentStep, newDraft.uis, action?.isExperiment),
      )
      const id = generateShortID()
      if (temp_new?.ui) temp_new.ui._id = id
      newDraft.uis.push({
        ...temp_new,
        index: newDraft.uiIndex,
      })

      update_onboarding_state()

      newDraft.uiIndex += 1
      newDraft.nextStep = {}

      break
    }
    case SET_UIS_CONFIG:
      newDraft.uis = [...action.uis]
      newDraft.app_id = action.appId
      newDraft.uiIndex =
        action.uis.length > 0 ? action.uis[action.uis.length - 1].index + 1 : 0
      break
    case SET_WEB_VIEW:
      getUI(currentStep, newDraft.uis).ui.is_wv = action.value
      getUI(currentStep, newDraft.uis).ui.url = action.value
        ? "https://unpkg.com/apxor-rtm-ui"
        : undefined
      break
    case SET_WEB_VIEW_TAG:
      getUI(currentStep, newDraft.uis).ui.wv_tag = action.value
      break
    case SET_DELAY:
      getUI(currentStep, newDraft.uis).ui.delay = action.value
      break
    case SET_BACKGROUND_COLOR:
      getUI(currentStep, newDraft.uis).ui.bg_color = action.value
      if (getUI(currentStep, newDraft.uis).ui?.arrow)
        getUI(currentStep, newDraft.uis).ui.arrow.color = action.value
      break
    case SET_BORDER_RADIUS:
      if (!getUI(currentStep, newDraft.uis).ui.corners)
        getUI(currentStep, newDraft.uis).ui.corners = {
          enabled: true,
        }
      getUI(currentStep, newDraft.uis).ui.corners.width = action.value
      break
    case SET_MESSAGE_POSITION:
      getUI(currentStep, newDraft.uis).ui.position = action.value
      break
    case SET_BACKGROUND_OFFSET_SKID:
      if (!getUI(currentStep, newDraft.uis).ui?.pos_offset) {
        getUI(currentStep, newDraft.uis).ui.pos_offset = {
          ...BACKGROUND_OFFSET_CONFIG.pos_offset,
        }
      }
      getUI(currentStep, newDraft.uis).ui.pos_offset.skid = action.value
      break
    case SET_BACKGROUND_OFFSET_DISTANCE:
      if (!getUI(currentStep, newDraft.uis).ui?.pos_offset) {
        getUI(currentStep, newDraft.uis).ui.pos_offset = {
          ...BACKGROUND_OFFSET_CONFIG.pos_offset,
        }
      }
      getUI(currentStep, newDraft.uis).ui.pos_offset.distance = action.value
      break
    case SET_WIDTH_TYPE: {
      if (action.value === WIDTH_TYPES.CUSTOM)
        getUI(currentStep, newDraft.uis).ui.width = 60
      getUI(currentStep, newDraft.uis).ui.width_type = action.value
      break
    }
    case SET_WIDTH:
      getUI(currentStep, newDraft.uis).ui.width = action.value
      break
    case CHECK_AND_REMOVE_WIDTH:
      if (
        getUI(currentStep, newDraft.uis, action.isExperiment).ui.width_type &&
        getUI(currentStep, newDraft.uis, action.isExperiment).ui.width_type ===
          WIDTH_TYPES.AUTO
      ) {
        delete getUI(currentStep, newDraft.uis, action.isExperiment).ui.width
      } else if (
        getUI(currentStep, newDraft.uis, action.isExperiment).ui.width_type &&
        getUI(currentStep, newDraft.uis, action.isExperiment).ui.width_type ===
          WIDTH_TYPES.CUSTOM &&
        !getUI(currentStep, newDraft.uis, action.isExperiment).ui.width
      ) {
        getUI(currentStep, newDraft.uis, action.isExperiment).ui.width = 60
      }
      break
    case CHECK_AND_REMOVE_ELEMENT_HIGHLIGHTER:
      if (
        !getUI(currentStep, newDraft.uis, action.isExperiment).ui
          .dim_background ||
        getUI(currentStep, newDraft.uis, action.isExperiment).ui
          .dim_background === false
      ) {
        delete getUI(currentStep, newDraft.uis, action.isExperiment).ui
          .highlighter
      } else if (
        getUI(currentStep, newDraft.uis, action.isExperiment).ui
          .dim_background &&
        getUI(currentStep, newDraft.uis, action.isExperiment).ui
          .dim_background === true &&
        !getUI(currentStep, newDraft.uis, action.isExperiment).ui.highlighter
      ) {
        getUI(currentStep, newDraft.uis, action.isExperiment).ui.highlighter =
          DEFAULT_ELEMENT_HIGHLIGHTER_CONFIG.highlighter
      }
      break
    case SET_INAPP_POSITION:
      getUI(currentStep, newDraft.uis).ui.position.position = action.value
      break
    case SET_BACKGROUND_POSITION_OFFSET_DISTANCE:
      if (!getUI(currentStep, newDraft.uis).ui.position.enable_offset) {
        getUI(currentStep, newDraft.uis).ui.position.enable_offset = true
      }
      if (getUI(currentStep, newDraft.uis).ui.position.offset) {
        getUI(currentStep, newDraft.uis).ui.position.offset.distance =
          action.value
      }
      break
    case SET_BACKGROUND_POSITION_OFFSET_SKID:
      if (!getUI(currentStep, newDraft.uis).ui.position.enable_offset) {
        getUI(currentStep, newDraft.uis).ui.position.enable_offset = true
      }
      if (getUI(currentStep, newDraft.uis).ui.position.offset) {
        getUI(currentStep, newDraft.uis).ui.position.offset.skid = action.value
      }
      break
    case SET_DIM_BACKGROUND: {
      const enabled = action.value

      getUI(currentStep, newDraft.uis).ui.dim_background = enabled
      getUI(currentStep, newDraft.uis, isExperiment).ui.dimmed_background =
        enabled
      getUI(currentStep, newDraft.uis, isExperiment).ui.enable_dimbackground =
        enabled

      if (enabled) {
        getUI(currentStep, newDraft.uis).ui.dim_background_color = "#000000"
        getUI(currentStep, newDraft.uis, isExperiment).ui.dim_background_color =
          "#000000"
        getUI(currentStep, newDraft.uis).ui.dim_background_opacity = 0.8
        getUI(
          currentStep,
          newDraft.uis,
          isExperiment,
        ).ui.dim_background_opacity = 0.8
        getUI(currentStep, newDraft.uis).ui.highlighter =
          DEFAULT_ELEMENT_HIGHLIGHTER_CONFIG.highlighter
      } else {
        delete getUI(currentStep, newDraft.uis).ui.dim_background_color
        delete getUI(currentStep, newDraft.uis).ui.dim_background_opacity
        delete getUI(currentStep, newDraft.uis).ui.highlighter
      }
      break
    }
    case SET_DIM_BACKGROUND_COLOR: {
      let temp_new = cloneDeep(getUI(currentStep, newDraft.uis).ui)
      temp_new = {
        ...temp_new,
        dim_background_color: action.value,
        dimbackground: {
          ...temp_new?.dimbackground,
          color: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui = temp_new
      break
    }
    case SET_DIM_BACKGROUND_OPACITY: {
      let temp_new = cloneDeep(getUI(currentStep, newDraft.uis).ui)
      temp_new = {
        ...temp_new,
        dim_background_opacity: action.value,
        dimbackground: {
          ...temp_new?.dimbackground,
          opacity: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui = temp_new
      break
    }
    case SET_TOOLTIP_TEXT_TYPE:
      if (action.value === 0) {
        delete getUI(currentStep, newDraft.uis).ui["title_config"]
      } else if (action.value === 1) {
        getUI(currentStep, newDraft.uis).ui.title_config = {
          ...TEXT_CONFIG,
          size: 18,
          style: "bold",
          __id: generateShortID(),
        }
      }
      getUI(currentStep, newDraft.uis).ui.text_config = {
        ...TEXT_CONFIG,
        size: 16,
        __id: generateShortID(),
      }
      break

    // Close Button Actions
    case SET_CLOSE_BUTTON_ENABLED: {
      let temp_new = cloneDeep(getUI(currentStep, newDraft.uis).ui)
      temp_new = {
        ...temp_new,
        enable_close_button: action.value,
        close_button: {
          ...(!temp_new?.close_button
            ? { ...CLOSE_CONFIG.close_button }
            : {
                ...temp_new?.close_button,
                ...(!temp_new?.close_button.width ? { width: 20 } : {}),
                ...(!temp_new?.close_button.height ? { height: 20 } : {}),
              }),
          enable: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui = temp_new
      break
    }
    case SET_CLOSE_BUTTON_SHAPE:
      getUI(currentStep, newDraft.uis).ui.close_button.shape = action.value
      break
    case SET_CLOSE_BUTTON_COLOR:
      getUI(currentStep, newDraft.uis).ui.close_button.color = action.value
      break
    case SET_CLOSE_BUTTON_FILL_COLOR:
      getUI(currentStep, newDraft.uis).ui.close_button.fill_color = action.value
      break
    case SET_CLOSE_BUTTON_TYPE: {
      const closeBtnConfig = getUI(currentStep, newDraft.uis).ui.close_button
      if (action.value === "filled") {
        closeBtnConfig.color = "#017DFD"
        closeBtnConfig.fill_color = "#AFAAAA"
      }
      if (action.value !== "filled") {
        closeBtnConfig.color = "#000000"
        closeBtnConfig.fill_color = "transparent"
      }
      getUI(currentStep, newDraft.uis).ui.close_button.type = action.value
      break
    }
    case SET_CLOSE_BUTTON_PATH:
      getUI(currentStep, newDraft.uis).ui.close_button.path = action.value
      break
    case SET_CLOSE_BUTTON_OFFSET_TOP:
      getUI(currentStep, newDraft.uis).ui.close_button.offset_top = action.value
      break
    case SET_CLOSE_BUTTON_OFFSET_RIGHT:
      getUI(currentStep, newDraft.uis).ui.close_button.offset_right =
        action.value
      break
    case SET_CLOSE_BUTTON_HEIGHT:
      if (action.value === 0) {
        newDraft.moduleType = ""
        getUI(currentStep, newDraft.uis).ui.enable_close_button = false
      }
      getUI(currentStep, newDraft.uis).ui.close_button.height = action.value
      break
    case SET_CLOSE_BUTTON_WIDTH:
      if (action.value === 0) {
        newDraft.moduleType = ""
        getUI(currentStep, newDraft.uis).ui.enable_close_button = false
      }
      getUI(currentStep, newDraft.uis).ui.close_button.width = action.value
      break
    case SET_CLOSE_BUTTON_ACTION:
      getUI(currentStep, newDraft.uis).ui.close_button.action = action.value
      break

    // Pip Actions
    case SET_PIP_MODE:
      getUI(currentStep, newDraft.uis).ui.mode = action.value
      break

    // Pip Button Actions
    case SET_PIP_BUTTON_ENABLED: {
      let temp_new = cloneDeep(getUI(currentStep, newDraft.uis).ui)
      temp_new = {
        ...temp_new,
        enable_pip_button: action.value,
        pip_button: {
          ...(!temp_new?.pip_button
            ? { ...PIP_BUTTON_CONFIG.pip_button }
            : { ...temp_new?.pip_button }),
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui = temp_new
      break
    }
    case SET_PIP_BUTTON_SHAPE:
      getUI(currentStep, newDraft.uis).ui.pip_button.shape = action.value
      break
    case SET_PIP_BUTTON_COLOR:
      getUI(currentStep, newDraft.uis).ui.pip_button.color = action.value
      break
    case SET_PIP_BUTTON_FILL_COLOR:
      getUI(currentStep, newDraft.uis).ui.pip_button.fill_color = action.value
      break
    case SET_PIP_BUTTON_TYPE: {
      const pipBtnConfig = getUI(currentStep, newDraft.uis).ui.pip_button
      if (pipBtnConfig.type === "filled" && action.value !== "filled") {
        pipBtnConfig.color = PIP_BUTTON_CONFIG.pip_button.color
        pipBtnConfig.fill_color = PIP_BUTTON_CONFIG.pip_button.fill_color
      }
      getUI(currentStep, newDraft.uis).ui.pip_button.type = action.value
      break
    }
    case SET_PIP_BUTTON_PATH:
      getUI(currentStep, newDraft.uis).ui.pip_button.path = action.value
      break
    case SET_PIP_BUTTON_OFFSET_TOP:
      getUI(currentStep, newDraft.uis).ui.pip_button.offset_top = action.value
      break
    case SET_PIP_BUTTON_OFFSET_LEFT:
      getUI(currentStep, newDraft.uis).ui.pip_button.offset_left = action.value
      break
    case SET_PIP_BUTTON_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.pip_button.height = action.value
      break
    case SET_PIP_BUTTON_WIDTH:
      getUI(currentStep, newDraft.uis).ui.pip_button.width = action.value
      break

    // Element Highlighter Actions
    case SET_ELEMENT_HIGHLIGHTER_SHAPE: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.shape = action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_OFFSET_RADIUS: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.offset_radius =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_BORDER_COLOR: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.border.color =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_BORDER_WIDTH: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.border.width =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_BORDER_OPACITY: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.border.opacity =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_OFFSET_BOTTOM: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.offset.bottom =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_OFFSET_LEFT: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.offset.left =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_OFFSET_RIGHT: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.offset.right =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_OFFSET_TOP: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.offset.top =
          action.value
      break
    }
    case SET_ELEMENT_HIGHLIGHTER_CORNER_RADIUS: {
      if (getElementHighlighterConfig(currentStep, newDraft.uis))
        getUI(currentStep, newDraft.uis).ui.highlighter.corner_radius =
          action.value
      break
    }

    case SET_WEB_URL:
      getUI(currentStep, newDraft.uis).ui.web.url = action.value
      break
    case SET_BUTTON_PLACEMENT_MODE:
      newDraft.enable_button_placement_mode = action.value
      break
    case SET_BUTTONS_SLOTS_UPDATE_MODE:
      newDraft.enable_buttons_slots_update_mode = action.value
      break
    case SET_END_OF_VIDEO:
      newDraft.enable_end_of_video = action.value
      break
    case SET_ENABLE_PIP_MODE:
      newDraft.enable_pip_mode = action.value
      break
    case CHECK_AND_SET_ENABLE_PIP_MODE: {
      const mode = getUI(newDraft.currentStep, newDraft.uis)?.ui?.mode ?? "mini"

      if (mode === "PIP") {
        newDraft.enable_pip_mode = true
      } else {
        newDraft.enable_pip_mode = false
      }
      break
    }
    case SET_BUTTONS_SLOTS_COUNT:
      getUI(currentStep, newDraft.uis, isExperiment).ui.buttons_count =
        action.value
      break
    case SET_BUTTONS: {
      const { key } = action
      if (key === "buttons_config") {
        const { count, isActionButtons } = action

        if (count === 0) delete getUI(currentStep, newDraft.uis).ui[key]
        else {
          let buttons = []
          const existingButtons = getUI(currentStep, newDraft.uis).ui[key] ?? []
          if (existingButtons.length >= count)
            buttons = existingButtons.slice(0, count)
          else {
            for (let i = 0; i < count; i++) {
              buttons.push({
                ...(isActionButtons ? ACTION_BUTTON_CONFIG : BUTTON_CONFIG),
                __id: generateShortID(),
                ...(isActionButtons ? ACTION_BUTTON_CONFIG : BUTTON_CONFIG),
              })
            }
          }
          getUI(currentStep, newDraft.uis).ui[key] = buttons
        }
      } else if (key === "buttons") {
        const { row_name, slot, operation = "add" } = action

        if (operation === "delete") {
          const toBeDeleted = getUI(currentStep, newDraft.uis).ui.buttons[
            row_name
          ].findIndex((button) => button.slot === slot)
          getUI(currentStep, newDraft.uis, isExperiment).ui.buttons[
            row_name
          ].splice(toBeDeleted, 1)
        } else if (operation === "add") {
          getUI(currentStep, newDraft.uis, isExperiment).ui.buttons[
            row_name
          ].push({
            __id: generateShortID(),
            ...NEW_BUTTON_CONFIG,
            slot,
          })
        }
      }
      break
    }
    case SET_IMAGE_ONLY_REDIRECTION_CONFIG:
      getUI(currentStep, newDraft.uis).ui[action.buttonKeyName] = action.data
      break
    case SET_DISMISS_OUTSIDE_TOUCH: {
      getUI(currentStep, newDraft.uis).ui.dismiss_outside_touch = action.value
      let temp_new = cloneDeep(getUI(currentStep, newDraft.uis).ui)
      temp_new = {
        ...temp_new,
        dismiss_actions: {
          ...(!temp_new?.dismiss_actions
            ? {
                ...DISMISS_ACTIONS_CONFIG.dismiss_actions,
                outside_touch: action.value,
              }
            : { ...temp_new?.dismiss_actions, outside_touch: action.value }),
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui = temp_new
      break
    }
    case SET_DISMISS_OVERLAP:
      getUI(currentStep, newDraft.uis).ui.dismiss_on_overlap = action.value
      break
    case SET_ANIMATION_ENABLED:
      getUI(currentStep, newDraft.uis).ui.animation.enabled = action.value
      break
    case SET_ANIMATION_DURATION:
      getUI(currentStep, newDraft.uis).ui.animation.duration = action.value
      break
    case SET_ANIMATION_TYPE:
      getUI(currentStep, newDraft.uis).ui.animation.type = action.value
      break
    case SET_INLINE_ANIMATION_ENABLED:
      getUI(currentStep, newDraft.uis).ui.enable_animation = action.value
      break
    case SET_ARROW_ENABLED:
      getUI(currentStep, newDraft.uis).ui.enable_arrow = action.value
      break
    case SET_ARROW_TYPE:
      getUI(currentStep, newDraft.uis).ui.arrow.type = action.value
      break
    case SET_ARROW_COLOR:
      getUI(currentStep, newDraft.uis).ui.arrow.color = action.value
      break
    case SET_ARROW_BORDER_ENABLED:
      getUI(currentStep, newDraft.uis).ui.arrow.enable_border = action.value
      break
    case SET_ARROW_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.arrow.border.color = action.value
      break
    case SET_ARROW_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.arrow.border.radius = action.value
      break
    case SET_ARROW_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.arrow.border.width = action.value
      break
    case SET_ARROW_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.arrow.border.style = action.value
      break
    case SET_WEB_HTML_DATA:
      getUI(currentStep, newDraft.uis).ui.web.data = action.value
      break
    case SET_PASSIVE_NUDGE_ANIMATION_TYPE:
      getUI(currentStep, newDraft.uis).ui.nudge_config = {
        type: "dot",
        anim_type: action.value,
      }
      break
    case SET_CURRENT_STEP:
      newDraft.currentStep = action.value
      break

    // Font and Text Actions
    case SET_TEXT:
      getUI(currentStep, newDraft.uis).ui[keyName].text = action.value
      break
    case SET_COLOR:
      getUI(currentStep, newDraft.uis).ui[keyName].color = action.value
      break
    case SET_GRADIENT:
      getUI(currentStep, newDraft.uis).ui[keyName].gradient_config = {
        colors: action.value,
        type: "linear",
        angle: 180,
      }
      break
    case SET_FONT_FAMILY:
      getUI(currentStep, newDraft.uis).ui[keyName].font_family = action.value

      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(action.platform)) {
        getUI(currentStep, newDraft.uis).ui[keyName].font_type = action.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(action.platform)
      ) {
        getUI(currentStep, newDraft.uis).ui[keyName].font_source = action.source
      }

      break
    case SET_NEW_FONT_FAMILY:
      getUI(currentStep, newDraft.uis).ui[keyName].font.family = action.value

      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(action.platform)) {
        getUI(currentStep, newDraft.uis).ui[keyName].font.type = action.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(action.platform)
      ) {
        getUI(currentStep, newDraft.uis).ui[keyName].font.source = action.source
      }

      //ios and Omni
      if ([APP_PLATFORMS.ios, APP_PLATFORMS.omni].includes(action.platform)) {
        getUI(currentStep, newDraft.uis).ui[keyName].font.source = action.source
      }

      break
    case SET_FONT_SIZE:
      getUI(currentStep, newDraft.uis).ui[keyName].size = action.value
      break
    case SET_ADAPTIVE_TEXT:
      getUI(currentStep, newDraft.uis).ui[keyName].is_adaptive_text =
        action.value
      break
    case SET_NEW_FONT_SIZE:
      getUI(currentStep, newDraft.uis).ui[keyName].font.size = action.value
      break
    case SET_NEW_ADAPTIVE_TEXT:
      getUI(currentStep, newDraft.uis).ui[keyName].font.is_adaptive_text =
        action.value
      break
    case SET_FONT_STYLE:
      getUI(currentStep, newDraft.uis).ui[keyName].style = action.value
      break
    case SET_NEW_FONT_STYLE:
      getUI(currentStep, newDraft.uis).ui[keyName].font.style = action.value
      break
    case SET_NEW_FONT_WEIGHT:
      getUI(currentStep, newDraft.uis).ui[keyName].font.weight = action.value
      break
    case SET_TEXT_ALIGNMENT:
      getUI(currentStep, newDraft.uis).ui[keyName].alignment = action.value
      break
    case SET_NEW_TEXT_ALIGNMENT:
      getUI(currentStep, newDraft.uis).ui[keyName].alignment = action.value
      break

    // Old Button Actions
    case SET_BUTTON_TEXT:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].text = action.value
      }
      break
    case SET_BUTTON_BORDER_RADIUS:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].borders.radius = action.value
      }
      break
    case SET_BUTTON_COLOR:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].color = action.value
      }
      break
    case SET_BUTTON_PADDING:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].padding[action.direction] = action.value
      }
      break
    case SET_BUTTON_MARGIN:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].margin[action.direction] = action.value
      }
      break
    case SET_BUTTON_TEXT_PADDING:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].padding_config[action.direction] =
          action.value
      }
      break
    case SET_BUTTON_TEXT_MARGIN:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].margin_config[action.direction] = action.value
      }
      break

    case SET_BUTTON_DYNAMIC_TEXT_CONFIG:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        const confi = config[index][keyName[1]]
        const dynamicType = action.d_type
        const isDynamic =
          dynamicType === "u" || dynamicType === "v" || dynamicType === "s"

        if (!isDynamic) {
          delete confi.d_type
          delete confi.url
          delete confi.mvm
          delete confi.script
        } else {
          confi.d_type = dynamicType
          if (dynamicType === "v") {
            confi.mvm = action.data
            confi.url = ""
          } else if (dynamicType === "u") {
            confi.url = action.data
          } else if (dynamicType === "s") {
            confi.script = {
              cmds: action.data.commands,
              vmap: action.data.variableMap,
              src: action.data.originalScript,
              t: action.data.tab,
            }
          }
        }
        confi.text = isDynamic ? action.value : confi.text
        confi.is_dyn = isDynamic
      }
      break

    case SET_BUTTON_FONT_FAMILY:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].font_family = action.value

        // Web and Omni
        if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(action.platform)) {
          config[index][keyName[1]].font_type = action.kind
        }

        // Android and Omni
        if (
          [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(action.platform)
        ) {
          config[index][keyName[1]].font_source = action.source
        }
      }
      break
    case SET_BUTTON_FONT_SIZE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].size = action.value
      }
      break
    case SET_BUTTON_ADAPTIVE_TEXT:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].is_adaptive_text = action.value
      }
      break
    case SET_BUTTON_FONT_STYLE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index][keyName[1]].style = action.value
      }
      break
    case SET_BUTTON_BG_COLOR:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        if (Array.isArray(action.value)) {
          config[index].color = ""

          config[index]["gradient_config"] = {
            colors: action.value.map((eachObj) => eachObj.color),
            type: "linear",
            angle: 180,
            palette: action.value,
          }
        } else {
          config[index]["gradient_config"] &&
            delete config[index]["gradient_config"]
          config[index].color = action.value
        }
      }
      break
    case SET_BUTTON_ACTION_TYPE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].action_config.action = action.value
        if (action.value !== BTN_ACTION_ENUM.REDIRECT) {
          config[index].action_config.activity = ""
          config[index].action_config.uri = ""
        }
      }
      break
    case SET_BUTTON_REDIRECT_TYPE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].action_config.is_deep_link = action.value
      }
      break
    case SET_BUTTON_REDIRECT_ACTIVITY:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].action_config.activity = action.value
      }
      break
    case SET_BUTTON_REDIRECT_DEEPLINK:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].action_config.deep_link = {
          uri: action.value,
          intent_action: DEFAULT_INTENT_ACTION,
        }
      }
      break
    case SET_BUTTON_ACTION:
      ;[config, index] = getButtonConfigByModKey(
        newDraft,
        currentStep,
        keyName,
        action.buttonKeyName,
      )
      if (index > -1) {
        config[index].action_config = action.data
      }
      break
    case SET_BUTTON_IMAGE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].image = action.value
      }
      break

    // New Button Actions
    case SET_NEW_BUTTON_BG_COLOR:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].color = action.value
      }
      break
    case SET_NEW_BUTTON_PADDING:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1 && config[index].padding) {
        config[index].padding[action.direction] = action.value
      }
      break
    case SET_NEW_BUTTON_MARGIN:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1 && config[index].margin) {
        config[index].margin[action.direction] = action.value
      }
      break

    // New Button Text Actions
    case SET_NEW_BUTTON_TEXT:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].text.text = action.value
      }
      break
    case SET_NEW_BUTTON_TEXT_COLOR:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].text.color = action.value
      }
      break
    case SET_NEW_BUTTON_TEXT_PADDING:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1 && config[index].text.padding) {
        config[index].text.padding[action.direction] = action.value
      }
      break
    case SET_NEW_BUTTON_TEXT_MARGIN:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1 && config[index].text.margin) {
        config[index].text.margin[action.direction] = action.value
      }
      break

    // New Button Text Font Actions
    case SET_NEW_BUTTON_FONT_FAMILY:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].text.font.family = action.value

        // Web and Omni
        if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(action.platform)) {
          config[index].text.font.type = action.kind
        }

        // Android and Omni
        if (
          [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(action.platform)
        ) {
          config[index].text.font.source = action.source
        }
      }
      break
    case SET_NEW_BUTTON_FONT_SIZE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].text.font.size = action.value
      }
      break
    case SET_NEW_BUTTON_ADAPTIVE_TEXT:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].text.font.is_adaptive_text = action.value
      }
      break
    case SET_NEW_BUTTON_FONT_STYLE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].text.font.style = action.value
      }
      break
    case SET_NEW_BUTTON_FONT_WEIGHT:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        config[index].text.font.weight = action.value
      }
      break

    case SET_NEW_BUTTON_DYNAMIC_TEXT:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName[0],
        row_name,
      )
      if (index > -1) {
        const confi = config[index].text
        const dynamicType = action.d_type
        const isDynamic =
          dynamicType === "u" || dynamicType === "v" || dynamicType === "s"

        if (!isDynamic) {
          delete confi.d_type
          delete confi.url
          delete confi.mvm
          delete confi.script
        } else {
          confi.d_type = dynamicType
          if (dynamicType === "v") {
            confi.mvm = action.data
            confi.url = ""
          } else if (dynamicType === "u") {
            confi.url = action.data
          } else if (dynamicType === "s") {
            confi.script = {
              cmds: action.data.commands,
              vmap: action.data.variableMap,
              src: action.data.originalScript,
              t: action.data.tab,
            }
          }
        }
        confi.text = isDynamic ? action.value : confi.text
        confi.is_dyn = isDynamic
      }
      break

    // Action Config for New Buttons
    case SET_NEW_BUTTON_ACTION_ENABLED:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].enable_action = action.value
      }
      break
    case SET_NEW_BUTTON_ACTION_DATA:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].action = action.value
      }
      break
    case SET_NEW_BUTTON_ACTION_TYPE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].action.type = action.value
        config[index].action.action = action.value
        if (action.value !== "redirect") {
          config[index].action.url = ""
          config[index].action.is_external = false
          config[index].action.is_within = true
        } else {
          config[index].action.activity = ""
          config[index].action.is_deep_link = false
          config[index].action.deep_link = null
          config[index].action.internal_redirection = []
        }
      }
      break
    case SET_NEW_BUTTON_REDIRECT_TYPE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        if (action.value === "internal") {
          config[index].action.is_external = false
          config[index].action.is_within = true
        } else if (action.value === "external") {
          config[index].action.is_external = true
          config[index].action.is_within = false
        }
      }
      break
    case SET_NEW_BUTTON_REDIRECT_URL:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].action.url = action.value
      }
      break

    // New Button Border Actions
    case SET_NEW_BUTTON_BORDER_ENABLED:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].enable_border = action.value
      }
      break
    case SET_NEW_BUTTON_BORDER_COLOR:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].border.color = action.value
      }
      break
    case SET_NEW_BUTTON_BORDER_WIDTH:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].border.width = action.value
      }
      break
    case SET_NEW_BUTTON_BORDER_STYLE:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].border.style = action.value
      }
      break
    case SET_NEW_BUTTON_BORDER_RADIUS:
      ;[config, index] = getButtonConfig(
        newDraft,
        currentStep,
        keyName,
        row_name,
      )
      if (index > -1) {
        config[index].border.radius = action.value
      }
      break

    // Coachmark Actions
    case SET_COACHMARK_HAS_ARROW:
      getUI(currentStep, newDraft.uis).ui.coach_mark_details.has_arrow =
        action.value
      break
    case SET_COACHMARK_HAS_RIPPLE:
      getUI(currentStep, newDraft.uis).ui.coach_mark_details.has_ripple =
        action.value
      break
    case SET_COACHMARK_TYPE:
      getUI(currentStep, newDraft.uis).ui.coach_mark_details.type = action.value
      if (action.value === "circle")
        getUI(currentStep, newDraft.uis).ui.coach_mark_details.has_arrow = false
      break
    case SET_OPACITY:
      getUI(currentStep, newDraft.uis).ui.opacity = action.value
      break
    case SET_MODULE_TYPE:
      newDraft.moduleType = action.value
      break
    case SET_ROW_NAME:
      newDraft.row_name = action.value
      break
    case SET_SLOT_NUMBER:
      newDraft.slot = action.value
      break
    case SET_SHOW_CONFIGURATION_DRAWER:
      newDraft.showConfigurationDrawer = action.value
      break
    case SET_SHOW_WEB_VIEW_TEMPLATES:
      newDraft.showWebViewTemplates = action.payload
      break
    case SET_SHOW_CUSTOMIZE_TEMPLATE_CONFIRMATION:
      newDraft.showCustomizeTemplateConfirmation = action.value
      break
    case SET_SHOW_EDIT_SIMULATOR_BUTTON:
      newDraft.showEditSimulatorButton = action.value
      break
    case SET_BADGE_VARIANT:
      newDraft.selectedBadgeVariant = action.value
      break
    case SET_SHOW_QR_IN_VIEW_ID_PATH_SELECTION:
      newDraft.showQRinViewIdPathSelection = action.value
      break
    case SET_SHOW_QR_FOR_TEST_DEVICE_ADDITION:
      newDraft.showQRForTestDeviceAddition = action.value
      break
    case SET_PREVIEW_MODE:
      newDraft.preview_mode = action.value
      break
    case SET_BADGE_CAN_HORIZONTAL_AUTO_SCROLL_OPTION_SHOW:
      newDraft.can_horizontal_auto_scroll_option_show = action.value
      break
    case SET_IMAGE:
      getUI(currentStep, newDraft.uis).ui.image = action.value
      break
    case SET_TOOLTIP_IMAGE_TYPE:
      getUI(currentStep, newDraft.uis).ui.image_type = action.value
      break
    case SET_TOOLTIP_IMAGE_URL:
      getUI(currentStep, newDraft.uis).ui.image_url = action.value
      break
    case SET_IMAGE_SIZE:
      getUI(currentStep, newDraft.uis).ui.size = action.value
      break
    case SET_ONLY_IMAGE:
      getUI(currentStep, newDraft.uis).ui.only_image = action.value
      if (!action.value) {
        getUI(currentStep, newDraft.uis).ui.bg_image = ""
      }
      break
    case SET_TOUCH_URL:
      getUI(currentStep, newDraft.uis).ui.touch_redirect_url = action.value
      break
    case SET_BG_IMAGE_SIZE:
      getUI(currentStep, newDraft.uis).ui.image_size = action.value
      break
    case SET_DIMMED_BACKGROUND:
      getUI(currentStep, newDraft.uis).ui.window_attributes.dimmed_background =
        action.value
      break
    case SET_INAPP_DISMISS_OUTSIDE_TOUCH:
      getUI(
        currentStep,
        newDraft.uis,
      ).ui.window_attributes.dismiss_outside_touch = action.value
      break
    case SET_INAPP_HTML: {
      getUI(currentStep, newDraft.uis).ui.html_config.data = action.value

      const parser = new DOMParser()
      const doc = parser.parseFromString(action.value, "text/html")
      const imgElements = doc.querySelectorAll("img")
      const imageUrls = Array.from(imgElements).map((img) => ({
        url: img.src,
        format: "png",
      }))
      getUI(currentStep, newDraft.uis).ui.enable_download_files = true
      getUI(currentStep, newDraft.uis).ui.download_files = imageUrls
      break
    }
    case SET_INAPP_HTML_URL:
      getUI(currentStep, newDraft.uis).ui.html_config.url = action.value
      break
    case SET_MAXIMUM_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.html_config.height = action.value
      break
    case SET_BG_IMAGE:
      getUI(currentStep, newDraft.uis).ui.bg_image = action.value
      break
    case SET_ONBOARDING_MODE:
      newDraft.isOnboardingMode = action.value
      break

    // Video InApp Actions
    case SET_VIDEO_LINK:
      getUI(currentStep, newDraft.uis).ui.v_url = action.value
      break
    case SET_VALID_VIDEO_LINK:
      getUI(currentStep, newDraft.uis).ui.valid_video_url = action.value
      break
    case SET_VIDEO_HAS_AUDIO:
      getUI(currentStep, newDraft.uis).ui.add_audio = action.value
      if (!action.value) {
        getUI(currentStep, newDraft.uis).ui.mute_audio = true
      }
      break
    case SET_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.height = action.value
      break
    case SET_VIDEO_ACTIVITY:
      getUI(currentStep, newDraft.uis).ui.activity = action.value
      break
    case SET_VIDEO_CLOSE:
      getUI(currentStep, newDraft.uis).ui.add_close = action.value
      break
    case SET_VIDEO_AUTO_REPLAY:
      getUI(currentStep, newDraft.uis).ui.auto_replay = action.value
      break
    case SET_VIDEO_CAN_MINIMIZE:
      getUI(currentStep, newDraft.uis).ui.can_minimize = action.value
      break
    case SET_VIDEO_LOADING_TYPE:
      getUI(currentStep, newDraft.uis).ui.download = action.value
      break
    case SET_VIDEO_BINDING:
      getUI(currentStep, newDraft.uis).ui.binding = action.value
      if (action.value === false)
        getUI(currentStep, newDraft.uis).ui.activity = ""
      break
    case SET_VIDEO_PROGRESS_BAR:
      getUI(currentStep, newDraft.uis).ui.progress_bar = action.value
      break
    case SET_VIDEO_ISFULLSCREEN:
      getUI(currentStep, newDraft.uis).ui.is_full_scr = action.value
      break
    case SET_VIDEO_MUTE_AUDIO:
      getUI(currentStep, newDraft.uis).ui.mute_audio = action.value
      break
    case SET_VIDEO_BUTTON_COLOR:
      getUI(currentStep, newDraft.uis).ui.x_icon_color = action.value
      getUI(currentStep, newDraft.uis).ui.sound_icon_color = action.value
      getUI(currentStep, newDraft.uis).ui.mini_color = action.value
      break

    // Text Actions
    case SET_TITLE_ENABLED:
      getUI(currentStep, newDraft.uis).ui.enable_title = action.value
      break
    case SET_TITLE_MARGIN: {
      switch (action.direction) {
        case TITLE_OR_DESC_PADDING_MARGIN.BOTTOM:
          getUI(currentStep, newDraft.uis).ui.title.margin[action.direction] =
            action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.LEFT:
          getUI(currentStep, newDraft.uis).ui.title.margin[action.direction] =
            action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.RIGHT:
          getUI(currentStep, newDraft.uis).ui.title.margin[action.direction] =
            action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.TOP:
          getUI(currentStep, newDraft.uis).ui.title.margin[action.direction] =
            action.value
          break
        default:
          break
      }
      break
    }
    case SET_TITLE_PADDING: {
      switch (action.direction) {
        case TITLE_OR_DESC_PADDING_MARGIN.BOTTOM:
          getUI(currentStep, newDraft.uis).ui.title.padding[action.direction] =
            action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.LEFT:
          getUI(currentStep, newDraft.uis).ui.title.padding[action.direction] =
            action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.RIGHT:
          getUI(currentStep, newDraft.uis).ui.title.padding[action.direction] =
            action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.TOP:
          getUI(currentStep, newDraft.uis).ui.title.padding[action.direction] =
            action.value
          break
        default:
          break
      }
      break
    }
    case SET_DESCRIPTION_MARGIN: {
      switch (action.direction) {
        case TITLE_OR_DESC_PADDING_MARGIN.BOTTOM:
          getUI(currentStep, newDraft.uis).ui.description.margin[
            action.direction
          ] = action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.LEFT:
          getUI(currentStep, newDraft.uis).ui.description.margin[
            action.direction
          ] = action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.RIGHT:
          getUI(currentStep, newDraft.uis).ui.description.margin[
            action.direction
          ] = action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.TOP:
          getUI(currentStep, newDraft.uis).ui.description.margin[
            action.direction
          ] = action.value
          break
        default:
          break
      }
      break
    }
    case SET_DESCRIPTION_PADDING: {
      switch (action.direction) {
        case TITLE_OR_DESC_PADDING_MARGIN.BOTTOM:
          getUI(currentStep, newDraft.uis).ui.description.padding[
            action.direction
          ] = action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.LEFT:
          getUI(currentStep, newDraft.uis).ui.description.padding[
            action.direction
          ] = action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.RIGHT:
          getUI(currentStep, newDraft.uis).ui.description.padding[
            action.direction
          ] = action.value
          break
        case TITLE_OR_DESC_PADDING_MARGIN.TOP:
          getUI(currentStep, newDraft.uis).ui.description.padding[
            action.direction
          ] = action.value
          break
        default:
          break
      }
      break
    }
    case SET_DESCRIPTION_ENABLED:
      getUI(currentStep, newDraft.uis).ui.enable_description = action.value
      break
    case SET_MODAL_DIRECTION:
      getUI(currentStep, newDraft.uis).ui.direction.nudge = action.value
      break
    case SET_MODAL_NONMEDIA_DIRECTION:
      getUI(currentStep, newDraft.uis).ui.direction.non_media = action.value
      break

    // Scrollable Actions
    case SET_SCROLLABLE:
      getUI(currentStep, newDraft.uis).ui.enable_scroll = action.value
      break
    case SET_SCROLLABLE_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.scroll.height = action.value
      break
    case SET_SCROLLABLE_BORDER:
      getUI(currentStep, newDraft.uis).ui.scroll.enable_border = action.value
      break
    case SET_SCROLLABLE_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.scroll.border.width = action.value
      break
    case SET_SCROLLABLE_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.scroll.border.color = action.value
      break
    case SET_SCROLLABLE_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.scroll.border.style = action.value
      break
    case SET_SCROLLABLE_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.scroll.border.radius = action.value
      break

    // WebSDK InApp Modals Border Actions :
    case SET_NEW_BORDER_ENABLED:
      getUI(currentStep, newDraft.uis).ui.enable_border = action.value
      break
    case SET_NEW_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.border.color = action.value
      break
    case SET_NEW_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.border.width = action.value
      break
    case SET_NEW_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.border.radius = action.value
      break
    case SET_NEW_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.border.style = action.value
      break

    // Shadow Actions :
    case SET_SHADOW_ENABLED:
      getUI(currentStep, newDraft.uis).ui.enable_shadow = action.value
      break
    case SET_SHADOW_COLOR:
      getUI(currentStep, newDraft.uis).ui.shadow.color = action.value
      break
    case SET_SHADOW_OFFSET_X:
      getUI(currentStep, newDraft.uis).ui.shadow.offset_x = action.value
      break
    case SET_SHADOW_OFFSET_Y:
      getUI(currentStep, newDraft.uis).ui.shadow.offset_y = action.value
      break
    case SET_SHADOW_BLUR_RADIUS:
      getUI(currentStep, newDraft.uis).ui.shadow.blur_radius = action.value
      break
    case SET_SHADOW_SPREAD_RADIUS:
      getUI(currentStep, newDraft.uis).ui.shadow.spread_radius = action.value
      break

    // New Padding Actions :
    case SET_MODAL_PADDING_TOP:
      getUI(currentStep, newDraft.uis).ui.padding.top = action.value
      break
    case SET_MODAL_PADDING_BOTTOM:
      getUI(currentStep, newDraft.uis).ui.padding.bottom = action.value
      break
    case SET_MODAL_PADDING_LEFT:
      getUI(currentStep, newDraft.uis).ui.padding.left = action.value
      break
    case SET_MODAL_PADDING_RIGHT:
      getUI(currentStep, newDraft.uis).ui.padding.right = action.value
      break

    // New Margin Actions :
    case SET_MODAL_MARGIN_TOP:
      getUI(currentStep, newDraft.uis).ui.margin.top = action.value
      break
    case SET_MODAL_MARGIN_BOTTOM:
      getUI(currentStep, newDraft.uis).ui.margin.bottom = action.value
      break
    case SET_MODAL_MARGIN_LEFT:
      getUI(currentStep, newDraft.uis).ui.margin.left = action.value
      break
    case SET_MODAL_MARGIN_RIGHT:
      getUI(currentStep, newDraft.uis).ui.margin.right = action.value
      break

    case SET_MODAL_MEDIA_TYPE: {
      switch (action.value) {
        case MEDIA_TYPES_ENUM.ICON:
          getUI(currentStep, newDraft.uis).ui.enable_icon = true
          getUI(currentStep, newDraft.uis).ui.enable_image = false
          getUI(currentStep, newDraft.uis).ui.enable_video = false
          break
        case MEDIA_TYPES_ENUM.IMAGE:
          getUI(currentStep, newDraft.uis).ui.enable_icon = false
          getUI(currentStep, newDraft.uis).ui.enable_image = true
          getUI(currentStep, newDraft.uis).ui.enable_video = false
          break
        case MEDIA_TYPES_ENUM.VIDEO:
          getUI(currentStep, newDraft.uis).ui.enable_icon = false
          getUI(currentStep, newDraft.uis).ui.enable_image = false
          getUI(currentStep, newDraft.uis).ui.enable_video = true
          break
        case MEDIA_TYPES_ENUM.NONE:
          getUI(currentStep, newDraft.uis).ui.enable_icon = false
          getUI(currentStep, newDraft.uis).ui.enable_image = false
          getUI(currentStep, newDraft.uis).ui.enable_video = false
          getUI(currentStep, newDraft.uis).ui.direction.nudge = "vertical"
          break
        default:
          break
      }

      break
    }
    case SET_MODAL_WIDTH:
      getUI(currentStep, newDraft.uis).ui.width = action.value
      break

    // Title Icon Actions
    case SET_TITLE_ICON_ENABLED:
      getUI(currentStep, newDraft.uis).ui.title.enable_icon = action.value
      break
    case SET_TITLE_ICON_PATH:
      getUI(currentStep, newDraft.uis).ui.title.icon.path = action.value

      break
    case SET_TITLE_ICON_WIDTH:
      getUI(currentStep, newDraft.uis).ui.title.icon.width = action.value
      break
    case SET_TITLE_ICON_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.title.icon.height = action.value
      break
    case SET_TITLE_ICON_BORDER_ENABLED:
      getUI(currentStep, newDraft.uis).ui.title.icon.enable_border =
        action.value
      break
    case SET_TITLE_ICON_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.title.icon.border.width = action.value
      break
    case SET_TITLE_ICON_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.title.icon.border.color = action.value
      break
    case SET_TITLE_ICON_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.title.icon.border.radius =
        action.value
      break
    case SET_TITLE_ICON_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.title.icon.border.style = action.value
      break

    // Media Icon Actions
    case SET_MEDIA_ICON_PATH:
      getUI(currentStep, newDraft.uis).ui.icon.path = action.value
      break
    case SET_MEDIA_ICON_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.icon.height = action.value
      break
    case SET_MEDIA_ICON_WIDTH:
      getUI(currentStep, newDraft.uis).ui.icon.width = action.value
      break
    case SET_MEDIA_ICON_ALIGNMENT:
      getUI(currentStep, newDraft.uis).ui.icon.alignment = action.value
      break
    case SET_MEDIA_ICON_BORDER_ENABLED:
      getUI(currentStep, newDraft.uis).ui.icon.enable_border = action.value
      break
    case SET_MEDIA_ICON_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.icon.border.width = action.value
      break
    case SET_MEDIA_ICON_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.icon.border.radius = action.value
      break
    case SET_MEDIA_ICON_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.icon.border.color = action.value
      break
    case SET_MEDIA_ICON_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.icon.border.style = action.value
      break

    // Media Image Actions
    case SET_MEDIA_IMAGE_PATH:
      getUI(currentStep, newDraft.uis).ui.image.path = action.value
      break
    case SET_MEDIA_IMAGE_WIDTH:
      getUI(currentStep, newDraft.uis).ui.image.width = action.value
      break
    case SET_MEDIA_IMAGE_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.image.height = action.value
      break
    case SET_MEDIA_IMAGE_BORDER_ENABLED:
      getUI(currentStep, newDraft.uis).ui.image.enable_border = action.value
      break
    case SET_MEDIA_IMAGE_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.image.border.width = action.value
      break
    case SET_MEDIA_IMAGE_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.image.border.radius = action.value
      break
    case SET_MEDIA_IMAGE_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.image.border.color = action.value
      break
    case SET_MEDIA_IMAGE_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.image.border.style = action.value
      break
    case SET_MEDIA_IMAGE_ACTION_ENABLED:
      getUI(currentStep, newDraft.uis).ui.image.enable_action = action.value
      break
    case SET_MEDIA_IMAGE_ACTION:
      getUI(currentStep, newDraft.uis).ui.image.action = action.value
      break

    // Media Video Actions
    case SET_MEDIA_VIDEO_PATH:
      getUI(currentStep, newDraft.uis).ui.video.path = action.value
      break
    case SET_MEDIA_VIDEO_WIDTH:
      getUI(currentStep, newDraft.uis).ui.video.width = action.value
      break
    case SET_MEDIA_VIDEO_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.video.height = action.value
      break
    case SET_MEDIA_VIDEO_BORDER_ENABLED:
      getUI(currentStep, newDraft.uis).ui.video.enable_border = action.value
      break
    case SET_MEDIA_VIDEO_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.video.border.width = action.value
      break
    case SET_MEDIA_VIDEO_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.video.border.radius = action.value
      break
    case SET_MEDIA_VIDEO_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.video.border.color = action.value
      break
    case SET_MEDIA_VIDEO_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.video.border.style = action.value
      break
    case SET_MEDIA_VIDEO_EMBED_ENABLED:
      getUI(currentStep, newDraft.uis).ui.video.enable_embed = action.value
      getUI(currentStep, newDraft.uis).ui.video.enable_border = false
      getUI(currentStep, newDraft.uis).ui.video.enable_audio = false
      if (action.value) getUI(currentStep, newDraft.uis).ui.video.path = ""

      break
    case SET_MEDIA_VIDEO_EMBED_HTML:
      getUI(currentStep, newDraft.uis).ui.video.embed.html =
        action.value.replaceAll('"', "'")
      break
    case SET_MEDIA_VIDEO_AUDIO_ENABLED:
      getUI(currentStep, newDraft.uis).ui.video.enable_audio = action.value
      break

    // Onboarding Actions
    case SET_ONBOARDING_PAGINATION_TYPE:
      newDraft.onboardingState.carousal.dots = action.dots
      newDraft.onboardingState.has_counter = action.counter
      newDraft.onboardingState.carousal.counter = action.counter
      newDraft.uis.forEach((i) => {
        i.ui.carousal.dots = action.dots
        i.ui.has_counter = action.counter
        i.ui.carousal.counter = action.counter
      })
      if (!action.counter) {
        delete newDraft.onboardingState.counter
      } else if (!newDraft.onboardingState.counter) {
        newDraft.onboardingState.counter = INITIAL_ONBOARDING_STATE.counter
      }
      break
    case SET_ONBOARDING_HAS_COUNTER:
      newDraft.onboardingState.has_counter = action.value
      newDraft.uis.map((i) => (i.ui.has_counter = action.value))
      break
    case SET_ONBOARDING_HAS_DOTS:
      newDraft.onboardingState.carousal.dots = action.value
      newDraft.uis.map((i) => (i.ui.carousal.dots = action.value))
      break
    case SET_ONBOARDING_PAGINATION_COUNTER_TYPE:
      newDraft.onboardingState.counter.counter_style = action.value

      break
    case SET_ONBOARDING_DISMISS_TYPE:
      newDraft.onboardingState.carousal.d_type = action.value
      break
    case SET_ONBOARDING_TOP_PAD:
      getUI(currentStep, newDraft.uis).ui.carousal.d_pad.top = action.value
      break
    case SET_ONBOARDING_BOTTOM_PAD:
      getUI(currentStep, newDraft.uis).ui.carousal.d_pad.bottom = action.value
      break
    case SET_ONBOARDING_LEFT_PAD:
      getUI(currentStep, newDraft.uis).ui.carousal.d_pad.left = action.value
      break
    case SET_ONBOARDING_RIGHT_PAD:
      getUI(currentStep, newDraft.uis).ui.carousal.d_pad.right = action.value
      break
    case SET_ONBOARDING_HORIZONTAL_MARGIN:
      newDraft.onboardingState.carousal.m_margin.hz = action.value
      getUI(currentStep, newDraft.uis).ui.carousal.m_margin.hz = action.value
      break
    case SET_ONBOARDING_VERTICAL_MARGIN:
      newDraft.onboardingState.carousal.m_margin.vr = action.value
      getUI(currentStep, newDraft.uis).ui.carousal.m_margin.vr = action.value
      break
    case SET_ONBOARDING_ANIM_TYPE:
      newDraft.onboardingState.anim_type = action.value
      newDraft.uis.forEach((i) => (i.ui.anim_type = action.value))
      break
    case SET_ONBOARDING_ANIM_DURATION:
      newDraft.onboardingState.duration = action.value
      newDraft.uis.forEach((i) => (i.ui.duration = action.value))
      break
    case SET_ONBOARDING_FADEOUT_OPACITY:
      newDraft.onboardingState.carousal.f_alpha = action.value
      break
    case SET_ONBOARDING_DOTS_ACTIVE_COLOR:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.carousal.d_acolor = action.value))
      newDraft.onboardingState.carousal.d_acolor = action.value
      break
    case SET_ONBOARDING_DOTS_BOTTOM_DIST:
      if (newDraft.uis.length > 0)
        newDraft.uis.map(
          (item) => (item.ui.carousal.bottom_dist = action.value),
        )
      newDraft.onboardingState.carousal.bottom_dist = action.value
      break
    case SET_ONBOARDING_DOTS_OPACITY:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.carousal.d_opacity = action.value))
      newDraft.onboardingState.carousal.d_opacity = action.value
      break
    case SET_ONBOARDING_DOTS_RADIUS:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.carousal.radius = action.value))
      newDraft.onboardingState.carousal.radius = action.value
      break
    case SET_ONBOARDING_DOTS_DISTANCE:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.carousal.dots_dist = action.value))
      newDraft.onboardingState.carousal.dots_dist = action.value
      break
    case SET_ONBOARDING_DOTS_INACTIVE_COLOR:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.carousal.d_icolor = action.value))
      newDraft.onboardingState.carousal.d_icolor = action.value
      break
    case SET_ONBOARDING_SKIP_RIGHT:
      getUI(currentStep, newDraft.uis).ui.skip.left_dist = action.value
      break
    case SET_ONBOARDING_SKIP_TOP:
      getUI(currentStep, newDraft.uis).ui.carousal.skip_top = action.value
      break
    case SET_ONBOARDING_TOOLTIP_MARGIN:
      getUI(currentStep, newDraft.uis).ui.carousal.msg_margins[action.index] =
        action.value
      break
    case SET_ONBOARDING_COUNTER_FONT_FAMILY:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.counter.font_family = action.value))
      break
    case SET_ONBOARDING_COUNTER_TEXT_SIZE:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.counter.size = action.value))
      break
    case SET_ONBOARDING_COUNTER_FONT_STYLE:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.counter.style = action.value))
      break
    case SET_ONBOARDING_COUNTER_COLOR:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.counter.color = action.value))
      break
    case SET_ONBOARDING_COUNTER_TEXT:
      getUI(currentStep, newDraft.uis).ui.counter.text = action.value
      break
    case SET_ONBOARDING_COUNTER_BOTTOM_DISTANCE:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.counter.bottom_dist = action.value))
      break
    case SET_ONBOARDING_COUNTER_LEFT_DISTANCE:
      if (newDraft.uis.length > 0)
        newDraft.uis.map((item) => (item.ui.counter.left_dist = action.value))
      break
    case SET_ONBOARDING_SKIP_ENABLED:
      newDraft.onboardingState.skipEnabled = action.value
      if (action.value) {
        newDraft.uis.forEach((eachUi, index) => {
          eachUi.ui.skip = { ...SKIP_BUTTON_STATE.skip }
          eachUi.ui.skipEnabled = true
        })
      } else {
        newDraft.uis.forEach((eachUi, index) => {
          delete eachUi?.ui?.skip
          eachUi.ui.skipEnabled = false
        })
      }
      update_onboarding_state()
      break
    case SET_ONBOARDING_DIVIDER_ENABLED:
      newDraft.onboardingState.carousal.has_divider = action.value
      newDraft.uis.forEach((eachUi) => {
        eachUi.ui.carousal.has_divider = action.value
      })
      update_onboarding_state()
      break
    case SET_ONBOARDING_DISMISS_ACTION:
      newDraft.onboardingState.carousal.d_type = action.value

      newDraft.uis.forEach((eachUi) => {
        eachUi.ui.carousal.d_type = action.value
      })
      break
    case SET_ONBOARDING_CORNER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.corners.width = action.value
      break
    case SET_ONBOARDING_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.border.width = action.value
      break
    case SET_ONBOARDING_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.border.color = action.value
      break
    case SET_ONBOARDING_DIVIDER_HEIGHT:
      newDraft.onboardingState.carousal.divider_height = action.value
      newDraft.uis.forEach((eachUi) => {
        eachUi.ui.carousal.divider_height = action.value
      })
      break
    case SET_ONBOARDING_DIVIDER_COLOR:
      newDraft.onboardingState.carousal.divider_color = action.value
      newDraft.uis.forEach((eachUi) => {
        eachUi.ui.carousal.divider_color = action.value
      })
      break
    case SET_ONBOARDING_DIVIDER_MARGIN:
      newDraft.onboardingState.carousal.divider_margins[action.index] =
        action.value
      newDraft.uis.forEach((eachUi) => {
        eachUi.ui.carousal.divider_margins[action.index] = action.value
      })
      break
    case SET_ONBOARDING_NEXT_PREV_ENABLED:
      if (action.value) {
        newDraft.onboardingState.nextPrevEnabled = action.value
        newDraft.uis.forEach((eachUi) => {
          if (eachUi.ui.layout_type === "default") {
            eachUi.ui.next = { ...NEXT_BUTTON_STATE.next }
            eachUi.ui.prev = { ...PREV_BUTTON_STATE.prev }
          } else {
            eachUi.ui.next = { ...NEXT_BUTTON_STATE_FOR_CAROUSAL.next }
            eachUi.ui.prev = { ...PREV_BUTTON_STATE_FOR_CAROUSAL.prev }
          }
          eachUi.ui.nextPrevEnabled = true
        })
      } else {
        newDraft.onboardingState.nextPrevEnabled = action.value
        newDraft.uis.forEach((eachUi) => {
          delete eachUi?.ui?.next
          delete eachUi?.ui?.prev
          eachUi.ui.nextPrevEnabled = false
        })
      }
      update_onboarding_state()
      break
    case SET_ONBOARDING_LAST_STEP_TEXT:
      newDraft.onboardingState.lastStepText = action.value
      break
    case SET_ONBOARDING_BTN_TEXT:
      getUI(currentStep, newDraft.uis).ui[action.keyName].text = action.value
      break
    case SET_ONBOARDING_BTN_COLOR:
      getUI(currentStep, newDraft.uis).ui[action.keyName].color = action.value
      break
    case SET_ONBOARDING_BTN_BACKGROUND_COLOR:
      getUI(currentStep, newDraft.uis).ui[action.keyName].bg_color =
        action.value
      break
    case SET_ONBOARDING_BACKGROUND_COLOR:
      getUI(currentStep, newDraft.uis).ui.bg_color = action.value
      break
    case SET_ONBOARDING_ACTION_BUTTON_MARGIN:
      getUI(currentStep, newDraft.uis).ui[action.keyName].margins = action.value
      break
    case SET_ONBOARDING_PREV_NEXT_DIST:
      getUI(currentStep, newDraft.uis).ui[action.actionType][action.key] =
        action.value
      break
    case SET_ONBOARDING_BTN_FONT_FAMILY:
      getUI(currentStep, newDraft.uis).ui[action.keyName].font_family =
        action.value
      break
    case SET_ONBOARDING_BTN_FONT_SIZE:
      getUI(currentStep, newDraft.uis).ui[action.keyName].size = action.value
      break
    case SET_ONBOARDING_BTN_ADAPTIVE_TEXT:
      getUI(currentStep, newDraft.uis).ui[action.keyName].is_adaptive_text =
        action.value
      break
    case SET_ONBOARDING_BTN_FONT_STYLE:
      getUI(currentStep, newDraft.uis).ui[action.keyName].style = action.value
      break
    case SET_ONBOARDING_TEXT_MARGIN:
      getUI(currentStep, newDraft.uis).ui[action.keyName].margins[action.idx] =
        action.value
      break
    case SET_ONBOARDING_TEXT_PADDING:
      getUI(currentStep, newDraft.uis).ui[action.keyName].paddings[action.idx] =
        action.value
      break
    case SET_ONBOARDING_STATE:
      newDraft.onboardingState = action.value || INITIAL_ONBOARDING_STATE
      newDraft.isOnboardingMode = true
      update_onboarding_state(true)
      break
    case UPDATE_ONBOARDING_STATE_IN_UIS:
      update_onboarding_state()
      break
    case SET_DYNAMIC_TEXT_CONFIG: {
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      config = getUI(currentStep, newDraft.uis).ui[keyName]
      if (!isDynamic) {
        delete config.d_type
        delete config.url
        delete config.mvm
        delete config.script
      } else {
        config.d_type = dynamicType
        if (dynamicType === "v") {
          config.mvm = action.data
          config.url = ""
        } else if (dynamicType === "u") {
          config.url = action.data
        } else if (dynamicType === "s") {
          config.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      config.text = isDynamic ? action.value : config.text
      config.is_dyn = isDynamic
      break
    }
    case SET_PREVIEW_DATA:
      Object.keys(action.value).map(
        (key) => (getUI(currentStep, newDraft.uis).ui[key] = action.value[key]),
      )
      break
    case SET_BORDER_BOTTOM_RADIUS:
      getUI(currentStep, newDraft.uis).ui.border_radius = action.value
      break
    case SET_PLATFORM:
      newDraft.platform = action.value
      break
    case SET_CURRENT_IMAGE:
      newDraft.currentImage = action.value
      break
    case SET_IMG:
      /**
       * This Mechanism of storing images by index will not work for A/B
       *
       * Needs to be re-written to store key value pairs based on uiIndex
       */
      newDraft.img[currentStep] = action.value
      break
    case SET_LAYOUT:
      newDraft.layout = action.value
      break
    case SET_TREEVIEW:
      newDraft.treeView = action.value
      break
    case SET_ORIENTATION:
      newDraft.orientation = action.value
      break
    case SET_SELECTED_HTML_VIEW:
      newDraft.selectedHTMLView = action.value
      break
    case SET_HTML_SCRIPT_CONFIG: {
      const { scriptName, tab, cmds, script, vmap } = action
      html_config = getUI(currentStep, newDraft.uis).ui.html_config
      html_config.has_scripts = true

      if (!html_config.script) {
        html_config.script = JSON.parse(
          JSON.stringify(DEFAULT_HTML_SCRIPT_JSON),
        )
      }

      const currentKey = html_config.script.keys.find((c) => c === scriptName)

      if (!currentKey) {
        html_config.script.keys.push(scriptName)
      }

      html_config.script[scriptName] = cmds
      html_config.script.srcs = {
        ...html_config.script.srcs,
        [scriptName]: {
          t: tab,
          src: script,
        },
      }
      html_config.script.vmap = {
        ...html_config.script.vmap,
        ...vmap,
      }
      break
    }
    case REMOVE_SNIPPET_FROM_HTML_CONFIG: {
      html_config = getUI(currentStep, newDraft.uis).ui.html_config

      let srcName = ""

      if (action.scriptName.includes("-")) {
        srcName = html_config.script.srcs[action.scriptName].src
          .replace("{", "")
          .replace("}", "")

        // Remove `scriptName` key from `srcs` object
        html_config.script.srcs = Object.keys(html_config.script.srcs).reduce(
          (obj, key) => {
            if (key !== action.scriptName) {
              obj[key] = html_config.script.srcs[key]
            }
            return obj
          },
          {},
        )
      }

      // Remove `scriptName` from `keys` array
      html_config.script.keys = html_config.script.keys.filter(
        (key) => key !== action.scriptName,
      )

      // Remove `scriptName` key from `html_config.script` object
      html_config.script = Object.keys(html_config.script).reduce(
        (obj, key) => {
          if (key !== action.scriptName) {
            obj[key] = html_config.script[key]
          }
          return obj
        },
        {},
      )

      // Remove `scriptName` key from `vmap` object
      html_config.script.vmap = Object.keys(html_config.script.vmap).reduce(
        (obj, key) => {
          if (key !== srcName) {
            obj[key] = html_config.script.vmap[key]
          }
          return obj
        },
        {},
      )

      if (html_config.script.keys.length < 1) {
        html_config.has_scripts = false
        delete html_config.script
      }

      break
    }
    case SET_HTML_ACTION_CONFIG:
      getUI(currentStep, newDraft.uis).ui.action_config = action.value
      break
    case SET_VIEWID_SELECTION_DIALOG_DATA:
      if (currentStep >= 0) {
        getUI(currentStep, newDraft.uis).ui = {
          ...getUI(currentStep, newDraft.uis).ui,
          ...action.options.value,
        }
        getUI(currentStep, newDraft.uis).provider = action.options.provider
        getUI(currentStep, newDraft.uis).blobName = action.options.blobName
      }
      break
    case SET_CAROUSEL_COUNT:
      if (getUI(currentStep, newDraft.uis).ui.carousel)
        getUI(currentStep, newDraft.uis).ui.carousel.count = action.value
      else
        getUI(currentStep, newDraft.uis).ui.carousel = {
          count: action.value,
        }
      break
    case SET_CAROUSEL_IMAGES:
      if (getUI(currentStep, newDraft.uis).ui.carousel)
        getUI(currentStep, newDraft.uis).ui.carousel.images
          ? (getUI(currentStep, newDraft.uis).ui.carousel.images[
              action.index - 1
            ] = action.value)
          : (getUI(currentStep, newDraft.uis).ui.carousel.images = [
              action.value,
            ])
      else
        getUI(currentStep, newDraft.uis).ui.carousel = {
          count: 1,
          images: [action.value],
        }
      break
    case UNSET_CAROUSAL_IMAGE:
      if (
        !!getUI(currentStep, newDraft.uis).ui.carousel &&
        !!getUI(currentStep, newDraft.uis).ui.carousel.images
      )
        getUI(currentStep, newDraft.uis).ui.carousel.images.pop()
      break
    case SET_EXPERIMENT_STATE:
      newDraft.isExperiment = action.value
      break

    case SET_REFRESH_STATE:
      newDraft.isRefresh = action.value
      break
    // Badge Variant Actions
    case ADD_BADGE_VARIANT:
      getUI(currentStep, newDraft.uis).ui.badge.content.push(
        ADD_BADGE_VARIANT_CONFIG,
      )
      break
    case CLONE_BADGE_VARIANT: {
      const badgeVariant = getUI(currentStep, newDraft.uis).ui.badge.content[
        action.value
      ]
      getUI(currentStep, newDraft.uis).ui.badge.content.push({
        ...badgeVariant,
      })
      break
    }
    case DELETE_BADGE_VARIANT:
      getUI(currentStep, newDraft.uis).ui.badge.content = getUI(
        currentStep,
        newDraft.uis,
      ).ui.badge.content?.filter((_, idx) => idx !== action.value)
      break

    // Badge Position
    case SET_BADGE_POSITION:
      getUI(currentStep, newDraft.uis).ui.badge.position.position = action.value
      break
    case SET_BADGE_OFFSET:
      getUI(currentStep, newDraft.uis).ui.badge.position.offset[
        action.direction
      ] = action.value
      break

    // Badge Background
    case SET_BADGE_ENABLE_BACKGROUND:
      getUI(currentStep, newDraft.uis).ui.badge.enable_background = action.value
      break
    case SET_BADGE_BACKGROUND_WIDTH:
      getUI(currentStep, newDraft.uis).ui.badge.background.width = action.value
      break
    case SET_BADGE_BACKGROUND_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.badge.background.height = action.value
      break
    case SET_BADGE_BACKGROUND_SHAPE_TYPE:
      if (
        getUI(currentStep, newDraft.uis).ui.badge.background.shape.type ===
          "rectangle" &&
        action.value === "circle"
      ) {
        let directions = ["top", "left", "bottom", "right"]
        directions.map(
          (direction) =>
            (getUI(
              currentStep,
              newDraft.uis,
            ).ui.badge.background.shape.border.width[direction] = 0),
        )
      }
      getUI(currentStep, newDraft.uis).ui.badge.background.shape.type =
        action.value
      break
    case SET_BADGE_BACKGROUND_SHAPE_COLOR:
      getUI(currentStep, newDraft.uis).ui.badge.background.shape.color =
        action.value
      break

    // Badge Background Image
    case SET_BADGE_BACKGROUND_ENABLE_IMAGE:
      getUI(currentStep, newDraft.uis).ui.badge.background.enable_image =
        action.value
      break
    case SET_BADGE_BACKGROUND_IMAGE_TYPE:
      getUI(currentStep, newDraft.uis).ui.badge.background.image.type =
        action.value
      break
    case SET_BADGE_BACKGROUND_IMAGE_PATH:
      getUI(currentStep, newDraft.uis).ui.badge.background.image.path =
        action.value
      break

    // Badge Background Border
    case SET_BADGE_BACKGROUND_SHAPE_ENABLE_BORDER:
      getUI(currentStep, newDraft.uis).ui.badge.background.shape.enable_border =
        action.value
      break
    case SET_BADGE_BACKGROUND_SHAPE_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.badge.background.shape.border.color =
        action.value
      break
    case SET_BADGE_BACKGROUND_SHAPE_BORDER_CORNERS:
      getUI(currentStep, newDraft.uis).ui.badge.background.shape.border.corners[
        action.corner
      ] = action.value
      break
    case SET_BADGE_BACKGROUND_SHAPE_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.badge.background.shape.border.width[
        action.direction
      ] = action.value
      break

    // Badge Content Label
    case SET_BADGE_CONTENT_ENABLE_LABEL:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].enable_label = action.value
      if (
        getUI(currentStep, newDraft.uis).ui.badge.content[action.contentStep]
          .label.text === ""
      ) {
        getUI(currentStep, newDraft.uis).ui.badge.content[
          action.contentStep
        ].label.text = "Text"
      }
      break
    case SET_BADGE_CONTENT_LABEL_COLOR:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].label.color = action.value
      break
    case SET_BADGE_CONTENT_LABEL_TEXT:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].label.text = action.value
      break
    case SET_BADGE_CONTENT_LABEL_FONT:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].label.font[action.fontProperty] = action.value
      break
    case SET_BADGE_CONTENT_LABEL_ALIGNMENT:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].label.alignment = action.value
      break

    // Badge Content Label Margin
    case SET_BADGE_CONTENT_LABEL_ENABLE_MARGIN:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].label.enable_margin = action.value
      break
    case SET_BADGE_CONTENT_LABEL_MARGIN:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].label.margin[action.direction] = action.value
      break

    // Badge Content Icon
    case SET_BADGE_CONTENT_ENABLE_ICON:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].enable_icon = action.value
      if (
        getUI(currentStep, newDraft.uis).ui.badge.content[action.contentStep]
          .icon.width === 0
      ) {
        getUI(currentStep, newDraft.uis).ui.badge.content[
          action.contentStep
        ].icon.width = 20
      }
      if (
        getUI(currentStep, newDraft.uis).ui.badge.content[action.contentStep]
          .icon.height === 0
      ) {
        getUI(currentStep, newDraft.uis).ui.badge.content[
          action.contentStep
        ].icon.height = 20
      }
      break
    case SET_BADGE_CONTENT_ICON_TYPE:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].icon.type = action.value
      break
    case SET_BADGE_CONTENT_ICON_PATH:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].icon.path = action.value
      break
    case SET_BADGE_CONTENT_ICON_POSITION:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].icon.position = action.value
      break
    case SET_BADGE_CONTENT_ICON_WIDTH:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].icon.width = action.value
      break
    case SET_BADGE_CONTENT_ICON_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].icon.height = action.value
      break

    // Badge Content Icon Margin
    case SET_BADGE_CONTENT_ICON_ENABLE_MARGIN:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].icon.enable_margin = action.value
      break
    case SET_BADGE_CONTENT_ICON_MARGIN:
      getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].icon.margin[action.direction] = action.value
      break

    // Badge Shadow
    case SET_BADGE_ENABLE_SHADOW:
      getUI(currentStep, newDraft.uis).ui.badge.enable_shadow = action.value
      break
    case SET_BADGE_SHADOW:
      getUI(currentStep, newDraft.uis).ui.badge.shadow[action.shadowProperty] =
        action.value
      break

    // Badge Animations
    case SET_BADGE_ENABLE_ANIMATION:
      getUI(currentStep, newDraft.uis).ui.badge.enable_animation = action.value
      break
    case SET_BADGE_ANIMATION_TYPE:
      getUI(currentStep, newDraft.uis).ui.badge.animation.type = action.value
      break
    case SET_BADGE_ANIMATION_DURATION_MILLIS:
      getUI(currentStep, newDraft.uis).ui.badge.animation.duration_millis =
        action.value
      break

    // Badge Animations Stop Conditions
    case SET_BADGE_ANIMATION_DELAY_MILLIS: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        delay_millis: action.value,
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_INTER_COUNT: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        inter_count: action.value,
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_INTRA_COUNT: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        intra_count: action.value,
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    // Badge Animations Ripple
    case SET_BADGE_ANIMATION_RIPPLE_AREA: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        ripple: {
          ...BADGE_ANIMATION_CONFIG.animation.ripple,
          ...newBadgeAnimationConfig?.ripple,
          area: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_RIPPLE_COLOR: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        ripple: {
          ...BADGE_ANIMATION_CONFIG.animation.ripple,
          ...newBadgeAnimationConfig?.ripple,
          color: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_RIPPLE_CORNER_RADIUS: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        ripple: {
          ...BADGE_ANIMATION_CONFIG.animation.ripple,
          ...newBadgeAnimationConfig?.ripple,
          corner_radius: action.value,
        },
      }

      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_RIPPLE_SHAPE: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        ripple: {
          ...BADGE_ANIMATION_CONFIG.animation.ripple,
          ...newBadgeAnimationConfig?.ripple,
          shape: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    // Badge Animations Shine
    case SET_BADGE_ANIMATION_SHINE_COLOR: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        shine: {
          ...BADGE_ANIMATION_CONFIG.animation.shine,
          ...newBadgeAnimationConfig?.shine,
          color: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_SHINE_LINES_GAP: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        shine: {
          ...BADGE_ANIMATION_CONFIG.animation.shine,
          ...newBadgeAnimationConfig?.shine,
          lines_gap: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_SHINE_DIRECTION: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        shine: {
          ...BADGE_ANIMATION_CONFIG.animation.shine,
          ...newBadgeAnimationConfig?.shine,
          direction: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case SET_BADGE_ANIMATION_SHINE_LINES: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        shine: {
          ...BADGE_ANIMATION_CONFIG.animation.shine,
          ...newBadgeAnimationConfig?.shine,
          lines: [
            ...(newBadgeAnimationConfig?.shine
              ? [
                  ...(newBadgeAnimationConfig?.shine?.lines
                    ? newBadgeAnimationConfig.shine.lines.map((line, idx) => {
                        if (idx === action.idx) {
                          line.width = action.value
                        }
                        return line
                      })
                    : []),
                ]
              : [
                  ...BADGE_ANIMATION_CONFIG.animation.shine.lines.map(
                    (line, idx) => {
                      if (idx === action.idx) {
                        line.width = action.value
                      }
                      return line
                    },
                  ),
                ]),
          ],
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case ADD_SHINE_LINE_VARIANT: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        shine: {
          ...BADGE_ANIMATION_CONFIG.animation.shine,
          ...newBadgeAnimationConfig?.shine,
          lines: [
            ...(newBadgeAnimationConfig?.shine
              ? [
                  ...(newBadgeAnimationConfig?.shine?.lines
                    ? newBadgeAnimationConfig.shine.lines
                    : []),
                  BADGE_SHINE_ONE_LINE_CONFIG,
                ]
              : [
                  ...BADGE_ANIMATION_CONFIG.animation.shine.lines,
                  BADGE_SHINE_ONE_LINE_CONFIG,
                ]),
          ],
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    case DELETE_SHINE_LINE_VARIANT: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )

      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        shine: {
          ...BADGE_ANIMATION_CONFIG.animation.shine,
          ...newBadgeAnimationConfig?.shine,
          lines: [
            ...(newBadgeAnimationConfig?.shine
              ? [
                  ...(newBadgeAnimationConfig?.shine?.lines
                    ? newBadgeAnimationConfig.shine.lines.filter(
                        (_, idx) => idx !== 1,
                      )
                    : []),
                ]
              : [
                  ...BADGE_ANIMATION_CONFIG.animation.shine.lines.filter(
                    (_, idx) => idx !== 1,
                  ),
                ]),
          ],
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    // Badge Animations Shake
    case SET_BADGE_ANIMATION_SHAKE_DIRECTION: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )
      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        shake: {
          ...BADGE_ANIMATION_CONFIG.animation.shake,
          ...newBadgeAnimationConfig?.shake,
          direction: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    // Badge Animations Fade
    case SET_BADGE_ANIMATION_FADE_OPACITY: {
      let newBadgeAnimationConfig = cloneDeep(
        getUI(currentStep, newDraft.uis).ui.badge.animation,
      )
      newBadgeAnimationConfig = {
        ...BADGE_ANIMATION_CONFIG.animation,
        ...newBadgeAnimationConfig,
        fade: {
          ...BADGE_ANIMATION_CONFIG.animation.fade,
          ...newBadgeAnimationConfig?.fade,
          opacity: action.value,
        },
      }
      getUI(currentStep, newDraft.uis, isExperiment).ui.badge.animation =
        newBadgeAnimationConfig
      break
    }

    // Badge Animations Scroll
    case SET_BADGE_ENABLE_AUTO_SCROLL:
      getUI(currentStep, newDraft.uis).ui.badge.enable_auto_scroll =
        action.value
      break
    case SET_BADGE_AUTO_SCROLL_SPEED:
      getUI(currentStep, newDraft.uis).ui.badge.auto_scroll.duration_millis =
        action.value
      break
    case SET_BADGE_AUTO_SCROLL_DIRECTION:
      getUI(currentStep, newDraft.uis).ui.badge.auto_scroll.direction =
        action.value
      break
    case SET_BADGE_AUTO_SCROLL_HORIZONTAL_GAP:
      getUI(currentStep, newDraft.uis).ui.badge.auto_scroll.horizontal_gap =
        action.value
      break

    // Badge Dynamic Text
    case SET_BADGE_DYNAMIC_TEXT_CONFIG: {
      const d_Type = action.d_type
      const isDynamicScrpt = d_Type === "u" || d_Type === "v" || d_Type === "s"
      config = getUI(currentStep, newDraft.uis).ui.badge.content[
        action.contentStep
      ].label
      if (!isDynamicScrpt) {
        delete config.d_type
        delete config.url
        delete config.mvm
        delete config.script
      } else {
        config.d_type = d_Type
        if (d_Type === "v") {
          config.mvm = action.data
          config.url = ""
        } else if (d_Type === "u") {
          config.url = action.data
        } else if (d_Type === "s") {
          config.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      config.text = isDynamicScrpt ? action.value : config.text
      config.is_dyn = isDynamicScrpt
      break
    }

    // NEW CONFIG REDUCER
    case NEW_CONFIG_SET_BUTTONS:
      if (action.value) {
        getUI(currentStep, newDraft.uis).ui.content.enable_buttons = true
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list.push(
          NEW_COACHMARKS_LIST_BUTTONS,
        )
      } else {
        getUI(currentStep, newDraft.uis).ui.content.enable_buttons = false
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list = []
      }
      break
    case NEW_CONFIG_SET_BUTTONS_LENGTH:
      if (
        action.value === "2" &&
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list
          .length === 1
      ) {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list.push(
          {
            ...NEW_COACHMARKS_LIST_BUTTONS,
            color: "#",
            text: {
              text: "No",
              alignment: "center",
              color: "#ffffff",
              font: {
                ...FONT_CONFIG.font,
                size: 14,
              },
            },
            enable_border: true,
            border: {
              radius: 10,
              color: "#000000",
              width: 1.5,
              style: "solid",
            },
            enable_margin: true,
            margin: {
              bottom: 0,
              left: 10,
              right: 0,
              top: 0,
            },
          },
        )
      } else if (
        action.value === "1" &&
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list
          .length === 2
      ) {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list.pop(
          NEW_COACHMARKS_LIST_BUTTONS,
        )
      }
      break
    case NEW_CONFIG_SET_BUTTONS_POSITION:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.direction =
        action.value
      break
    case NEW_CONFIG_SET_BUTTONS_ALIGNMENT:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.alignment =
        action.value
      break
    case NEW_CONFIG_COACHMARK_SHAPE:
      if (action.value)
        getUI(currentStep, newDraft.uis).ui.outer_highlighter.shape =
          action.value
      break
    case NEW_CONFIG_SET_BACKGROUND_COLOR:
      getUI(currentStep, newDraft.uis).ui.outer_highlighter.color = action.value
      break
    case NEW_CONFIG_SET_BACKGROUND_OPACITY:
      getUI(currentStep, newDraft.uis).ui.outer_highlighter.opacity = Number(
        action.value,
      )
      break
    case NEW_CONFIG_SET_BACKGROUND_OFFSET:
      getUI(currentStep, newDraft.uis).ui.outer_highlighter.offset[
        action.direction
      ] = Number(action.value)
      break
    case NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_CIRCLE:
      getUI(currentStep, newDraft.uis).ui.outer_highlighter.delta_circle =
        Number(action.value)

      break
    case NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_RECT:
      getUI(currentStep, newDraft.uis).ui.outer_highlighter.delta_rect[
        action.direction
      ] = Number(action.value)
      break
    case SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_SHAPE:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.shape = action.value
      break
    case SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_ENABLE:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.enable_border =
        action.value
      if (!action.value) {
        getUI(currentStep, newDraft.uis).ui.inner_highlighter.border = {
          ...NEW_COACHMARKS_HIGHLIGHTER.inner_highlighter.border,
          width: 0,
        }
      } else {
        getUI(currentStep, newDraft.uis).ui.inner_highlighter.border = {
          ...NEW_COACHMARKS_HIGHLIGHTER.inner_highlighter.border,
        }
      }
      break
    case SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_RADIUS:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.border.radius =
        Number(action.value)
      break
    case SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_COLOR:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.border.color =
        action.value
      break
    case SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_WIDTH:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.border.width =
        Number(action.value)
      break
    case SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_OPACITY:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.border.opacity =
        Number(action.value)
      break
    case SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_STYLE:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.border.style =
        action.value
      break
    case NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_CIRCLE:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.delta_circle =
        Number(action.value)
      break
    case NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_RECT:
      getUI(currentStep, newDraft.uis).ui.inner_highlighter.delta_rect[
        action.direction
      ] = Number(action.value)
      break
    case SET_NEW_CONFIG_COACHMARK_ENABLE_RIPPLE:
      getUI(currentStep, newDraft.uis).ui.enable_ripple = action.value
      break
    case SET_NEW_CONFIG_COACHMARK_RIPPLE_OPACITY:
      getUI(currentStep, newDraft.uis).ui.ripple.opacity = Number(action.value)
      break
    case SET_NEW_CONFIG_COACHMARK_RIPPLE_COLOR:
      getUI(currentStep, newDraft.uis).ui.ripple.color = action.value
      break
    case SET_NEW_CONFIG_COACHMARK_RIPPLE_TYPE:
      getUI(currentStep, newDraft.uis).ui.ripple.ripple_type = action.value
      break
    case SET_NEW_CONFIG_COACHMARK_RIPPLE_DURATION:
      getUI(currentStep, newDraft.uis).ui.ripple.duration = Number(action.value)
      break
    case SET_NEW_CONFIG_COACHMARK_RIPPLE_WIDTH:
      getUI(currentStep, newDraft.uis).ui.ripple.width = Number(action.value)
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE:
      getUI(currentStep, newDraft.uis).ui.enable_image = action.value
      if (action.value)
        getUI(currentStep, newDraft.uis).ui.image = {
          ...NEW_COACHMARKS_IMAGE.image,
        }
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_DIMENSION:
      getUI(currentStep, newDraft.uis).ui.image[action.dim] = Number(
        action.value,
      )
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE_BORDER:
      getUI(currentStep, newDraft.uis).ui.image.enable_border = action.value
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_BORDER_PROPERTY:
      getUI(currentStep, newDraft.uis).ui.image.border[action.borderProperty] =
        action.value
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_TYPE:
      getUI(currentStep, newDraft.uis).ui.image.type = action.value
      getUI(currentStep, newDraft.uis).ui.image.path = ""

      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_PATH:
      getUI(currentStep, newDraft.uis).ui.image.path = action.value
      if (action.height && action.width) {
        getUI(currentStep, newDraft.uis).ui.image.aspect_ratio.height =
          action.height
        getUI(currentStep, newDraft.uis).ui.image.aspect_ratio.width =
          action.width
      } else {
        getUI(currentStep, newDraft.uis).ui.image.aspect_ratio.height = 1
        getUI(currentStep, newDraft.uis).ui.image.aspect_ratio.width = 1
      }
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_POSITION:
      getUI(currentStep, newDraft.uis).ui.image.position = action.value
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_ASPECT_RATIO:
      getUI(currentStep, newDraft.uis).ui.image.aspect_ratio[action.direction] =
        action.value
      break
    case SET_NEW_CONFIG_COACHMARK_IMAGE_OFFSET:
      getUI(currentStep, newDraft.uis).ui.image.offset[action.direction] =
        action.value
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE:
      getUI(currentStep, newDraft.uis).ui.enable_arrow = action.value
      if (action.value)
        getUI(currentStep, newDraft.uis).ui.arrow = {
          ...NEW_COACHMARKS_ARROW.arrow,
        }
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_DIMENSION:
      getUI(currentStep, newDraft.uis).ui.arrow[action.dim] = Number(
        action.value,
      )
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_ANGLE:
      getUI(currentStep, newDraft.uis).ui.arrow.angle = Number(action.value)
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE_BORDER:
      getUI(currentStep, newDraft.uis).ui.arrow.enable_border = action.value
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_BORDER_PROPERTY:
      getUI(currentStep, newDraft.uis).ui.arrow.border[action.borderProperty] =
        action.value
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_TYPE:
      getUI(currentStep, newDraft.uis).ui.arrow.type = action.value
      getUI(currentStep, newDraft.uis).ui.arrow.path = ""
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_PATH:
      getUI(currentStep, newDraft.uis).ui.arrow.path = action.value
      if (action.height && action.width) {
        getUI(currentStep, newDraft.uis).ui.arrow.aspect_ratio.height =
          action.height
        getUI(currentStep, newDraft.uis).ui.arrow.aspect_ratio.width =
          action.width
      } else {
        getUI(currentStep, newDraft.uis).ui.arrow.aspect_ratio.height = 1
        getUI(currentStep, newDraft.uis).ui.arrow.aspect_ratio.width = 1
      }
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_POSITION:
      getUI(currentStep, newDraft.uis).ui.arrow.position = action.value
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_ASPECT_RATIO:
      getUI(currentStep, newDraft.uis).ui.arrow.aspect_ratio[action.direction] =
        action.value
      break
    case SET_NEW_CONFIG_COACHMARK_ARROW_OFFSET:
      getUI(currentStep, newDraft.uis).ui.arrow.offset[action.direction] =
        action.value
      break
    case SET_NEW_CONFIG_CONTENT_ENABLE_DESC:
      getUI(currentStep, newDraft.uis).ui.content.enable_description =
        action.value
      if (
        action.value &&
        getUI(currentStep, newDraft.uis).ui.content.description.text.length ===
          0
      ) {
        getUI(currentStep, newDraft.uis).ui.content.description.text =
          "Description"
      }
      break
    case SET_NEW_CONFIG_CONTENT_ENABLE_TITLE:
      getUI(currentStep, newDraft.uis).ui.content.enable_title = action.value
      if (
        action.value &&
        getUI(currentStep, newDraft.uis).ui.content.title.text.length === 0
      ) {
        getUI(currentStep, newDraft.uis).ui.content.title.text = "Title"
      }
      break
    case SET_NEW_CONFIG_CONTENT_HEIGHT:
      getUI(currentStep, newDraft.uis).ui.content.height = Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_WIDTH:
      getUI(currentStep, newDraft.uis).ui.content.width = Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_MARGIN:
      getUI(currentStep, newDraft.uis).ui.content.margin[action.direction] =
        Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_OFFSET:
      getUI(currentStep, newDraft.uis).ui.content.offset[action.direction] =
        Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_POSITION:
      getUI(currentStep, newDraft.uis).ui.content.position = action.value
      break
    case SET_NEW_CONFIG_CONTENT_TEXT:
      if (action.buttonText) {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].text.text = action.value
      } else
        getUI(currentStep, newDraft.uis).ui.content[action.textType].text =
          action.value
      break
    case SET_NEW_CONFIG_CONTENT_TEXT_ALIGNMENT:
      if (action.buttonText) {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].text.alignment = action.value
      } else
        getUI(currentStep, newDraft.uis).ui.content[action.textType].alignment =
          action.value
      break
    case SET_NEW_CONFIG_CONTENT_TEXT_COLOR:
      if (action.buttonText) {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].text.color = action.value
      } else
        getUI(currentStep, newDraft.uis).ui.content[action.textType].color =
          action.value
      break
    case SET_NEW_CONFIG_CONTENT_TEXT_FONT_PROPERTY:
      if (action.buttonText) {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].text.font[action.fontProperty] = action.value
      } else
        getUI(currentStep, newDraft.uis).ui.content[action.textType].font[
          action.fontProperty
        ] = action.value
      break
    case SET_NEW_CONFIG_CONTENT_TEXT_MARGIN:
      getUI(currentStep, newDraft.uis).ui.content[action.textType].margin[
        action.direction
      ] = Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_TEXT_PADDING:
      getUI(currentStep, newDraft.uis).ui.content[action.textType].padding[
        action.direction
      ] = Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_COLOR:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].color = action.value
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_PROPERTY:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].border[action.borderProperty] = action.value
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_ENABLED:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].enable_border = action.value
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_MARGIN:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].margin[action.direction] = Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_PADDING:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].padding[action.direction] = Number(action.value)
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].enable_action = action.value
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION_TYPE:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].action.type = action.value
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].action.action = action.value
      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_TYPE:
      if (action.value === "internal") {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].action.is_external = false
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].action.is_within = true
      } else if (action.value === "external") {
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].action.is_external = true
        getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
          action.buttonIndex
        ].action.is_within = false
      }

      break
    case SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_URL:
      getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons.list[
        action.buttonIndex
      ].action.url = action.value
      break
    case SET_THEME:
      getUI(currentStep, newDraft.uis).ui = {
        ...action.value,
        theme: action.theme,
      }
      break
    case SET_SCREEN_RESTRICTION_TYPE:
      getUI(currentStep, newDraft.uis).ui.restriction_type =
        action.restriction_type
      break
    case SET_WHITELIST_SCREENS:
      getUI(currentStep, newDraft.uis).ui.white_list_screens =
        action.white_list_screens
      break
    case SET_BLACKLIST_SCREENS:
      getUI(currentStep, newDraft.uis).ui.black_list_screens =
        action.black_list_screens
      break
    case SET_NEW_DYNAMIC_TEXT_CONFIG:
      {
        const dType = action.d_type
        const isDynamicScript = dType === "u" || dType === "v" || dType === "s"
        if (action.buttonText) {
          config = getUI(currentStep, newDraft.uis).ui.content.coachmark_buttons
            .list[action.buttonIndex].text
          if (!isDynamicScript) {
            delete config.d_type
            delete config.url
            delete config.mvm
            delete config.script
          } else {
            config.d_type = dType
            if (dType === "v") {
              config.mvm = action.data
              config.url = ""
            } else if (dType === "u") {
              config.url = action.data
            } else if (dType === "s") {
              config.script = {
                cmds: action.data.commands,
                vmap: action.data.variableMap,
                src: action.data.originalScript,
                t: action.data.tab,
              }
            }
          }
          config.text = isDynamicScript ? action.value : config.text
          config.is_dyn = isDynamicScript
        } else {
          config = getUI(currentStep, newDraft.uis).ui.content[action.textType]
          if (!isDynamicScript) {
            delete config.d_type
            delete config.url
            delete config.mvm
            delete config.script
          } else {
            config.d_type = dType
            if (dType === "v") {
              config.mvm = action.data
              config.url = ""
            } else if (dType === "u") {
              config.url = action.data
            } else if (dType === "s") {
              config.script = {
                cmds: action.data.commands,
                vmap: action.data.variableMap,
                src: action.data.originalScript,
                t: action.data.tab,
              }
            }
          }
          config.text = isDynamicScript ? action.value : config.text
          config.is_dyn = isDynamicScript
        }
      }
      break
    default:
      return newDraft
  }
  //FIXME: Find a better fix for generating HTML after every inapp ui state change.
  /**
   * This code is the actual HTML generator for INAPP type config, for every config
   * property change in an Inapp step, we generate HTML and place it in html_config.data
   * of that particular step.
   *
   * getUI(currentStep, newDraft.uis) check is added to handle campaign deletion case
   */
  if (
    newDraft?.currentStep >= 0 &&
    getUI(newDraft?.currentStep, newDraft.uis) &&
    getUI(newDraft?.currentStep, newDraft.uis).type === MESSAGE_ENUM.IN_APP
  ) {
    getUI(newDraft?.currentStep, newDraft.uis).ui.html_config.data =
      constructHTML(
        getUI(newDraft?.currentStep, newDraft.uis).ui,
        newDraft.platform,
      )
  }

  /**
   * Reason for 175 is because, this is inside immer callback function and it takes some time to reflect
   * in the REF that we maintain in DesignPageWithProvider component.
   *
   * So, in order to maintain that balance between state update, re-renders and auto save, we have kept this number.
   *
   * Don't jump to conclusions by looking at this :^)
   */

  // FIXME: This is kept as a random number. If we still face data update issues, have a look at this
  setTimeout(() => {
    sendEvent(ON_UIS_CHANGE)
  }, 175)

  return newDraft
})
