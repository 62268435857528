import { makeStyles } from "@material-ui/core"
import ThemedTooltip from "./ThemedTooltip"

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    background: props.disabled ? "#F4F4F4" : "#FFF",
    border: "1px solid transparent",
    borderRadius: 6,
    color:
      props.value === "" || props.value === null
        ? theme.palette.text.secondary
        : theme.palette.common.black,
    fontSize: 12,
    fontWeight: 600,
    padding: "6px 10px",
    maxWidth: props.maxWidth ? props.maxWidth : 150,
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&&&&:hover": {
      border: props.disabled ? "1px solid transparent" : "1px solid #606365",
      cursor: props.disabled ? "default" : "pointer",
    },
  }),
  withNoTruncation: (props) => ({
    background: props.disabled ? "#F4F4F4" : "#FFF",
    border: "1px solid transparent",
    borderRadius: 2,
    color:
      props.value === "" || props.value === null
        ? theme.palette.text.secondary
        : theme.palette.common.black,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0.01em",
    padding: "8px 14px",
    display: "inline-block",
    "&&&&:hover": {
      border: props.disabled ? "1px solid transparent" : "1px solid #606365",
      cursor: props.disabled ? "default" : "pointer",
    },
  }),
}))

export default function TruncatedText(props) {
  const {
    placeholder = null,
    value,
    onClick = null,
    styles = {},
    hasTooltip = true,
    tooltipTitle = null,
    tooltipPlacement = null,
    dontTruncate = false,
  } = props
  const classes = useStyles(props)

  const content = (
    <div
      className={dontTruncate ? classes.withNoTruncation : classes.root}
      onClick={(e) => {
        e.preventDefault()
        if (onClick) {
          onClick(e)
        }
      }}
      style={{ ...styles }}
    >
      {value === "" ? placeholder : value + ""}
    </div>
  )

  if (!hasTooltip) {
    return content
  }

  return (
    <ThemedTooltip
      dark
      title={tooltipTitle ? tooltipTitle : value === "" ? placeholder : value}
      placement={tooltipPlacement ? tooltipPlacement : "top"}
    >
      {content}
    </ThemedTooltip>
  )
}
