import { Button, Typography } from "@material-ui/core"
import { IconPlus } from "@tabler/icons-react"
import { Suspense, lazy, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { isSuper } from "../../../config"
import { useAuth } from "../../../contexts/AuthContext"
import useApxorClient from "../../../utils/use-apxor-client"
import useMutateRouterState from "../../../utils/use-mutate-router-state"
import HStack from "../../ReusableComponents/HStack"
import SearchBar from "../../ReusableComponents/SearchBar"
import VStack from "../../ReusableComponents/VStack"
import DialogLoader from "../../ReusableComponents/skeletons/DialogLoader"
import OrgsGrid, { OrgsGridSkeleton } from "../components/OrgsGrid"
import OrgLayout from "../layouts/OrgLayout"

const NewOrgDialog = lazy(() => import("../components/NewOrgDialog"))

export default function OrganisationsListPage() {
  const {
    user: { email },
  } = useAuth()
  const { state } = useLocation()
  const { logEvent } = useApxorClient()
  const { clearStateFields } = useMutateRouterState()

  const [openNewOrgDialog, setOpenNewOrgDialog] = useState(false)
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    if (state?.from_login) {
      logEvent("Orgs_PageLaunched", { source: "login-page" })
      clearStateFields(["from_login"])
    }
  }, [logEvent, state?.from_login, clearStateFields])

  return (
    <OrgLayout>
      <VStack
        spacing="50px"
        style={{
          padding: "40px",
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
        }}
      >
        {/* Header */}
        <HStack justify="space-between">
          <Typography
            style={{
              color: "#002845",
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Your Organisations
          </Typography>
          <HStack spacing="8px">
            <SearchBar
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              onClear={() => {
                setSearchText("")
              }}
              placeholder="Search by Organisation Name"
              style={{
                minWidth: "300px",
              }}
            />
            {isSuper(email) && (
              <Button
                startIcon={
                  <IconPlus
                    size={16}
                    color="white"
                  />
                }
                size="large"
                color="primary"
                variant="contained"
                onClick={() => setOpenNewOrgDialog(true)}
              >
                Add Organisation
              </Button>
            )}
          </HStack>
        </HStack>

        {/* Orgs Grid */}
        <Suspense fallback={<OrgsGridSkeleton />}>
          <OrgsGrid searchText={searchText} />
        </Suspense>
      </VStack>

      {/* Add New Org Dialog */}
      {isSuper(email) && openNewOrgDialog && (
        <Suspense fallback={<DialogLoader />}>
          <NewOrgDialog
            open={openNewOrgDialog}
            onClose={() => setOpenNewOrgDialog(false)}
          />
        </Suspense>
      )}
    </OrgLayout>
  )
}
