export const DEFAULT_DID_DID_NOT_OPTIONS = [
  {
    label: "did",
    value: "did",
  },
  {
    label: "didn't",
    value: "didn't",
  },
]

export const DEFAULT_DID_OPTIONS = [
  {
    label: "did",
    value: "did",
  },
]

export const WEB_DID_DID_NOT_OPTIONS = [
  {
    label: "did",
    value: "did",
  },
]

export const EQUALITY_OPERATOR_OPTIONS = [
  {
    label: "==",
    value: "EQ",
  },
  {
    label: "!=",
    value: "NEQ",
  },
]

export const NUMBER_OPERATOR_OPTIONS = [
  {
    label: ">",
    value: "GT",
  },
  {
    label: ">=",
    value: "GTE",
  },
  {
    label: "<",
    value: "LT",
  },
  {
    label: "<=",
    value: "LTE",
  },
  ...EQUALITY_OPERATOR_OPTIONS,
]

export const DEFAULT_OPERATOR_OPTIONS = [...NUMBER_OPERATOR_OPTIONS]

export const STRING_OPERATOR_OPTIONS = [
  ...EQUALITY_OPERATOR_OPTIONS,
  { label: "RegEx", value: "R" },
]

export const DATA_TYPES = {
  STRING: "String",
  BOOLEAN: "Boolean",
  DECIMAL: "Decimal",
  INTEGER: "Integer",
}

export const DEFAULT_DATATYPE_OPTIONS = [
  {
    label: DATA_TYPES.STRING,
    value: "s",
  },
  {
    label: DATA_TYPES.BOOLEAN,
    value: "b",
  },
  {
    label: DATA_TYPES.DECIMAL,
    value: "f",
  },
  {
    label: DATA_TYPES.INTEGER,
    value: "l",
  },
]

export const DEFAULT_COMBINE_OPERATOR_OPTIONS = [
  {
    label: "OR",
    value: "OR",
    enabled: false,
  },
  {
    label: "AND",
    value: "AND",
    enabled: false,
  },
  {
    label: "AND THEN",
    value: "AND_THEN",
    enabled: true,
  },
  // {
  //   label: "AND THEN ONLY",
  //   value: "AND_THEN_ONLY",
  //   enabled: true,
  // },
]

export const DEFAULT_COMBINE_OPERATOR_OPTIONS_ADVANCE = [
  {
    label: "OR",
    value: "OR",
    enabled: false,
  },
  {
    label: "AND",
    value: "AND",
    enabled: false,
  },
  {
    label: "AND THEN",
    value: "AND",
    enabled: true,
  },
]

export const EVENT_TYPES = Object.freeze({
  APP: { label: "APP", configName: "app_event", displayName: "App Events" },
  ACTIVITY: {
    label: "ACTIVITY",
    configName: "activity_event",
    displayName: "Screen Names",
  },
  CLIENT: {
    label: "CLIENT",
    configName: "client_event",
    displayName: "Client Events",
  },
  CUSTOM_SCREEN: {
    label: "CUSTOM_SCREEN",
    configName: "screen_event",
    displayName: "Screen Names",
  },
})

export const SESSION_TYPES = [
  {
    label: "single session",
    value: "single_session",
  },
  {
    label: "specific sessions",
    value: "specific_sessions",
  },
  {
    label: "multiple sessions",
    value: "multiple_sessions",
  },
]

export const EVENT_CATEGORIES = [
  {
    label: "Apxor Events",
    value: "apxor_event",
  },
  {
    label: "Customer Events",
    value: "customer_event",
  },
  {
    label: "System Events",
    value: "system_event",
  },
  {
    label: "Nudge Events",
    value: "nudge_event",
  },
  {
    label: "Navigation Events",
    value: "activity_event",
  },
  {
    label: "App Events",
    value: "app_event",
  },
  {
    label: "Client Events",
    value: "client_event",
  },
  {
    label: "Screen Events",
    value: "screen_event",
  },
]
