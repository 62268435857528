// import { logPageView, setUserId as setUserIdEvent } from "apxor"
import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import {
  DEMO_ECOMMERCE_APP_ID,
  isExperienceDashboard,
  isSuper,
} from "../../config"
import { useAuth } from "../../contexts/AuthContext"
import { useDeepCompareEffect } from "../../utils/use-deep-compare"
import Apps from "../App/pages/AppsListing"
import UsersListingPage from "../App/pages/workflowRequest/UsersListingPage"
import Dashboard from "../Dashboard"
import AddNewAppPage from "../IntegrationExperience/add-application/pages/AddNewAppPage"
import IntegratePage from "../IntegrationExperience/integrate/pages/IntegratePage"
import AppApprovalsPage from "../Organizations/pages/AppApprovalsPage"
import OrganisationsListPage from "../Organizations/pages/OrganisationsListPage"
import Profile from "../Profile/pages/ProfilePage"

const AuthorizedRoute = (props) => {
  const { component: Component, pending, logged, ...rest } = props
  const auth = useAuth()
  const { pathname } = useLocation()
  const [prevPathName, setPrevPathName] = useState()
  const [userId, setUserId] = useState("")

  useEffect(() => {
    if (!prevPathName || pathname !== prevPathName) {
      // logPageView(pathname)
      setPrevPathName(pathname)
    }
  }, [pathname, prevPathName])

  useDeepCompareEffect(() => {
    if (userId === "" || (auth && auth?.user && auth?.user?.email !== userId)) {
      // setUserIdEvent(auth?.user?.email)
      setUserId(auth?.user?.email)
    }
  }, [auth])

  return (
    <Route
      {...rest}
      render={() => {
        if (pending) return <div>Loading...</div>
        return auth &&
          auth.user &&
          auth.user.email &&
          auth.user.email !== "" ? (
          <>
            <Switch>
              <Route
                exact
                path="/orgs/:orgId/users"
              >
                {isExperienceDashboard() ? (
                  <Redirect
                    to={`/orgs/:orgId/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                  />
                ) : (
                  <UsersListingPage />
                )}
              </Route>
              <Route
                exact
                path="/orgs/:orgId/apps"
              >
                {isExperienceDashboard() ? (
                  <Redirect
                    to={`/orgs/:orgId/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                  />
                ) : (
                  <Apps />
                )}
              </Route>
              <Route
                exact
                path="/approvals"
              >
                {isExperienceDashboard() ? (
                  <Redirect
                    to={`/orgs/:orgId/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                  />
                ) : (
                  <>
                    {isSuper(auth?.user?.email) ? (
                      <AppApprovalsPage />
                    ) : (
                      <Redirect to="/unauthorised" />
                    )}
                  </>
                )}
              </Route>
              <Route
                exact
                path="/orgs"
              >
                {isExperienceDashboard() ? (
                  <Redirect
                    to={`/orgs/:orgId/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                  />
                ) : (
                  <OrganisationsListPage />
                )}
              </Route>
              <Route
                exact
                path="/orgs/:orgId/apps/create"
              >
                <AddNewAppPage />
              </Route>
              <Route
                exact
                path="/profile"
              >
                {isExperienceDashboard() ? (
                  <Redirect
                    to={`/orgs/:orgId/apps/${DEMO_ECOMMERCE_APP_ID}/dashboard`}
                  />
                ) : (
                  <Profile />
                )}
              </Route>
              <Route
                path="/orgs/:orgId/apps/:appId/integrate"
                exact
              >
                <IntegratePage />
              </Route>
              <Route path="/orgs/:orgId/apps/:appId">
                <Dashboard />
              </Route>
            </Switch>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }}
    />
  )
}

export default AuthorizedRoute
