import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import { useEffect } from "react"
import Select from "react-select/async"
// import { WALKTHROUGH_TYPE_ENUM } from "../../../../../../../../../constants"
import { Alert } from "@material-ui/lab"
import useApxorClient from "../../../../../../../../../utils/use-apxor-client"
import { apxTheme } from "../../../../../../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../../../../../../ReusableComponents/HStack"
import VStack from "../../../../../../../../ReusableComponents/VStack"
import { StyledNumberInput } from "../../../../../../../components/surveys/components/design/components/simulator/components/toolbars/TextToolbar"
import {
  APP_PLATFORMS,
  SET_COUNT_LIMIT_ENABLED,
  SET_DAYS_LIMITS_ENABLED,
  SET_FREQ_COUNT,
  SET_FREQ_DAYS,
  SET_FREQ_DAYS_LIMIT,
  SET_FREQ_ISLATEST,
  SET_FREQ_LIMITS_ENABLED,
  SET_FREQ_SESSION_LIMIT,
  SET_FREQ_VALIDITY,
  SET_RECUR_TYPE,
  SET_REPETITION_GAP_ENABLED,
  SET_REPETITION_GAP_UNIT,
  SET_REPETITION_GAP_VALUE,
} from "../../../../constants"
import { useTracked } from "../../../../store"
import {
  IndicatorSeparator,
  Option,
} from "../../../context/components/targetaudience/components/selects/DidNotDoEventsComboBox"

export const CustomCheckbox = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "#2563EB",
    },
  },
  checked: {},
})(Checkbox)

const customStyles = {
  control: (base, state) => {
    return {
      ...base,
      primary: "orange",
      "&:hover": { borderColor: "none" },
      width: 120,
      paddingInline: 6,
      paddingBlock: 0,
      fontWeight: 500,
      fontSize: 13,
      borderRadius: 8,
      border: "none",
      boxShadow: state.isFocused
        ? `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`
        : `0 0 0 ${state.hasValue ? "1px #CCD4DA" : "1.5px #D64A40"}`,
      backgroundColor: apxTheme.palette.common.white,
      color: state.isFocused
        ? apxTheme.palette.primary.main
        : apxTheme.palette.text.primary,
    }
  },
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  menu: (base) => ({
    ...base,
    maxWidth: 560,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

export const FREQUENCY_ENUM = [
  { label: "overall", value: "OVERALL" },
  { label: "per session", value: "SESSION" },
]

const REPETITION_GAP_UNIT_ENUM = [
  { label: "Days", value: "day" },
  { label: "Hours", value: "hour" },
  { label: "Minutes", value: "minute" },
  { label: "Sessions", value: "session" },
]

export default function RecurAndLimits() {
  const [state, dispatch] = useTracked()

  const {
    config: {
      frequency,
      //TODO: Log events for repeat conditions
      meta: { platform = APP_PLATFORMS.android } = {},
    },
  } = state

  const { logEvent } = useApxorClient()

  useEffect(() => {
    if (!frequency?.isLatest || frequency?.isLatest === undefined) {
      if (frequency.count !== 1) {
        if (frequency.validity !== "SESSION") {
          dispatch({
            type: SET_COUNT_LIMIT_ENABLED,
            value: true,
          })
          dispatch({
            type: SET_FREQ_VALIDITY,
            validity: FREQUENCY_ENUM[0].value,
          })
        } else {
          dispatch({
            type: SET_FREQ_LIMITS_ENABLED,
            enabled: true,
          })
          dispatch({
            type: SET_FREQ_SESSION_LIMIT,
            value: frequency.count,
          })
        }
      }
      if (frequency?.day_lmt) {
        dispatch({
          type: SET_DAYS_LIMITS_ENABLED,
          value: true,
        })
        dispatch({
          type: SET_FREQ_DAYS_LIMIT,
          value: Number(frequency.day_lmt),
        })
      }
      if (frequency.count === 1 && frequency.validity !== "SESSION") {
        dispatch({
          type: SET_RECUR_TYPE,
          value: "once",
        })
      } else {
        if (frequency.validity === "SESSION") {
          dispatch({
            type: SET_FREQ_LIMITS_ENABLED,
            enabled: true,
          })
          dispatch({
            type: SET_FREQ_SESSION_LIMIT,
            value: frequency.count,
          })
        }
        dispatch({
          type: SET_RECUR_TYPE,
          value: "limited",
        })
      }
      dispatch({
        type: SET_FREQ_VALIDITY,
        validity: FREQUENCY_ENUM[0].value,
      })
      dispatch({
        type: SET_FREQ_ISLATEST,
        value: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VStack>
      <Typography
        component="h2"
        color="textPrimary"
        style={{
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        Campaign repeat conditions?
      </Typography>
      <Typography
        component="h5"
        color="textSecondary"
        style={{
          fontSize: 12,
          fontWeight: 400,
        }}
      >
        Choose how to stop a campaign after user interaction
      </Typography>
      <VStack spacing="35px">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Recur Type"
            name="recur_type"
            value={frequency?.recur_type ?? "once"}
            onChange={(event) => {
              const value = event.target.value
              if (value === "once") {
                dispatch({
                  type: SET_FREQ_COUNT,
                  count: 1,
                })
              } else {
                dispatch({
                  type: SET_FREQ_COUNT,
                  count: -1,
                })
              }
              dispatch({
                type: SET_RECUR_TYPE,
                value: value,
              })
              dispatch({
                type: SET_FREQ_VALIDITY,
                validity: FREQUENCY_ENUM[0].value,
              })
              dispatch({
                type: SET_COUNT_LIMIT_ENABLED,
                value: false,
              })
              dispatch({
                type: SET_FREQ_LIMITS_ENABLED,
                enabled: false,
              })
            }}
          >
            <FormControlLabel
              value="once"
              control={<Radio color={"primary"} />}
              label="Show campaign to user only once"
            />
            <FormControlLabel
              value="limited"
              control={<Radio color={"primary"} />}
              label={
                frequency?.enable_count_limit &&
                frequency?.recur_type === "limited"
                  ? `Allow user to view campaign upto ${frequency.count} time(s)`
                  : `Allow user to view campaign multiple times`
              }
            />
            {frequency?.recur_type === "limited" && (
              <VStack style={{ marginLeft: 30 }}>
                <FormControl component="fieldset">
                  <HStack>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            color={"primary"}
                            checked={frequency?.enable_count_limit || false}
                          />
                        }
                        label="Allow user to view campaign upto"
                        onChange={(event) => {
                          dispatch({
                            type: SET_COUNT_LIMIT_ENABLED,
                            value: event.target.checked,
                          })
                          if (!event.target.checked) {
                            dispatch({
                              type: SET_FREQ_VALIDITY,
                              validity: FREQUENCY_ENUM[0].value,
                            })
                            dispatch({
                              type: SET_FREQ_COUNT,
                              count: -1,
                            })
                          } else {
                            dispatch({
                              type: SET_FREQ_VALIDITY,
                              validity: FREQUENCY_ENUM[0].value,
                            })
                            dispatch({
                              type: SET_FREQ_COUNT,
                              count: frequency.count > 0 ? frequency.count : 5,
                            })
                          }
                        }}
                      />
                    </FormControl>
                    <StyledNumberInput
                      min={1}
                      disabled={!frequency.enable_count_limit}
                      onChange={(e) => {
                        dispatch({
                          type: SET_FREQ_COUNT,
                          count: Number(e.target.value),
                        })
                        logEvent("SchedulePage_LimitNumberChanged", {
                          limit_entered: Number(e.target.value),
                        })
                      }}
                      id="freq-limit"
                      value={frequency.count === -1 ? 0 : frequency.count}
                      handleChange={(value) => {
                        dispatch({
                          type: SET_FREQ_COUNT,
                          count: Number(value),
                        })
                        logEvent("SchedulePage_LimitNumberChanged", {
                          limit_entered: Number(value),
                        })
                      }}
                      style={{
                        borderRadius: 8,
                        border: "1px solid #CCD4DA",
                        width: 86,
                        backgroundColor: "#fff",
                        marginInline: 8,
                        marginTop: 0,
                        maxHeight: 40,
                      }}
                    />
                    <Typography
                      component={"label"}
                      htmlFor="freq-limit"
                      color="textPrimary"
                      style={{ fontSize: 13, marginInline: 8 }}
                    >
                      times
                    </Typography>
                  </HStack>
                </FormControl>

                <FormControl component="fieldset">
                  <HStack>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            color={"primary"}
                            checked={frequency?.enable_days_limit || false}
                          />
                        }
                        label="Allow user to view campaign"
                        onChange={(event) =>
                          dispatch({
                            type: SET_DAYS_LIMITS_ENABLED,
                            value: event.target.checked,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <StyledNumberInput
                        disabled={!(frequency?.enable_days_limit || false)}
                        min={1}
                        max={
                          frequency.count === -1
                            ? Number.MAX_SAFE_INTEGER
                            : frequency.count
                        }
                        onChange={(e) => {
                          dispatch({
                            type: SET_FREQ_DAYS_LIMIT,
                            value: Number(e.target.value),
                          })
                        }}
                        id=""
                        value={frequency?.days_limit?.limit ?? 1}
                        handleChange={(value) => {
                          dispatch({
                            type: SET_FREQ_DAYS_LIMIT,
                            value: Number(value),
                          })
                        }}
                        style={{
                          borderRadius: 8,
                          border: "1px solid #CCD4DA",
                          width: 86,
                          backgroundColor: "#fff",
                          marginInline: 8,
                          marginTop: 0,
                          maxHeight: 40,
                        }}
                      />
                    </FormControl>
                    <Typography
                      component={"label"}
                      htmlFor="freq-days"
                      color="textPrimary"
                      style={{ fontSize: 13, marginLeft: 10 }}
                    >
                      times in
                    </Typography>
                    <StyledNumberInput
                      disabled={!(frequency?.enable_days_limit || false)}
                      min={1}
                      onChange={(e) => {
                        dispatch({
                          type: SET_FREQ_DAYS,
                          value: Number(e.target.value),
                        })
                      }}
                      id="freq-days"
                      value={frequency?.days_limit?.days ?? 1}
                      handleChange={(value) => {
                        dispatch({
                          type: SET_FREQ_DAYS,
                          value: Number(value),
                        })
                      }}
                      style={{
                        borderRadius: 8,
                        border: "1px solid #CCD4DA",
                        width: 86,
                        backgroundColor: "#fff",
                        marginInline: 8,
                        marginTop: 0,
                        maxHeight: 40,
                      }}
                    />
                    <Typography
                      component={"label"}
                      htmlFor="freq-days"
                      color="textPrimary"
                      style={{ fontSize: 13, marginLeft: 10 }}
                    >
                      days per user, in campaign period
                    </Typography>
                  </HStack>
                </FormControl>

                <FormControl component="fieldset">
                  <HStack>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            color={"primary"}
                            checked={frequency.enable_session_limit || false}
                          />
                        }
                        label="Allow user to view campaign"
                        onChange={(event) => {
                          dispatch({
                            type: SET_FREQ_LIMITS_ENABLED,
                            enabled: event.target.checked,
                          })
                        }}
                      />
                    </FormControl>

                    <StyledNumberInput
                      disabled={!(frequency.enable_session_limit || false)}
                      min={1}
                      max={
                        frequency.enable_days_limit
                          ? frequency.days_limit.limit
                          : frequency.count === -1
                            ? Number.MAX_SAFE_INTEGER
                            : frequency.count
                      }
                      onChange={(e) => {
                        dispatch({
                          type: SET_FREQ_SESSION_LIMIT,
                          value: Number(e.target.value),
                        })
                      }}
                      id="session-limits"
                      value={
                        (frequency.enable_session_limit
                          ? frequency.session_count
                          : frequency?.ses_lmt) ?? 1
                      }
                      handleChange={(value) => {
                        dispatch({
                          type: SET_FREQ_SESSION_LIMIT,
                          value: Number(value),
                        })
                      }}
                      style={{
                        borderRadius: 8,
                        border: "1px solid #CCD4DA",
                        width: 86,
                        backgroundColor: "#fff",
                        marginInline: 8,
                        marginTop: 0,
                        maxHeight: 40,
                      }}
                    />
                    <Typography
                      component={"label"}
                      htmlFor="session-limits"
                      color="textPrimary"
                      style={{ fontSize: 13, marginLeft: 10 }}
                    >
                      times in apxor session
                    </Typography>
                  </HStack>
                </FormControl>

                <FormControl component="fieldset">
                  <HStack>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            color={"primary"}
                            checked={frequency?.enable_repetition_gap ?? false}
                          />
                        }
                        label="Allow user to view campaign"
                        onChange={(event) =>
                          dispatch({
                            type: SET_REPETITION_GAP_ENABLED,
                            value: event.target.checked,
                          })
                        }
                      />
                    </FormControl>
                    <StyledNumberInput
                      disabled={!(frequency?.enable_repetition_gap ?? false)}
                      min={1}
                      onChange={(e) => {
                        dispatch({
                          type: SET_REPETITION_GAP_VALUE,
                          value: Number(e.target.value),
                        })
                      }}
                      id="repetition-gap"
                      value={frequency?.repetition_gap?.val ?? 1}
                      handleChange={(value) => {
                        dispatch({
                          type: SET_REPETITION_GAP_VALUE,
                          value: Number(value),
                        })
                      }}
                      style={{
                        borderRadius: 8,
                        border: "1px solid #CCD4DA",
                        width: 86,
                        backgroundColor: "#fff",
                        marginInline: 8,
                        marginTop: 0,
                        maxHeight: 40,
                      }}
                    />
                    <Select
                      isDisabled={!(frequency?.enable_repetition_gap ?? false)}
                      menuPlacement="top"
                      inputId="repetition-gap-unit"
                      placeholder="Days"
                      cacheOptions
                      defaultOptions
                      loadOptions={(inputValue) => {
                        return new Promise((resolve) => {
                          const filteredOptions =
                            REPETITION_GAP_UNIT_ENUM.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()),
                            )

                          resolve(filteredOptions)
                        })
                      }}
                      noOptionsMessage={() => (
                        <Typography
                          style={{
                            fontWeight: 700,
                            fontSize: 13,
                          }}
                        >
                          No options
                        </Typography>
                      )}
                      isSearchable={true}
                      isClearable={false}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      options={REPETITION_GAP_UNIT_ENUM}
                      value={REPETITION_GAP_UNIT_ENUM.filter(
                        (pair) =>
                          pair.value ===
                          (frequency?.repetition_gap?.unit ??
                            REPETITION_GAP_UNIT_ENUM[0].value),
                      )}
                      onChange={(pair) => {
                        const value = pair?.value ?? ""
                        dispatch({
                          type: SET_REPETITION_GAP_UNIT,
                          value: value,
                        })
                      }}
                      styles={{
                        ...customStyles,
                        control: (base, state) => ({
                          ...customStyles.control(base, state),
                          width: 140,
                        }),
                      }}
                      components={{
                        Option,
                        IndicatorSeparator,
                      }}
                    />
                    <Typography
                      component={"label"}
                      htmlFor="repetition-gap"
                      color="textPrimary"
                      style={{ fontSize: 13, marginLeft: 10 }}
                    >
                      after last seen
                    </Typography>
                  </HStack>
                </FormControl>
              </VStack>
            )}
          </RadioGroup>
        </FormControl>
      </VStack>
      {(frequency?.enable_days_limit || frequency?.enable_repetition_gap) && (
        <>
          <Alert
            severity="warning"
            icon={<ErrorOutlineIcon />}
            style={{
              marginLeft: "30px",
              width: "fit-content",
              paddingRight: "20px",
              borderRadius: "8px",
              border: "1px solid #ffa016",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            This condition works for the CE version{" "}
            <p
              style={{
                paddingInline: 5,
                marginTop: -2,
                display: "inline-block",
                fontSize: "16px",
              }}
            >
              &ge;
            </p>
            <span
              style={{
                fontFamily: "monospace",
              }}
            >
              {platform === APP_PLATFORMS.ios ? "1.05.13" : "1.7.0"}
            </span>
          </Alert>
        </>
      )}
    </VStack>
  )
}
