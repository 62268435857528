import { Typography } from "@material-ui/core"
import { IconCheck } from "@tabler/icons-react"
import { useRef } from "react"
import Select from "react-select/async"
import { APP_PLATFORMS } from "../../constants"
import { apxTheme } from "../ApplicationFrame/styling/CustomCSS"
import {
  ANDROID_DEFAULT_FONTS,
  IOS_DEFAULT_FONTS,
  WEB_DEFAULT_FONTS,
} from "../Dashboard/common/components/campaigncreator/constants"
import {
  CUSTOM_FONT_SOURCES,
  CUSTOM_FONT_TYPES,
  getCustomFontSource,
  getCustomFontType,
} from "../Dashboard/components/themes/common/constants"
import HStack from "./HStack"
import VStack from "./VStack"

const customStyles = {
  control: (base, state) => ({
    ...base,
    maxHeight: 34,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 200,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
}

const formatGroupLabel = (data) => (
  <HStack
    justify="space-between"
    style={{
      marginBlock: 6,
    }}
  >
    {/* Label */}
    <span
      style={{
        fontSize: 10,
        fontWeight: 800,
        color: apxTheme.palette.text.primary,
      }}
    >
      {data.label}
    </span>

    {/* Length */}
    <span
      style={{
        backgroundColor: apxTheme.palette.action.selected,
        borderRadius: "50%",
        color: apxTheme.palette.text.primary,
        fontSize: 10,
        fontWeight: 600,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center",
      }}
    >
      {data.options.length}
    </span>
  </HStack>
)

function Option({ innerProps, isDisabled, ...props }) {
  const ref = useRef(null)

  return (
    <div
      {...innerProps}
      {...props}
      ref={ref}
      style={{
        ...innerProps.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: isDisabled ? 0.5 : 1,
        backgroundColor: props.isFocused
          ? "#F8F8F8"
          : props.isSelected
            ? apxTheme.palette.grey[100]
            : "transparent",
        color: props.isFocused
          ? apxTheme.palette.primary.main
          : apxTheme.palette.text.primary,
        cursor: isDisabled ? "not-allowed" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 12,
        padding: 12,
        zIndex: 50,
      }}
    >
      <VStack
        spacing={1}
        style={{ flex: 1 }}
      >
        <span>{props.data?.label}</span>

        <HStack
          spacing={8}
          style={{
            maxWidth: ref?.current
              ? ref?.current?.getBoundingClientRect().width - 50
              : 100,
            width: "100%",
          }}
          justify="start"
          align="center"
        >
          {props.data?.platform === APP_PLATFORMS.android &&
            props.data?.source && (
              <Typography
                style={{
                  fontSize: 11,
                  fontWeight: 700,
                  color: apxTheme.palette.text.secondary,
                }}
              >
                Source : {getCustomFontSource(props.data?.source)}
              </Typography>
            )}

          {props.data?.platform === APP_PLATFORMS.web && props.data?.type && (
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 700,
                color: apxTheme.palette.text.secondary,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              {getCustomFontType(props.data?.type)} : {props.data?.value}
            </Typography>
          )}

          {props.data?.platform === APP_PLATFORMS.ios && props.data?.value && (
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 700,
                color: apxTheme.palette.text.secondary,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              {props.data.isWebViewFont ? "Name" : "Post Script Name"} :{" "}
              {props.data?.value}
            </Typography>
          )}
        </HStack>
      </VStack>

      {props.isSelected ? (
        <IconCheck
          color={apxTheme.palette.success.main}
          size={19}
        />
      ) : (
        <IconCheck
          color={"transparent"}
          size={19}
        />
      )}
    </div>
  )
}

export default function FontFamilySelector({
  platform,
  fontFamily,
  customFonts = [],
  onChange,
  showCustomFonts = true,
  isHtmlInApp = false,
}) {
  const filteredCustomFonts =
    customFonts.length > 0
      ? customFonts.filter((f) => f.platform === platform)
      : []

  return (
    <Select
      cacheOptions
      defaultOptions
      loadOptions={(inputValue) => {
        return new Promise((resolve) => {
          const defaultOptions = [
            // Android Fonts
            ...(platform === APP_PLATFORMS.android
              ? ANDROID_DEFAULT_FONTS
              : []),

            // iOS Fonts
            ...(platform === APP_PLATFORMS.ios ? IOS_DEFAULT_FONTS : []),

            // Web Fonts
            ...(platform === APP_PLATFORMS.web ? WEB_DEFAULT_FONTS : []),
          ]

          const options = [
            ...(showCustomFonts && filteredCustomFonts.length > 0
              ? [
                  {
                    label: "Custom Fonts",
                    options: filteredCustomFonts.map((font) => ({
                      label: font?.fontName,
                      platform,

                      // Web
                      ...(platform === APP_PLATFORMS.web && {
                        type: font?.fontType ?? "",
                        value:
                          (font?.fontType === CUSTOM_FONT_TYPES.NAME
                            ? font?.fontName
                            : font?.fontType === CUSTOM_FONT_TYPES.URL
                              ? font?.fontUrl
                              : font?.fontPath) ?? "",
                      }),

                      // Android
                      ...(platform === APP_PLATFORMS.android && {
                        source: font?.source ?? "",
                        value:
                          (font?.source === CUSTOM_FONT_SOURCES.ASSETS
                            ? font?.fontAssets
                            : font?.source === CUSTOM_FONT_SOURCES.URL
                              ? font?.fontName
                              : font?.source === CUSTOM_FONT_SOURCES.PATH
                                ? font?.fontPath
                                : font?.source === CUSTOM_FONT_SOURCES.NAME
                                  ? font?.webViewFontName
                                  : font?.fontResources) ?? "",
                        ...(font?.source === CUSTOM_FONT_SOURCES.URL && {
                          url: font?.fontUrl ?? "",
                        }),
                      }),

                      // iOS
                      ...(platform === APP_PLATFORMS.ios && {
                        value:
                          (isHtmlInApp
                            ? font?.fontName
                            : (font.webViewFontName
                                ? font?.webViewFontName
                                : font?.postScriptName) || font?.fontName) ??
                          "",
                        isWebViewFont: font?.webViewFontName ? true : false,
                      }),
                    })),
                  },
                ]
              : []),
            { label: "Default Fonts", options: defaultOptions },
          ]

          const filteredOptions = options.map((group) => ({
            ...group,
            options: group.options.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase()),
            ),
          }))

          resolve(filteredOptions)
        })
      }}
      placeholder="Select Font Family"
      noOptionsMessage={() => (
        <Typography
          style={{
            fontWeight: 700,
            fontSize: 13,
          }}
        >
          No fonts found
        </Typography>
      )}
      closeMenuOnSelect={true}
      isClearable={false}
      isSearchable={true}
      isMulti={false}
      onChange={(pair) => {
        onChange(pair)
      }}
      value={{
        label: fontFamily,
        value: fontFamily,
      }}
      formatGroupLabel={formatGroupLabel}
      components={{
        Option,
      }}
      styles={customStyles}
    />
  )
}
