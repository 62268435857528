import { IconButton, Typography } from "@material-ui/core"
import { IconPlus } from "@tabler/icons-react"
import { useDeepCompareCallback } from "../../../../../utils/use-deep-compare"
import HStack from "../../../../ReusableComponents/HStack"
import VStack from "../../../../ReusableComponents/VStack"
import EventBuilderForVisualization from "../../bi-dashboards/components/event-builder"
import { useSelector } from "../store"

export default function Filters({ instantFilters, setInstantFilters }) {
  const { dimensionWithType } = useSelector()

  const updateFilters = (key, value, index, data_type = "") => {
    const updatedFilters = instantFilters.map((filter, i) => {
      if (i === index) {
        return {
          ...filter,
          ...(key === "name" && { data_type: data_type }),
          [key]: value,
        }
      }
      return filter
    })

    setInstantFilters(updatedFilters)
  }

  const removeFilter = useDeepCompareCallback(
    (index) => {
      setInstantFilters([
        ...instantFilters.slice(0, index),
        ...instantFilters.slice(index + 1),
      ])
    },
    [instantFilters, setInstantFilters],
  )

  const handleAddFilter = useDeepCompareCallback(() => {
    setInstantFilters([
      ...instantFilters,
      { name: "", operator: "EQ", data_type: "", value: [] },
    ])
  }, [instantFilters, setInstantFilters])

  return (
    <>
      <VStack
        spacing={8}
        style={{
          width: "100%",
        }}
      >
        <HStack
          justify={"space-between"}
          style={{
            width: "50%",
          }}
        >
          <Typography
            color="textPrimary"
            style={{
              color: "#020617",
              textWrap: "nowrap",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Add Filters
          </Typography>
          <IconButton
            color="primary"
            size="16"
            style={{
              color: "#000000",
            }}
            onClick={handleAddFilter}
          >
            <IconPlus size={15} />
          </IconButton>
        </HStack>
        <VStack>
          {instantFilters.map((filter, index) => (
            <EventBuilderForVisualization
              index={index}
              property={filter}
              handleOnChange={(data) => {
                switch (data.type) {
                  case "property": {
                    const dataType =
                      data.value !== ""
                        ? dimensionWithType.find(
                            (p) => p.attribute === data.value,
                          ).typeOf
                        : "string"
                    updateFilters("name", data.value, index, dataType)
                    break
                  }
                  case "operator":
                    updateFilters("operator", data.value, index)
                    break
                  case "property-value":
                    updateFilters("value", data.value, index)
                    break
                  default:
                    break
                }
              }}
              handleOnDelete={(id) => {
                removeFilter(id)
              }}
              isBIReports={true}
            />
          ))}
        </VStack>
      </VStack>
    </>
  )
}
