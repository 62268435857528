import React from "react"
import Autocomplete from "../../../../../../../../../ReusableComponents/Autocomplete"

export default function EventSelector({
  type,
  value,
  options,
  multiple = false,
  handleOnChange,
  disableClearable = true,
  disableOtherGroups = false,
  ...props
}) {
  return (
    <Autocomplete
      grouped={true}
      multiple={multiple}
      disableClearable={disableClearable}
      disableOtherGroups={disableOtherGroups}
      styles={{ width: 300 }}
      defaultValue={multiple ? value : { label: value, value, type }}
      options={options}
      handleOnChange={(selected) => {
        handleOnChange(multiple ? selected : selected?.value, selected?.type)
      }}
      {...props}
    />
  )
}
